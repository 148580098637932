import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import Checkout from '@components/buy-module-components/checkout/Checkout';
import CheckoutSuccess from '@components/buy-module-components/checkout/CheckoutSuccess';
import LayoutBuy from '@components/LayoutBuy';
// Services
import {buyCommonWashPageServices, getCommonWashPageServices} from '@services/buy-module-services/common-wash-service';
// Reducers
import {setBarcodeString} from '@store/reducers/global-reducer';
import {setPurchaseDate} from '@store/reducers/global-reducer';
import {setSelectedPlan} from '@store/reducers/order-reducer';
import {setServices} from '@store/reducers/prepaid-wash-reducer';
// Utils
import {getNoLoginCheckoutJSON, getPCIDetailsLocal} from '@utils/common-repo';
import SkeletonWashManager from '@utils/skeletons/SkeletonWashManager';
import {currencyFormat} from '@utils/common-helper';
import {overwriteSiteProductColors} from '@utils/config/site-theme-config';
// CSS
import './../PrepaidWashPlans/PrepaidWashPlans.style.css';

const BuyCommonWashServices = (props) => {
    toast.configure();
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    const services = useSelector((state) => state.prepaidWash.services);
    const selectedPlan = useSelector((state) => state.order.selectedPlan);
    const orderJson = useState(getNoLoginCheckoutJSON());
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckoutShow, setIsCheckoutShow] = useState(false);
    const [viewCheckoutSuccess, setViewCheckoutSuccess] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const customerCode = localStorage.getItem('customerCode');
    const buylocationId = localStorage.getItem('buyLocationId');
    const PCIScript = getPCIDetailsLocal().PCIScript;
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);

        const getServies = async () => {
            if (services.length === 0 || services.length == undefined) {
                setIsLoading(true);
                const response = await getCommonWashPageServices(customerCode, '', props.page);
                console.log('washServices', response.data);
                if (response.status === 200) {
                    if (response.data.products.length>0) {
                        overwriteSiteProductColors(response?.data?.products[0].stylings);
                    }
                    dispatch(setServices({services: response.data.products}));
                    dispatch(setPurchaseDate({purchaseDateString: response.data.dtCreated}));
                    setIsLoading(false);
                }
            }
        };

        getServies();
        return () => document.body.removeChild(script);
    }, []);

    const innerServicesList = (data) => {
        if (data) {
            const serviceArray = data.serviceList !== null ? data.serviceList : [];
            return (
                <ul className='ws_extra_services'>
                    {serviceArray.map((value, index) => {
                        return <li key={index}>{value}</li>;
                    })}
                </ul>
            );
        }
    };

    const buyNow = (service) => {
        dispatch(setSelectedPlan({selectedPlan: service}));
        setIsCheckoutShow(true);
        orderJson[0].TaxAmount = 0.00;
        orderJson[0].TopHeaderText = props.pageTitle;
        orderJson[0].SubTotal = service.price;
        orderJson[0].ListItemText = props.pageSubTitle;
        orderJson[0].ListItemDescription = service.description;
        orderJson[0].GrandTotal = Number(orderJson[0].SubTotal) + orderJson[0].TaxAmount;
        orderJson[0].IsShowRecieveEmailReciept = false;
        orderJson[0].IsShowRecieveSMSReciept = false;
        orderJson[0].TermsId = 3;

        if (service.taxIncluded) {
            const taxRate = service.taxRate;
            const subTotal = (service.price * 100) / (100 + taxRate);
            const taxAmount = service.price - subTotal;
            orderJson[0].TaxAmount = taxAmount;
            orderJson[0].SubTotal = subTotal;
            orderJson[0].GrandTotal = Number(orderJson[0].SubTotal) + Number(orderJson[0].TaxAmount);
        } else {
            if (service.taxable) {
                const taxRate = service.taxRate;
                const taxAmount = (service.price * taxRate) / 100;
                orderJson[0].TaxAmount = taxAmount;
                orderJson[0].GrandTotal = Number(orderJson[0].SubTotal) + Number(orderJson[0].TaxAmount);
            } else {
                orderJson[0].GrandTotal = Number(orderJson[0].SubTotal);
            }
        }
    };

    const handleCheckout = async (order, ccDetails) => {
        console.log('ccDetails', ccDetails);
        if (order.IsCheckedTermAndCondition) {
            const postModel = {};
            postModel.productTypeId = selectedPlan.productTypeId;
            postModel.phoneNumber = ccDetails.phoneNumber;
            postModel.temporary_token = ccDetails.temporary_token;
            postModel.unixTime = ccDetails.unixTimeStamp;
            postModel.Span = ccDetails.Span;
            postModel.Bin = ccDetails.sBin;
            postModel.amount = selectedPlan.price;
            postModel.creditCardNumber = '';
            postModel.cardHolderName = '';
            // postModel.expiryDate = ccDetails.xExp;
            // postModel.xExp = ccDetails.xExp;
            postModel.alias = '';
            postModel.LocationId = buylocationId;

            if (gateWayType == 'cardknox') {
                postModel.expiryDate = ccDetails.xExp;
                postModel.xExp = ccDetails.xExp;
            } else {
                postModel.expiryDate = '';
            }
            setPhoneNumber(postModel.phoneNumber);
            const response = await buyCommonWashPageServices(customerCode, '', postModel);

            if (response.status === 200) {
                dispatch(setBarcodeString({barcodeString: response.data.Barcode}));
                dispatch(setPurchaseDate({purchaseDateString: response.data.WalletDate}));
                setViewCheckoutSuccess(true);
                setIsCheckoutShow(false);
            } else if (response.response.status === 400) {
                toast.error(response.response.data, {autoClose: 3000});
                return false;
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
                return false;
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card before checkout.');
            }
            return false;
        }
    };

    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 5; index++) {
            content.push(<SkeletonWashManager key={index} />);
        }
        return content;
    };

    return (
        <LayoutBuy>
            {viewCheckoutSuccess ? <CheckoutSuccess Annomous={true} order = {orderJson} phoneNumber = {phoneNumber}/> : isCheckoutShow ? <Checkout processCheckout={(o, cc) => handleCheckout(o, cc)} hideCheckoutPage={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                setIsCheckoutShow(false);
            }} order={orderJson} /> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <Link to='/buy' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex mb-4 mt-3 justify-content-between align-items-center flex-wrap'>
                                <div className='inner_title d-flex align-items-start'>
                                    <div className='progress-circle over50 p50'>
                                        <span>1 of 2</span>
                                        <div className='left-half-clipper'>
                                            <div className='first50-bar'></div>
                                            <div className='value-bar'></div>
                                        </div>
                                    </div>
                                    <div className='main_title'>
                                        <h2>{props.pageHeading}</h2>
                                        <p>Select a Wash Service</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {isLoading && !services.length ? <div className='new_wash_list'>{contentLoader()}</div> : ''}
                            <div className='new_wash_list'>
                                {services.length > 0 && services.map((service, index) => {
                                    if (service.price !== 0) {
                                        const serviceArray = service.serviceList !== null ? service.serviceList : [];
                                        return (
                                            <div className='col-md-12' id={service.lBookTypeId} key={index}>
                                                <div className='wash_serv_box mb-2'>
                                                    <div className="wsb_wrap">
                                                        {service.sImageUrl != null ?
                                                            <div className='wash_serv_info'>
                                                                <div className='wash_serv_img'>
                                                                    <img src={service.sImageUrl} alt='test-image' />
                                                                </div>
                                                                <div className="wsp_col wash_serv_price"><span className="wc_prices mb-0">{service.price != undefined ? currencyFormat(service.dblBookPrice) : (service.dblBookPrice)}</span></div>
                                                            </div> :
                                                            <div className="wash_serv_info">
                                                                <div className="wsp_col">
                                                                    <h3 className='sTitleColor'>{service.description}</h3>
                                                                    {serviceArray.length > 0 ? <h6>+ {serviceArray.length} more services</h6> : ''}
                                                                </div>
                                                                <div className='wsp_col wash_serv_price'>
                                                                    <span className='wc_prices'>{service.price != undefined ? currencyFormat(service.price) : (service.price)}</span>
                                                                    {serviceArray.length > 0 ?
                                                                        <a className="link-primary" data-bs-toggle="collapse" href={`#collapseWashServInfo${index}`} role="button" aria-expanded={'false'} aria-controls="collapseWashServInfo">
                                                                            <small className='more_info'>more details <FiChevronDown/></small>
                                                                            <small className='hide_info'>hide details <FiChevronUp/></small>
                                                                        </a> : ''
                                                                    }
                                                                </div>
                                                            </div>}
                                                        <div className='wash_serv_btns'>
                                                            <button type='button' className='btn btn-primary' onClick={() => buyNow(service)}>Buy Now!</button>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id={`collapseWashServInfo${index}`}>
                                                        <div className="wsb_extra_info">
                                                            <div><small>Includes {service.sBookDescription} and the follwing:</small></div>
                                                            {innerServicesList(service)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                                {services.length === 0 && isLoading === false ? <p className='notFound alert alert-info'>Services not available.</p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </LayoutBuy>
    );
};

export default BuyCommonWashServices;
