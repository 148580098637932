import {NavLink} from 'react-router-dom';
import React from 'react';
import {useDispatch} from 'react-redux';
// Reducer
import {setPreviousPage} from '@store/reducers/auth-reducer';

const Footer = (props) => {
    const dispatch = useDispatch();
    const customizedData = JSON.parse(localStorage.getItem('customizePage'));

    const d = new Date();
    const currentYear = d.getFullYear();

    const handlePreviousPage = () => {
        dispatch(setPreviousPage({previousPage: '/dashboard'}));
    };

    return (
        <footer className='pt-sm-4 pb-sm-4 pt-2 pb-2 footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='footer_menu'>
                            {customizedData.portalNavMenuList.sort((a, b) => a.iOrderNumber > b.iOrderNumber ? 1 : -1).map((service, index) => {
                                if (service.type === 2 && service.isActive === true) {
                                    if (service.isFeed === true) {
                                        return (
                                            <li className="nav-item" key={service.id}>
                                                <a className="nav-link" href={service.targetUrl} target='#'><span className={`service.icon`}></span>{service.title}</a>
                                            </li>
                                        );
                                    } else {
                                        switch (service.actionName) {
                                        case 'term':
                                            service.actionName = 'terms';
                                            break;
                                        case 'privacy':
                                            service.actionName = 'policies';
                                            break;
                                        case '':
                                            service.actionName = '#';
                                            break;
                                        }
                                        return (
                                            <li className="nav-item sitefooter" key={service.id}>
                                                <NavLink className={({isActive}) => (isActive ? 'nav-link active' : 'nav-link')} aria-current="page" to={`/${service.actionName}`} onClick={() => handlePreviousPage()}><span className={`service.icon`}></span>{service.title}</NavLink>
                                            </li>
                                        );
                                    }
                                }
                            })}
                        </ul>
                    </div>
                    <div className='col-12'>
                        <div className='copy_text text-center'><small>All rights reserved © Copyright 2018-{currentYear}</small></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;

