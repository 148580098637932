import React, {useEffect, useRef} from 'react';
import Barcode from 'react-barcode';
import {FiDownload} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import QRCode from 'react-qr-code';
import {useSelector} from 'react-redux';
// Utils
import {currencyFormat, phoneNumberFormat} from '@utils/common-helper';
import {convertPurcahseUTCDateToLocalDate} from '../../../utils/date-formatter';
import exportAsImage from '@utils/export-as-image';

const CheckoutSuccess = (props) => {
    const utcCurrent = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    const exportRef = useRef();
    const barcodeString = useSelector((state) => state.global.barcodeString);
    const purchaseDateString = useSelector((state) => state.global.purchaseDateString);
    const barcodeStr = barcodeString !== undefined ? barcodeString : 'DemoBarcode';
    const PurchaseDateStr = purchaseDateString !== undefined ? purchaseDateString : utcCurrent;
    const thankYouPara1 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para1');
    const thankYouPara2 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para2');
    const thankYouPara3 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para3');
    const thankYouMedia = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_media');
    const purchaseDateTime = convertPurcahseUTCDateToLocalDate(PurchaseDateStr);
    const giftcardthankYouPara3 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_giftcard_thankyou_para1');
    const orderitem = props?.order[0]?.ListItemText;
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    return (
        <div className='container'>
            <div className="row">
                <div className="col">
                    <div className="main_title mb-3"><h2>Order Confirmation</h2></div>
                </div>
            </div>
            <div className="oc_receipt_wrap">
                <div className='row payment_method_wrap' ref={exportRef}>
                    <div className='col-lg-6 py-4'>
                        <div className='barcode_img mx-auto text-center'>
                            <Barcode value={barcodeStr} width={2} height={60} />
                        </div>
                        <div className='barcode_img mx-auto text-center mt-4'>
                            <QRCode value={barcodeStr} size={200} />
                        </div>
                        <div className='barcode_number text-center p-4'>
                            <small>Your Redeem Code:</small>
                            <h3 className='mt-2'>{barcodeStr}</h3>
                        </div>
                    </div>
                    <div className="col-lg-6 py-4">
                        <div className="oc_bill_detail">
                            <div className="oc_bill_detail_inner">
                                <ul className='oc_bill_pro_details'>
                                    <li>
                                        <span>Product Name:</span>
                                        <h3>{props?.order[0]?.ListItemDescription}</h3>
                                    </li>
                                    <li>
                                        <span>Purchase Date:</span>
                                        <h3>{purchaseDateTime}</h3>
                                    </li>
                                </ul>
                                <ul className={`oc_bill_user_details`}>
                                    <li>
                                        <span>Phone:</span>
                                        <h3>{phoneNumberFormat(props?.phoneNumber?.replace(/([() -])+/g, ''))}</h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="bill_total">
                                <div className="main_total"><span>Subtotal:</span><span>{props.order[0] && currencyFormat(props.order[0].SubTotal)}</span></div>
                                <div className="main_total"><span>Discount:</span><span>{props.order[0] && currencyFormat(props.order[0].DiscountAmount)}</span></div>
                                <div className="main_total"><span>Tax:</span><span>{props.order[0] && currencyFormat(props.order[0].TaxAmount)}</span></div>
                            </div>
                            <div className="main_total"><span><b>Total:</b></span><span><b>{props.order[0] && currencyFormat(props.order[0].GrandTotal)}</b></span></div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-lg-12 py-4">
                        <div className='btn_grps d-flex flex-wrap justify-content-center'>
                            <button className='btn btn-outline-secondary me-3' onClick={() => exportAsImage(exportRef.current, barcodeStr)}><FiDownload className='me-2' />Download</button>
                            {props.Annomous ?
                                <Link className='btn btn-primary' to="/buy">Back to Home</Link> :
                                <Link className='btn btn-primary' to='/'>Back to Home</Link>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                        {thankYouMedia?.sValue != undefined && thankYouMedia?.sValue != '' && thankYouMedia?.sValue != null ?
                            <div className='video_promo d-flex justify-content-center mb-4'>
                                <iframe width='560' height='315' src={thankYouMedia?.sValue} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                            </div> : ''}
                        <div className='barcode_msg pt-4 pb-3 mt-3 mb-3 border-top text-center'>
                            {orderitem != 'Gift Card' ? <p className='thankyou_p'>{thankYouPara1?.sValue != undefined ? thankYouPara1?.sValue : ''}</p> : <p className='thankyou_p'>Your gift card has been purchased</p>}
                            {orderitem != 'Gift Card' ? <p>{thankYouPara2?.sValue != undefined ? thankYouPara2?.sValue : ''}</p> : <p> {giftcardthankYouPara3?.sValue != undefined ? giftcardthankYouPara3?.sValue : ''} </p>}
                            {orderitem != 'Gift Card' ? <p>{thankYouPara3?.sValue != undefined ? thankYouPara3?.sValue : ''}</p> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSuccess;
