import React from 'react';
import {useSelector} from 'react-redux';
// Components
import Footer from './layout/BuyFooter';
import Header from './layout/Header';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';

const Layout = (props) => {
    const isLoading = useSelector((state) => state.global.isLoading);
    return (
        <React.Fragment>
            {isLoading ? <FullScreenLoader /> : ''}
            <Header />
            <div className='main_wrap pt-4'>{props.children}</div>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
