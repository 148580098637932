// Services
import {detailingServices, washPlans, washServices} from '@services/wash-service';
import {giftCardList} from '@services/gift-card-service';
import {location} from '@services/location-service';
import {orderHistory} from '@services/wallet-service';
import {seqLogger} from '@services/seq-logger-service';
import {wallet} from '@services/wallet-service';
// Reducers
import {setDetailingServices, setPrepaidWashPlans, setServices} from '@store/reducers/prepaid-wash-reducer';
import {setGiftCards} from '@store/reducers/gift-card-reducer';
import {setLocations} from '@store/reducers/auth-reducer';
import {setServices as setLocationsInfo} from '@store/reducers/location-reducer';
import {setOrderHistory} from '@store/reducers/wallet-reducer';
import {setwalletServices} from '@store/reducers/wallet-reducer';
// Utils
import {seqReqBodyObj} from './common-helper';

const LoadApis = {
    prepaidWashPlans: async (accessToken, locationId, dispatch, phoneNumber) => {
        const response = await washPlans(accessToken, locationId);
        if (response.status === 200) {
            setTimeout(() => {
                dispatch(setPrepaidWashPlans({prepaidWashPlans: response.data}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `All Prepaid Wash Plan retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    washServices: async (accessToken, locationId, dispatch, phoneNumber) => {
        const response = await washServices(accessToken, locationId);
        if (response.status === 200) {
            setTimeout(() => {
                dispatch(setServices({services: response.data}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `All Wash Services retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    detailingServices: async (accessToken, locationId, dispatch, phoneNumber) => {
        const response = await detailingServices(accessToken, locationId);
        if (response.status === 200) {
            setTimeout(() => {
                dispatch(setDetailingServices({detailingServices: response.data}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `All Detailing services retrieve successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    wallet: async (userInfo, dispatch, phoneNumber) => {
        const response = await wallet(userInfo.accessToken, userInfo.customerIdentityID);
        if (response.status === 200) {
            setTimeout(() => {
                const shortWalletData = response.data.sort((a, b) => new Date(b.walletDate) - new Date(a.walletDate));
                dispatch(setwalletServices({services: shortWalletData}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `Wallet details retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    orderHistory: async (userInfo, dispatch, phoneNumber) => {
        const response = await orderHistory(userInfo.accessToken, userInfo.customerIdentityID);
        if (response.status === 200) {
            setTimeout(() => {
                const shortWalletData = response.data.sort((a, b) => new Date(b.walletDate) - new Date(a.walletDate));
                dispatch(setOrderHistory({orderHistory: shortWalletData}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `Order history retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    locations: async (accessToken, dispatch, phoneNumber) => {
        const response = await location(accessToken);
        if (response.status === 200) {
            localStorage.setItem('locations', JSON.stringify(response.data));
            dispatch(setLocations({locations: response.data}));
            setTimeout(() => {
                dispatch(setLocationsInfo({services: response.data}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `All Location retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    },
    giftCards: async (userInfo, dispatch, phoneNumber) => {
        const response = await giftCardList(userInfo.accessToken, userInfo.customerIdentityID);
        if (response.status === 200) {
            setTimeout(() => {
                dispatch(setGiftCards({giftCards: response.data}));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `All gift card retrieved successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        } else if (response.status == 400 ) {
            const seqReqBody = seqReqBodyObj('Worning', `No Cards Found`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        }
    }
};

export default LoadApis;
