import API from './../api';

export const getCommonWashPageServices = async (customerCode, locationId, pageName) => {
    let url= '';
    if (pageName== 'wash_service') {
        url= 'QuickBuy/GetWashPlans';
    } else if (pageName== 'detail_service') {
        url= 'QuickBuy/GetDetailServices';
    } else if (pageName== 'prepaid_service') {
        url= 'QuickBuy/GetWashServices';
    }

    try {
        return await API.get(url, {
            headers: {
                'X-Customer-Code': customerCode,
                'X-Location-Id': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        console.clear();
        return error;
    }
};

export const buyCommonWashPageServices = async (customerCode, locationId, reqBody) => {
    try {
        return await API.post('QuickBuy/QuickBuyPrepaidBookWashService', reqBody, {
            headers: {
                'X-Customer-Code': customerCode,
                'X-Location-Id': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
