import React, {useEffect, useState} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {toast} from 'react-toastify';
// import {useParams} from 'react-router-dom';
// Services
import {walletBarcodeDetail} from '@services/wallet-service';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import dateFormatter from '@utils/date-formatter';

const TinyWalletBarcode = (props) => {
    toast.configure();
    const [walletDetail, setWalletDetail] = useState([]);
    // const urlParam = useParams();
    const url = window.location.pathname;
    const indexOfDetails = url.indexOf('Details');
    const id = url.slice(indexOfDetails + 'Details'.length + 1);
    const [Loader, setLoader] = useState(false);

    const handleWalletBarcodeDetail = async () => {
        setLoader(true);
        const walletId= id;
        const response = await walletBarcodeDetail(walletId);
        if (response.status === 200) {
            setLoader(false);
            setWalletDetail(response.data);
        } else {
            setLoader(false);
        }
    };
    useEffect(() => {
        handleWalletBarcodeDetail();
    }, []);

    const barcodeString = walletDetail.barcode;
    const barcodeStr = barcodeString !== '' && barcodeString !== undefined ? barcodeString : 'Used';

    return (
        <div className='vh-100'>
            {Loader === true ? <FullScreenLoader /> :
                <div className='container'>
                    <div className='row payment_method_wrap'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='barcode_header mb-4 mt-5 justify-content-center'>
                                <div className='text-center'>
                                    <h6>Product Name</h6>
                                    <p><b>{walletDetail.description ?? ''}</b>
                                    </p>
                                    <h6>Purchase Date</h6>
                                    <p><b>{walletDetail?.walletDate ? dateFormatter(convertUTCDateToLocalDate(walletDetail.walletDate)):''}</b></p>
                                </div>
                            </div>
                            <div className='barcode_img mx-auto text-center'>
                                {walletDetail.active ? '' :
                                    <div className='text-center redeem_text'>
                                        {walletDetail.active ? '' : 'Redeemed'}
                                    </div>
                                }
                                <div className={walletDetail.active ? '' : 'opacity-25'}>
                                    <Barcode value={barcodeStr} width={2} height={60} />
                                </div>
                            </div>
                            <div className='barcode_msg pt-4 pb-3 mt-3 mb-3 border-top text-center'>
                                <p>Make sure to show this code to the attendant or, scan the bar code in the machine.</p>
                            </div>
                            <div className='barcode_img mx-auto text-center mt-4'>
                                {walletDetail.active ? '' :
                                    <div className='text-center redeem_text'>
                                        {walletDetail.active ? '' : 'Redeemed'}
                                    </div>
                                }
                                <div className={walletDetail.active ? '' : 'opacity-25'}>
                                    <QRCode value={barcodeStr} size={200} />
                                </div>
                            </div>
                            {walletDetail.active ?
                                <div className='barcode_number text-center p-4'>
                                    <small>Your Redeem Code:</small>
                                    <h3 className='mt-2'>{barcodeStr}</h3>
                                </div>:''
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default TinyWalletBarcode;
