import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
// Service
import {employeeLoginApi} from '@services/employee-module-services/employee-auth-service';
// Reducers
import {setUserInfo} from '@store/reducers/auth-reducer';
// Utils
import {fetchPCIDetailsFromServer, getPCIDetailsLocal} from '@utils/common-repo';
import {initFormik} from '@utils/formik-helper';
// Validation Schemas
import employeeLoginSchema from '@validation-schemas/employee-login-schema';

const EmployeeLogin = (props) =>{
    const [loading, setLoading] = useState(false);
    const toastId = React.useRef(null);

    toast.configure();
    const dispatch = useDispatch();
    const handleSubmit = async (values, resetForm)=>{
        setLoading(true);
        const reqBody = {
            userName: values.userId,
            password: values.password,
            customerCode: values.customerCode
        };
        const response = await employeeLoginApi(reqBody);
        if (response.status === 200) {
            if (response.data.accessToken !== null) {
                setLoading(false);
                const userData = response.data;
                userData['expiryTime'] = new Date(userData.expires).getTime();
                dispatch(setUserInfo({userInfo: userData}));
                localStorage.setItem('userInfo', JSON.stringify(userData));
                localStorage.setItem('customerCode', response.data.customerCode);
                localStorage.setItem('employeeAccessToken', userData.accessToken);
                localStorage.setItem('userData', JSON.stringify(response.data));
                await fetchPCIDetailsFromServer();

                const PCIScript= getPCIDetailsLocal().PCIScript;
                const script = document.createElement('script');
                script.src = PCIScript;
                script.async = false;
                document.body.appendChild(script);
                document.getElementById('nextS2Submit').click();
            } else {
                if (!toast.isActive(toastId.current)) {
                    toast.error('You have entered invalid credentials. Please try again.', {autoClose: 3000, toastId: 'falied'});
                }
                setLoading(false);
                resetForm();
            }
        } else {
            toast.error('You have entered invalid credentials. Please try again.', {autoClose: 3000});
            setLoading(false);
        }
    };
    const initialValues = {
        userId: '',
        password: '',
        customerCode: ''
    };
    const formik = initFormik(initialValues, employeeLoginSchema, handleSubmit, true);
    return (
        <React.Fragment>
            <div className='col-12'>
                <div className='form_wrap step_one show animate_next' id='stepOne'>
                    <div className='auth_form_sec'>
                        <div className='form_title'>
                            <h2>Employee Login</h2>
                        </div>
                        <form className='auth_form' onSubmit={formik.handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='userID' className='form-label'>User ID<sup className='text-danger'>*</sup></label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    id='userID'
                                    placeholder=''
                                    name='userId'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.userId}
                                    disabled={loading}
                                />
                                {formik.touched.userId && formik.errors.userId ? (
                                    <small className="text-danger validationError">{formik.errors.userId}</small>
                                ) : null}
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='password' className='form-label'>Password<sup className='text-danger'>*</sup></label>
                                <input
                                    type='password'
                                    className='form-control form-control-lg'
                                    id='password'
                                    placeholder=''
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    disabled={loading}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <small className="text-danger validationError">{formik.errors.password}</small>
                                ) : null}
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='customerCode' className='form-label'>Customer Code<sup className='text-danger'>*</sup></label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    id='customerCode'
                                    placeholder=''
                                    name='customerCode'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customerCode}
                                    disabled={loading}
                                />
                                {formik.touched.customerCode && formik.errors.customerCode ? (
                                    <small className="text-danger validationError">{formik.errors.customerCode}</small>
                                ) : null}
                            </div>
                            <button type="submit" className="main_btn" disabled={loading || formik.values.userId === '' || formik.values.password === '' || formik.values.customerCode ===''} id="nextS2">
                                {loading && <span className="spinner-border text-light btnLoader me-2"></span>}<span>Continue</span>
                            </button>
                            <button type="button" className="main_btn d-none" id="nextS2Submit" onClick={props.handleOnClick}>
                                Continue
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeLogin;
