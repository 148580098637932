import {FiEdit2, FiMail, FiSmartphone, FiUser} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
// import InputMask from 'react-input-mask';
import React from 'react';
// Reducers
import {setFormOtherData} from '@store/reducers/auth-reducer';
// Utils
import {initFormik} from '@utils/formik-helper';
// Validation Schemas
import {useRef} from 'react';
import {useState} from 'react';
import washCardSchema from '@validation-schemas/wash-card-schema';

const OtherFormModal = (props) => {
    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        // phone,
        setPhoneNumber,
        email,
        setEmail,
        message,
        setMessage,
        showForOthersPopup,
        setShowForOthersPopup
    } = props;

    const dispatch = useDispatch();
    const commonTabs = useSelector((state) => state.auth.pageCommonTab);
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const inputCard = useRef();
    const [phonError, setPhoneError] = useState(true);
    const [err, setErr] = useState('');
    const [card, setCard] = useState();

    const forOtherTabJson = {
        FirstName: commonTabs.forOther.FirstName,
        LastName: commonTabs.forOther.LastName,
        PhoneNumber: commonTabs.forOther.PhoneNumber,
        RecipientEmail: commonTabs.forOther.RecipientEmail,
        Message: commonTabs.forOther.Message
    };
    const handleForOtherInputChange = (event, seqNo) => {
        if (seqNo == 1) {
            forOtherTabJson.FirstName = event.target.value;
        } else if (seqNo == 2) {
            forOtherTabJson.LastName = event.target.value;
        } else if (seqNo == 3) {
            forOtherTabJson.PhoneNumber = card;
        } else if (seqNo == 4) {
            forOtherTabJson.RecipientEmail = event.target.value;
        } else {
            forOtherTabJson.Message = event.target.value;
        }
        dispatch(setFormOtherData({forOther: forOtherTabJson}));
    };
    const handleSubmit = () => {
        props.setShowAddServicePopup&&props.setShowAddServicePopup(false);
        props.setIsCheckoutShow&&props.setIsCheckoutShow(true);
        setShowForOthersPopup(false);
    };
    const initialValues = {
        firstname: commonTabs.forOther.FirstName,
        lastname: commonTabs.forOther.LastName,
        email: commonTabs.forOther.RecipientEmail,
        phone: commonTabs.forOther.PhoneNumber,
        message: commonTabs.forOther.Message
    };
    const formik = initFormik(initialValues, washCardSchema, handleSubmit);

    const phoneMask = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputCard.current.value = !cardValue[2] ?
            cardValue[1] :
            `(${cardValue[1]}) ${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        if (inputCard.current.value.length == 0) {
            setErr('Cell Phone is required.');
            setPhoneError(true);
        } else if (!phoneRegExp.test(inputCard.current.value)) {
            setErr('Cell Phone  Number is not valid.');
            setPhoneError(true);
        } else {
            setErr('');
            setPhoneError(false);
        }
        setCard(numbers);
    };
    return (
        <div className='container'>
            <div className='row'>
                <div className={`modal fade cw_modal add_info_pop ${showForOthersPopup ? 'show d-block' : '' }`} id='updatePersonalInfo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='updatePersonalInfoLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered '>
                        <div className='modal-content personalinfo  p-4'>
                            <form action='#' onSubmit={formik.handleSubmit}>
                                <div className='row g-3'>
                                    <div className='col-12'><h5 className='mb-4'>Recipient Information</h5></div>
                                    <div className='col-12 col-sm-6 col-md-6'>
                                        <div className='input_wrap form-floating'>
                                            <input type='text' id='fName' className='form-control form-control-lg mb-1' placeholder='First Name'
                                                name="firstname"
                                                onChange={(event) => {
                                                    formik.handleChange(event);
                                                    handleForOtherInputChange(event, 1);
                                                    setFirstName(event.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={firstName}
                                            />
                                            <label htmlFor='fName' className='form-label'><FiUser/> First Name <sup className='text-danger'>*</sup></label>
                                            {formik.touched.firstname && formik.errors.firstname ? (
                                                <small className="text-danger">{formik.errors.firstname}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-6'>
                                        <div className='input_wrap form-floating'>
                                            <input type='text' id='lName' className='form-control form-control-lg mb-1' placeholder='Last Name'
                                                name="lastname"
                                                onChange={(event) => {
                                                    formik.handleChange(event), handleForOtherInputChange(event, 2);
                                                    setLastName(event.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={lastName}
                                            />
                                            <label htmlFor='lName' className='form-label'><FiUser/> Last Name <sup className='text-danger'>*</sup></label>
                                            {formik.touched.lastname && formik.errors.lastname ? (
                                                <small className="text-danger">{formik.errors.lastname}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-6'>
                                        <div className='input_wrap form-floating'>
                                            {/* <InputMask
                                                className="form-control form-control-lg mb-1"
                                                onChange={(event) => {
                                                    formik.handleChange(event), handleForOtherInputChange(event, 3);
                                                    setPhoneNumber(event.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                mask="(999) 999-9999"
                                                maskChar=""
                                                value={phone}
                                                name="phone"
                                                placeholder="(000) 000-0000"
                                            /> */}
                                            <input type="text" className="form-control form-control-lg"
                                                name="phone"
                                                // onChange={phoneMask}
                                                onChange={(event) => {
                                                    handleForOtherInputChange(event, 3);
                                                    setPhoneNumber(event.target.value);
                                                    phoneMask();
                                                }}
                                                ref={inputCard}
                                                placeholder="(000) 000-0000"
                                            />
                                            <label htmlFor='phoneNumber' className='form-label'><FiSmartphone/>Cell Phone <sup className='text-danger'>*</sup></label>
                                            <small className="text-danger validationError">{err}</small>
                                            {/* {formik.touched.phone && formik.errors.phone ? (
                                                <small className="text-danger">{formik.errors.phone}</small>
                                            ) : null} */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-6'>
                                        <div className='input_wrap form-floating'>
                                            <input type='email' id='repEmail' className='form-control form-control-lg mb-1' placeholder='Email'
                                                name="email"
                                                onChange={(event) => {
                                                    formik.handleChange(event), handleForOtherInputChange(event, 4);
                                                    setEmail(event.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={email}
                                            />
                                            <label htmlFor='repEmail' className='form-label'><FiMail/> Recipient Email <sup className='text-danger'>*</sup></label>
                                            {formik.touched.email && formik.errors.email ? (
                                                <small className="text-danger">{formik.errors.email}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='input_wrap your_message form-floating'>
                                            <textarea type='text' name='message' rows="8" cols="50" id='message' className='form-control form-control-lg mb-1' placeholder='Message'
                                                value={message}
                                                onBlur={formik.handleBlur}
                                                onChange={(event) => {
                                                    formik.handleChange(event), handleForOtherInputChange(event, 5);
                                                    setMessage(event.target.value);
                                                }}/>
                                            <label htmlFor='message' className='form-label'><FiEdit2/> Your Message <sup className='text-danger'>*</sup></label>
                                            {formik.touched.message && formik.errors.message ? (
                                                <small className="text-danger">{formik.errors.message}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='modal-footer justify-content-center'>
                                        <button type='submit' className='btn btn-primary'disabled = {!formik.isValid || phonError} id="btnAlertYes"> <span id="spanLoader" className="spinner-border text-light btnLoader" style={{display: 'none'}} />Continue</button>
                                        <button type='button' className='btn btn-secondary' onClick={() => {
                                            setShowForOthersPopup(false);
                                            props.setShowAddServicePopup&&props.setShowAddServicePopup(false);
                                        }} data-bs-dismiss='modal'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {showForOthersPopup ?
                    <div className="modal-backdrop fade show"></div> : null}
            </div>
        </div>
    );
};

export default OtherFormModal;
