import {createSlice} from '@reduxjs/toolkit';

const buyPolicyServicesSlice = createSlice({
    name: 'buypolicyservices',
    initialState: {
        selectedpolicy: {}
    },
    reducers: {
        setSelectedPolicy: (state, action) => {
            state.selectedPolicy = action.payload.selectedPolicy;
        }
    }
});

export const {setSelectedPolicy} = buyPolicyServicesSlice.actions;
export default buyPolicyServicesSlice.reducer;
