import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import {initializeCardknoxPCI} from './cardknox-payment';
import moment from 'moment';
import {useEffect} from 'react';


// import '../../../assets/css/cardknox.css';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

const CardknoxPaymentCard = (props) => {
    toast.configure();
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [expiry, setExpiry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [isCreditCardValid, setIsCreditCardValid] = useState(false);
    const [isCvvValid, setIsCvvValid] = useState(false);
    const [isExpValid, setIsExpValid] = useState(false);
    const [isZipCodeValid, setIsZipCodeValid] = useState(false);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const preferedHomeLocation = useSelector((state) => state.auth.userInfo.preferedHomeLocation);

    const handleChangeBillingZipCode = async (e) => {
        const billingZipCode = document.getElementById('zipCodeCardknox');
        const zipCodeRegex = /^[0-9]+$/;
        setZipCode(e.target.value);
        if (e.target.value === '' || !zipCodeRegex.test(e.target.value) || e.target.value.length < 5 || e.target.value.length >= 6) {
            billingZipCode.style.border = '0.5px solid #FF0000';
            setIsZipCodeValid(false);
        } else {
            billingZipCode.removeAttribute('style');
            setIsZipCodeValid(true);
        }
    };

    const handleChangeMobileNo = (e) => {
        const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        const txtMobileNo= document.getElementById('txtMobileNoCardknox');
        if (!regex.test(e.target.value)) {
            txtMobileNo.style.border = '0.5px solid #FF0000';
        } else {
            txtMobileNo.removeAttribute('style');
        }
    };
    const isFutureDate = (dateString) => {
        const currentDate = moment();
        const selectedDate = moment(dateString, 'MM/YY');

        return selectedDate.isValid() && selectedDate.isAfter(currentDate, 'year');
    };
    const handleExpiryChange = (e) => {
        const expDate = document.getElementById('expDate');
        const input = e.target.value;
        // Format the input to MM/YY or any desired format
        const formattedExpiry = input
            .replace(/\D/g, '') // Remove non-numeric characters
            .slice(0, 4) // Limit to 4 characters (MMYY)
            .replace(/(\d{2})(\d{0,2})/, '$1/$2'); // Add a slash between MM and YY
        if (isFutureDate(formattedExpiry)) {
            setIsExpValid(true);
            expDate.removeAttribute('style');
        } else {
            setIsExpValid(false);
            expDate.style.border = '0.5px solid #FF0000';
        };
        setExpiry(formattedExpiry);
        console.log('exp', formattedExpiry.replace('/', ''));
    };
    if (props.noLogged == undefined) {
        useEffect(()=>{
            initializeCardknoxPCI(userInfo, preferedHomeLocation, props, setIsCreditCardValid, setIsCvvValid, setExpiry, setZipCode, toast, toastId, dispatch, setIsExpValid, setIsZipCodeValid);
        }, []);
    } else { // for annomous
        useEffect(()=>{
            initializeCardknoxPCI(null, null, props, setIsCreditCardValid, setIsCvvValid, setExpiry, setZipCode, toast, toastId, null);
        }, []);
    }

    return (
        <div>
            <form id="payment-form-cardknox">
                <h5 className='mb-3'>{(props.noLogged == undefined) ? 'Add Credit Card' : 'Enter card details'}</h5>
                <div className="row">
                    { (props.noLogged != undefined) ? <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="phone"><small>Phone<sup className="text-danger">*</sup></small></label>
                            <InputMask
                                id="txtMobileNoCardknox"
                                className="form-control form-control-lg"
                                onChange={(e) => handleChangeMobileNo(e)}
                                mask="(999) 999-9999"
                                maskChar=" "
                                onKeyPress={(e) => handleChangeMobileNo(e)}
                                name="phone"
                                placeholder="(000) 000-0000"
                            />
                        </div>
                    </div> : '' }
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Card Holder Name</small></label>
                            <div>
                                <input className="form-control form-control-lg" id="name" name="xName" placeholder="Name" autoComplete="cc-name"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-none">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>ACH</small></label>
                            <div>
                                <iframe data-ifields-id="ach" data-ifields-placeholder="Checking Account Number" src="https://cdn.cardknox.com/ifields/2.6.2006.0102/ifield.htm"></iframe>
                                <input data-ifields-id="ach-token" name="xACH" type="hidden"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Credit Card Number<sup className='text-danger'> *</sup></small></label>
                            <div>
                                <iframe data-ifields-id="card-number" data-ifields-placeholder="Card Number" src="https://cdn.cardknox.com/ifields/2.6.2006.0102/ifield.htm"></iframe>
                                <input data-ifields-id="card-number-token" name="xCardNum" type="hidden"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Billing Zip Code<sup className='text-danger'> *</sup></small></label>
                            <input placeholder='Billing Zip Code' onChange={(e) => handleChangeBillingZipCode(e)} value={zipCode} className="form-control form-control-lg" id="zipCodeCardknox" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>CVV<sup className='text-danger'> *</sup></small></label>
                            <div>
                                <iframe data-ifields-id="cvv" data-ifields-placeholder="CVV" src="https://cdn.cardknox.com/ifields/2.6.2006.0102/ifield.htm"></iframe>
                                <input data-ifields-id="cvv-token" name="xCVV" type="hidden"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-none">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Amount</small></label>
                            <div>
                                <input id="amount" type='hidden' name="xAmount" placeholder="Amount"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-none">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Month</small></label>
                            <div>
                                <input id="month" name="xMonth" placeholder="Month" autoComplete="cc-exp-month"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-none">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Year</small></label>
                            <div>
                                <input id="year" name="xYear" placeholder="Year" autoComplete="cc-exp-year"></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Expiration (MM/YY)</small></label>
                            <div>
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    id="expDate"
                                    value={expiry}
                                    onChange={handleExpiryChange}
                                    placeholder="MM/YY"
                                    maxLength="5"
                                />
                                {/* <input id="expYear" placeholder="MM/YY" pattern="\d{2}/\d{2}"></input> */}
                            </div>
                        </div>
                    </div>
                    {props.noLogged == undefined?
                        <button className='btn btn-primary w-100 mt-3' id="submit-btn" type="submit" disabled={!isCreditCardValid || !isCvvValid || !isZipCodeValid || !isExpValid}>Save Card</button>:
                        <button className='invisible' id="submit-btn" type="submit" disabled={!isCreditCardValid || !isCvvValid || !isZipCodeValid || !isExpValid}></button>
                    }
                    <input id="clear-btn" type="hidden" value="Clear"></input>
                    <label id="transaction-status"></label>
                    <label data-ifields-id="card-data-error"></label>
                </div>
            </form>
        </div>
    );
};

export default CardknoxPaymentCard;
