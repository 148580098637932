import React from 'react';
import errImg from '@assets/images/errorPage.png';
import {useLocation} from 'react-router-dom';

const ErrorPage = (props) => {
    const {state} = useLocation();
    // const customerUrl = localStorage.getItem('customerUrl');
    return (
        <div className='missing_page h-100 d-flex justify-content-center align-items-center text-center'>
            <div className='fourofour_wrap'>
                <img src={errImg} className='img-fluid mb-4' alt='404'/>
                <h5>Page Not Found.</h5>
                {state.message == '0' ?
                    <>
                        <p>CPV3 is not configured for you.</p>
                        {/* <p>You can use CPV2 <a className='link-primary' href={`https://Sitcp.washassist.com/${customerUrl}`} target="_blank" rel="noopener noreferrer">Click here</a></p> */}
                    </> :
                    <p>Please check your URL and try again.</p>
                }
            </div>
        </div>
    );
};
export default ErrorPage;
