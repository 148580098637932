/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {FiArrowLeft} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {currencyFormat} from '@utils/common-helper';
// Components
import CardknoxPaymentCard from '../../order/PCI/CardknoxPaymentCard';
import DataCapPaymentCard from '../../order/PCI/DataCapPaymentCard';
import PaymentCard from '../../order/PCI/PaymentCard';
import TermsModal from '../../modals/TermsModal';

export default function Checkout(props) {
    toast.configure();
    const [modal, setModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [newAddedCard, setNewAddedCard] = useState(-1);
    const [order, setOrder]=useState(props.order[0]);
    const [Loader, setLoader] = useState(false);

    const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');


    const applyPromotionCode = async () => {
        order.IsPromocodeApplied = true;
        setOrder(order);
        const response = await getPromotionAvailable(userInfo.accessToken);
        if (response.status === 200) {
            if (response.data[0].discountAmount > 0) {
                order.DiscountAmount= response.data[0].discountAmount;
                order.GrandTotal= order.SubTotal - order.DiscountAmount;
                setOrder(order);
            } else {
                toast.warning('Discount not available on this code.', {autoClose: 3000});
            }
        } else {
            toast.error('Something went wrong.', {autoClose: 3000});
        }
    };

    const handleChangeTerm = (e) => {
        order.IsCheckedTermAndCondition = e.target.checked;
        setOrder(order);
    };

    const setPromocode = (code) => {
        order.Promocode = code;
        setOrder(order);
    };

    const callbackOnPreviousPage = () => {
        props.hideCheckoutPage();
    };

    const processCheckout = async (ccDetails) => {
        setLoader(true);
        const isLoading= await props.processCheckout(order, ccDetails);
        setLoader(isLoading);
    };
    const handleCarknoxCheckout = () => {
        document.getElementById('submit-btn').click();
    };
    const handleDataCapCheckout = () => {
        document.getElementById('pay_button').click();
    };
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    const [card, setCard] = useState('');
    const restDataCapForm = () => {
        setCard('');
        // document.querySelector('[data-token="card_number"]').value = '';
        document.querySelector('[data-token="exp_month"]').value = '';
        document.querySelector('[data-token="exp_year"]').value = '';
        document.querySelector('[data-token="cvv"]').value = '';
        document.getElementById('cardHolderName').value = '';
        document.getElementById('billingZipCodeDataCap').value = '';
    };
    return (
        <div>
            {modal ? <TermsModal id={order.termsId} closeModal={() => setModal(false)} /> : ''}
            {Loader === true ? <FullScreenLoader /> : ''}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap border-bottom">
                            <div className="inner_title d-flex align-items-start">
                                <div className="progress-circle over50 p100">
                                    <span>2 of 2</span>
                                    <div className="left-half-clipper">
                                        <div className="first50-bar"></div>
                                        <div className="value-bar"></div>
                                    </div>
                                </div>
                                <div className="main_title">
                                    <h2>{order.TopHeaderText}</h2>
                                    <p>Order Summary</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row payment_method_wrap">
                    {gateWayType == 'openEdge' &&
                        <div className="col-lg-8">
                            <div className="pay_method_list">
                                <div className="new_card_add">
                                    <div className="add_cc_form" id="add_new_card_from">
                                        <PaymentCard checkout={(data) => processCheckout(data)} loader={(val) => setLoader(val)} noLogged={true} setCard={(id) => setNewAddedCard(id)} hidePop={() => setViewCardForm(false)} />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {gateWayType == 'cardknox' &&
                        <div className="col-lg-8">
                            <div className="pay_method_list">
                                <div className="new_card_add">
                                    <div className="add_cc_form" id="add_new_card_from">
                                        <CardknoxPaymentCard checkout={(data) => processCheckout(data)} loader={(val) => setLoader(val)} setCard={(card) => setNewAddedCard(card)} noLogged={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {gateWayType == 'dataCap' &&
                    <div className="col-lg-8">
                        <div className="pay_method_list">
                            <div className="new_card_add">
                                <div className="add_cc_form" id="add_new_card_from">
                                    <DataCapPaymentCard cardInfo={card} setCardInfo={setCard} restDataCapForm={restDataCapForm} checkout={(data) => processCheckout(data)} loader={(val) => setLoader(val)} noLogged={true}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="col-lg-4">
                        <div className="order_summ_wrap">
                            <h5>Order Summary</h5>
                            <div className="summary_card">
                                <ul className="list_items os_user_plan" style={{minHeight: 'auto'}}>
                                    <li>
                                        <span>{order.ListItemText}:</span> <b>{order.ListItemDescription}</b>
                                    </li>
                                </ul>

                                {order.IsShowPromocodeDiv ?
                                    <div className='promo_form'>
                                        <div className='form-group d-flex mt-3 mb-3'>
                                            <input type='text' className='form-control promo_code me-2 form-control-lg  id="myInput' placeholder='Enter Promotion Code' value={order.Promocode} onChange={(event) => setPromocode(event.target.value)} />
                                            <button type='button' className='main_btn' onClick={() => applyPromotionCode()}>Apply</button>
                                        </div>
                                        {order.Promocode === '' && order.IsPromocodeApplied ? <p className='text-danger'>Please Enter Promotion Code</p> : ''}
                                    </div> : ''}

                                <ul className="list_items order_details">
                                    <li>
                                        <span>Subtotal:</span> <span>{order.SubTotal != undefined ? currencyFormat(order.SubTotal) : parseFloat(order.SubTotal)}</span>
                                    </li>
                                    <li>
                                        <span>Discount:</span> <span>{order.DiscountAmount != undefined ? currencyFormat(order.DiscountAmount) : parseFloat(order.DiscountAmount)}</span>
                                    </li>
                                    <li>
                                        <span>Tax:</span> <span>{order.TaxAmount != undefined ? currencyFormat(order.TaxAmount) : parseFloat(order.TaxAmount)}</span>
                                    </li>
                                    <li>
                                        <b>Total:</b> <b>{order.GrandTotal != undefined ? currencyFormat(order.GrandTotal) : (order.GrandTotal)}</b>
                                    </li>
                                </ul>
                            </div>
                            <div className='condi_checks pt-4'>
                                {order.IsShowRecieveEmailReciept ?
                                    <div className='form-check'>
                                        <input className='form-check-input check_pointer' type='checkbox' id='rEmail' onChange={(event) => {
                                            order.IsRecieveEmailReciept= event.target.checked; setOrder(order);
                                        }} />
                                        <label className='form-check-label' htmlFor='rEmail'>
                                            Receive email receipt
                                        </label>
                                    </div> : '' }
                                {order.IsShowRecieveSMSReciept ?
                                    <div className='form-check'>
                                        <input className='form-check-input check_pointer' type='checkbox' id='rSMS' onChange={(event) => {
                                            order.IsRecieveSMSReciept=event.target.checked; setOrder(order);
                                        }} />
                                        <label className='form-check-label' htmlFor='rSMS'>
                                            Receive SMS receipt
                                        </label>
                                    </div> : '' }
                                <div className='form-check'>
                                    <input className='form-check-input check_pointer' type='checkbox' id='termcondi' {...(order.IsCheckedTermAndCondition ? 'checked' : '')} onChange={(event) => handleChangeTerm(event)}/>
                                    <label className='form-check-label' htmlFor='termcondi'>
                                        I Agree to the <Link to='#' className='termandcondition_link' onClick={() => setModal(true)}>Terms and Conditions*</Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='d-flex justify-content-end align-items-center mt-3 pt-3 border-top'>
                            <div className='btn_grps d-flex flex-wrap align-items-center'>
                                <div className='mt-2'>
                                    <button className='btn btn-secondary me-2' onClick={() => callbackOnPreviousPage()}><FiArrowLeft/> Back</button>
                                </div>
                                {gateWayType == 'openEdge' &&
                                    <div id="credit-card-submit" className='buy-pci-credit-card-submit-btn-wrapper'></div>}
                                {gateWayType == 'cardknox' &&
                                    <button className='btn btn-primary mt-2' type="button" onClick={()=> handleCarknoxCheckout()}>Checkout</button>
                                }
                                {gateWayType == 'dataCap' &&
                                    <button className='btn btn-primary mt-2' type="button" onClick={()=> handleDataCapCheckout()}>Checkout</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
