import {Link, useNavigate} from 'react-router-dom';
import React, {useRef, useState} from 'react';
// import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
// Components
import Layout from '@components/Layout';
import TermsModal from '@components/modals/TermsModal';
import TermsOffcanvas from '@components/modals/OffcanvasTerms';
// Services
import {loginByPhoneV2Api, signupApi, updateRegisterApi} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';
// Validation Schemas
import signupSchema from '@validation-schemas/signup-schema';
// Utils
import {initFormik} from '@utils/formik-helper';
import {phoneNumberFormat} from '@utils/common-helper';
import {seqReqBodyObj} from '@utils/common-helper';
// Assets
import authImg from '@assets/images/auth_bg.png';
import {useEffect} from 'react';

const Signup = (props) => {
    toast.configure();
    const portalCustomizationsList = useSelector((state) => state.global.portalCustomizationsList);
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const inputCard = useRef();
    const [phonError, setPhoneError] = useState(true);
    const [err, setErr] = useState('');
    const [card, setCard] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false);
    const navigate = useNavigate();
    const setIsContinue = () => {
        localStorage.setItem('isContinue', true);
    };

    const handleSubmit = async (values, resetForm)=>{
        setLoading(true);
        const phoneNo = card;
        values.preferedHomeLocation = '';
        values.preferedWorkLocation = '';
        const reqData ={
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: phoneNo,
            preferedHomeLocation: '',
            preferedWorkLocation: ''
        };
        const response = await signupApi(reqData);
        if (response.status === 200) {
            if (response.data.status !== null && response.data.status !== undefined && response.data.isValidPhoneNumber !== undefined && response.data.isValidPhoneNumber != null && !response.data.isValidPhoneNumber) {
                const seqReqBody = seqReqBodyObj(response.data.message, `${phoneNo}`);
                await seqLogger(seqReqBody);
                toast.warning(response.data.message, {autoClose: 3000});
                setLoading(false);
            }
            if (response.data.message === 'Customer record already exist') {
                const seqReqBody = seqReqBodyObj('Information', `Customer record already exist`, `${phoneNo}`);
                await seqLogger(seqReqBody);
                setLoading(false);
                resetForm();
                toast.warning(`${response.data.message}.`, {autoClose: 3000});
            }
        } else if (response.status === 201) {
            const seqReqBody = seqReqBodyObj('Information', `Customer registerd successfully`, `${response.data.phoneNumber}`);
            await seqLogger(seqReqBody);
            const reqBody={
                Firstname: response.data.firstname,
                Lastname: response.data.lastname,
                PhoneNumber: response.data.phoneNumber,
                CustomerIdentityID: response.data.id
            };
            const res = await updateRegisterApi(reqBody);
            if (res.status === 201) {
                // const phoneNumber = (values.phoneNumber).replace(/([() -])+/g, '');
                const seqReqBody = seqReqBodyObj('Information', `Customer record updated successfully`, `${phoneNo}`);
                await seqLogger(seqReqBody);
                const reqLoginByPhone = await loginByPhoneV2Api(phoneNo);
                if (reqLoginByPhone.status==200) {
                    if (reqLoginByPhone.data === undefined); {
                        setLoading(false);
                    }
                    if (reqLoginByPhone.data.message==='OTP Sent') {
                        const seqReqBody = seqReqBodyObj('Information', `Successfully OTP sent on your registered Phone number`, `${phoneNo}`);
                        await seqLogger(seqReqBody);
                        setLoading(false);
                        localStorage.setItem('phoneNumber', phoneNo);
                        return navigate('/verify-otp');
                    } else if (reqLoginByPhone.data.message === 'Please enter valid US phone number') {
                        toast.warning(reqLoginByPhone.data.message, {autoClose: 3000});
                        const seqReqBody = seqReqBodyObj('Information', `Please enter USA phone number`, `${phoneNo}`);
                        await seqLogger(seqReqBody);
                    } else if (reqLoginByPhone.data != null && !reqLoginByPhone.data.status) {
                        toast.warning('There was an error sending OTP. Please try again.', {autoClose: 3000});
                    }
                } else if (reqLoginByPhone.status == 400) {
                    setLoading(false);
                    // eslint-disable-next-line quotes
                    const substring = 'is not a valid phone';
                    if (reqLoginByPhone.data.includes(substring) == true) {
                        toast.error('Please enter USA phone number', {autoClose: 3000});
                        const seqReqBody = seqReqBodyObj('Information', `Please enter USA phone number`, `${phoneNo}`);
                        await seqLogger(seqReqBody);
                    } else {
                        toast.error('Internal server error', {autoClose: 3000});
                        const seqReqBody = seqReqBodyObj('Error', `Internal server error`, `${phoneNo}`);
                        await seqLogger(seqReqBody);
                    }
                }
            } else {
                setLoading(false);
                toast.error('Internal server error', {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Error', `Internal server error`, `${phoneNo}`);
                await seqLogger(seqReqBody);
            }
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
            setLoading(false);
        }
    };
    const initialValues = {
        firstName: '',
        lastName: '',
        phoneNumber: localStorage.getItem('newMob')
    };
    const formik = initFormik(initialValues, signupSchema, handleSubmit, true);

    const phoneMask = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputCard.current.value = !cardValue[2] ?
            cardValue[1] :
            `(${cardValue[1]}) ${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        if (inputCard.current.value.length == 0) {
            setErr('Cell Phone is required.');
            setPhoneError(true);
        } else if (!phoneRegExp.test(inputCard.current.value)) {
            setErr('Cell Phone  Number is not valid.');
            setPhoneError(true);
        } else {
            setErr('');
            setPhoneError(false);
        }
        setCard(numbers);
    };

    // Background Image Dynamically
    const backgroundImgUrl = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_background_image');

    const bgImage = backgroundImgUrl.sValue;
    useEffect(()=>{
        if (localStorage.getItem('newMob') != '') {
            inputCard.current.value = phoneNumberFormat(localStorage.getItem('newMob'));
            setCard(localStorage.getItem('newMob'));
            setPhoneError(false);
        }
    }, []);
    return (
        <Layout>
            {modal ? <TermsModal id={0} closeModal={() => setModal(false)}/> : '' }
            {modalPrivacy ? <TermsModal id={1} closeModal={() => setModalPrivacy(false)}/> : '' }
            <div className="auth_page">
                <div className="auth_watermark" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                <div className="auth_wrap">
                    <div className="auth_left">
                        <div className="auth_form_wrap">
                            <div className="brand_logo mb-4">
                                <Link to="/">
                                    {portalCustomizationsList.length && portalCustomizationsList.map((value, key) => {
                                        if (value.sName === 'site_logo_url') {
                                            return <img src={value.sValue} className="img-fluid" alt="logo" key={key} />;
                                        }
                                    })}
                                </Link>
                            </div>
                            <div className="auth_form_sec">
                                <h1 className="form_title mt-5 mb-5">Lets create your account!</h1>
                                <form className="auth_form mb-5" onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="firstName" className="form-label">First Name<sup className="text-danger">*</sup></label>
                                                <input type="text" className="form-control form-control-lg" name="firstName" id="firstName"
                                                    disabled={loading}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstName}
                                                />
                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                    <small className="text-danger validationError">{formik.errors.firstName}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="lastName" className="form-label">Last Name<sup className="text-danger">*</sup></label>
                                                <input type="text" className="form-control form-control-lg" name="lastName" id="lastName"
                                                    disabled={loading}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lastName}
                                                />
                                                {formik.touched.lastName && formik.errors.lastName ? (
                                                    <small className="text-danger validationError">{formik.errors.lastName}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label htmlFor="phoneNumber" className="form-label">Phone<sup className="text-danger">*</sup></label>
                                                {/* <InputMask
                                                    className="form-control form-control-lg"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    mask="(999) 999-9999"
                                                    maskChar=""
                                                    value={formik.values.phoneNumber}
                                                    name="phoneNumber"
                                                    placeholder="(000) 000-0000"
                                                    disabled={loading}
                                                /> */}
                                                <input type="text" className="form-control form-control-lg"
                                                    name="phone"
                                                    onChange={phoneMask}
                                                    ref={inputCard}
                                                    // value={card}
                                                    placeholder="(000) 000-0000"
                                                />
                                                {/* {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                                    <small className="text-danger validationError">{formik.errors.phoneNumber}</small>
                                                ) : null} */}
                                                <small className="text-danger validationError">{err}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="main_btn btn btn-primary" disabled={loading || !formik.isValid || phonError} onClick={() => setIsContinue()}>
                                        {loading && <span className="spinner-border text-light btnLoader"></span>}<span>Submit</span>
                                    </button>
                                </form>
                                <div className="switch_form text-center">Already a member? <Link to="/" className='link-primary'>Login Now!</Link></div>
                                <div className="auth_policy_text text-center mt-4">
                                    <p>
                                        <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#terms"
                                            aria-controls="offcanvasTop" className='link-primary'>Terms of Use</Link>&nbsp;&nbsp; | &nbsp;&nbsp;
                                        <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#policy"
                                            aria-controls="offcanvasTop" className='link-primary'>Privacy Policy</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auth_right" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                </div>
            </div>
            <TermsOffcanvas targetId='terms' id={0}/>
            <TermsOffcanvas targetId='policy' id={1}/>
        </Layout>
    );
};

export default Signup;
