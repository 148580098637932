/* eslint-disable camelcase */
export const dateFormatter = (param) => {
    let date = new Date(param);
    const time = new Date(param)?.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    date = ('0' + (parseInt(date.getMonth()) + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    return (date + ' ' + time);
};
export const dateFormatterYY_MM_DD = (param) => {
    let date = new Date(param);
    const time = new Date(param)?.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    date = date.getFullYear()+'-'+('0' + (parseInt(date.getMonth()) + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    return (date + ' ' + time);
};
export const dateFormatterWithoutTime = (param) => {
    let date = new Date(param);
    date = ('0' + (parseInt(date.getMonth()) + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    return (date);
};

export const ddmmyy = (param) => {
    let today = new Date(param);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return (today);
};

export const dateWithMonthName = (dateString) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(dateString);
    return `${date.getDate()} ${monthNames[date.getMonth()]}, ${date.getFullYear()}`;
};
export function convertUTCDateToLocalDate(dthistory) {
    const date = (new Date(dthistory));
    let time = new Date(dthistory);
    time = time.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });
    const dt = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${time} UTC`;
    return new Date(dt);
}
export const getUtcDateTime = () =>{
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = now.getUTCDate().toString().padStart(2, '0');
    let hours = now.getUTCHours();
    const minutes = now.getUTCMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    hours = hours.toString().padStart(2, '0');
    const formattedUTCTime = `${year}-${month}-${day} ${hours}:${minutes} ${period}`;
    return formattedUTCTime;
};
export const convertPurcahseUTCDateToLocalDate = (date) =>{
    const newDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000);
    const offset = new Date(date).getTimezoneOffset() / 60;
    const hours = new Date(date).getHours();
    newDate.setHours(hours - offset);
    let localdate = new Date(newDate);
    const time = new Date(newDate)?.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    localdate = ('0' + (parseInt(localdate.getMonth()) + 1)).slice(-2) + '/' + ('0' + localdate.getDate()).slice(-2) + '/' + localdate.getFullYear();
    return (localdate + ' ' + time);
};
export default dateFormatter;
