import API from './api';
const customerCode = localStorage.getItem('customerCode');
async function getUnlimitedPlans(accessToken, locationId) {
    try {
        return await API.get(`VIPAccountType/GetUnlimitedTypesForLocationForPortal?locationId=${locationId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

async function getCustomerProRateAmountMobile(accessToken, param) {
    try {
        return await API.get(`Customers/GetCustomerProRateAmount?MonthlyAccountTypeId=${param.MonthlyAccountTypeId}&NewMonthlyAccountTypeId=${param.NewMonthlyAccountTypeId}&CustomerGuid=${param.CustomerGuid}&ConfigurationUniqId=${param.ConfigurationUniqId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

async function addNewUWCPlan(accessToken, reqBody, purchaseDate) {
    try {
        return await API.post(`Customers/ChargeUnlimitedPlanWeb?purchaseDate=${purchaseDate}`, reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};

async function upgradeUWCPlan(accessToken, reqBody, purchaseDate) {
    try {
        return await API.post(`Customers/CustomerPlanChangeWeb?purchaseDate=${purchaseDate}`, reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};
async function checkExistBarcode(accessToken, param) {
    try {
        return await API.get(`VIPAccountType/CheckExistBarcode?barCode=${param.barCode}&cardNumber=${param.cardNumber}&CustomerIdentityId=${param.CustomerIdentityId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': localStorage.getItem('customerCode'),
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};

async function getVehiclePlatesByCustomerGuid(accessToken, customerGuid) {
    try {
        return await API.get(`Vehicles/getVehiclePlatesByCustomerGuid/${customerGuid}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CustomerGuid': customerGuid,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

export {
    getUnlimitedPlans,
    getCustomerProRateAmountMobile,
    addNewUWCPlan,
    upgradeUWCPlan,
    checkExistBarcode,
    getVehiclePlatesByCustomerGuid
};
