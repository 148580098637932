import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonOrderSummaryCC = () => {
    return (
        <div className="cc_card d-flex">
            <div className='col-4'><SkeletonElement type="text" /></div>
            <div className='col-6'></div>
            <div className='col-1 me-2'><SkeletonElement type="text" /></div>
            <div className='col-1'><SkeletonElement type="text" /></div>
        </div>
    );
};

export default SkeletonOrderSummaryCC;
