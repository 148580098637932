import API from './api';
// GC is a alias of GiftCard

export const giftCardList = async (accessToken, customeridentityid) => {
    return await API.get(`GiftCards/GetByCustomerIdentityId/${customeridentityid}`, {headers: {
        'X-Customer-Code': localStorage.getItem('customerCode'),
        'X-Authentication-Token': accessToken,
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        return error.response;
    });
};
const customerCode = localStorage.getItem('customerCode');
export const giftCardAmountList = async (accessToken) => {
    return await API.get(`GiftCards/GetGiftCardListForWeb?LocationId=${localStorage.getItem('locationIdForService') == null ? localStorage.getItem('locationId') : localStorage.getItem('locationIdForService')}`, {headers: {
        'X-Authentication-Token': accessToken,
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        return error;
    });
};

export const mergeGiftCard = async (accessToken, reqBody, customerCode) => {
    return await API.post('GiftCards/MergeGiftcardWeb', reqBody, {headers: {
        'X-Authentication-Token': accessToken,
        'Content-Type': 'application/json',
        'X-Customer-Code': customerCode,
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        return error;
    });
};
const addValueOnExistingGC = async (accessToken, location, reqBody) => {
    try {
        return await API.post('GiftCards/AddGiftCardFundsWeb', reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'X-Location-Id': location,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};

const getCalculatedGCTax = async (accessToken, reqBody) => {
    try {
        const url = `GiftCards/CalculateGiftCardTax?SurchargeId=${reqBody.SurchargeId}&Other=${reqBody.Other}&CustomerGuid=${reqBody.CustomerGuid}&CustomeridentityId=${reqBody.customerIdentityID}`;
        return await API.get(url, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

const buyGiftCardOnline = async (accessToken, otherPhoneNumber, reqBody, purchaseDate) => {
    try {
        return await API.post(`GiftCards/BuyGiftCardOnlineWeb/?otherPhoneNumber=${otherPhoneNumber}&purchaseDate=${purchaseDate}`, reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};

const getGiftCardReceipt = async (accessToken, customerIdentityId, referencedGUID) => {
    try {
        const url = `GiftCards/GetGiftCardReceipt?customerIdentityId=${customerIdentityId}&referencedGUID=${referencedGUID}`;
        return await API.get(url, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

export {addValueOnExistingGC, getCalculatedGCTax, buyGiftCardOnline, getGiftCardReceipt};
