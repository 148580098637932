import React, {useEffect, useRef, useState} from 'react';
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import {setOrderHistory, setSelectedWallet} from '@store/reducers/wallet-reducer';
import {useDispatch, useSelector} from 'react-redux';
import {FiDownload} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import SkeletonOrderHistory from '@utils/skeletons/SkeletonOrderHistory';
import WalletBarcode from '../../components/common/WalletBarcode';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import dateFormatter from '@utils/date-formatter';
import exportAsImage from '@utils/export-as-image';
import {orderHistory} from '@services/wallet-service';
import {seqLogger} from '@services/seq-logger-service';
import {setPreviousPage} from '@store/reducers/auth-reducer';
import {setServices} from '@store/reducers/manage-account-reducer';
import {toast} from 'react-toastify';


const RecentOrderHistory = () => {
    let rowCount = 0;
    const exportRef = useRef();
    const services = useSelector((state) => state.wallet.orderHistory);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [isLoading, setIsLoading] = useState(services.length ? false : true);
    const [isDownload, setIsDownload] = useState(false);
    const phoneNumber = localStorage.getItem('phoneNumber');
    const dispatch = useDispatch();
    toast.configure();
    const getServices = async () => {
        if (services.length === 0) {
            setIsLoading(true);
            const response = await orderHistory(userInfo.accessToken, userInfo.customerIdentityID);
            if (response.status === 200) {
                setTimeout(() => {
                    setIsLoading(false);
                    dispatch(setOrderHistory({orderHistory: response.data}));
                }, 1000);
                const seqReqBody = seqReqBodyObj('Information', `Recent Order history retrieved successfully`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
            } else {
                setIsLoading(false);
                toast.error('Something went wrong.', {autoClose: 3000});
            }
        } else {
            setServices(services);
        }
    };
    const servicesArr = services.map((obj) => {
        return {...obj, walletDate: new Date(obj.walletDate)};
    });
    const sortedDescServicesArr = servicesArr.sort(
        (objA, objB) => new Date(objB.walletDate) - new Date(objA.walletDate)
    );
    const handleSelectedWallet = (wallet) => {
        const upDatedWallet = {...wallet, pageBackUrl: '/order-history'};
        dispatch(setSelectedWallet({selectedWallet: upDatedWallet}));
    };
    useEffect(() => {
        getServices();
    }, []);

    return (
        <div className="odr_history_table">
            <div className="odr_history_table_top">
                <h2 className="main_heading">Recent Order History</h2>
                {sortedDescServicesArr.length >0?
                    <Link className="btn btn-primary" to="/order-history" onClick={()=>dispatch(setPreviousPage({previousPage: '/manage_account'}))}>View All</Link>:''
                }
            </div>
            <div className="odr_history_table_table_resp">
                {isLoading ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Plans/Cards</th>
                                <th scope="col">Purchase Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Order Number</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <SkeletonOrderHistory count={5}></SkeletonOrderHistory>}
                        </tbody>
                    </table>:''
                }
                {!isLoading && sortedDescServicesArr.length > 0 &&
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Plans/Cards</th>
                            <th scope="col">Purchase Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Order Number</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && sortedDescServicesArr.length > 0 && sortedDescServicesArr.map((service) => {
                            if (service.amount !== 0 && rowCount <= 4) {
                                {rowCount += 1;}
                                return (
                                    <tr key={service.barcode}>
                                        <td>{service.description}</td>
                                        <td>{dateFormatter(convertUTCDateToLocalDate(service.walletDate))}</td>
                                        <td>{currencyFormat(service?.totalAmount)}</td>
                                        <td>{service.orderNumber}</td>
                                        <td>
                                            <button className='btn btn-outline-secondary' onClick={() => {
                                                handleSelectedWallet(service);
                                                setTimeout(() => {
                                                    setIsDownload(true);
                                                    exportAsImage(exportRef.current, service.barcode);
                                                    setIsDownload(false);
                                                }, 100);
                                            }}><FiDownload/> Download</button>
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
                }
                {!isLoading && sortedDescServicesArr?.length == 0 &&
                <p className='notFound alert alert-info'>Order history not available.</p>
                }
                {
                    isDownload ?
                        <div ref={exportRef}>
                            <WalletBarcode />
                        </div> : ''
                }
            </div>
        </div>
    );
};

export default RecentOrderHistory;
