import authSlice from './reducers/auth-reducer';
import buypolicyservices from './reducers/buy-reducer';
import {configureStore} from '@reduxjs/toolkit';
import customerInfoSlice from './reducers/user-info-reducer';
import giftCardSlice from './reducers/gift-card-reducer';
import globalSlice from './reducers/global-reducer';
import locationSlice from './reducers/location-reducer';
import manageAccountSlice from './reducers/manage-account-reducer';
import orderHistorySlice from './reducers/order-history-reducer';
import orderSlice from './reducers/order-reducer';
import prepaidWashSlice from './reducers/prepaid-wash-reducer';
import walletSlice from './reducers/wallet-reducer';
const store = configureStore({
    reducer: {
        auth: authSlice,
        giftCard: giftCardSlice,
        prepaidWash: prepaidWashSlice,
        customer: customerInfoSlice,
        manageAccount: manageAccountSlice,
        global: globalSlice,
        location: locationSlice,
        wallet: walletSlice,
        order: orderSlice,
        orderHistory: orderHistorySlice,
        buypolicyservices: buypolicyservices
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({

        serializableCheck: false

    })
});

export default store;
