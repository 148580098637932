import * as Yup from 'yup';
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export default Yup.object().shape({
    phone: Yup.
        string()
        .test('valid', 'Cell Phone  Number is not valid.', (val = '') => {
            const checkLength = val.replace(/\(|\)/g, '').length;
            if (checkLength === 0) {
                return true;
            }
            if (phoneRegExp.test(val)) {
                return true;
            }
            return false;
        })
        .test('empty', 'Cell Phone is required.', (val = '') => {
            const checkLength = val.replace(/\(|\)/g, '').length;
            return checkLength > 0;
        })
        .min(10, 'Cell Phone must be 10 digits.')
        .max(14, 'Cell Phone must be 10 digits.')
});
