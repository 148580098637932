import {createSlice} from '@reduxjs/toolkit';

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        services: [],
        orderHistory: [],
        selectedWallet: {}
    },
    reducers: {
        setwalletServices: (state, action) => {
            state.services = action.payload.services;
        },
        setOrderHistory: (state, action) => {
            state.orderHistory = action.payload.orderHistory;
        },
        setSelectedWallet: (state, action) => {
            state.selectedWallet = action.payload.selectedWallet;
        }
    }
});
export const {setwalletServices, setSelectedWallet, setOrderHistory} = walletSlice.actions;
export default walletSlice.reducer;
