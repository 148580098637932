import {Link} from 'react-router-dom';
import React from 'react';
import parse from 'html-react-parser';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
// Components
import Layout from '../LayoutBuy';

const BuyPolicies = (props) => {
    toast.configure();
    const policyDetail = JSON.parse(localStorage.getItem('policyData'));
    const selectedPolicy = useSelector((state) => state.buypolicyservices.selectedPolicy);
    let id = 0;
    if (selectedPolicy !== null && selectedPolicy !== undefined) {
        id = selectedPolicy.id;
    }
    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Link to="/buy" className="btn btn-outline-secondary icon_btn"><span className="icon arrow_left"></span> Back</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="policy_wrap mb-5">
                            {
                                policyDetail.length > 0 && policyDetail.filter((value) => value.contentId === id).map((detail, index) => {
                                    return (
                                        <>
                                            <h5>{parse(detail.title ?? '')}</h5><div>{parse(detail.contentDescription ?? '')}</div>
                                        </>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default BuyPolicies;
