import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonWashManager = () => {
    return (
        <div className="col-12">
            <div className=' wash_serv_box mb-2 d-flex'>
                <div className='col-3'>
                    <SkeletonElement type="text" />
                    {/* <SkeletonElement type="text" /> */}
                </div>
                <div className="col-6">
                </div>
                <div className="col-2 me-2">
                    <SkeletonElement type="text" />
                    {/* <SkeletonElement type="text" /> */}
                </div>
                <div className="col-1">
                    <SkeletonElement type="text" />
                </div>
            </div>
        </div>
    );
};

export default SkeletonWashManager;
