import * as Yup from 'yup';
// const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const nameRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

export default Yup.object().shape({
    firstname: Yup.string().required('First Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'First Name must have maximum 25 characters.'),
    lastname: Yup.string().required('Last Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'Last Name must have maximum 25 characters.'),
    email: Yup.string().required('Recipient Email is required.').matches(emailRegExp, 'Email must be a valid email.'),
    // phone: Yup.
    //     string()
    //     .test('valid', 'Phone Number is not valid.', (val = '') => {
    //         const checkLength = val.replace(/\(|\)/g, '').length;
    //         if (checkLength === 0) {
    //             return true;
    //         }
    //         if (phoneRegExp.test(val)) {
    //             return true;
    //         }
    //         return false;
    //     })
    //     .test('empty', 'Phone Number is required.', (val = '') => {
    //         const checkLength = val.replace(/\(|\)/g, '').length;
    //         return checkLength > 0;
    //     })
    //     .min(10, 'Phone Number must be 10 digits.')
    //     .max(14, 'Phone Number must be 10 digits.'),
    message: Yup.string().required('Message is required.')
});
