// import Countdown, {zeroPad} from 'react-countdown';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactInputMask from 'react-input-mask';
import {toast} from 'react-toastify';
// Components
import Layout from '@components/Layout';
import TermsModal from '@components/modals/TermsModal';
import TermsOffcanvas from '@components/modals/OffcanvasTerms';
// Services
import {creditCardsList} from '@services/manage-account-service';
import {getCustomerInfoByGuid} from '@services/user-services';
import {location} from '@services/location-service';
import {loginByPhoneV2Api} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';

import {validateOTP, verifyOTPApi} from '@services/auth-service';
// Reducers
import {setLocations, setLoginStatus, setUserInfo} from '@store/reducers/auth-reducer';
import {setBarcodeSignupOTPFlag} from '@store/reducers/global-reducer';
import {setCreditCards} from '@store/reducers/manage-account-reducer';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {fetchPCIDetailsFromServer} from '@utils/common-repo';
import {initFormik} from '@utils/formik-helper';
import {seqReqBodyObj} from '@utils/common-helper';
// Validation Schemas
import otpVerificationSchema from '@validation-schemas/otp-verification-schema';
// Assets
import {checkCustomerDuplicateRecord, submitbarcodesignup, updateCustomerGuid} from '../services/auth-service';
import authImg from '@assets/images/auth_bg.png';

const Verification = (props) => {
    toast.configure();
    const {portalCustomizationsList, barcodeSignupOTPFlag} = useSelector((state) => state.global);
    const toastId = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [FullLoader, setFullLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalP, setModalP] = useState(false);
    const [custId, setCustId] = useState('');
    const [timer, setTimer] = useState(false);
    const [verificationRes, setVerificationResponse] = useState({});
    const [resendTime, setResendTime] = useState('5:00');

    const [isCustomerRecordModelOpen, setCustRecModel] = useState(false);
    const [duplicateCustomerRecord, setDuplicateCustomerRecord] = useState([]);
    const [cancelLoading, setCancelLoading] = useState(false);
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const phoneNumber = localStorage.getItem('phoneNumber');
    const customerCode = localStorage.getItem('customerCode');

    const goToDashBoard = async (userData) => {
        await getCustomerInfo(userData);
        getCreditCardsList(userData);
        userData['expiryTime'] =new Date(userData.expires).getTime();
        getlocations(userData.accessToken);
        await fetchPCIDetailsFromServer();
        dispatch(setUserInfo({userInfo: userData}));
        localStorage.setItem('userInfo', JSON.stringify(userData));
        localStorage.setItem('locationId', userData.preferedHomeLocation);
        localStorage.setItem('gender', userData.gender);
        localStorage.setItem('showCustomerRecord', true);
        setTimeout(() => {
            setLoading(false);
            setSelectLoading(false);
            setCancelLoading(false);
            dispatch(setLoginStatus({isLoggedIn: true}));
            return navigate('/dashboard');
        }, 3000);
    };

    const checkDuplicateRecord = async (userData) => {
        const response = await checkCustomerDuplicateRecord(phoneNumber, customerCode, '');
        if (response.status == 200) {
            if (response.data.length > 1) {
                setLoading(false);
                formik.resetForm();
                setDuplicateCustomerRecord(response.data);
                setCustRecModel(true);
            } else {
                if (response.data.length == 1) {
                    userData.customerGuid = response.data[0].customerGUID;
                }
                goToDashBoard(userData);
            }
        }
    };
    const selectRecord = async (data) => {
        setCustId(data.lCustomerID);
        setSelectLoading(true);
        const response = await updateCustomerGuid(data.customerGUID, verificationRes.customerIdentityID, customerCode);
        if (response.status === 200) {
            goToDashBoard(response.data);
        }
    };
    const onCancelcustomerDupRecord = async () =>{
        setCancelLoading(true);
        setSelectLoading(true);
        verificationRes.customerGuid=0;
        goToDashBoard(verificationRes);
    };
    const handleSubmit = async (values, resetForm) => {
        setLoading(true);
        if (barcodeSignupOTPFlag==true) {
            const VerifyotpResponse = await validateOTP(phoneNumber, values.otp);
            if (VerifyotpResponse.status==200) {
                submitBarcodeSignupDetails();
            } else {
                resetForm();
                setLoading(false);
                toastId.current = toast.error(varificationResponse.data.message, {autoClose: 3000});
            }
        } else {
            const varificationResponse = await verifyOTPApi(phoneNumber, values.otp);
            if (varificationResponse.status === 200) {
                setVerificationResponse(varificationResponse.data);
                checkDuplicateRecord(varificationResponse.data);
            } else {
                if (!toast.isActive(toastId.current)) {
                    if (varificationResponse.data.message === 'Expired Token') {
                        toastId.current = toast.error('Your OTP has been Expired.', {autoClose: 3000});
                    } else {
                        toastId.current = toast.error(varificationResponse.data.message, {autoClose: 3000});
                    }
                    resetForm();
                    setLoading(false);
                }
                setLoading(false);
            }
        }
    };
    const initialValues = {
        otp: ''
    };
    const formik = initFormik(initialValues, otpVerificationSchema, handleSubmit, true);
    const submitBarcodeSignupDetails = async () => {
        const signupdetails= JSON.parse(localStorage.getItem('barcodeSignupModalInfo'));
        const CustomerGuid=localStorage.getItem('CustomerGuidBarcodeSignUp');
        const data= {
            Firstname: signupdetails.firstName,
            Lastname: signupdetails.lastName,
            PhoneNumber: signupdetails.phoneNumber,
            CustomerGUID: CustomerGuid,
            CustomerIdentityID: 0,
            Email: ''
        };
        const response = await submitbarcodesignup(data);
        localStorage.removeItem('barcodeSignupModalInfo');
        localStorage.removeItem('barcodeSignupModalInfo');
        localStorage.removeItem('CustomerGuidBarcodeSignUp');
        dispatch(setBarcodeSignupOTPFlag({barcodeSignupOTPFlag: false}));

        if (response.status === 200) {
            if (response.data.accessToken !== null) {
                await getCustomerInfo(response.data);
                const userData = response.data;
                userData['expiryTime'] = new Date(userData.expires).getTime();
                getAllLocations(userData.accessToken);
                await fetchPCIDetailsFromServer();
                dispatch(setUserInfo({userInfo: userData}));
                localStorage.setItem('userInfo', JSON.stringify(userData));
                setLoading(false);
                setTimeout(() => {
                    dispatch(setLoginStatus({isLoggedIn: true}));
                    return navigate('/dashboard');
                }, 500);
            } else {
                // Need to open Popup
                setLoading(false);
                resetForm();
                toast.error('Something went wrong.', {autoClose: 3000, toastId: 'failed'});
            }
        } else {
            setLoading(false);
            toast.error('Customer not found, Please register.', {autoClose: 3000});
        }
    };
    const getAllLocations = async (accessToken) => {
        const response = await location(accessToken);
        if (response.status == 200) {
            dispatch(setLocations({locations: response.data}));
            localStorage.setItem('locations', JSON.stringify(response.data));
            localStorage.setItem('locationId', response.data[0].locationId);
        }
    };
    const getCustomerInfo = async (userInfo) => {
        const response = await getCustomerInfoByGuid(userInfo.accessToken, userInfo.customerGuid, userInfo.customerIdentityID);
        if (response.status == 200) {
            const seqReqBody = seqReqBodyObj('Information', `Customer information retrieve successfully`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
        }
    };
    const getlocations = async (accessToken) => {
        const response = await location(accessToken);
        if (response.status == 200) {
            const seqReqBody = seqReqBodyObj('Information', `All Locations retrieve successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
            dispatch(setLocations({locations: response.data}));
            localStorage.setItem('locations', JSON.stringify(response.data));
            localStorage.setItem('utcOffset', response.data[0].utcOffset);
        }
    };
    const getCreditCardsList = async (userInfo) => {
        if (creditCards.length === 0) {
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            if (response.status === 200) {
                dispatch(setCreditCards({creditCards: response.data}));
                localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
            } else {
                dispatch(setCreditCards({creditCards: []}));
            }
        }
    };
    const resendOtp = async (values) => {
        setFullLoader(true);
        if (barcodeSignupOTPFlag==true) {
            const sendOTPResponse = await sendOTP(phoneNumber);
            setFullLoader(false);
            if (sendOTPResponse.status == 200) {
                if (sendOTPResponse.data.status==true) {
                    // localStorage.setItem('customerDetail', JSON.stringify(response.data));
                    // localStorage.setItem('phoneNumber', response.data.customer.sPhone);
                    // dispatch(setPhoneNumber({phoneNumber}));
                    setFullLoader(false);
                    localStorage.setItem('phoneNumber', phoneNumber);
                    otpCountDown();
                    formik.resetForm();
                    const seqReqBody = seqReqBodyObj('Information', `OTP resend successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                } else {
                    setSignupLoading(false);
                    toast.warning('There was an error sending OTP. Please try again.', {autoClose: 3000});
                }
            } else if (response.status === (401 | 404 | 470)) {
                setSignupLoading(false);
                toast.warning(`${response.data.title}.`, {autoClose: 3000});
            } else {
                setSignupLoading(false);
                toast.error('Some error occurred.', {autoClose: 3000});
            }
        } else {
            const response = await loginByPhoneV2Api(phoneNumber);
            if (response.status === 200) {
                setFullLoader(false);
                localStorage.setItem('phoneNumber', phoneNumber);
                otpCountDown();
                formik.resetForm();
                const seqReqBody = seqReqBodyObj('Information', `OTP resend successfully`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
            } else {
                toast.error('You have entered invalid mobile number. Please try again.', {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Error', `You have entered invalid mobile number. Please try again`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
                setFullLoader(false);
            }
        }
    };

    // Background Image Dynamically
    const backgroundImgUrl = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_background_image');

    const bgImage = backgroundImgUrl.sValue;

    const otpCountDown = () => {
        setTimer(true);
        let time = 299;
        const t = setInterval(() => {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;
            seconds = seconds<10 ? '0' + seconds : seconds;
            const result = `${minutes}:${seconds}`;
            setResendTime(result);
            time--;
            if (minutes == 0 && seconds == 0) {
                clearInterval(t);
                setTimer(false);
                setResendTime('5:00');
            }
        }, 1000);
    };
    useEffect(()=>{
        // setTimer(true);
        otpCountDown();
    }, []);
    return (
        <Layout>
            {modal ? <TermsModal id={0} closeModal={() => setModal(false)}/> : '' }
            {modalP ? <TermsModal id={1} closeModal={() => setModalP(false)}/> : '' }
            {FullLoader === true ? <FullScreenLoader /> : ''}
            <div className="auth_page">
                <div className="auth_watermark" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                <div className="auth_wrap">
                    <div className="auth_left">
                        <div className="auth_form_wrap">
                            <div className="brand_logo mb-5">
                                <Link to="/">
                                    {portalCustomizationsList.length && portalCustomizationsList.map((value, key) => {
                                        if (value.sName === 'site_logo_url') {
                                            return <img src={value.sValue} className="img-fluid" alt="logo" key={key} />;
                                        }
                                    })}
                                </Link>
                            </div>
                            <div className="auth_form_sec">
                                <h1 className="form_title mt-3">Verification Code</h1>
                                <p className={`mb-3`}>To verify your cell phone number, enter the One Time Password(OTP) sent to&nbsp;
                                    <ReactInputMask
                                        className="headerPhone"
                                        readOnly={true}
                                        mask="(999) 999-9999"
                                        maskChar=""
                                        value={phoneNumber}
                                        name="phone"
                                        placeholder="(000) 000-0000"
                                    />
                                </p>
                                <form onSubmit={formik.handleSubmit} className="auth_form mb-5">
                                    <div className="mb-4">
                                        <input type="text" className={`form-control form-control-lg mb-1 otp_input`} id="otp" aria-describedby="nameHelp"
                                            name="otp"
                                            onChange={(e)=>{
                                                formik.handleChange(e);
                                            }}
                                            onBlur={() => formik.handleBlur}
                                            value={formik.values.otp}
                                            maxLength='6'
                                        />
                                        {formik.touched.otp && formik.errors.otp ? (
                                            <small className="text-danger">{formik.errors.otp}</small>
                                        ) : null}
                                    </div>
                                    {timer ?
                                        <div className="switch_form text-center mb-4">
                                            <strong>
                                                <span>OTP will expire in {resendTime}</span>
                                            </strong>
                                        </div>:
                                        <div className="switch_form text-center mb-4 cursor_pointer" onClick={() => resendOtp()}><strong className='res_otp link-primary'>Resend OTP</strong></div>
                                    }
                                    <button type="submit" className={`main_btn button btn btn-primary`} disabled={loading || formik.values.otp === '' || !formik.isValid}>
                                        {loading && <span className="spinner-border text-light btnLoader"></span>}<span>Verify</span>
                                    </button>
                                </form>
                                {/* <OtpTimer /> */}
                                <div className="switch_form text-center">Login with another Number<Link to="/" className='link-primary'>Click Here</Link></div><br/>
                                <div className="auth_policy_text text-center mt-3">
                                    <p>
                                        <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#terms"
                                            aria-controls="offcanvasTop" className='link-primary'>Terms of Use</Link>&nbsp;&nbsp; | &nbsp;&nbsp;
                                        <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#policy"
                                            aria-controls="offcanvasTop" className='link-primary'>Privacy Policy</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auth_right" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                </div>
            </div>
            <TermsOffcanvas targetId='terms' id={0}/>
            <TermsOffcanvas targetId='policy' id={1}/>

            <div className={`modal fade cw_modal cust_info_mdl ${isCustomerRecordModelOpen ? 'show d-block' : ''}`} id="cust_info" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="cust_infoLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="cust_infoLabel">Please Select Your Customer Information</h5>
                        </div>
                        <div className="modal-body">
                            <div className="cust_info_mdl_body">
                                <form>
                                    {duplicateCustomerRecord.length && duplicateCustomerRecord.map((c, index) => {
                                        // console.log(c);
                                        return (
                                            <div className="cust_info_mdl_form" key={index}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="fName" className="form-label">First Name</label>
                                                            <input type="text" id="fName" readOnly className="form-control form-control-md"
                                                                value={c.sFirstName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">Last Name</label>
                                                            <input type="text" id="lName" readOnly className="form-control form-control-md"
                                                                value={c.sLastName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">Address</label>
                                                            <input type="text" id="lName" readOnly className="form-control form-control-md"
                                                                value={c.sAddress1} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">Phone Number</label>
                                                            <ReactInputMask
                                                                readOnly
                                                                className="form-control form-control-lg"
                                                                mask="(999) 999-9999"
                                                                maskChar=""
                                                                value={phoneNumber}
                                                                name="phone"
                                                                placeholder="(000) 000-0000"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">State</label>
                                                            <input type="text" id="lName" readOnly className="form-control form-control-md"
                                                                value={c.sState} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">City</label>
                                                            <input type="tel" id="lName" readOnly className="form-control form-control-md"
                                                                value={c?.sCity} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lName" className="form-label">Zip Code</label>
                                                            <input type="tel" id="lName" readOnly
                                                                className="form-control form-control-lg form-control-md" value={c?.sZipcode} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-12">
                                                        <div className="mb-3">
                                                            <button type="button" className="btn btn-primary w-100" disabled={selectLoading || cancelLoading} onClick={()=> selectRecord(c)}>{selectLoading && c.lCustomerID == custId && <span className="spinner-border text-light btnLoader"></span>}Select</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-start">
                            <button type="button" disabled={selectLoading} onClick={() => {
                                onCancelcustomerDupRecord();
                            }}className="btn btn-secondary w-100 justify-content-center"
                            data-bs-dismiss="modal">{cancelLoading && <span className="spinner-border text-light btnLoader"></span>}Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {isCustomerRecordModelOpen ?
                <div className="modal-backdrop fade show"></div>: ''
            }
        </Layout>
    );
};

export default Verification;
