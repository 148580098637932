import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useRef} from 'react';
import Barcode from 'react-barcode';
import {FiDownload} from 'react-icons/fi';
import QRCode from 'react-qr-code';
import {useSelector} from 'react-redux';
// Components
import Layout from '../Layout';
// Utils
import {currencyFormat, phoneNumberFormat} from '@utils/common-helper';
import {convertPurcahseUTCDateToLocalDate} from '../../utils/date-formatter';
import exportAsImage from '@utils/export-as-image';
import {GetCreditCardIcon as getCCIcon} from '@utils/common-repo';

const OrderSuccess = (props) => {
    const utcCurrent = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    const barcodeString = useSelector((state) => state.global.barcodeString);
    const barcodeStr = barcodeString !== undefined ? barcodeString : 'DemoBarcode';
    const purchaseDateString = useSelector((state) => state.global.purchaseDateString);
    const PurchaseDateStr = purchaseDateString !== undefined ? purchaseDateString : utcCurrent;
    const thankYouPara1 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para1');
    const thankYouPara2 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para2');
    const thankYouPara3 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_para3');
    const thankYouMedia = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_thankyou_media');
    const giftcardthankYouPara3 = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_giftcard_thankyou_para1');
    const portalCustomizationsList = useSelector((state) => state.global.portalCustomizationsList);
    const {state} = useLocation();
    const exportRef = useRef();
    const orderReceipt = useSelector((state) => state.order.orderReceipt);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const userInfo = useSelector((state) => state.auth.userInfo);
    const purchaseDateTime = convertPurcahseUTCDateToLocalDate(PurchaseDateStr);
    let ccDetail = {};
    if (orderReceipt?.SelectedCard!=null) {
        ccDetail= getCCIcon(orderReceipt?.SelectedCard);
    }

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    return (
        <Layout>
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <div className="main_title mb-3"><h2>Order Confirmation</h2></div>
                    </div>
                </div>
                <div className="oc_receipt_wrap">
                    <div className='row payment_method_wrap' ref={exportRef}>
                        <div className='col-lg-6 py-4 mt-3'>
                            <div>
                                <div className="oc_brand_logo">
                                    {portalCustomizationsList?.length && portalCustomizationsList?.map((value, key) => {
                                        if (value.sName === 'site_logo_url') {
                                            return <img src={value.sValue} className="img-fluid" alt="logo" key={key} />;
                                        }
                                    })}
                                </div>
                                <div className='barcode_img mx-auto text-center'>
                                    <Barcode value={barcodeStr} width={2} height={60} />
                                </div>
                                <div className='barcode_img mx-auto text-center mt-4'>
                                    <QRCode value={barcodeStr} size={200} />
                                </div>
                                <div className='barcode_number text-center p-4'>
                                    <small>Your Redeem Code:</small>
                                    <h3 className='mt-2'>{barcodeStr}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 py-4 mt-3">
                            <div className="oc_bill_detail">
                                <div className="oc_bill_detail_inner">
                                    <ul className='oc_bill_pro_details'>
                                        <li>
                                            <span>Product Name:</span>
                                            <h3>{orderReceipt&&orderReceipt.ListItemDescription}</h3>
                                        </li>
                                        <li>
                                            <span>Purchase Date:</span>
                                            <h3>{purchaseDateTime}</h3>
                                        </li>
                                        <li>
                                            <span>Payment method:</span>
                                            <div className='d-flex'>
                                                <h3 className='me-2'>xxxx xxxx xxxx {orderReceipt.SelectedCard?.sSpan}</h3>
                                                <span className={`card_icon ${ccDetail?.icon}`}></span>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className={`oc_bill_user_details ${state?.isForMySelf?'border-bottom-0':''}`}>
                                        <li>
                                            <span>Customer:</span>
                                            <h3>{`${customerDetail?.customer.sFirstName !== null ? customerDetail.customer.sFirstName : userInfo.firstname}`} {customerDetail.customer?.sFirstName?.length > 18 || customerDetail.customer?.sLastName?.length > 18 ? <br /> : ''}{`${customerDetail?.customer?.sLastName !== null ? customerDetail?.customer?.sLastName : userInfo?.lastName}`}</h3>
                                        </li>
                                        <li>
                                            <span>Cell Phone:</span>
                                            <h3>{phoneNumberFormat(customerDetail.customer.sPhone)}</h3>
                                        </li>
                                        <li>
                                            <span>Email:</span>
                                            <h3>{customerDetail.customer.sEmail}</h3>
                                        </li>
                                    </ul>
                                    {state?.isForMySelf?'':
                                        <ul className={`oc_bill_user_details`}>
                                            <li>
                                                <span>Recipient:</span>
                                                <h3>{state.firstName} {state.lastName}</h3>
                                            </li>
                                            <li>
                                                <span>Cell Phone:</span>
                                                <h3>{phoneNumberFormat(state.phoneNumber)}</h3>
                                            </li>
                                            <li>
                                                <span>Email:</span>
                                                <h3>{state.email}</h3>
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="bill_total">
                                    {orderReceipt.ListItemText != 'Gift Card' ?
                                        <div className="main_total"><span>Wash Price:</span><span>{orderReceipt && currencyFormat(orderReceipt.SubTotal)}</span></div> : ''
                                    }
                                    {orderReceipt.addon > 0 ?
                                        <div className="main_total"><span>Add-on Services:</span><span>{orderReceipt&&currencyFormat(orderReceipt.addon)}</span></div> : ''}
                                    {
                                        orderReceipt.addon > 0 ?
                                            <div className="main_total"><span>Subtotal:</span><span><b>{orderReceipt&&currencyFormat(orderReceipt.SubTotal + orderReceipt.addon)}</b></span></div>:
                                            <div className="main_total"><span>Subtotal:</span><span><b>{orderReceipt&&currencyFormat(orderReceipt.SubTotal)}</b></span></div>
                                    }
                                    <div className="main_total"><span>Tax:</span><span>{orderReceipt&&currencyFormat(orderReceipt.TaxAmount + orderReceipt.addonTax)}</span></div>
                                </div>
                                {orderReceipt.addon > 0 ?
                                    <div className="main_total"><span><b>Total:</b></span><span><b>{orderReceipt&&currencyFormat(orderReceipt.GrandTotal + orderReceipt.addon + orderReceipt.addonTax)}</b></span></div>:
                                    <div className="main_total"><span><b>Total:</b></span><span><b>{orderReceipt&&currencyFormat(orderReceipt.GrandTotal)}</b></span></div>}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 py-4">
                            <div className='btn_grps d-flex flex-wrap justify-content-center'>
                                <Link className='btn btn-primary' to='/'>Back to Home</Link>
                                <button className='btn btn-outline-secondary ms-2' onClick={() => exportAsImage(exportRef.current, barcodeStr)}><FiDownload className='me-2'/>Download</button>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-4">
                            {thankYouMedia?.sValue != undefined&&thankYouMedia?.sValue !=''&&thankYouMedia?.sValue !=null ?
                                <div className='video_promo d-flex justify-content-center mb-4'>
                                    <iframe src={thankYouMedia?.sValue} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                                </div> : ''}
                            <div className='barcode_msg pt-4 pb-3 mt-3 mb-3 border-top text-center'>
                                {orderReceipt.ListItemText != 'Gift Card' ? <p className='thankyou_p'>{thankYouPara1?.sValue != undefined ? thankYouPara1?.sValue : ''}</p> : <p className='thankyou_p'>Your gift card has been purchased</p>}
                                {orderReceipt.ListItemText != 'Gift Card' ? <p>{thankYouPara2?.sValue != undefined ? thankYouPara2?.sValue : ''}</p> : <p> {giftcardthankYouPara3?.sValue != undefined ? giftcardthankYouPara3?.sValue : ''} </p>}
                                {orderReceipt.ListItemText != 'Gift Card' ? <p>{thankYouPara3?.sValue != undefined ? thankYouPara3?.sValue : ''}</p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default OrderSuccess;
