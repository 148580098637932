import React from 'react';
import parse from 'html-react-parser';

const TermsOffcanvas = (props) => {
    const policyDetail = localStorage.getItem('policyData') == ' '? null: JSON.parse(localStorage.getItem('policyData'));
    return (
        <div className="policy_offcanwas">
            <div className="offcanvas offcanvas-top" tabIndex="-1" id={props.targetId} aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    {
                        policyDetail != null && policyDetail.length >0 && policyDetail.filter((value)=>value.contentId===props.id || value.contentId===props.id1).map((detail, index) => {
                            return (
                                <>
                                    <h5>{parse(detail.title ?? '')}</h5>
                                    <div>{parse(detail.contentDescription ?? '')}</div>
                                </>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default TermsOffcanvas;
