import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {FiMapPin, FiPhone} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactInputMask from 'react-input-mask';
import {toast} from 'react-toastify';
// Components
import Layout from '@components/Layout';
import LocationMap from '@components/map/LocationMap';
// Services
import {location, locationDetail, setPreferedLocationWeb} from '@services/location-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setLocations, setUserInfo} from '@store/reducers/auth-reducer';
import {setSelectedLocation, setServices} from '@store/reducers/location-reducer';
import {setGiftCardAmounts} from '@store/reducers/gift-card-reducer';
import {setIsLoading} from '@store/reducers/global-reducer';
// Wash Reducers
import {setDetailingServices, setPrepaidWashPlans, setServices as setServicesWash} from '../../store/reducers/prepaid-wash-reducer';
// Utils
import SkeletonLocation from '@utils/skeletons/SkeletonLocation';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './Location.style.css';
import {Link} from 'react-router-dom';

const Location = (props) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const selectedLocation = useSelector((state) => state.location.selectedLocation);
    const [locationId, setLocationId] = useState('');
    const [flag, setFlage] = useState(false);
    const [userDetail, setUserDetail] = useState([]);
    const [locationInfo, setLocationInfo] = useState(useSelector((state) => state.location.services));
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));

    const getServices = async () => {
        if (locationInfo.length === 0) {
            const response = await location(userInfo.accessToken);
            if (response.status === 200) {
                const seqReqBody = seqReqBodyObj('Information', `All Location retrieved successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
                setLocationInfo(response.data);
                localStorage.setItem('locations', JSON.stringify(response.data));
                dispatch(setLocations({locations: response.data}));
                setTimeout(() => {
                    dispatch(setServices({services: response.data}));
                }, 1000);
            }
        }
    };

    useEffect(() => {
        setUserDetail(JSON.parse(localStorage.getItem('userInfo')));
        getServices();
    }, []);

    const addLocation = async (data) => {
        const userInfoUpdated = JSON.parse(localStorage.getItem('userInfo'));
        dispatch(setIsLoading({isLoading: true}));
        const response = await setPreferedLocationWeb(userInfo.accessToken, {
            locationId: data.locationId,
            id: userInfo.customerIdentityID
        });
        if (response.status === 200) {
            setTimeout(() => {
                userInfoUpdated.preferedHomeLocation = data.locationId;
                localStorage.setItem('locationIdForService', data.locationId);
                dispatch(setUserInfo({userInfo: userInfoUpdated}));
                localStorage.setItem('userInfo', JSON.stringify(userInfoUpdated));
                dispatch(setServicesWash({services: []}));
                dispatch(setPrepaidWashPlans({prepaidWashPlans: []}));
                dispatch(setDetailingServices({detailingServices: []}));
                dispatch(setGiftCardAmounts({giftCardAmounts: []}));
                dispatch(setIsLoading(false));
                getServices();
                toast.success('Your active location has been changed successfully.', {autoClose: 3000, poistion: 'bottom-right'});
                setUserDetail(JSON.parse(localStorage.getItem('userInfo')));
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `Your active location has been changed successfully`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            dispatch(setIsLoading(false));
            toast.error('Something went wrong.', {autoClose: 3000});
        }
    };

    const getLocationDetail = async (data) => {
        setLocationId(data.locationId);
        if (!flag) {
            if (selectedLocation !== undefined && data.locationId === selectedLocation.locationId) {
                return false;
            }
            if (data.locationId == locationId) {
                return false;
            }
            dispatch(setIsLoading({isLoading: true}));
            const response = await locationDetail(userInfo.accessToken, {
                lat: data.sLatitude,
                lng: data.sLongitude,
                locationId: data.locationId,
                customerIdentityId: userInfo.customerIdentityID
            });
            if (response.status === 200) {
                setFlage(true);
                setTimeout(() => {
                    dispatch(setIsLoading(false));
                    dispatch(setSelectedLocation({selectedLocation: response.data}));
                }, 1000);
                setFlage(false);
                const seqReqBody = seqReqBodyObj('Information', `Location detail retrieved successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            } else if (response.status === 400) {
                dispatch(setIsLoading(false));
                dispatch(setSelectedLocation({selectedLocation: {}}));
            } else {
                dispatch(setSelectedLocation({selectedLocation: {}}));
                dispatch(setIsLoading(false));
                toast.error('Something went wrong.', {autoClose: 3000});
            }
        }
    };
    const weekDaysList = (data) => {
        if (data && Object.keys(data).length > 0) {
            const serviceArray = data.locationDetailDayHours;
            return (
                <ul className='timings'>
                    {serviceArray.map((value, index) => {
                        return (
                            <li key={index} className="d-flex justify-content-between">
                                <div>{value.day}</div>
                                <div>{value.hours}</div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    const locationDetailUI = (index, service) => {
        return (
            <div id={`loc_one_${index}`} className='accordion-collapse collapse' aria-labelledby='flush-headingOne' data-bs-parent='#accordionFlushExample'>
                <div className='accordion-body'>
                    <div className='loc_details'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-12 col-sm-6'>
                                <div className='loc_img'><img src={service.sStoreImageUrl} className='img-fluid' alt='' /></div>
                            </div>
                            <div className='col-xl-6 col-lg-12 col-sm-6 pt-3'>
                                <strong>Hours</strong>
                                {weekDaysList(selectedLocation)}
                            </div>
                            <div className='col-sm-6'>
                                <div className='add_fav_btn mt-3 text-center'>
                                    <button className={`btn btn-primary w-100 ${service.locationId == userDetail.preferedHomeLocation ? 'd-none' : ''}`} disabled = {service.locationId == userDetail.preferedHomeLocation} onClick={() => addLocation(service)}>Add as Favorite Location</button>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <ul className='contact_info'>
                                    <li><div><FiPhone className='me-2'/> Phone</div>
                                        <span>
                                            {service?.sPhone ?
                                                <ReactInputMask
                                                    className="headerPhone"
                                                    readOnly={true}
                                                    mask="(999) 999-9999"
                                                    maskChar=""
                                                    value={service?.sPhone}
                                                    name="phone"
                                                    placeholder="(000) 000-0000"
                                                /> : 'Contact number not available.'
                                            }
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                            <div className='main_title'>
                                <h2>Our Locations</h2>
                                <p>Manage your preferred location.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <div className='accordion loc_list' id='accordionFlushExample'>
                                {locationInfo.length === 0 && <SkeletonLocation count={5}></SkeletonLocation>}
                                {locationInfo.map((service, index) => {
                                    if (service.locationId == userDetail.preferedHomeLocation) {
                                        return (
                                            <div className='accordion-item' key={index} >
                                                <h2 className='accordion-header' style={{cursor: 'not-allowed'}} id='flush-headingOne'>
                                                    <button className='prefferd_location accordion-button collapsed px-sm-3 px-2' id={index} type='button' data-bs-toggle='collapse' data-bs-target={`#loc_one_${index}`} aria-expanded='false' aria-controls={`#loc_one_${index}`} onClick={() => {
                                                        getLocationDetail(service);
                                                    }}>
                                                        <div className='loc_box'>
                                                            <div className='d-flex align-items-center'>
                                                                <FiMapPin className='icon me-2 me-sm-3'/>
                                                                <div className='loc_info' style={{cursor: 'not-allowed'}}>
                                                                    <div className='loc_name'>{service.sName}</div>
                                                                    <div className='loc_address'>{service.sAddress}</div>
                                                                </div>
                                                            </div>
                                                            <div className='mi_links'>
                                                                <small className='more_info'>more details <FiChevronDown/></small>
                                                                <small className='hide_info'>hide details <FiChevronUp/></small>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                {locationDetailUI(index, service)}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className='accordion-item' key={index} >
                                                <h2 className='accordion-header' id='flush-headingOne'>
                                                    <button className='accordion-button collapsed px-sm-3 px-2' id={index} type='button' data-bs-toggle='collapse' data-bs-target={`#loc_one_${index}`} aria-expanded='false' aria-controls={`#loc_one_${index}`} onClick={() => {
                                                        getLocationDetail(service);
                                                    }}>
                                                        <div className='loc_box '>
                                                            <div className='d-flex align-items-center'>
                                                                <FiMapPin className='icon me-2 me-sm-3'/>
                                                                <div className='loc_info'>
                                                                    <div className='loc_name'>{service.sName}</div>
                                                                    <div className='loc_address'>{service.sAddress}</div>
                                                                </div>
                                                            </div>
                                                            <div className='mi_links'>
                                                                <small className='more_info'>more details <FiChevronDown/></small>
                                                                <small className='hide_info'>hide details <FiChevronUp/></small>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                {locationDetailUI(index, service)}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='loc_map'>
                            <LocationMap />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Location;
