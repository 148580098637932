import {createSlice} from '@reduxjs/toolkit';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const authInfo = JSON.parse(localStorage.getItem('authInfo'));
const phoneNumber = localStorage.getItem('phoneNumber');

const locationsData = JSON.parse(localStorage.getItem('locations'));
const customizedData=JSON.parse(localStorage.getItem('customizePage'));
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: userInfo !== null ? true : false,
        phoneNumber: phoneNumber !== null ? phoneNumber : null,
        userInfo: userInfo !== null ? userInfo : {},
        authInfo: authInfo !== null ? authInfo : {},
        locations: locationsData !== null ? locationsData : [],
        customizePage: customizedData !== null?customizedData:{},
        previousPage: '',
        customizePage: [],
        updateUserInfoOnUpdateUser: [],
        pageCommonTab: {
            isForMySelf: true,
            forOther: {
                FirstName: '',
                LastName: '',
                PhoneNumber: '',
                RecipientEmail: '',
                Message: ''
            }
        },
        isPolicy: true
    },
    reducers: {
        setLoginStatus: (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload.phoneNumber;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload.userInfo;
        },
        setAuthInfo: (state, action) => {
            state.authInfo = action.payload.authInfo;
        },
        setPreviousPage: (state, action) => {
            state.previousPage = action.payload.previousPage;
        },
        setCustomizePage: (state, action) => {
            state.customizePage = action.payload.customizePage;
        },
        setBarcodeCC: (state, action) => {
            state.barcodeCC = action.payload.barcodeCC;
        },
        setCreditCardN: (state, action) => {
            state.creditcardN = action.payload.creditcardN;
        },
        setCustomerCodeUrl: (state, action) => {
            state.customerCodeUrl = action.payload.customerCodeUrl;
        },
        setLocations: (state, action) => {
            state.locations = action.payload.locations;
        },
        updateUserInfoOnUpdateUser: (state, action) => {
            state.userInfo.firstname = action.payload.user.firstname;
            state.userInfo.lastname = action.payload.user.lastname;
            state.userInfo.customerEmail = action.payload.user.customerEmail;
            localStorage.setItem('phoneNumber', action.payload.user.phoneNumber);
            localStorage.setItem('userInfo', state.userInfo);
        },
        setCommonTabs: (state, action) => {
            state.pageCommonTab = action.payload.pageCommonTab;
        },
        setIsForMySelf: (state, action) => {
            state.pageCommonTab.isForMySelf = action.payload.isForMySelf;
        },
        setFormOtherData: (state, action) => {
            state.pageCommonTab.forOther= action.payload.forOther;
        },
        setIsPolicy: (state, action) => {
            state.isPolicy = action.payload.isPolicy;
        }
    }
});

export const {setCommonTabs, setIsForMySelf, setFormOtherData, setLoginStatus, setUserInfo, setAuthInfo, setPhoneNumber, setPreviousPage, setCustomizePage, setBarcodeCC, setCreditCardN, setCustomerCodeUrl, setLocations, updateUserInfoOnUpdateUser, setIsPolicy} = authSlice.actions;
export default authSlice.reducer;
