import API from './api';
const cancelMembershipList = async (accessToken) => {
    return await API.get('CustomerCancelReasons', {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
const customerCode = localStorage.getItem('customerCode');
const cancelMemberShip = async (accessToken, postData, IsEmailSend, purchaseDate) => {
    return await API.post(`Customers/DeactivateCustomerAccountWeb?IsMailSend=${IsEmailSend}&sCancelDate=${purchaseDate}`, postData, {
        headers: {
            'X-Authentication-Token': accessToken,
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
const cancelAllow = async (accessToken) => {
    return await API.get('Customers/IsCancelAllow', {
        headers: {
            'X-Authentication-Token': accessToken,
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error.response;
    });
};
const pauseAndActiveMemberShipFromHold = async (accessToken, postData) => {
    const customerGuid = Number(postData.customerGuid);
    const action = postData.action;
    return API.post(`/Customers/PauseAndActiveMemberShipFromHold?customerGuid=${customerGuid}&action=${action}`, null, {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error.response;
    });
};
export {cancelMembershipList, cancelMemberShip, cancelAllow, pauseAndActiveMemberShipFromHold};
