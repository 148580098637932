import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FiArrowLeft} from 'react-icons/fi';
import {FiTrash} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import AlertModalModule from '../modals/AlertModal';
import Layout from '@components/Layout';
import PaymentCard from '../order/PCI/PaymentCard';
// Services
import {creditCardsList, deleteCreditCard, updateCreditCardInfo} from '@services/manage-account-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setCreditCards} from '@store/reducers/manage-account-reducer';
// Utils
import {GetCreditCardIcon as getCCIcon, getPCIDetailsLocal} from '@utils/common-repo';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import SkeletonOrderSummaryCC from '@utils/skeletons/SkeletonOrderSummaryCC';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './PaymentOptions.css';
import * as Yup from 'yup';
import CardknoxPaymentCard from '../order/PCI/CardknoxPaymentCard';
import DataCapPaymentCard from '../order/PCI/DataCapPaymentCard';
import {Formik} from 'formik';
// import axios from 'axios';

const PaymentOptions = (props) => {
    const dispatch = useDispatch();
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const previousPage = useSelector((state) => state.auth.previousPage);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(creditCards.length ? false : true);
    const [CardId, setCardId] = useState('');
    const [viewCardForm, setViewCardForm] = useState(false);
    const [Loader, setLoader] = useState(false);
    const [preferedAlertModalData, setPreferedAlertModalData] = useState({});
    const [preferedModal, setPreferedModal] = useState(false);
    const [newPreferredCard, setNewPreferredCard]= useState({});
    const [alertModalData, setAlertModalData] = useState({});
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const [isZipModalOpen, setIsZipModal] = useState(false);
    const [sBillPostalCode, setSbillPostalCode] = useState('');

    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 5; index++) {
            content.push(<SkeletonOrderSummaryCC key={index} />);
        }
        return content;
    };

    const expandPCIForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setViewCardForm(true);
    };

    const deletePaymentCard = async () => {
        setIsLoading(true);
        const response = await deleteCreditCard(userInfo.accessToken, CardId);
        if (response.status === 200) {
            setIsLoading(false);
            getCreditCardsList(true);
            toast.success('Credit Card Deleted Successfully.', {autoClose: 3000});
            const seqReqBody = seqReqBodyObj('Information', `Credit Card Deleted Successfully`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            setIsLoading(false);
            toast.error('Something went wrong.', {autoClose: 3000});
        }
    };

    const setPaymentOption = () => {
        setModal(false);
    };
    const handleShow = (paymentOption, id) => {
        setCardId(id);
        setAlertModalData({
            modalClass: 'alert_pop card_delete',
            title: 'Are you sure?',
            message: 'Do you really want to delete this credit card?',
            btnSuccessText: 'Yes, Delete it',
            btnCancelText: 'No, Cancel',
            btnPrimary: true
        });
        setModal(true);
    };

    const getCreditCardsList = async (isForceLoad) => {
        if (creditCards.length === 0 || isForceLoad) {
            setIsLoading(true);
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            if (response.status === 200) {
                setTimeout(() => {
                    setIsLoading(false);
                    dispatch(setCreditCards({creditCards: response.data}));
                    localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
                }, 1000);
                const seqReqBody = seqReqBodyObj('Information', `All credit cards retrieved Successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            } else {
                setIsLoading(false);
            }
        }
    };

    const PCIScript = getPCIDetailsLocal().PCIScript;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [PCIScript]);

    useEffect(() => {
        getCreditCardsList(true);
    }, [creditCards.length === 0]);

    // Change preffered card
    const showChangePreferredCardConfirmationPopup = (card) => {
        setPreferedAlertModalData({
            modalClass: 'alert_pop change_card',
            title: 'Change Preferred Card',
            message: 'Do you want to change preferred card?',
            btnSuccessText: 'Yes',
            btnCancelText: 'No',
            btnPrimary: true
        });
        setNewPreferredCard(card);
        if (card.hasOwnProperty('sBillPostalCode')) {
            if (card.sBillPostalCode === '' || card.sBillPostalCode === null) {
                setIsZipModal(true);
            } else {
                setPreferedModal(true);
            }
        } else {
            setIsZipModal(true);
        }
    };

    const submitChangePreferredCard = async () => {
        setPreferedModal(false);
        setLoader(true);
        const expiryDate= `${String(newPreferredCard.sExpiredMonth)}${String(newPreferredCard.sExpireYear.length > 2 ? newPreferredCard.sExpireYear.substr(2, 2) : newPreferredCard.sExpireYear)}`;
        const postJson= {
            'customerGUID': userInfo.customerGuid,
            'sMaskedCCNumber': `${newPreferredCard.sBin}xxxxxx${newPreferredCard.sSpan}`,
            'sCCExpDate': expiryDate,
            'token': newPreferredCard.sPayerIdentifier,
            'sCCType': '',
            'sCCHolderName': '',
            'customerIdentityID': userInfo.customerIdentityID,
            'sBin': newPreferredCard.sBin,
            'sSpan': newPreferredCard.sSpan,
            'sBillPostalCode': sBillPostalCode
        };

        const response = await updateCreditCardInfo(userInfo.accessToken, postJson);
        if (response.status == 200) {
            const seqReqBody = seqReqBodyObj('Information', `Successfully set preferred card detail `, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            if (response.status === 200) {
                const seqReqBody = seqReqBodyObj('Information', `Card list retrieve successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
                dispatch(setCreditCards({creditCards: response.data}));
            } else {
                dispatch(setCreditCards({creditCards: []}));
            }
            setPreferedModal(false);
            setLoader(false);
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
            setLoader(false);
            const seqReqBody = seqReqBodyObj('Worning', `${response.data.title}`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
            setLoader(false);
        }
    };

    const [card, setCard] = useState('');
    const restDataCapForm = () => {
        setCard('');
        // document.querySelector('[data-token="card_number"]').value = '';
        document.querySelector('[data-token="exp_month"]').value = '';
        document.querySelector('[data-token="exp_year"]').value = '';
        document.querySelector('[data-token="cvv"]').value = '';
        document.getElementById('cardHolderName').value = '';
        document.getElementById('billingZipCodeDataCap').value = '';
    };

    return (
        <Layout>
            {Loader === true ? <FullScreenLoader /> : ''}
            {preferedModal ? <AlertModalModule data={preferedAlertModalData} targetEvent={() => submitChangePreferredCard()} closeModal={() => setPreferedModal(false)} /> : '' }
            <div className='container'>
                <div className='row'>
                    {previousPage!=='/dashboard'?
                        <div className='col-12 mb-3'>
                            <Link to='/manage_account' className='btn btn-outline-secondary icon_btn'><FiArrowLeft/> Back</Link>
                        </div>:
                        <div className='col-12 mb-3'>
                            <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><FiArrowLeft/> Back</Link>
                        </div>
                    }
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center flex-wrap'>
                            <div className='main_title'>
                                <h2>Change Card</h2>
                                <p>Manage credit cards</p>
                            </div>
                            {gateWayType == 'openEdge' &&
                                <button type='button' className='btn btn-primary' data-bs-toggle='modal'
                                    data-bs-target='#addCardpop' disabled={isLoading} onClick={(event) => expandPCIForm(event)}>
                                    <span>+ Add a New Card</span></button>
                            }
                            {gateWayType == 'cardknox' &&
                                <button type='button' className='btn btn-primary' data-bs-toggle='modal'
                                    data-bs-target='#addCardpop3' disabled={isLoading}>
                                    <span>+ Add a New Card</span></button>
                            }
                            {gateWayType == 'dataCap' &&
                            <button type='button' className='btn btn-primary' data-bs-toggle='modal'
                                data-bs-target='#addCardpop4' disabled={isLoading}>
                                <span>+ Add a New Card</span></button>
                            }
                        </div>
                    </div>
                </div>
                {modal ? <AlertModalModule data={alertModalData} targetEvent={() => {
                    setPaymentOption();
                    deletePaymentCard();
                }} closeModal={() => setModal(false)} /> : ''}
                <div className='row'>
                    {(creditCards.length > 0 && !isLoading) ?
                        <div className="col-lg-12 mb-2"><h5>Your Saved Cards</h5></div>:''
                    }
                    <div className='col-lg-12'>
                        <div className='cc_card_list change_cards mb-2'>
                            {isLoading && contentLoader()}
                            {(creditCards.length > 0 && !isLoading) ? creditCards.map((cc, index) => {
                                const ccDetail= getCCIcon(cc);
                                return (
                                    <div className={`cc_card ${cc.bIsRecurring?'cc_card_rc':''}`} key={index}>
                                        <div className='form-check'>
                                            <input
                                                className='form-check-input check_pointer d-none'
                                                type='radio'
                                                name='ccCardRadio'
                                                key={index}
                                                id={`card-${cc.id}`}
                                                onClick = {()=>showChangePreferredCardConfirmationPopup(cc)}
                                            />
                                            <label className='form-check-label' htmlFor={`card-${cc.id}`}>
                                                <div className="cc_number ms-0">
                                                    <div className='text-start mb-2'>xxxx xxxx xxxx {cc.sSpan}</div>
                                                    <div className='cc_more_info'>
                                                        <div className='exp_date'>Expiration Date: {cc.sExpiredMonth}/{cc.sExpireYear}</div>
                                                        {cc.bIsRecurring!=null && cc.bIsRecurring ?
                                                            <div className='rc_card_badge'>Primary Card</div> : '' }
                                                    </div>
                                                </div>
                                                <div className='cc_type_action'>
                                                    <div className='card_type'>
                                                        <span className={`card_icon ${ccDetail.icon}`}></span>
                                                    </div>
                                                </div>
                                            </label>
                                            {cc.bIsRecurring !=true ? <div className='remove_btn' onClick={() => handleShow(cc.sSpan, cc.id)}><FiTrash/></div>: ''}
                                        </div>
                                    </div>
                                );
                            }) : ''}
                        </div>
                        {creditCards.length === 0 && isLoading === false && <p className='notFound alert alert-info'>Credit Cards not available.</p>}
                    </div>
                </div>
            </div>
            <div className='modal fade cw_modal add_card_pop' id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCancel" data-bs-dismiss="modal" onClick={() => {
                            setViewCardForm(false);
                        }}></button>
                        <div className='modal-body'>
                            {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} hidePop={() => setViewCardForm(false)} /> : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade cw_modal add_card_pop' id='addCardpop3' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCancelCardKnox" data-bs-dismiss="modal"></button>
                        <div className='modal-body'>
                            <CardknoxPaymentCard loader={(val) => setLoader(val)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade cw_modal add_card_pop' id='addCardpop4' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCanceldataCap" data-bs-dismiss="modal" onClick={() => {
                            console.log('dataCap');
                            restDataCapForm();
                        }}></button>
                        <div className='modal-body'>
                            <DataCapPaymentCard cardInfo={card} setCardInfo={setCard} restDataCapForm={restDataCapForm} loader={(val) => setLoader(val)} />
                        </div>
                    </div>
                </div>
            </div>
            {/* zip code  */}
            <div className={`modal fade cw_modal add_card_pop ${isZipModalOpen ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <Formik
                            initialValues={{zipcode: ''}}
                            onSubmit={(values, {resetForm}) =>{
                                setSbillPostalCode(values.zipcode);
                                setIsZipModal(false);
                                setPreferedModal(true);
                                resetForm();
                            }}
                            validationSchema={Yup.object().shape({
                                zipcode: Yup.string()
                                    .required('Bill Zip Code is required.')
                                    .matches(/^[0-9]+$/, 'Bill Zip Code must be only digits.')
                                    .min(5, 'Bill Zip Code must be min 5 digits.')
                                    .max(6, 'Please enter valid Bill Zip Code.')
                            })}
                        >
                            {({values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isValid,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='modal-body zip_input_modal pt-4 pb-4'>
                                        <input
                                            type='text'
                                            name='zipcode'
                                            id='zipcode'
                                            className='form-control form-control-lg mb-1 text-center'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.zipcode}
                                            placeholder='Please enter your Zip Code.'
                                        />
                                        {touched.zipcode && errors.zipcode ? (
                                            <small className="text-danger">{errors.zipcode}</small>
                                        ) : null}
                                    </div>
                                    <div className='modal-footer justify-content-center'>
                                        <button type='submit' className='btn btn-primary' disabled={!isValid||values.zipcode===''}>Submit</button>
                                        <button type='button' className='btn btn-secondary' onClick={() => {
                                            setIsZipModal(false);
                                            resetForm();
                                        }}>Cancel</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {isZipModalOpen ? <div className="modal-backdrop fade show"></div>:''}
        </Layout>
    );
};

export default PaymentOptions;
