import API from './../api';

const getActiveQuickBuyServices = async (customerCode) => {
    try {
        return await API.get('QuickBuy/GetActiveQuickBuyServices', {
            headers: {
                'X-Customer-Code': customerCode,
                'X-Location-Id': '',
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        console.clear();
        return error;
    }
};

export {getActiveQuickBuyServices};
