import API from './api';

export const location = async (accessToken) => {
    return await API.get(`Locations/GetLocationsMobile`, {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const locationDetail = async (accessToken, reqBody) => {
    return await API.post(`Locations/LocationDetailById`, reqBody, {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error.response;
    });
};

export const nearByLocation = async (accessToken, reqBody) => {
    return await API.post('Locations/SetNearByLocation', reqBody, {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const getLocationsForQuickBuy = async (customerCode) => {
    return await API.get('QuickBuy/getLocationsForQuickBuy', {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const verifyZipcode = async (zipcode) => {
    return await API.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAW9jj6HnCRWDP6Xb9I8Pf2Mk8c0-LWgj4&components=postal_code:${zipcode}`, {
    }).catch((error) => {
        return error;
    });
};

export const setPreferedLocationWeb = async (accessToken, reqBody) =>{
    return await API.post('Locations/Location/SetPreferedLocationWeb', reqBody, {
        headers: {
            'X-Authentication-Token': accessToken
        }
    }).catch((error)=>{
        return error.response;
    });
};
