import API from '../api';
export const loginApi = async (phoneNumber) => {
    return await API.get(`CustomerIdentities/LoginByPhone/?phone=${phoneNumber}`, {headers: {
        'Content-Type': 'application/json',
        'X-Customer-Code': localStorageCustomerCode,
        'X-Location-Id': '',
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        console.clear();
        return error;
    });
};

export const employeeLoginApi = async (data) => {
    const reqBody = {
        userName: data.userName,
        password: data.password
    };
    return await API.post('Login/OwnerEmployeeLogin', reqBody, {headers: {
        'Content-Type': 'application/json',
        'X-Customer-Code': data.customerCode,
        'X-Location-Id': '',
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        console.clear();
        return error;
    });
};
