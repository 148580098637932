import React, {useEffect, useState} from 'react';
// Services
import {getUnlimitedTypesForLocation} from '@services/employee-module-services/employee-plan-service';
// Utils
import {fetchPCIDetailsFromServer, getPCIDetailsLocal} from '@utils/common-repo';
import SkeletonEmpWashCard from '@utils/skeletons/SkeletonEmpWashCard';
import {currencyFormat} from '@utils/common-helper';
// Components
import TermsModal from '@components/modals/TermsModal';

const EmployeeSelectPlan = (props) =>{
    const [checkAvalablePlan, setCheckAvalablePlan] = useState(true);
    const [unlimietPlanTypesData, setUnlimitedPlanTypesData] = useState([]);
    const [description, setDescription] = useState('');
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [termAndCondtion, setTermAndCondition] = useState(false);
    const [activeId, setActiveId] = useState('');
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(unlimietPlanTypesData.length ? false : true);


    const handleClick = (e, data, index) => {
        setActiveId(index);
        setDescription(data.sDescription);
        setSubTotal(data.dblAmount);
        setDiscount(0);
        setTax(0);
        setTotal(data.dblAmount);
        console.log(data, checkAvalablePlan);
        props.callBackEmployee(data);
    };
    useEffect(() => {
        const getPlan = async () => {
            const response = await getUnlimitedTypesForLocation();
            if (response.status === 200) {
                setUnlimitedPlanTypesData(response.data);
                setIsLoading(false);
                setTimeout(async () => {
                    await fetchPCIDetailsFromServer();
                    const PCIScript= getPCIDetailsLocal().PCIScript;
                    const script = document.createElement('script');
                    script.src = PCIScript;
                    script.async = false;
                    document.body.appendChild(script);
                }, 1000);
            } else {
                setCheckAvalablePlan(false);
            }
        };
        setCheckAvalablePlan(true);
        getPlan();
        setCheckAvalablePlan(false);
    }, []);
    const handleChangeTerm = (e) => {
        setTermAndCondition(e.target.checked);
        props.handleTermAndCondtion(e.target.checked);
    };
    const filterData = unlimietPlanTypesData.filter((item) => item.dblAmount !== 0 && item.dblAmount !== null);
    return (
        <React.Fragment>
            {modal ? <TermsModal closeModal={() => setModal(false)} /> : ''}
            <form className='subs_form p-4'>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='mt-3 mb-3'>
                            <label className='form-label mb-3'>Select Unlimited Wash Plans<sup className='text-danger'>*</sup></label>

                            <div className='plans_list'>
                                {isLoading ?
                                    <>
                                        <SkeletonEmpWashCard type='plan_box emp_wash_loder'/>
                                        <SkeletonEmpWashCard type='plan_box emp_wash_loder'/>
                                    </> : ''
                                }
                                {filterData.map((items, index)=>(
                                    // eslint-disable-next-line react/jsx-key
                                    <div className={`plan_box  ${index === activeId? 'plan_active' : ''}`} data-user='123' onClick={((e) => handleClick(e, items, index))} key={index} id={`emp_pln`+index}>
                                        <h5>{items.sDescription}</h5>
                                        <span>${items.dblAmount.toFixed(2)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 offset-md-1'>
                        <div className='order_summ_wrap'>
                            <div className='summary_card mt-3'>
                                <div className='list_items border-bottom mb-3 pb-4'>
                                    <span>Subscription:</span>
                                    <b className='d-block mt-3'>{description}</b>
                                </div>
                                <ul className='list_items order_details'>
                                    <li><span>Subtotal:</span> <span>{currencyFormat(subTotal)}</span></li>
                                    <li><span>Discount:</span> <span>{currencyFormat(discount)}</span></li>
                                    <li><span>Tax:</span> <span>{currencyFormat(tax)}</span></li>
                                    <li><b>Total:</b> <b>{currencyFormat(total)}</b></li>
                                </ul>
                            </div>
                            <div className='condi_checks pt-4'>
                                <div className='form-check'>
                                    <input className='form-check-input check_pointer' type='checkbox' id='termcondi' {...(termAndCondtion ? {checked: true} : {checked: false})} onChange={(event) => handleChangeTerm(event)} />
                                    <label className='form-check-label'>
                                        {/* I Agree to the and understand starting today my credit card will be charged monthly until I discontinue
                                    my membership. */}
                                    I agree to the terms and conditions and understand starting today my credit card will be charged monthly until I discontinue my membership.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

export default EmployeeSelectPlan;
