import {FiArrowRight, FiUser} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import {GetCreditCardIcon as getCCIcon, getPCIDetailsLocal, isStringNullOrEmpty} from '@utils/common-repo';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {dateFormatterWithoutTime} from '@utils/date-formatter';
import {initFormik} from '@utils/formik-helper';
// Validation Schema
import barcodeSchema from '@validation-schemas/barcode-schema';
// Services
import {checkExistBarcode} from '@services/unlimited-plan-service';
import {creditCardsList} from '@services/manage-account-service';
import {pauseAndActiveMemberShipFromHold} from '@services/cancel-membership-service';
import {seqLogger} from '@services/seq-logger-service';
// Components
import PaymentCard from '@components/order/PCI/PaymentCard';
// Store
import AlertModalModalUnlimitedInfo from '../components/modals/AlertModalModalUnlimitedInfo';
import CardknoxPaymentCard from '../components/order/PCI/CardknoxPaymentCard';
import DataCapPaymentCard from '../components/order/PCI/DataCapPaymentCard';
import {checkStr} from '../utils/common-helper';
import {getCardTypeFromIIN} from '../utils/common-repo';
import {setCreditCards} from '@store/reducers/manage-account-reducer';
import {setPreviousPage} from '@store/reducers/auth-reducer';

const Account = (props) => {
    toast.configure();
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const [viewCardForm, setViewCardForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [alertModalData, setAlertModalData] = useState({});
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');
    const customerInfo = JSON.parse(localStorage.getItem('customerDetail'));
    const preferredCardDetails = creditCards.filter((creditCards) => creditCards.bIsRecurring);
    const initialValuesLinkExistingPlan = {barCode: '', ccNumber: ''};
    let ccDetail = {};
    let firstName = '';
    let lastName = '';
    const PCIScript = getPCIDetailsLocal().PCIScript;
    const bShowPause = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalSettingsList.find((value) => value.name === 'AllowPauseMembership');
    if (customerInfo !== null) {
        firstName = customerInfo.customer.sFirstName;
        lastName = customerInfo.customer.sLastName;
    }

    if (isStringNullOrEmpty(firstName) && !isStringNullOrEmpty(userInfo.firstname)) {
        firstName = userInfo.firstname;
    }

    if (isStringNullOrEmpty(lastName) && !isStringNullOrEmpty(userInfo.lastname)) {
        lastName = userInfo.lastname;
    }

    const onClickLinkExistingPlanSubmit = async (values, resetForm) => {
        const linkExtObj = {
            barCode: values.barCode,
            cardNumber: values.ccNumber,
            CustomerIdentityId: userInfo.customerIdentityID
        };
        const result = await checkExistBarcode(userInfo.accessToken, linkExtObj);
        if (result.status == 200) {
            localStorage.setItem('customerGuid', result.data);
            props.getCustomerInfo();
            resetForm();
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success('Existing plan linked successfully.');
                const seqReqBody = seqReqBodyObj('Information', `Existing plan linked successfully`, `${customerInfo.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
        } else if (result.status == 404) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(result.data);
                const seqReqBody = seqReqBodyObj('Information', `${result.data}`, `${customerInfo.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Some error occurred.');
            }
        }
    };

    const linkExistingformik = initFormik(initialValuesLinkExistingPlan, barcodeSchema, onClickLinkExistingPlanSubmit, true);

    const onClickPauseAndActiveMember = async () => {
        setLoader(true);
        const postData = {};
        let bMonthlyHoldVal = 'Resume';
        postData.customerGuid = localStorage.getItem('customerGuid');
        if (customerInfo.customerDetail.dtMonthlyPaused === null) {
            bMonthlyHoldVal = 'Pause';
        }
        postData.action = bMonthlyHoldVal;
        const response = await pauseAndActiveMemberShipFromHold(userInfo.accessToken, postData);
        if (response.status == 200) {
            setLoader(false);
            props.getCustomerInfo();
            const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response.status == 400) {
            setLoader(false);
            toast.error(response.data);
        } else {
            setLoader(false);
            toast.error('Something went wrong.');
        }
    };

    const getCreditCardsList = async () => {
        if (creditCards.length === 0) {
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            const seqReqBody = seqReqBodyObj('Information', `Card list retrieve successfully`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
            if (response.status === 200) {
                dispatch(setCreditCards({creditCards: response.data}));
                localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
            } else {
                dispatch(setCreditCards({creditCards: []}));
            }
        }
    };

    if (preferredCardDetails.length > 0) {
        ccDetail = getCCIcon(preferredCardDetails[0]);
    }

    useEffect(()=>{
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);

        getCreditCardsList();
    }, [creditCards.length == 0]);

    const [card, setCard] = useState('');
    const restDataCapForm = () => {
        setCard('');
        // document.querySelector('[data-token="card_number"]').value = '';
        document.querySelector('[data-token="exp_month"]').value = '';
        document.querySelector('[data-token="exp_year"]').value = '';
        document.querySelector('[data-token="cvv"]').value = '';
        document.getElementById('cardHolderName').value = '';
        document.getElementById('billingZipCodeDataCap').value = '';
    };

    const expandPCIForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setViewCardForm(true);
    };

    const ShowPlanStatus = () => {
        const cSharpNullDateInSQL = new Date(null);
        const currentDate = new Date();
        const monthlyNextPay = new Date(customerInfo?.customerDetail?.dtMonthlyNextPay);
        const DifferenceInTime = monthlyNextPay.getTime() - currentDate.getTime();
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

        if ( customerInfo?.customerDetail != null &&customerInfo?.customerDetail.bMonthlyActive != null && customerInfo?.customerDetail.bMonthlyActive) {
            return (
                <Link to='/unlimited_plans' onClick={()=>{
                    localStorage.setItem('isReactivate', 'false');
                    dispatch(setPreviousPage({previousPage: '/dashboard'}));
                }
                }>
                    <div className="myacc_cm_detail">
                        <div className="" >
                            <h5>Unlimited Membership</h5>
                            <span className='me-2'>{customerInfo.vipAccountType?.sDescription}</span>
                            <strong>{currencyFormat(customerInfo.vipAccountType?.dblAmount ?? '')}</strong>
                            {customerInfo.vipAccountType.bUnlimitedRecurring?
                                <div className="plan_link">
                                    {customerInfo.customerDetail.dtMonthlyNextPay != null && customerInfo.customerDetail.dtMonthlyNextPay != cSharpNullDateInSQL?
                                        <span className='next_paydate'>Next Pay: <b>{dateFormatterWithoutTime(customerInfo.customerDetail.dtMonthlyNextPay)}</b></span>:''}
                                    <span className='active_btn' href="#">Active</span>
                                </div>:
                                <div className="plan_link">
                                    {customerInfo.customerDetail.dtMonthlyExpires != null && customerInfo.customerDetail.dtMonthlyExpires != cSharpNullDateInSQL?
                                        <span className='next_paydate'>Expires on: <b>{dateFormatterWithoutTime(customerInfo.customerDetail.dtMonthlyExpires)}</b></span>:''}
                                    <span className='active_btn' href="#">Active</span>
                                </div>
                            }
                        </div>
                        <div className="card_arrow">
                            <span><FiArrowRight/></span>
                        </div>
                    </div>
                </Link>
            );
        } else if (customerInfo?.customerDetail != null && customerInfo?.customerDetail.bMonthlyActive != null && customerInfo?.customerDetail.bMonthlyActive == false && customerInfo?.customerDetail.dtMonthlyPaused != null) {
            return (
                <div className="myacc_cm_detail">
                    <div className="">
                        <h5>Unlimited Membership</h5>
                        <span className='me-2'>{customerInfo?.vipAccountType?.sDescription}</span>
                        <strong>{currencyFormat(customerInfo?.vipAccountType?.dblAmount ?? '')}</strong>
                        <div className="plan_link">
                            <span className='inactive_btn' href="#">Inactive</span>
                            {bShowPause?.value?.toUpperCase() == 'FALSE' ? '' :
                                <a onClick={()=>onClickPauseAndActiveMember()} data-bs-toggle="offcanvas" href="#"
                                    role="button" aria-controls="offcanvasExample">Resume Membership</a>
                            }
                        </div>
                    </div>
                    <div className="card_arrow">
                        <a href="#"><FiArrowRight/></a>
                    </div>
                </div>
            );
        } else if ( customerInfo?.customerDetail != null && customerInfo?.customerDetail.bMonthlyDeclined != null && customerInfo?.customerDetail.bMonthlyDeclined == true) {
            return (
                <div className="myacc_cm_detail" onClick={()=>{
                    setModal(true);
                    setAlertModalData({
                        modalClass: 'alert_pop',
                        title: 'Account info Expires Soon!',
                        message: 'Please update your account details at the nearest location.',
                        btnSuccessText: 'Yes',
                        btnCancelText: 'No',
                        btnPrimary: true
                    });
                }}>
                    <div className="">
                        <h5>Unlimited Membership</h5>
                        <span className='text-danger'>Please update your account details at the nearest location,your credit card is declined.</span>
                    </div>
                    <div className="card_arrow">
                        <span><FiArrowRight/></span>
                    </div>
                </div>
            );
        } else if ( customerInfo?.customerDetail != null && customerInfo?.customerDetail.dtMonthlyNextPay !=null && customerInfo?.customerDetail.dtMonthlyNextPay !=cSharpNullDateInSQL) {
            if (customerInfo.location.iInactiveDaysForReactivation < DifferenceInDays) {
                return (
                    <Link to='/unlimited_plans' onClick={()=>{
                        localStorage.setItem('isReactivate', 'true');
                        localStorage.setItem('isNewUnlimited', 'false');
                        dispatch(setPreviousPage({previousPage: '/dashboard'}));
                    }}>
                        <div className="myacc_cm_detail">
                            <div className="">
                                <h5>Unlimited Membership</h5>
                                <span className='me-2'>{customerInfo.vipAccountType?.sDescription}</span>
                                <strong>{currencyFormat(customerInfo.vipAccountType?.dblAmount ?? '')}</strong>
                                <div className="plan_link">
                                    <span className='inactive_btn' href="#">Inactive</span>
                                    <small className='link-primary'>Reactivate Membership</small>
                                </div>
                            </div>
                            <div className="card_arrow">
                                <span><FiArrowRight/></span>
                            </div>
                        </div>
                    </Link>
                );
            } else {
                return (
                    <Link to='/unlimited_plans' onClick={()=>{
                        localStorage.setItem('isReactivate', 'false');
                        localStorage.setItem('isNewUnlimited', 'true');
                        dispatch(setPreviousPage({previousPage: '/dashboard'}));
                    }
                    }>
                        <div className="myacc_cm_detail">
                            <div className="">
                                <h5>Unlimited Membership</h5>
                                <span>You don't have an unlimited membership</span>
                                <div className="plan_link">
                                    <small className='link-primary'>+ Add New Plan</small>
                                </div>
                            </div>
                            <div className="card_arrow">
                                <span><FiArrowRight/></span>
                            </div>
                        </div>
                    </Link>
                );
            }
        } else {
            return (
                <Link to='/unlimited_plans' onClick={()=>{
                    localStorage.setItem('isReactivate', 'false');
                    localStorage.setItem('isNewUnlimited', 'true');
                    dispatch(setPreviousPage({previousPage: '/dashboard'}));
                }
                }>
                    <div className="myacc_cm_detail">
                        <div className="">
                            <h5>Unlimited Membership</h5>
                            <span>You don't have an unlimited membership</span>
                            <div className="plan_link">
                                <small className='link-primary'>+ Add New Plan</small>
                            </div>
                        </div>
                        <div className="card_arrow">
                            <span><FiArrowRight/></span>
                        </div>
                    </div>
                </Link>
            );
        }
    };

    return (
        <React.Fragment>
            {loader === true ? <FullScreenLoader /> : ''}
            {modal?<AlertModalModalUnlimitedInfo data={alertModalData} targetEvent={()=>setModal(false)}/>:''}
            <div className="accordion-item mb-4">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <div className="acc_profile">
                            <a href="#" className='userIcon'><FiUser/></a>
                            <div className="">
                                {/* <h5>{firstName} {lastName}</h5> */}
                                <h5 className='text-capitalize'>{checkStr(firstName)} {checkStr(lastName)}</h5>
                            </div>
                        </div>
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <Link to='/manage_account'>
                            <div className="myacc_cm_detail">
                                <div className="">
                                    <h5>My Account</h5>
                                    <span>Manage your account info</span>
                                </div>
                                <div className="card_arrow"><FiArrowRight/></div>
                            </div>
                        </Link>
                        <ShowPlanStatus />
                        {creditCards != null && creditCards.length > 0 ?
                            <Link to="/payment-options" onClick={()=>dispatch(setPreviousPage({previousPage: '/dashboard'}))}>
                                <div className="myacc_cm_detail myacc_cp_detail">
                                    <div className="">
                                        <h5>Payment Options</h5>
                                        {preferredCardDetails != null && preferredCardDetails.length > 0 ?
                                            <>
                                                <span>{getCardTypeFromIIN(preferredCardDetails[0]?.sBin)} ending with {preferredCardDetails[0].sSpan}</span>
                                                <div className='d-flex'>
                                                    <span>Expires on: {preferredCardDetails[0].sExpiredMonth}/{preferredCardDetails[0].sExpireYear}</span>
                                                    <div className={`card_icon ms-2 ${ccDetail.icon}`}></div>
                                                </div>
                                                <div className="plan_link">
                                                    <small className='link-primary'>Change Card</small>
                                                </div>
                                            </> :
                                            <>
                                                <span>No primary card found.</span>
                                                <div className="plan_link">
                                                    <small className='link-primary'>Manage Card</small>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="card_arrow">
                                        <span><FiArrowRight/></span>
                                    </div>
                                </div>
                            </Link> :
                            <div className="myacc_cm_detail myacc_cp_detail">
                                <div className="">
                                    <h5>Payment Options</h5>
                                    <span>No card found.</span>
                                    <div className="plan_link">
                                        {gateWayType == 'openEdge' &&
                                            <button type='button' data-bs-toggle='modal' data-bs-target='#addCardpop2' onClick={(event) => expandPCIForm(event)}>+ Add Card</button>}
                                        {gateWayType == 'cardknox' &&
                                            <button type='button' data-bs-toggle='modal' data-bs-target='#addCardpop3'>+ Add Card</button>
                                        }
                                        {gateWayType == 'dataCap' &&
                                        <button type='button' data-bs-toggle='modal' data-bs-target='#addCardpop4'>+ Add Card</button>
                                        }
                                    </div>
                                </div>
                                <div className="card_arrow">
                                    <Link to="#"><FiArrowRight/></Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="cw_offcanvas ">
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="link_existing_offcanvas"
                    aria-labelledby="link_existing_offcanvasLabel1">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="link_existing_offcanvasLabel">Link Existing Plan</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <form onSubmit={linkExistingformik.handleSubmit}>
                            <div className="exstingplan_detail">
                                <div className="mb-4">
                                    <label htmlFor="">Barcode or License Plate # and CC*</label>
                                    <input type="text" name="barCode" placeholder="Enter Code"
                                        maxLength='20'
                                        onChange={linkExistingformik.handleChange}
                                        onBlur={linkExistingformik.handleBlur}
                                        value={linkExistingformik.values.barCode}
                                    />
                                    {linkExistingformik.touched.barCode && linkExistingformik.errors.barCode ? (
                                        <small className="text-danger validationError">{linkExistingformik.errors.barCode}</small>
                                    ) : null}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="">Card Number (Last 4 digits)*</label>
                                    <input type="text" name="ccNumber" maxLength="4" placeholder="0  0  0  0"
                                        onChange={linkExistingformik.handleChange}
                                        onBlur={linkExistingformik.handleBlur}
                                        value={linkExistingformik.values.ccNumber}
                                        min="0"
                                        max="10000"
                                    />
                                </div>
                            </div>
                            <div className="mx-2 row">
                                <div className='col-6'>
                                    <button className="btn btn-secondary w-100" data-bs-dismiss="offcanvas" onClick={()=>{
                                        linkExistingformik.errors.barCode='';
                                    }}>Cancel</button>
                                </div>
                                <div className='col-6'>
                                    <button className="btn btn-primary w-100" disabled={!linkExistingformik.isValid ||linkExistingformik.values.barCode === ''||linkExistingformik.values.ccNumber === '' }>Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='modal fade cw_modal add_card_pop' id='addCardpop2' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCancel" data-bs-dismiss="modal" onClick={() => {
                            setViewCardForm(false);
                        }}></button>
                        <div className='modal-body'>
                            {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} hidePop={() => setViewCardForm(false)} /> : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade cw_modal add_card_pop' id='addCardpop3' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCancelCardKnox" data-bs-dismiss="modal" onClick={() => {
                            // setViewCardForm(false);
                            console.log('cardknox');
                        }}></button>
                        <div className='modal-body'>
                            <CardknoxPaymentCard loader={(val) => setLoader(val)} />
                            {/* {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} hidePop={() => setViewCardForm(false)} /> : ''} */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade cw_modal add_card_pop' id='addCardpop4' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <button type="button" className="btn-close" id="btnCCPopCanceldataCap" data-bs-dismiss="modal" onClick={() => {
                            console.log('dataCap');
                            restDataCapForm();
                        }}></button>
                        <div className='modal-body'>
                            <DataCapPaymentCard cardInfo={card} setCardInfo={setCard} restDataCapForm={restDataCapForm} loader={(val) => setLoader(val)} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Account;
