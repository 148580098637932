import html2canvas from 'html2canvas';

const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el, {
        allowTaint: false,
        logging: false,
        useCORS: true,
        backgroundColor: '#ffffff', removeContainer: true
    });
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
};

const exportAsImageOfHiddenElement = async (el, imageFileName) => {
    const canvas = await html2canvas(el, {
        onclone: function(clonedDoc) {
            clonedDoc.getElementById('hiddenElement').style.display = 'block';
        }
    });
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
};

export default exportAsImage;
export {exportAsImageOfHiddenElement};
