import SkeletonGiftCardTable from './skeletons/SkeletonGiftCardTable';

const commonTableLoader = (loopCount=1, skeletonType) => {
    const content = [];
    for (let index = 0; index < loopCount; index++) {
        if (skeletonType== 'skeletonAddGiftCardAmounts') {
            content.push(<SkeletonGiftCardTable key={index} page={{GiftCardTable: false, GiftCardAmount: true}} />);
        }
    }
    return content;
};
export default commonTableLoader;
