import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Component
import Layout from '@components/Layout';
// Services
import {cancelMemberShip, cancelMembershipList} from '@services/cancel-membership-service';
import {putCustomerInfoByGuid} from '@services/user-services';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setCreditCards, setServices} from '@store/reducers/manage-account-reducer';
import {setUserInfo} from '@store/reducers/auth-reducer';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import dateFormatter from '@utils/date-formatter';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './CancelMembership.css';

const CancelMembership = (props) => {
    const customerInfo = JSON.parse(localStorage.getItem('customerDetail'));
    toast.configure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [IsEmailSend, setIsEmailSend] = useState(false);
    const services = useSelector((state) => state.manageAccount.services);
    const [cancelId, setCancelId] = useState(null);
    const [Loader, setLoader] = useState(false);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [emailIsTrueFalse, setEmailIsTrueFalse] = useState(null);
    const [IsRecieveEmailReciept, setIsRecieveEmailReciept]=useState(false);
    const [loading, setLoading] = useState(false);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

    const onChangeCancelReason = (cancelId) => {
        setCancelId(cancelId);
    };

    const processCancelMembership = async () => {
        setLoader(true);
        const postData = {};
        postData.CustomerGuid = userInfo.customerGuid;
        postData.CustomerIdentityId = userInfo.customerIdentityID;
        postData.CancelId= cancelId;
        postData.Barcode = '';
        let purchaseDate = new Date();
        purchaseDate = dateFormatter(purchaseDate);
        const response = await cancelMemberShip(userInfo.accessToken, postData, IsEmailSend, purchaseDate);
        if (response.status === 200) {
            setLoader(false);
            dispatch(setCreditCards({creditCards: []}));
            toast.success('Membership cancelled successfully.');
            const seqReqBody = seqReqBodyObj('Information', `Membership cancelled successfully `, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
            localStorage.removeItem('customerGuid');
            return navigate('/manage_account');
        } else {
            setLoader(false);
            toast.error('Some error occurred.', {autoClose: 3000});
        }
    };
    const handleEmail = async () => {
        setLoading(true);
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userEmail.match(validRegex)) {
            const customerData = customerInfo;
            customerData.customer.sEmail = userEmail;
            localStorage.setItem('customerDetail', JSON.stringify(customerData));
            const values = {
                firstName: customerInfo.customer.sFirstName == null ? '' : customerInfo.customer.sFirstName,
                lastName: customerInfo.customer.sLastName == null ? '' : customerInfo.customer.sLastName,
                phoneNumber: localStorage.getItem('phoneNumber').replace(/([() -])+/g, ''),
                email: userEmail,
                customerGuid: customerInfo.customer.customerGUID,
                customerIdentityID: userInfo.customerIdentityID
            };
            const button = document.getElementById('btnAlertYes');
            button.disabled = true;
            const response = await putCustomerInfoByGuid(userInfo.accessToken, values);
            if (response.status === 200) {
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                dispatch(setUserInfo({userInfo: response.data}));
                setLoading(false);
                setShowEmailPopup(false);
                setEmailIsTrueFalse(null);
                setUserEmail('');
                const seqReqBody = seqReqBodyObj('Information', `Your email added successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
            button.disabled = false;
        } else {
            setLoading(false);
            toast.error('Invalid email address.', {autoClose: 3000});
        }
    };
    useEffect(() => {
        const getServices = async () => {
            if (services.length === 0) {
                setLoader(true);
                const response = await cancelMembershipList(userInfo.accessToken);
                if (response.status === 200) {
                    const seqReqBody = seqReqBodyObj('Information', `All Membership Cancel Reasons retrieved successfully `, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                    dispatch(setServices({services: response.data}));
                }
                setLoader(false);
            }
        };
        getServices();
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    return (
        <>
            {Loader === true ? <FullScreenLoader /> : ''}
            <Layout>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='main_title mb-4 mt-3'>
                                <h2>Select reason for cancelling membership</h2>
                                <p>By ending your membership you will loss access to your unlimited plan benefits.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <form action='' className='cancel_reason_list pt-3 '>
                                {services.map((service, index) => {
                                    return (
                                        <div className='form-check' key={index} onChange={() => onChangeCancelReason(service.lCustomerCancelReasonId)}>
                                            <><input className='form-check-input check_pointer' id={service.lCustomerCancelReasonId} type='radio' name='flexRadioDefault' key={service.lCustomerCancelReasonId} /><label className='form-check-label' htmlFor={service.lCustomerCancelReasonId}>
                                                {service.sDescription}
                                            </label></>
                                        </div>
                                    );
                                })}
                            </form>
                            {!services.length && Loader === false ? <p className='notFound alert alert-info'>Services not available.</p> : ''}
                            {services.length > 0?
                                <div className='mt-3 pt-3'>
                                    <div className='form-check mb-3'>
                                        <input className='form-check-input check_pointer mt-0' type='checkbox' id='rEmail' checked={IsRecieveEmailReciept} onChange={(event) => {
                                            setIsRecieveEmailReciept(event.target.checked);
                                            localStorage.setItem('SetIsEmail', event.target.checked);
                                            setIsEmailSend(event.target.checked);
                                            if (customerInfo !== null && Object.keys(customerInfo).length > 0) {
                                                if (customerInfo.customer.sEmail === null || customerInfo.customer.sEmail === ''||!userInfo.bEmailVerified) {
                                                    if (event.target.checked) {
                                                        setShowEmailPopup(true);
                                                    }
                                                } else {
                                                    setShowEmailPopup(false);
                                                }
                                            }
                                        }} />
                                        <label className='form-check-label check_pointer' htmlFor='rEmail'>Receive email receipt</label>
                                    </div>
                                    <div className='btn_grps d-flex flex-wrap mt-3'>
                                        <Link className='back_btn icon_btn me-2 d-none' onClick={()=> localStorage.setItem('SetIsEmail', false)} to='/my-account'><span className='icon arrow_left'></span> Back</Link>
                                        { cancelId > 0 ? (
                                            <button className='main_btn icon_btn btn btn-primary' onClick={() => processCancelMembership()}>End Membership</button>
                                        ) : (
                                            <Link className='main_btn icon_btn mem_cen_btn btn btn-primary' to='' style={{cursor: 'not-allowed'}} >End Membership</Link>
                                        )
                                        }
                                    </div>
                                </div>:''
                            }
                        </div>
                    </div>
                </div>

                {/* email popup */}
                <div className={`modal fade cw_modal add_card_pop ${showEmailPopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content p-4'>
                            <form>
                                <div className='modal-body pt-4 pb-4'>
                                    <label className='mb-2'>Enter email for receiving receipt.</label>
                                    <input
                                        disabled={loading}
                                        type='text'
                                        name='emailReceipt'
                                        id='emailReceipt'
                                        className='form-control form-control-lg mb-1'
                                        placeholder='Email'
                                        onChange={(e) => {
                                            setUserEmail(e.target.value);
                                            if (e.target.value.match(emailRegExp)) {
                                                setEmailIsTrueFalse(true);
                                            } else {
                                                setEmailIsTrueFalse(false);
                                            }
                                        }}
                                    />
                                    {emailIsTrueFalse == false && userEmail != ''? (
                                        <small className="text-danger pt-2">Please enter valid Email.</small>
                                    ) : null}
                                    {userEmail == '' && emailIsTrueFalse == false ? (
                                        <small className="text-danger pt-2">Email is required</small>
                                    ) : null}
                                </div>
                                <div className='modal-footer justify-content-center'>
                                    <button type='submit' id="btnAlertYes" onClick={(e)=> {
                                        e.preventDefault();
                                        handleEmail();
                                    }} className='btn btn-primary' disabled={loading || emailIsTrueFalse == false && userEmail == '' || emailIsTrueFalse == null && userEmail == '' || emailIsTrueFalse == false && userEmail != ''}> {loading && <span id="spanLoader" className="spinner-border text-light btnLoader"></span>}Save</button>
                                    <button type='button' className='btn btn-secondary' onClick={() => {
                                        setShowEmailPopup(false);
                                        setIsRecieveEmailReciept(false);
                                    }}>Cancel</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* email fade */}
                {showEmailPopup ?
                    <div className="modal-backdrop fade show"></div>:
                    ''
                }
            </Layout>
        </>
    );
};

export default CancelMembership;
