import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// Reducers
import {setSelectedPolicy} from '@store/reducers/buy-reducer';

const BuyFooter = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customizedData = JSON.parse(localStorage.getItem('customizePage'));

    const continueClick = async (service) => {
        dispatch(setSelectedPolicy({selectedPolicy: service}));
        navigate('/buy/buy_Policy');
    };

    return (
        <footer className='pt-4 pb-4 footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='footer_menu'>
                            {customizedData.portalNavMenuList.sort((a, b) => a.iOrderNumber > b.iOrderNumber ? 1 : -1).map((service) => {
                                if (service.type === 2 && service.isActive === true) {
                                    if (service.isFeed === true) {
                                        return (
                                            <li className="nav-item" key={service.id}>
                                                <a className="nav-link font_color" href={service.targetUrl} target='#'><span className={`service.icon`}></span>{service.title}</a>
                                            </li>
                                        );
                                    } else {
                                        switch (service.actionName) {
                                        case 'term':
                                            service.id = 0;
                                            break;
                                        case 'privacy':
                                            service.id = 1;
                                            break;
                                        case '':
                                            service.actionName = '#';
                                            break;
                                        }
                                        return (
                                            <li className="nav-item" key={service.id}>
                                                <a href="#" className='nav-link font_color' onClick={() => continueClick(service)} aria-current="page">
                                                    <span className={`service.icon`}></span>{service.title}
                                                </a>
                                            </li>
                                        );
                                    }
                                }
                            })}
                        </ul>
                    </div>
                    <div className='col-12'>
                        <div className='copy_text text-center'><small>All rights reserved © Copyright 2018-2022</small></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default BuyFooter;

