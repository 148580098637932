import {createSlice} from '@reduxjs/toolkit';

const orderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState: {
        orderHistorypreviousPage: ''
    },
    reducers: {
        setOrderHistoryPreviousPage: (state, action) => {
            state.orderHistorypreviousPage = action.payload.orderHistorypreviousPage;
        }
    }
});
export const {setOrderHistoryPreviousPage} = orderHistorySlice.actions;
export default orderHistorySlice.reducer;
