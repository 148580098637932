import API from './api';
const customerCode = localStorage.getItem('customerCode');
export const creditCardsList = async (accessToken, id) => {
    return await API.get(`CustomerIdentityCardOnFiles?id=${id}`, {headers: {
        'X-Authentication-Token': accessToken,
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        return error;
    });
};

export const deleteCreditCard = async (accessToken, id) => {
    try {
        return await API.post('CustomerIdentityCardOnFiles/DeleteCard?CardId=' + id, null, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

export const cancelMembershipList = async (accessToken) => {
    return await API.get('CustomerCancelReasons', {
        headers: {
            'X-Authentication-Token': accessToken,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const updateCreditCardInfo = async (accessToken, reqBody) => {
    try {
        return await API.post('Customers/UpdateCreditCardInfoMobile', reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
