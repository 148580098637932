import '@assets/css/skeleton.css';
import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonUnlimitedPlan = () => {
    return (
        <div className="col-12">
            <div className=' wash_serv_box unlimitedPlan mb-2 d-flex justify-content-between'>
                <div className="col-1 me-2" style={{width: '20px'}}>
                    <SkeletonElement type="text" />
                </div>
                <div className="col-4">
                    <SkeletonElement type="text" />
                </div>
                <div className="col-6">
                </div>
                <div className='col-1 text-right'>
                    <SkeletonElement type="text"/>
                </div>
            </div>
        </div>
    );
};

export default SkeletonUnlimitedPlan;
