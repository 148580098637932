import {FiAlertCircle} from 'react-icons/fi';
import React from 'react';

const AlertModalModalUnlimitedInfo = (props) => {
    const {
        data,
        targetEvent} = props;

    return (
        <>
            <div className={`modal fade custom_pop ${data.modalClass ? data.modalClass : '' } show`} id='modal_pop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='errorModalLabel' aria-hidden='true' style={{display: 'block'}}>
                <div className='modal-dialog  modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <div className="modal_icon">
                                <FiAlertCircle/>
                            </div>
                            <h2>{data.title}</h2>
                            <p className='px-4'>{data.message}</p>
                        </div>
                        <div className='modal-footer justify-content-center'>
                            <button className={data.btnPrimary ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => {
                                targetEvent();
                            }} id="btnAlertYes">
                                 OK
                            </button>
                            {/* <button className={!data.btnPrimary ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => closeModal()} data-bs-dismiss='modal'>{data.btnCancelText}</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};

export default AlertModalModalUnlimitedInfo;
