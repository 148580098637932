import {toast} from 'react-toastify';

function authVerification() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo !== null) {
        const customerCode = localStorage.getItem('customerCode');
        const customerUrl = localStorage.getItem('customerUrl');
        const expiryTime = new Date(userInfo.expiryTime);
        const currentDate = new Date();
        if (expiryTime.getTime() < currentDate.getTime()) {
            localStorage.clear();
            localStorage.setItem('customerCode', customerCode);
            localStorage.setItem('customerUrl', customerUrl);
            window.location.href = `/${customerUrl}`;
            toast.configure();
            toast.error('Session time out. Please login again!');
            setTimeout(() => {
                window.location.href = `/${customerUrl}`;
            }, 3000);
        }
    }
}

export {authVerification};
