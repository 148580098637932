import {Link} from 'react-router-dom';
import React from 'react';

const EmployeeHeader = (props) =>{
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const logout = () => {
        localStorage.clear();
        window.location.href = `/employee`;
    };
    let userName = 'test';

    if (userInfo != null) {
        userName = userInfo.userName;
    }
    return (
        <React.Fragment>
            <header className='emp_head'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="head_sec head_sec_login d-flex align-items-center">
                                <div className="header_logo"><a href="#"><img src="../assets/images/Hoffman-Car-Wash-Logo.png" className="img-fluid" alt="" /></a></div>
                                <div className={`user_acc_head d-flex justify-content-end h-100 ${props.isLoggedIn ? 'd-none' : ''}`}>
                                    <div className="btn-group dropdown align-items-end align-self-center">
                                        <span className="user_name_i">{(userName != '' ) ? (userName).charAt(0) : ''}</span>
                                        <div className="uah_info">
                                            <div className="uah_name">{(userName != '') ? userName : ''}</div>
                                            <small></small>
                                        </div>
                                        <button className="ms-2" type="button" id="userHeaddrop" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="icon arrow_down"></span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userHeaddrop">
                                            <li><Link className="dropdown-item" to="#" onClick={() => logout()}><span className="icon power_icon"></span>Logout</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default EmployeeHeader;
