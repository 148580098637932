import {FiCalendar, FiEdit2, FiMail, FiMapPin, FiSmartphone, FiUser} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {getCustomerInfoByGuid, putCustomerInfoByGuid} from '@services/user-services';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-date-picker';
import InputMask from 'react-input-mask';
import ReactTooltip from 'react-tooltip';
import customerSchema from '@validation-schemas/update-customer-info-schema';
import {dateFormatterWithoutTime} from '@utils/date-formatter';
import {initFormik} from '@utils/formik-helper';
import {isStringNullOrEmpty} from '@utils/common-repo';
import {phoneNumberFormat} from '@utils/common-helper';
import {seqLogger} from '@services/seq-logger-service';
import {seqReqBodyObj} from '@utils/common-helper';
import {setUserInfo} from '@store/reducers/auth-reducer';
import {toast} from 'react-toastify';

const PersonalInfo = (props) => {
    toast.configure();
    const dispatch = useDispatch();
    const {customerInfo, setCustomerInfo, loading, reload, setLoader} = props;
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [showUpdatePersonalInfoPopup, setUpdatePersonalInfoPopup] = useState(false);
    const [address, setAddress] = useState(customerInfo?.customer.sAddress1 == null ? '' : customerInfo?.customer.sAddress1);
    const [address2, setAddress2] = useState(customerInfo?.customer.sAddress2 == null ? '' : customerInfo?.customer.sAddress2);
    const [state, setState] = useState(customerInfo?.customer.sState == null ? '' : customerInfo?.customer.sState);
    const [city, setCity] = useState(customerInfo?.customer.sCity == null ? '' : customerInfo?.customer.sCity);
    const [zipCode, setZipCode] = useState(customerInfo?.customer.sZipcode == null ? '' : customerInfo?.customer.sZipcode);
    const [startDate, setStartDate] = useState(customerInfo?.customer.dtBirthday == null ? '' : new Date(customerInfo?.customer.dtBirthday));
    const [gender, setGender] = useState(localStorage.getItem('gender') == null ? 'Male': localStorage.getItem('gender'));
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    let firstName = '';
    let lastName = '';
    let email = '';
    if (customerInfo !== null) {
        firstName = customerInfo.customer.sFirstName;
        lastName = customerInfo.customer.sLastName;
    }
    if (isStringNullOrEmpty(firstName) && !isStringNullOrEmpty(userInfo.firstname)) {
        firstName = userInfo.firstname;
    }
    if (isStringNullOrEmpty(lastName) && !isStringNullOrEmpty(userInfo.lastname)) {
        lastName = userInfo.lastname;
    }
    if (!isStringNullOrEmpty(userInfo.customerEmail)) {
        email = userInfo.customerEmail;
    }

    const getCustomerInfo = async () => {
        const response = await getCustomerInfoByGuid(userInfo.accessToken, localStorage.getItem('customerGuid'), userInfo.customerIdentityID);
        if (response.status == 200) {
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
            console.log('cus', response.data);
            // localStorage.setItem('userInfo', JSON.stringify({...userInfo, firstname: response.data.customer.sFirstName, lastname: response.data.customer.sLastName, customerEmail: response.data.customer.sEmail, gender: response.data.customer.sSex, dtBirthday: response.data.customer.dtBirthday}));
            // dispatch(setUserInfo({userInfo: {...userInfo, firstname: response.data.customer.sFirstName, lastname: response.data.customer.sLastName, customerEmail: response.data.customer.sEmail, gender: response.data.customer.sSex, dtBirthday: response.data.customer.dtBirthday}}));
            setCustomerInfo(response.data);
            setLoader(false);
            const seqReqBody = seqReqBodyObj('Information', `Customer information retrieve successfully`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
            const seqReqBody = seqReqBodyObj('Error', `${response.data.title}`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
        }
    };
    const phoneNumber = localStorage.getItem('phoneNumber');
    const dtBirthday = customerInfo?.customer.dtBirthday;
    let disabled=true;
    if (dtBirthday === null) {
        disabled = false;
    }
    const fn = ' n';
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const initialValues = {
        firstName,
        lastName,
        email,
        phoneNumber: localStorage.getItem('phoneNumber') == 'null' ? '' : localStorage.getItem('phoneNumber'),
        addressLine_1: address,
        addressLine_2: address2,
        city: city,
        state: state,
        zipCode: zipCode,
        gender: '',
        dob: customerInfo?.customer.dtBirthday == null ? '' : new Date(customerInfo.customer.dtBirthday)
    };
    const dateConvert = (str) => {
        if (str === '' || str === null) {
            return '';
        } else {
            const date = new Date(str);
            const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join('-');
        }
    };
    const handleSubmit = async (values, resetForm) => {
        setIsFormSubmitted(true);
        values.dob = dateConvert(startDate);
        values.phoneNumber = (values.phoneNumber).replace(/([() -])+/g, '');
        values.customerGuid = userInfo.customerGuid;
        values.CustomerIdentityID = userInfo.customerIdentityID;
        values.gender = gender;
        const response = await putCustomerInfoByGuid(userInfo.accessToken, values);
        if (response.status === 200) {
            if (response.data == 'Phone number already taken') {
                toast.warning(response.data, {autoClose: 3000});
                setIsFormSubmitted(false);
                resetForm();
                return false;
            }
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            localStorage.setItem('gender', response.data.gender);
            dispatch(setUserInfo({userInfo: response.data}));
            setIsFormSubmitted(false);
            setAddress(values.addressLine_1);
            setAddress2(values.addressLine_2);
            setState(values.state);
            setCity(values.city);
            setZipCode(values.zipCode);
            resetForm();
            getCustomerInfo();
            setUpdatePersonalInfoPopup(false);
            toast.success('Your personal information has been updated.', {autoClose: 3000});
            const seqReqBody = seqReqBodyObj('Information', `Your personal information has been updated`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response.status === 404 && response.data == 'Invalid DOB') {
            toast.error('Please enter valid Date Of Birth.', {autoClose: 3000});
            setIsFormSubmitted(false);
            const seqReqBody = seqReqBodyObj('Error', `Please enter valid Date Of Birth`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            toast.error('Internal Server Error.', {autoClose: 3000});
            setIsFormSubmitted(false);
        }
    };
    const formik = initFormik(initialValues, customerSchema, handleSubmit, true);

    useEffect(() => {
        if (localStorage.getItem('customerGuid') == null) {
            localStorage.setItem('customerGuid', userInfo.customerGuid);
        }
        getCustomerInfo();
    }, [reload]);

    return (
        <>
            <div className="per_info">
                <div className="row">
                    <div className="col-12">
                        <div className="per_info_heading">
                            <h2 className="main_heading">Personal Information</h2>
                            <a href='#' onClick={() => setUpdatePersonalInfoPopup(true)} className="edit_btn"><FiEdit2/></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon userIcon"><FiUser/></div>
                            <div className="">
                                <span>Name</span>
                                <h5 className='text-capitalize'>{userInfo?.firstname} {userInfo?.lastname}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMail/></div>
                            <div className="">
                                <span>Email</span>
                                <h5>{userInfo?.customerEmail}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiSmartphone/></div>
                            <div className="">
                                <span>Cell Phone</span>
                                <h5>
                                    {phoneNumberFormat(phoneNumber)}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiCalendar/></div>
                            <div className="">
                                <span>Date of Birth</span>
                                {dtBirthday !== null?
                                    <h5>{dateFormatterWithoutTime(dtBirthday)}</h5>:''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMapPin/></div>
                            <div className="">
                                <span>Address 1</span>
                                <h5>{address}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMapPin/></div>
                            <div className="">
                                <span>Address 2</span>
                                <h5>{address2}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMapPin/></div>
                            <div className="">
                                <span>State</span>
                                <h5>{state?`${state}`:''}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMapPin/></div>
                            <div className="">
                                <span>City</span>
                                <h5>{city?`${city}`:''} </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="per_info_detail">
                            <div className="perInfo_icon"><FiMapPin/></div>
                            <div className="">
                                <span>Zip Code</span>
                                <h5>{zipCode?`${zipCode}`:''}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade cw_modal add_info_pop  ${showUpdatePersonalInfoPopup?'show d-block' : ''} `} id='updatePersonalInfo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='updatePersonalInfoLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered '>
                    <div className='modal-content personalinfo p-5'>
                        <form className="perinfo_canvasdetail" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className='col-12'>
                                    <h5 className='mb-4'>Update Personal Information</h5>
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>First Name<sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control form-control-lg" id="firstName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstName}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <small className="text-danger validationError">{formik.errors.firstName}</small>
                                    ) : null}
                                    {fn?.charAt(0) === formik.values.firstName?.charAt(0) ? (
                                        <small className="text-danger validationError">Please enter the firstname without space</small>
                                    ) : null}
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>Last Name<sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control form-control-lg" id="lastName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastName}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <small className="text-danger validationError">{formik.errors.lastName}</small>
                                    ) : null}
                                    {fn?.charAt(0) === formik.values.lastName?.charAt(0) ? (
                                        <small className="text-danger validationError">Please enter the lastname without space.</small>
                                    ) : null}
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>Cell Phone</label>
                                    <InputMask
                                        className="form-control form-control-lg"
                                        readOnly={customerDetail?.customer.sPhone === null || customerDetail?.customer.sPhone === '' ? false : true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        mask="(999) 999-9999"
                                        maskChar=" "
                                        value={formik.values.phoneNumber}
                                        name="phoneNumber"
                                        placeholder="(000) 000-0000"
                                    />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <small className="text-danger validationError">{formik.errors.phoneNumber}</small>
                                    ) : null}
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>Date of Birth</label>
                                    <DatePicker
                                        onChange={(date) =>{
                                            setStartDate(date);
                                        }}
                                        value={startDate}
                                        disabled={disabled}
                                        dateFormat='MM/dd/yyyy'
                                        monthPlaceholder='mm'
                                        dayPlaceholder='dd'
                                        yearPlaceholder='yyyy'
                                        className='form-control form-control-lg'
                                        minDate = {new Date(1900, 11, 24, 10, 33, 30, 0)}
                                        maxDate={new Date()}
                                        onBlur={formik.handleBlur}
                                        showNavigation
                                    />
                                    {disabled === true ? <span><li className='info_icon1 ps-4' data-tip="Please call your nearest location for assistance.">Info <ReactTooltip /></li></span>: ''}
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <small className="text-danger validationError">{formik.errors.dob}</small>
                                    ) : null}
                                </div>

                                <div className="col-12 mb-3">
                                    <label htmlFor="" className='mb-2'>Email Address<sup className="text-danger">*</sup></label>
                                    <input type="email" className="form-control form-control-lg" id="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <small className="text-danger validationError">{formik.errors.email}</small>
                                    ) : null}
                                </div>
                                <div className="col-12 mb-3">
                                    <label htmlFor="" className='mb-2'>Address 1</label>
                                    <input type="text" className="form-control form-control-lg" id="addressLine_1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine_1}
                                    />
                                    {formik.touched.addressLine_1 && formik.errors.addressLine_1 ? (
                                        <small className="text-danger validationError">{formik.errors.addressLine_1}</small>
                                    ) : null}
                                </div>
                                <div className="col-12 mb-3">
                                    <label htmlFor="" className='mb-2'>Address 2</label>
                                    <input type="text" className="form-control form-control-lg" id="addressLine_2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine_2}
                                    />
                                    {formik.touched.addressLine_2 && formik.errors.addressLine_2 ? (
                                        <small className="text-danger validationError">{formik.errors.addressLine_2}</small>
                                    ) : null}
                                </div>
                                <div className="col-md-4 col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>City</label>
                                    <input type="text" className="form-control form-control-lg" name='city' id="firstName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <small className="text-danger validationError">{formik.errors.city}</small>
                                    ) : null}
                                </div>
                                <div className="col-md-4 col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>State</label>
                                    <input type="text" className="form-control form-control-lg" name='state' id="lastName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.state}
                                    />
                                    {formik.touched.state && formik.errors.state ? (
                                        <small className="text-danger validationError">{formik.errors.state}</small>
                                    ) : null}
                                </div>
                                <div className="col-md-4 col-sm-6 mb-3">
                                    <label htmlFor="" className='mb-2'>Zip Code<sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control form-control-lg" name='zipCode' id="lastName" maxLength="12"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.zipCode}
                                    />
                                    {formik.touched.zipCode && formik.errors.zipCode ? (
                                        <small className="text-danger validationError">{formik.errors.zipCode}</small>
                                    ) : null}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="gander_checks" onChange={(event) => {
                                        formik.handleChange;
                                        formik.values.gender = event.target.value;
                                        setGender(event.target.value);
                                    }}>
                                        <div className="form-check  form-check-inline">
                                            <input className="form-check-input mt-0" type="radio" value="Male" name="gender" id="exampleRadios1" defaultChecked={gender === 'Male'} />
                                            <label className="form-check-label" htmlFor="exampleRadios1">Male</label>
                                        </div>
                                        <div className="form-check  form-check-inline">
                                            <input className="form-check-input mt-0" type="radio" name="gender" id='exampleRadios2' value="Female" defaultChecked={gender === 'Female'} />
                                            <label className="form-check-label" htmlFor="exampleRadios2">Female</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <button type="submit" className='btn btn-primary w-100' disabled={loading || !formik.isValid || formik.values.firstName === ''|| formik.values.lastName === ''|| formik.values.email === ''|| formik.values.phoneNumber === ''|| fn?.charAt(0) === formik.values.firstName?.charAt(0) || fn?.charAt(0) === formik.values.lastName?.charAt(0) || formik.isSubmitting}>
                                        {isFormSubmitted && <div className="spinner-border spinner-border-sm text-light" role="status"></div>}Save Info
                                    </button></div>
                                <div className="col">
                                    <button type="button" aria-label="Close" className='btn btn-secondary w-100'
                                        onClick={() => {
                                            formik.resetForm();
                                            setUpdatePersonalInfoPopup(false);
                                            setStartDate(customerInfo.customer.dtBirthday == null ? '' : new Date(customerInfo.customer.dtBirthday));
                                            setGender(localStorage.getItem('gender') == null ? 'Male': localStorage.getItem('gender'));
                                        }}>Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {showUpdatePersonalInfoPopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
        </>
    );
};

export default PersonalInfo;
