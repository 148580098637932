import API from '../api';

export const giftCardList = async (customerCode) => {
    return await API.get(`/QuickBuy/GetGiftDenominationChoices`, {headers: {
        'X-Customer-Code': customerCode,
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        console.clear();
        return error;
    });
};

export const buyGiftCard = async (customerCode, locationId, reqBody) => {
    try {
        return await API.post('/QuickBuy/QuickBuyGiftCard', reqBody, {
            headers: {
                'X-Customer-Code': customerCode,
                'X-Location-Id': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
