import {FiArrowLeft, FiDownload} from 'react-icons/fi';
import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Barcode from 'react-barcode';
import InputMask from 'react-input-mask';
import Layout from './../Layout';
import QRCode from 'react-qr-code';
import {toast} from 'react-toastify';
// Services
import {putCustomerInfoByGuid} from '@services/user-services';
import {sendToDevic} from '@services/wallet-service';
// Utils
import {currencyFormat, phoneNumberFormat} from '@utils/common-helper';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import dateFormatter from '@utils/date-formatter';
import exportAsImage from '@utils/export-as-image';
import {initFormik} from '@utils/formik-helper';
// Validation
import loginSchema from '@validation-schemas/login-schema';
// CSS
import './WalletBarcode.css';

import {setUserInfo} from '@store/reducers/auth-reducer';

const WalletBarcode = (props) => {
    toast.configure();
    const {state} = useLocation();
    const userInfo= useSelector((state) => state.auth.userInfo);
    let selectedWallet = '';
    if (state && state.from !== undefined && state.from === 'giftCardDetails') {
        selectedWallet = useSelector((state) => state.giftCard.selectedGiftCardForViewReceipt);
    } else {
        selectedWallet = useSelector((state) => state.wallet.selectedWallet);
    }
    const [loading, setLoading] = useState(false);
    const [phoneLoading, setPhoneLoading] = useState(false);
    const [showPhonePopup, setShowPhonePopup] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(JSON.parse(localStorage.getItem('customerDetail')));
    const newCustomerInfo = JSON.parse(localStorage.getItem('customerDetail'));
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const dispatch = useDispatch();
    const exportRef = useRef();

    const handleSendToDevice = async () => {
        if (newCustomerInfo.customer.sPhone === null || newCustomerInfo.customer.sPhone === '') {
            setShowPhonePopup(true);
        } else {
            sendToDevice();
        }
    };
    const sendToDevice = async () => {
        setLoading(true);
        const reqBody = {
            type: selectedWallet.type,
            referencedGUID: selectedWallet.referencedGUID,
            customerIdentityId: userInfo.customerIdentityID
        };
        const response = await sendToDevic(userInfo.accessToken, reqBody);
        if (response.status === 200) {
            setLoading(false);
            toast.success(response.data, {autoClose: 3000});
        } else {
            setLoading(false);
        }
    };
    const initialValuesPhone = {
        phone: ''
    };
    const handlePhone = async (param, resetForm) => {
        setPhoneLoading(true);
        const customerData = customerInfo;
        const values = {
            firstName: newCustomerInfo.customer.sFirstName == null ? '' : newCustomerInfo.customer.sFirstName,
            lastName: newCustomerInfo.customer.sLastName == null ? '' : newCustomerInfo.customer.sLastName,
            phoneNumber: param.phone.replace(/([() -])+/g, ''),
            email: newCustomerInfo.customer.sEmail == null ? '' : newCustomerInfo.customer.sEmail,
            customerGuid: newCustomerInfo.customer.customerGUID,
            customerIdentityID: userInfo.customerIdentityID,
            bUpdateMobilephone: true
        };
        const response = await putCustomerInfoByGuid(userInfo.accessToken, values);
        if (response.status === 200) {
            if (response.data == 'Phone number already taken') {
                toast.warning(response.data, {autoClose: 3000});
                setPhoneLoading(false);
                resetForm();
                return false;
            }
            customerData.customer.sPhone = param.phone.replace(/([() -])+/g, '');
            setCustomerInfo(customerData);
            localStorage.setItem('customerDetail', JSON.stringify(customerData));
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            dispatch(setUserInfo({userInfo: response.data}));
            localStorage.setItem('phoneNumber', param.phone.replace(/([() -])+/g, ''));
            setPhoneLoading(false);
            setShowPhonePopup(false);
            sendToDevice();
        }
    };
    const phoneFormik = initFormik(initialValuesPhone, loginSchema, handlePhone, true);
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    const barcodeString = selectedWallet?.qRcode || selectedWallet?.barcode;
    const barcodeStr = barcodeString !== '' ? barcodeString : 'DemoBarcode';
    return (
        <Layout>
            {loading === true ? <FullScreenLoader /> : ''}
            <div className='container'>
                <div className='pb-3 mt-3'>
                    <Link to={selectedWallet.pageBackUrl ? selectedWallet.pageBackUrl : '/wallet'} className='btn btn-outline-secondary'><FiArrowLeft/> Back</Link>
                    <button className='d-none' disabled={selectedWallet.active ? loading : true} onClick={() => handleSendToDevice()}>{loading && <span className="spinner-border text-light btnLoader"></span>}{selectedWallet.active ? 'Send To Device' : 'Redeemed'}</button>
                </div>
                <div className="payment_method_wrap wallet_method_wrap">
                    <div className='row' ref={exportRef}>
                        {!selectedWallet.forSomeOneElse?
                            <div className='col-lg-6 text-center wallet_barcode mt-3'>
                                <div className='barcode_img mx-auto text-center'>
                                    <div className={selectedWallet.active ? '' : 'opacity-25'}>
                                        <Barcode value={selectedWallet.active ? barcodeStr : 'Redeemed'} width={2} height={60} />
                                    </div>
                                </div>
                                <div className='barcode_msg pt-3 pt-3'>
                                    <p>Make sure to show this code to the attendant or,<br/> scan the bar code in the machine.</p>
                                </div>
                                <div className='barcode_img mx-auto text-center mt-4'>
                                    <div className={selectedWallet.active ? '' : 'opacity-25'}>
                                        <QRCode value={selectedWallet.active ? barcodeStr : 'Redeemed'} size={200} />
                                    </div>
                                </div>
                                <div className='barcode_number text-center p-4'>
                                    <small>Your Redeem Code:</small>
                                    <h3 className='mt-2'>{barcodeStr}</h3>
                                </div>
                            </div>:
                            ''
                        }
                        <div className={`${selectedWallet.forSomeOneElse?'col-lg-12':'col-lg-6'}`}>
                            {/* new receipt */}
                            <div className="oc_bill_detail mt-3">
                                <h2 className='oc_bill_wm'>{selectedWallet.type===1?'GIFT CARD':'WASH CARD'}</h2>
                                <div className="oc_bill_detail_inner">
                                    <ul className='oc_bill_pro_details'>
                                        <li>
                                            <span>Product Name:</span>
                                            <h3>{selectedWallet.description}</h3>
                                        </li>
                                        <li>
                                            <span>Purchase Date:</span>
                                            <h3>{selectedWallet.active == false? dateFormatter(convertUTCDateToLocalDate(selectedWallet.walletDate)):dateFormatter(convertUTCDateToLocalDate(selectedWallet.walletDate))}</h3>
                                        </li>
                                    </ul>
                                    <ul className='oc_bill_user_details'>
                                        <li>
                                            <span>Customer:</span>
                                            <h3>{`${customerDetail?.customer.sFirstName !== null ? customerDetail.customer.sFirstName : userInfo.firstname}`} {customerDetail.customer?.sFirstName?.length > 18 || customerDetail.customer?.sLastName?.length > 18 ? <br /> : ''}{`${customerDetail?.customer?.sLastName !== null ? customerDetail?.customer?.sLastName : userInfo?.lastName}`}</h3>
                                        </li>
                                        <li>
                                            <span>Email:</span>
                                            <h3>{customerDetail.customer.sEmail}</h3>
                                        </li>
                                        <li>
                                            <span>Phone:</span>
                                            <h3>{phoneNumberFormat(customerDetail.customer.sPhone)}</h3>
                                        </li>
                                    </ul>
                                </div>
                                <div className="bill_total">
                                    <div className="main_total"><span>Product Price:</span><span>{currencyFormat(selectedWallet?.amount)}</span></div>
                                    <div className="main_total"><span>Add-on Service Price:</span><span>{currencyFormat(selectedWallet?.serviceTotalAmount)}</span></div>
                                    <div className="main_total"><span>Subtotal:</span><span><b>{currencyFormat(selectedWallet?.amount + selectedWallet?.serviceTotalAmount)}</b></span></div>
                                    <div className="main_total"><span>Tax:</span><span>{currencyFormat(selectedWallet?.taxAmount)}</span></div>
                                </div>
                                <div className="main_total"><span><b>Total:</b></span><span><b>{currencyFormat(selectedWallet?.amount + selectedWallet?.taxAmount)}</b></span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="walletbar_batn">
                            <div className="mb-3">
                                <button className='btn btn-outline-secondary' onClick={() => exportAsImage(exportRef.current, barcodeStr)}><FiDownload className='me-2'/>Download</button>
                            </div>
                            {!state ?
                                <div className="mb-3">
                                    <Link to='#' onClick={() => handleSendToDevice()} className='btn btn-primary'>Send Text Message</Link>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* phone popup */}
            <div className={`modal fade cw_modal add_card_pop ${showPhonePopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-3'>
                        <form onSubmit={phoneFormik.handleSubmit}>
                            <div className="modal-body p-3">
                                <label htmlFor="phone" className="form-label">Cell Phone<sup className="text-danger">*</sup></label>
                                <InputMask
                                    className="form-control form-control-lg"
                                    onChange={phoneFormik.handleChange}
                                    onBlur={phoneFormik.handleBlur}
                                    mask="(999) 999-9999"
                                    maskChar=""
                                    value={phoneFormik.values.phone}
                                    name="phone"
                                    placeholder="(000) 000-0000"
                                />
                                {phoneFormik.touched.phone && phoneFormik.errors.phone ? (
                                    <small className="text-danger validationError">{phoneFormik.errors.phone}</small>
                                ) : ''}
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type="submit" className="button btn btn-primary" disabled={phoneLoading || !phoneFormik.isValid || phoneFormik.values.phone === ''}>
                                    {phoneLoading && <span className="spinner-border text-light btnLoader"></span>}<span>Save</span>
                                </button>
                                <button type='button' className='btn btn-secondary' onClick={() => {
                                    setShowPhonePopup(false);
                                }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* phone fade */}
            {showPhonePopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
        </Layout>
    );
};

export default WalletBarcode;
