import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
// Components
import EmployeeCheckout from './EmployeeCheckout';
import EmployeeDiscription from './EmployeeDiscription';
import EmployeeHeader from './EmployeeHeader';
import EmployeeLogin from './EmployeeLogin';
import EmployeeSlider from './EmployeSlider';
import EmployeeThanks from './EmployeeThanks';
import Layout from '@components/Layout';

const Employee = () =>{
    toast.configure();

    const toastId = React.useRef(null);
    const [termAndCondtion, setTermAndCondition] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [subscription, setSubscription] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [thanks, setThanks] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [barcode, setBarcode] = useState('');

    const handleTermAndCondtion = (val) => {
        setTermAndCondition(val);
    };
    const handleOnClick = () => {
        setIsLoggedIn(false);
        setSubscription(true);
    };
    const handleSubscriptionCallBack = () => {
        setIsLoggedIn(true);
        setSubscription(false);
    };
    const handleSubscriptionNext = (data) => {
        if (data.length === 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select an unlimited wash plan.');
            }
            return false;
        }
        if (termAndCondtion === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check terms and conditions.');
            }
            return false;
        }
        setSubscriptionData(data);
        setIsLoggedIn(false);
        setSubscription(false);
        setCheckout(true);
    };
    const handleCheckoutCallBack = () => {
        setIsLoggedIn(false);
        setSubscription(true);
        setCheckout(false);
    };
    const handleCheckoutNext = (val) => {
        setBarcode(val);
        setIsLoggedIn(false);
        setSubscription(false);
        setCheckout(false);
        setThanks(true);
    };
    const handleThanksCallBack = () => {
        setIsLoggedIn(false);
        setSubscription(true);
        setThanks(false);
    };
    useEffect(() => {
        const employeeAccessToken = localStorage.getItem('employeeAccessToken');
        if (employeeAccessToken !== null) {
            setIsLoggedIn(false);
            setSubscription(true);
        }
    }, []);
    return (
        <Layout>
            {isLoggedIn ? '' : <EmployeeHeader isLoggedIn={isLoggedIn}></EmployeeHeader>}
            {isLoggedIn ?
                <div className='main_wrap pt-3 pb-0 grdnt_bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='main_title mb-0 mt-1 text-center'>
                                    <h2>Sign Up for an Unlimited Car Wash Plan</h2>
                                    <p>Simply fill out the form in four easy steps</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <EmployeeSlider isLoggedIn={isLoggedIn}/>
                            <EmployeeLogin handleOnClick={handleOnClick}/>
                        </div>
                    </div>
                </div> : ''
            }
            {subscription ?
                <div className='main_wrap pt-0 pb-2 grdnt_bg'>
                    <EmployeeSlider subscription={subscription}/>
                    <EmployeeDiscription handleSubscriptionCallBack={handleSubscriptionCallBack} handleSubscriptionNext={handleSubscriptionNext} handleTermAndCondtion={handleTermAndCondtion}/>
                </div> : ''
            }
            {checkout ?
                <div className='main_wrap pt-0 pb-2 grdnt_bg'>
                    <EmployeeSlider checkout={checkout}/>
                    <EmployeeCheckout handleCheckoutCallBack={handleCheckoutCallBack} handleCheckoutNext={handleCheckoutNext} subscriptionData={subscriptionData} isLoggedIn={isLoggedIn} handleTermAndCondtion={handleTermAndCondtion}/>
                </div> : ''
            }
            {thanks ?
                <div className='main_wrap pt-0 pb-2 grdnt_bg'>
                    <EmployeeThanks barcode={barcode} handleThanksCallBack={handleThanksCallBack}/>
                </div> : ''
            }
        </Layout>
    );
};

export default Employee;
