import {createSlice} from '@reduxjs/toolkit';
const creditCardDetail = JSON.parse(localStorage.getItem('creditCardDetail'));
const manageAccountSlice = createSlice({
    name: 'manageAccount',
    initialState: {
        creditCards: creditCardDetail !== null ? creditCardDetail : [],
        services: [],
        CC: {}
    },
    reducers: {
        setCreditCards: (state, action) => {
            state.creditCards = action.payload.creditCards;
        },
        addNewAddedCard: (state, action) => {
            state.creditCards= [...state.creditCards, action.payload.cC];
        },
        setServices: (state, action) => {
            state.services = action.payload.services;
        }
    }
});

export const {setCreditCards, setServices, addNewAddedCard} = manageAccountSlice.actions;
export default manageAccountSlice.reducer;
