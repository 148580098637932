import {getPCIDetails} from '@services/order-service';

export function getCardTypeFromIIN(sBin) {
    // Remove spaces and non-numeric characters
    const cleanedIIN = sBin.replace(/\D/g, '');
    // Card type detection based on IIN ranges
    if (/^4\d{5}$/.test(cleanedIIN)) {
        return 'Visa Card';
    } else if (/^5[1-5]\d{4}$/.test(cleanedIIN)) {
        return 'Master Card';
    } else if (/^3[47]\d{4}$/.test(cleanedIIN)) {
        return 'American Express Card';
    } else if (/^(6011|622|644|645|646)\d{2,}$/.test(cleanedIIN)) {
        return 'Discover Card';
    } else if (/^(2131|1800)\d{2}$/.test(cleanedIIN)) {
        return 'JCB';
    } else {
        return 'Unknown';
    }
}

function GetCreditCardIcon(card) {
    if (card.sBin!= null && card.sBin !='') {
        const cardType = getCardTypeFromIIN(card?.sBin);
        switch (cardType) {
        case 'American Express Card':
            return {name: 'American Express', icon: 'amex_icon'};
        case 'Visa Card':
            return {name: 'Visa', icon: 'visa_icon'};
        case 'Master Card':
            return {name: 'Master', icon: 'mastercard_icon'};
        case 'Discover Card':
            return {name: 'Discover', icon: 'discover_icon'};
        default:
            return {name: 'Visa', icon: 'visa_icon'};
        }
    } else {
        return {name: 'Visa', icon: 'visa_icon'};
    }
}

const fetchPCIDetailsFromServer = async (toast) => {
    const response = await getPCIDetails();
    if (response.status == 200) {
        localStorage.setItem('pciDetails', JSON.stringify(response.data));
    } else {
        delete localStorage['pciDetails'];
        toast.error('Something went wrong, When fetching PCI details.');
    }
};

function isStringNullOrEmpty(str) {
    return (str == null || str === '' || str.length === 0);
}

function getPCIDetailsLocal() {
    const PCIJson= {
        PCIScript: '',
        XGPApiKey: '',
        Enviornment: '',
        isPaymentLive: false
    };
    if (localStorage.getItem('pciDetails') != null && localStorage.getItem('pciDetails') != undefined && localStorage.getItem('pciDetails') !='null') {
        const details= JSON.parse(localStorage.getItem('pciDetails'));
        PCIJson.PCIScript= details.PCIScript;
        PCIJson.XGPApiKey= details.XGPApiKey;
        PCIJson.Enviornment= details.Enviornment;
        PCIJson.isPaymentLive= details.isPaymentLive;
    }
    return PCIJson;
}

function getNoLoginCheckoutJSON() {
    return {
        TopHeaderText: '',
        ListItemText: '',
        ListItemDescription: '',
        IsCheckedTermAndCondition: false,
        IsShowPromocodeDiv: false,
        IsPromocodeApplied: false,
        IsRecieveSMSReciept: false,
        IsShowRecieveSMSReciept: true,
        IsRecieveEmailReciept: false,
        IsShowRecieveEmailReciept: true,
        IsSavePaymentInfoForFuturePurchase: false,
        MobileNo: '',
        termsId: 0,
        Promocode: '',
        TaxAmount: 0.0,
        DiscountAmount: 0.0,
        SubTotal: 0.0,
        GrandTotal: 0.0,
        AdditionalPageData: null
    };
};

function quickBuyGiftCardModel(isSelected, isTaxable, price, productTypeId, description, taxAmount) {
    const jsonData = {};
    jsonData.IsSelected = isSelected;
    jsonData.IsTaxable = isTaxable;
    jsonData.Amount =parseFloat(price) > 0 ? parseFloat(price).toFixed(2) : '';
    jsonData.TaxAmount =parseFloat(taxAmount).toFixed(2);
    jsonData.ProductTypeId = productTypeId;
    jsonData.Description = description;

    return jsonData;
}

function giftCardModel(isSelected, isTaxable, price, surchargeId, description, other, customerGuid, TaxIncluded) {
    const jsonData = {};
    jsonData.IsSelected = isSelected;
    jsonData.IsTaxable = isTaxable;
    jsonData.Other = parseFloat(other) > 0 ? parseFloat(other).toFixed(2) : '';
    jsonData.Amount =parseFloat(price) > 0 ? parseFloat(price).toFixed(2) : '';
    jsonData.SurchargeId = surchargeId;
    jsonData.CustomerGuid = customerGuid;
    jsonData.Description = description;
    jsonData.TaxIncluded = TaxIncluded;

    return jsonData;
}

function isNumeric(str) {
    return /^-?\d+$/.test(str);
}

function isNumericFloat(str) {
    return /^-?\d+(\.\d+)?$/.test(str);
}

const printText = (str, defaultValue)=>{
    if (str != null && str != undefined && str != '') {
        return str;
    } else {
        return defaultValue;
    }
};

export {GetCreditCardIcon, getPCIDetailsLocal, fetchPCIDetailsFromServer, getNoLoginCheckoutJSON, quickBuyGiftCardModel, giftCardModel, isNumeric, isStringNullOrEmpty, printText, isNumericFloat};
