import * as Yup from 'yup';
const nameRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
export default Yup.object().shape({
    firstName: Yup.string().required('First Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'First Name must have maximum 25 characters.'),
    lastName: Yup.string().required('Last Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'Last Name must have maximum 25 characters.'),
    email: Yup.string().required('Email is required.').matches(emailRegExp, 'Email must be a valid email.'),
    addressLine_1: Yup.string().max(25, 'Address  must have maximum 25 characters.'),
    addressLine_2: Yup.string().max(25, 'Address  must have maximum 25 characters.'),
    zipCode: Yup.string()
        .required('Zip Code is required.')
        .matches(/^[0-9]+$/, 'Zip Code must be only digits.')
        .min(5, 'Zip Code must be min 5 digits.')
        .max(5, 'Zip Code must be max 5 digits.')
});
