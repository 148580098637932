import '@assets/css/skeleton.css';
import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonOrderHistory = (props) => {
    let index = 0;
    const data = [];
    const makeXerox = () => {
        do {
            data.push(
                <div className='accordion-item skeletonLocation p-0' key={index} >
                    <h2 className='accordion-header' id='flush-headingOne'>
                        <button className='accordion-button collapsed' id={index} type='button'>
                            <div className='loc_box '>
                                <div className='d-flex align-items-center'>
                                    <span className='icon mappin_icon me-3'></span>
                                    <div className='loc_info'>
                                        <div className='loc_name'><SkeletonElement type="text" /></div>
                                        <div className='loc_address'><SkeletonElement type="text" /></div>
                                    </div>
                                </div>
                                <div className='mi_links'>
                                    <small className='more_info d-inline-block'><SkeletonElement type="text" /></small>
                                </div>
                            </div>
                        </button>
                    </h2>
                </div>
            );
            index += 1;
        } while (index < props.count);
        return data;
    };

    return makeXerox();
};

export default SkeletonOrderHistory;
