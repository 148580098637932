import React from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
// Services
import {checkExistBarcode} from '@services/unlimited-plan-service';
import {seqLogger} from '@services/seq-logger-service';
// Utils
import {initFormik} from '@utils/formik-helper';
import {seqReqBodyObj} from '@utils/common-helper';
// Validation Schemas
import barcodeSchema from '@validation-schemas/barcode-schema';

const LinkExistingModal = (props) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const toastId = React.useRef(null);
    toast.configure();
    const onClickLinkExistingPlanSubmit = async (values, resetForm) =>{
        const linkExtObj = {
            barCode: values.barCode,
            cardNumber: values.ccNumber,
            CustomerIdentityId: userInfo.customerIdentityID
        };
        const result = await checkExistBarcode(userInfo.accessToken, linkExtObj);
        if (result.status == 200) {
            localStorage.setItem('customerGuid', result.data);
            resetForm();
            props.setAddExistingPlan(false);
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success('Existing plan linked successfully.');
                const seqReqBody = seqReqBodyObj('Information', `Existing plan linked successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
        } else if (result.status == 404) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(result.data);
                const seqReqBody = seqReqBodyObj('Information', `${result.data}`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Some error occurred.');
            }
        }
    };
    const initialValuesLinkExistingPlan = {
        barCode: '',
        ccNumber: ''
    };
    const linkExistingformik = initFormik(initialValuesLinkExistingPlan, barcodeSchema, onClickLinkExistingPlanSubmit, true);
    return (
        <>
            <div>
                <div className={`modal fade cw_modal add_card_pop ${props.showExistingPlanPopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content p-4'>
                            <form onSubmit={linkExistingformik.handleSubmit}>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='updatePersonalInfoLabel'>Link Existing Plan</h5>
                                </div>
                                <div className='modal-body'>
                                    <div className="mb-3">
                                        <label className='mb-2'>Barcode or License Plate # and CC<sup className='text-danger'>*</sup></label>
                                        <input
                                            type='text'
                                            name='barCode'
                                            id='barCode'
                                            className='form-control form-control-lg'
                                            placeholder='Enter Code'
                                            maxLength='20'
                                            onChange={linkExistingformik.handleChange}
                                            onBlur={linkExistingformik.handleBlur}
                                            value={linkExistingformik.values.barCode}
                                        />
                                        {linkExistingformik.touched.barCode && linkExistingformik.errors.barCode ? (
                                            <small className="text-danger validationError">{linkExistingformik.errors.barCode}</small>
                                        ) : null}
                                    </div>
                                    <div className="mb-0">
                                        <label className='mb-2'>Card Number (Last 4 digits)<sup className='text-danger'>*</sup></label>
                                        <input
                                            type='text'
                                            pattern="[0-9]*"
                                            name='ccNumber'
                                            id='cardNumber'
                                            className='form-control form-control-lg'
                                            placeholder='0000'
                                            onChange={linkExistingformik.handleChange}
                                            onBlur={linkExistingformik.handleBlur}
                                            value={linkExistingformik.values.ccNumber}
                                            maxLength='4'
                                            min="0"
                                            max="10000"
                                        />
                                        {linkExistingformik.touched.ccNumber && linkExistingformik.errors.ccNumber ? (
                                            <small className="text-danger validationError">{linkExistingformik.errors.ccNumber}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='modal-footer justify-content-center'>
                                    <button type='submit' id="btnAlertYes" className='btn btn-primary' disabled={!linkExistingformik.isValid ||linkExistingformik.values.barCode === ''||linkExistingformik.values.ccNumber === '' }><span id="spanLoader" className="spinner-border text-light btnLoader" style={{display: 'none'}} />Continue</button>
                                    <button type='button' className='btn btn-secondary' onClick={() => {
                                        linkExistingformik.resetForm();
                                        props.setAddExistingPlan(false);
                                    }}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {props.showExistingPlanPopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
        </>
    );
};

export default LinkExistingModal;
