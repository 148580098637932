import {addNewAddedCard, setCreditCards} from '../../../store/reducers/manage-account-reducer';
import {config} from '../../../utils/config/config';
import {saveCreditCard} from '../../../services/order-service';
import {seqLogger} from '@services/seq-logger-service';
import {seqReqBodyObj} from '@utils/common-helper';

function initializeCardknoxPCI(userInfo, preferedHomeLocation, props, setIsCreditCardValid, setIsCvvValid, setExpiry, setZipCode, toast, toastId, dispatch, setIsExpValid, setIsZipCodeValid) {
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const defaultStyle = {
        'border': '1px solid rgb(230, 230, 230)',
        'font-size': '1.05rem',
        'border-radius': '10px',
        'display': 'block',
        'background': 'white',
        'color': 'black',
        'text-align': 'left',
        'width': 'calc(100% - 2px)',
        'height': '54px',
        'overflow': 'hidden',
        'padding': '0',
        'text-indent': '1rem'
    };

    const validStyle = {
        'border': '1px solid green',
        'font-size': '1.05rem',
        'border-radius': '10px',
        'display': 'block',
        'background': 'white',
        'color': 'black',
        'text-align': 'left',
        'width': 'calc(100% - 2px)',
        'height': '54px',
        'overflow': 'hidden',
        'padding': '0',
        'text-indent': '1rem'
    };

    const invalidStyle = {
        'border': '1px solid red',
        'font-size': '1.05rem',
        'border-radius': '10px',
        'display': 'block',
        'background': 'white',
        'color': 'black',
        'text-align': 'left',
        'width': 'calc(100% - 2px)',
        'height': '54px',
        'overflow': 'hidden',
        'padding': '0',
        'text-indent': '1rem'
    };

    /*
     * [Optional]
     * You can enable allowing the user to submit the form by pressing the 'enter' key on their keyboard when the ifield is focused by calling
     * enableAutoSubmit(formElementId)
     *
     * The formElementId is the id of your form that gets submit when the user presses 'enter' on their keyboard.
     *
     * Note: If this feature is enabled, the getTokens must be handled in a submit event listener for the form as that event is what gets triggered.
     */
    enableAutoSubmit('payment-form-cardknox');

    /*
    * [Optional]
    * You can enable 3DS Protection using enable3DS(amountElementId, monthElementId, yearElementId, waitForResponse, waitForResponseTimeout)
    *
    * The amountElementId, monthElementId, and yearElementId parameters are the ids for the html elements that contain the resepective values for those fields.
    *
    * The waitForResponse parameter is an optional flag that indicates whether the getToken function should wait for 3DS data if it did not yet
    * recieve it before generating the tokens (the default value is true). If the value is set to false, the tokens will be created without 3DS protection.
    *
    * The waitForResponseTimeout parameter is how long it should wait for a 3DS response before creating the token without it (the default value is 20,000ms).
    *
    * Note: The timeout for waitForResponse and getToken are not cumulative. If they are the same value and the waitForResponse timeout is hit, the getToken timeout
    * will trigger as well.
    */
    enable3DS('amount', 'month', 'year', true, 20000);

    /*
     * [Optional]
     * You can customize the iFields by passing in the appropriate css as JSON using setIfieldStyle(ifieldName, style)
     */
    setIfieldStyle('ach', defaultStyle);
    setIfieldStyle('card-number', defaultStyle);
    setIfieldStyle('cvv', defaultStyle);

    /*
     * [Required]
     * Set your account data using setAccount(ifieldKey, yourSoftwareName, yourSoftwareVersion).
     */
    setAccount(config.cardkNoxIfield, 'iFields_Sample_Form', '1.0');
    // setAccount('ifieldssamplekey_7e133696ce254383824cdff436c50c7b', 'iFields_Sample_Form', '1.0');

    /*
     * [Optional]
     * Use enableAutoFormatting(separator) to automatically format the card number field making it easier to read
     * The function contains an optional parameter to set the separator used between the card number chunks (Default is a single space)
     */
    enableAutoFormatting();

    /*
     * [Optional]
     * Use addIfieldCallback(event, callback) to set callbacks for when the event is triggered inside the ifield
     * The callback function receives a single parameter with data about the state of the ifields
     * The data returned can be seen by using alert(JSON.stringify(data));
     * The available events are ['input', 'click', 'focus', 'dblclick', 'change', 'blur', 'keypress']
     *
     * The below example shows a use case for this, where you want to visually alert the user regarding the validity of the card number, cvv and ach ifields
     */
    addIfieldCallback('input', function(data) {
        // const submitBtn = document.getElementById('submit-btn');
        if (data.ifieldValueChanged) {
            if (data.cardNumberIsValid) {
                setIsCreditCardValid(true);
            } else {
                setIsCreditCardValid(false);
            }
            setIfieldStyle('card-number', data.cardNumberFormattedLength <= 0 ? defaultStyle : data.cardNumberIsValid ? validStyle : invalidStyle);
            if (data.lastIfieldChanged === 'cvv') {
                setIfieldStyle('cvv', data.issuer === 'unknown' || data.cvvLength <= 0 ? defaultStyle : data.cvvIsValid ? validStyle : invalidStyle);
                if (data.cvvIsValid) {
                    setIsCvvValid(true);
                } else {
                    setIsCvvValid(false);
                }
            } else if (data.lastIfieldChanged === 'card-number') {
                if (data.issuer === 'unknown' || data.cvvLength <= 0) {
                    setIfieldStyle('cvv', defaultStyle);
                } else if (data.issuer === 'amex') {
                    setIfieldStyle('cvv', data.cvvLength === 4 ? validStyle : invalidStyle);
                } else {
                    setIfieldStyle('cvv', data.cvvLength === 3 ? validStyle : invalidStyle);
                }
            } else if (data.lastIfieldChanged === 'ach') {
                setIfieldStyle('ach', data.achLength === 0 ? defaultStyle : data.achIsValid ? validStyle : invalidStyle);
            }
        }
    });

    /*
     * [Optional]
     * You can set focus on an ifield by calling focusIfield(ifieldName), in this case a delay is added to ensure the iframe has time to load
     */
    const checkCardLoaded = setInterval(function() {
        clearInterval(checkCardLoaded);
        focusIfield('card-number');
    }, 1000);

    /*
     * [Optional]
     * You can clear the ifield by calling clearIfield(ifieldName)
     */
    document.getElementById('clear-btn').addEventListener('click', function(e) {
        e.preventDefault();
        clearIfield('card-number');
        clearIfield('cvv');
        clearIfield('ach');
    });
    if (!props.noLogged) {
        document.getElementById('btnCCPopCancelCardKnox').addEventListener('click', function(e) {
            e.preventDefault();
            clearIfield('card-number');
            clearIfield('cvv');
            clearIfield('ach');
            setExpiry('');
            setZipCode('');
            setIsCreditCardValid(false);
            setIsCvvValid(false);
            setIsZipCodeValid(false);
            setIsExpValid(false);
        });
    }

    /*
     * [Required]
     * Call getTokens(onSuccess, onError, [timeout]) to create the SUTs. * Pass in callback functions for onSuccess and onError. Timeout is an optional
     * parameter that will exit the function if the call to retrieve the SUTs take too long. The default timeout is 60 seconds. It takes an number value
     * marking the number of milliseconds.
     *
     * If autoSubmit is enabled, this must be done in a submit event listener for the form element.
     */
    document.getElementById('payment-form-cardknox').addEventListener('submit', function(e) {
        e.preventDefault();
        // document.getElementById('transaction-status').innerHTML = 'Processing Transaction...';
        // const submitBtn = document.getElementById('submit-btn');
        // submitBtn.disabled = true;
        props.loader(true);
        getTokens(async function() {
            console.log('success');
            // const achToken = document.querySelector('[data-ifields-id=\'ach-token\']').value;
            const cardToken = document.querySelector('[data-ifields-id=\'card-number-token\']').value;
            // const cvvToken = document.querySelector('[data-ifields-id=\'cvv-token\']').value;
            const billingZipCode = document.getElementById('zipCodeCardknox').value;
            const ExpDate = document.getElementById('expDate').value;
            if (props.noLogged == undefined) {
                const postModel = {
                    temporary_token: cardToken,
                    unixTimeStamp: Date.now(),
                    CustomerIdentityId: userInfo.customerIdentityID,
                    CustomerGuid: userInfo.customerGuid,
                    PreferHomeLocationId: preferedHomeLocation == null ? localStorage.getItem('locationId'):preferedHomeLocation,
                    sBillPostalCode: billingZipCode,
                    xExp: ExpDate?.replace('/', '')
                };
                const response = await saveCreditCard(userInfo.accessToken, postModel);
                const phoneNumber = customerDetail.customer.sPhone;
                if (response.status === 200) {
                    props.loader(false);
                    if (props.setCard!= undefined && props.setCard.length !=undefined) {
                        props.setCard(response.data.item1);
                    }
                    dispatch(addNewAddedCard({cC: response.data.item1}));
                    dispatch(setCreditCards({creditCards: []}));
                    toast.success(response.data.item2, {autoClose: 3000});
                    const seqReqBody = seqReqBodyObj('Information', `Card Added Successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                    document.getElementById('btnCCPopCancelCardKnox').click();
                } else if (response.status === 400) {
                    if (!toast.isActive(toastId.current)) {
                        props.loader(false);
                        toastId.current = toast.error(response.data);
                        if (response.data == 'This card has already been added') {
                            const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${phoneNumber}`);
                            await seqLogger(seqReqBody);
                        } else {
                            const seqReqBody = seqReqBodyObj('Error', `${response.data}`, `${phoneNumber}`);
                            await seqLogger(seqReqBody);
                        }
                        await seqLogger(seqReqBody);
                    }
                    return false;
                } else {
                    toast.error('Error Processing Payment, Please Try Again.', {autoClose: 3000});
                }
            } else {
                const txtMobileNo= document.getElementById('txtMobileNoCardknox');
                const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
                if (!regex.test(txtMobileNo.value)) {
                    txtMobileNo.style.border = '0.5px solid #FF0000';
                    toast.error('Invalid mobile no.');
                    props.loader(false);
                    return false;
                }
                const postModel = {
                    // eslint-disable-next-line camelcase
                    temporary_token: cardToken,
                    unixTimeStamp: Date.now(),
                    xExp: ExpDate?.replace('/', ''),
                    phoneNumber: String(txtMobileNo.value).replace('(', '').replace(')', '').replace(' ', '').replace(' ', '')
                    // phoneNumber: String(txtMobileNo.value).replace(/([() -])+/g, '')
                };
                props.loader(false);
                props.checkout(postModel);
            }

            // The following line of code has been commented out for the benefit of the sample form. Uncomment this line on your actual webpage.
            // document.getElementById('payment-form').submit();
        },
        function() {
            document.getElementById('transaction-status').innerHTML = '';
            document.getElementById('ach-token').innerHTML = '';
            document.getElementById('card-token').innerHTML = '';
            document.getElementById('cvv-token').innerHTML = '';
            submitBtn.disabled = false;
        },
        30000
        );
    });
};

export {initializeCardknoxPCI};
