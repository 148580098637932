import '@assets/css/skeleton.css';
import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonOrderHistory = (props) => {
    let index = 0;
    const data = [];
    const makeRows = () => {
        do {
            data.push(
                <tr key={index} className="orderHistory">
                    <td><SkeletonElement type="text" /></td>
                    <td><SkeletonElement type="text" /></td>
                    <td><SkeletonElement type="text" /></td>
                    <td><SkeletonElement type="text" /></td>
                    <td><SkeletonElement type="text downloadBtn" /></td>
                </tr>
            );
            index += 1;
        } while (index < props.count);
        return data;
    };

    return makeRows();
};

export default SkeletonOrderHistory;
