import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import React from 'react';
import {config} from '../../utils/config/config';

const LocationMap = () => {
    const mapStyles = {height: '445px', width: '100%'};
    const googleMapsApiKey = config.googleMapsApiKey;
    const preferedHomeLocation = JSON.parse(localStorage.getItem('userInfo')).preferedHomeLocation;
    const locationLocalData = JSON.parse(localStorage.getItem('locations'));
    let lat = 39.733821868896484;
    let lang = -82.62784576416016;
    if (locationLocalData.length > 0) {
        locationLocalData.map((locationLocalData) => {
            if (preferedHomeLocation != null) {
                if (preferedHomeLocation === locationLocalData.locationId) {
                    if (locationLocalData.sLatitude != null) {
                        lat = locationLocalData.sLatitude;
                    }
                    if (locationLocalData.sLongitude != null) {
                        lang = locationLocalData.sLongitude;
                    }
                }
            }
        });
    }
    const defaultCenter = {lat: lat, lng: lang};
    return (
        <LoadScript
            googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={defaultCenter}>
                <Marker position={defaultCenter}
                    draggable={true}
                />
            </GoogleMap>
        </LoadScript>
    );
};

export default LocationMap;
