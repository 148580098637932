import * as Yup from 'yup';
import {BiGift, BiWallet} from 'react-icons/bi';
import {FiArrowRight, FiMapPin, FiPhoneCall} from 'react-icons/fi';
import React, {Component} from 'react';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';
import {RiCarWashingLine} from 'react-icons/ri';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
// Store
import {setIsForMySelf, setPreviousPage} from '@store/reducers/auth-reducer';
import {setCreditCards} from '../../store/reducers/manage-account-reducer';
import {setIsLoading} from '@store/reducers/global-reducer';
import {setSelectedPlan} from '@store/reducers/order-reducer';
import {setSelectedWallet} from '@store/reducers/wallet-reducer';
import {setUserInfo} from '@store/reducers/auth-reducer';
import store from '@store/index';
// Components
import Account from '../Account';
import Layout from '@components/Layout';
// Services
import {nearByLocation, setPreferedLocationWeb, verifyZipcode} from '@services/location-service';
import {creditCardsList} from '@services/manage-account-service';
import {getCustomerInfoByGuid} from '@services/user-services';
import {getPortalThemeColor} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';
// Utils
import LoadApis from '@utils/load-apis';
import {getPCIDetailsLocal} from '@utils/common-repo';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './Dashboard.style.css';
import {convertHexToHSL} from '../../utils/common-helper';
import {printText} from '../../utils/common-repo';
// import {checkCustomerDuplicateRecord} from '../../services/auth-service';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PCIConfig: getPCIDetailsLocal(),
            isContinue: JSON.parse(localStorage.getItem('isContinue')),
            count: 0,
            userInfo: store.getState().auth.userInfo,
            phoneNumber: localStorage.getItem('phoneNumber'),
            isZipModalOpen: false,
            // isCustomerRecordModelOpen: false,
            isNearByLocationOpen: false,
            locationData: store.getState().auth.locations,
            prepaidWashService: {},
            washService: {},
            detailingService: {},
            wallet: {},
            giftCard: {},
            location: {},
            customerData: [],
            duplicateCustomerRecord: [],
            vipAccountData: {},
            preferredCard: '',
            portalCustomizationsList: JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList,
            preferredLocation: {}
        };
    };

    async componentDidMount() {
        localStorage.setItem('isReactivate', 'false');
        document.title = localStorage.getItem('title');
        const customizedData = JSON.parse(localStorage.getItem('customizePage'))?.portalScreenOptionsList.filter((service) => service.type === 1 && service.isActive === true);
        // const customizedData1 = JSON.parse(localStorage.getItem('customizePage'))?.portalScreenOptionsList.filter((service) => service.type === 2 && service.isActive === true);
        const prepaidWashService = customizedData?.find((x) => x.targetId === 'prepaid_wash_service');
        const washService = customizedData?.find((x) => x.targetId === 'wash_services');
        const detailingService = customizedData?.find((x) => x.targetId === 'detailing_services');
        const wallet = customizedData?.find((x) => x.targetId === 'wallet');
        const giftCard = customizedData?.find((x) => x.targetId === 'gift_card');
        const location = customizedData?.find((x) => x.targetId === 'find_location_btn');
        const customerCode = localStorage.getItem('customerCode');
        this.setState({prepaidWashService});
        this.setState({washService});
        this.setState({detailingService});
        this.setState({wallet});
        this.setState({giftCard});
        this.setState({location});
        store.dispatch(setPreviousPage({previousPage: ''}));
        store.dispatch(setSelectedWallet({selectedWallet: {}}));
        store.dispatch(setSelectedPlan({selectedPlan: {}}));
        if (localStorage.getItem('customerGuid') == null) {
            localStorage.setItem('customerGuid', this.state.userInfo.customerGuid);
        }
        this.getCustomerInfo();
        this.getCreditCardsList();
        localStorage.setItem('SetIsEmail', false);
        toast.configure();
        const customSetState = (data) => this.setState(data);
        const getLocation = () => {
            const success = (position) => console.log(position.coords);
            const permissionDeny = this.permissionDeny;
            const setNearBuyLocation = this.setNearBuyLocation;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success); // If we need to show geolocation error then we can pass error function in it.
            }

            navigator.geolocation.getCurrentPosition(
                (i) => {
                    setNearBuyLocation('granted', false, false);
                },
                (i) => {
                    permissionDeny();
                }
            );
        };

        if (this.state.userInfo.preferedHomeLocation === null) {
            getLocation();
            navigator.geolocation.watchPosition(function(position) { },
                function(error) {
                    if (error.code == error.PERMISSION_DENIED) customSetState({isZipModalOpen: true});
                });
        }

        if (this.state.locationData.length) {
            for (let index = 0; index < this.state.locationData.length; index++) {
                const location = this.state.locationData[index];
                if (location.locationId === this.state.userInfo.preferedHomeLocation) {
                    this.setState({preferredLocation: location});
                    break;
                }
            }
        }

        // Dynamic theme color.
        const getPortalThemeColorResponse = await getPortalThemeColor(customerCode);
        if (this.state.portalCustomizationsList !== null && this.state.portalCustomizationsList.length) {
            for (let index = 0; index < this.state.portalCustomizationsList.length; index++) {
                const obj = this.state.portalCustomizationsList[index];
                if (obj.sName === 'site_cpv3_theme_color' && obj.sValue !== null && obj.sValue !== undefined && obj.sValue !== '') {
                    document.documentElement.style.setProperty('--_hue', convertHexToHSL(obj.sValue));
                    break;
                }
            }
        }
        if (getPortalThemeColorResponse.status === 200) {
            const colorValue = getPortalThemeColorResponse.data.sValue;
            if (colorValue !== null && colorValue !== undefined && colorValue !== '') {
                document.documentElement.style.setProperty('--_hue', convertHexToHSL(colorValue));
            }
        }
    }

    getCustomerInfo = async () => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.setState({customerData: []});
        const response = await getCustomerInfoByGuid(this.state.userInfo.accessToken, localStorage.getItem('customerGuid'), userInfo.customerIdentityID);
        if (response.status == 200) {
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
            localStorage.setItem('userInfo', JSON.stringify({...userInfo, firstname: response.data.customer.sFirstName, lastname: response.data.customer.sLastName, customerEmail: response.data.customer.sEmail, gender: response.data.customer.sSex, dtBirthday: response.data.customer.dtBirthday}));
            store.dispatch(setUserInfo({userInfo: {...userInfo, firstname: response.data.customer.sFirstName, lastname: response.data.customer.sLastName, customerEmail: response.data.customer.sEmail, gender: response.data.customer.sSex, dtBirthday: response.data.customer.dtBirthday}}));
            const seqReqBody = seqReqBodyObj('Information', `Customer information retrieve successfully`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
            this.setState({customerData: response.data});
            this.setState({vipAccountData: response.data.vipAccountType});
            this.setState({preferredCard: response.data.customerDetail != null ? response.data.customerDetail.sPayerIdentifier : null});
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
            const seqReqBody = seqReqBodyObj('Error', `${response.data.title}`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
        }
    };
    getCreditCardsList = async () => {
        const response = await creditCardsList(this.state.userInfo.accessToken, this.state.userInfo.customerIdentityID);
        if (response.status === 200) {
            setTimeout(() => {
                store.dispatch(setCreditCards({creditCards: response.data}));
                localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
            }, 1000);
        }
    };
    // On location allow
    setNearBuyLocation = async (data, viaZipcode, viaLocation) => {
        const userInfo = {...this.state.userInfo};
        (viaZipcode || viaLocation) && this.props.setIsLoading(true);
        const response = await nearByLocation(userInfo.accessToken, {
            lat: data.latitude,
            lng: data.longitude,
            phoneNumber: this.state.phoneNumber.replace(/([() -])+/g, ''),
            customerIdentityId: userInfo.customerIdentityID
        });
        if (response.status === 200) {
            setTimeout(async () => {
                userInfo.preferedHomeLocation = response.data.preferedHomeLocation;
                this.props.setUserInfo(userInfo);
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                this.props.setIsLoading(false);
                const phoneNumber = this.state.phoneNumber.replace(/([() -])+/g, '');
                const seqReqBody = seqReqBodyObj('Information', `Your preferred location has been set successfully`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
                if (viaZipcode) {
                    this.setState({isZipModalOpen: false});
                } else if (viaLocation) {
                    this.setState({isNearByLocationOpen: false});
                }
                (viaZipcode || viaLocation) && toast.success(response.data.message, {autoClose: 3000});
            }, 1000);
        } else {
            this.props.setIsLoading(false);
        }
    };

    // Location permission deny
    permissionDeny = () => this.setState({isZipModalOpen: true});

    setIsContinue = () => {
        JSON.stringify(localStorage.setItem('isContinue', false));
        this.setState({isContinue: false});
    };

    hideContinue = () => {
        const customizedData = JSON.parse(localStorage.getItem('customizePage'));
        const portalScreenOptionsList = customizedData?.portalScreenOptionsList?.filter((x) => x.type == 2 && x.isActive == true).length;
        this.setState({count: this.state.count + 1});
        if (this.state.count === portalScreenOptionsList - 1) {
            JSON.stringify(localStorage.setItem('isContinue', false));
            this.setState({isContinue: false});
        }
    };

    setNearByLocationByZip = async (values, {resetForm}) => {
        const phoneNumber = this.state.phoneNumber.replace(/([() -])+/g, '');
        this.props.setIsLoading(true);
        const response = await verifyZipcode(values.zipcode);
        if (response.status === 200) {
            const seqReqBody = seqReqBodyObj('Information', `Veryfy zipcode successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
            const resData = response.data;
            if (resData.status === 'OK' && resData.results.length > 0) {
                const data = {
                    latitude: resData.results[0].geometry?.location.lat,
                    longitude: resData.results[0].geometry.location.lng
                };
                this.setNearBuyLocation(data, true, false);
            } else {
                toast.warning('Wrong ZIP Code.', {autoClose: 3000});
                this.props.setIsLoading(false);
                resetForm();
                const seqReqBody = seqReqBodyObj('Information', `Wrong ZIP Code`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
            }
        } else {
            this.props.setIsLoading(false);
        }
    };

    setNearByLocationByLocation = async (data) => {
        const userInfo = {...this.state.userInfo};
        this.props.setIsLoading(true);
        const response = await setPreferedLocationWeb(userInfo.accessToken, {
            locationId: data.locationId,
            id: userInfo.customerIdentityID
        });
        if (response.status === 200) {
            const phoneNumber = this.state.phoneNumber.replace(/([() -])+/g, '');
            this.setState({isNearByLocationOpen: false});
            this.props.setIsLoading(false);
            setTimeout(async () => {
                userInfo.preferedHomeLocation = data.locationId;
                this.props.setUserInfo(userInfo);
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                toast.success('Your preferred location has been set successfully.', {autoClose: 3000, poistion: 'bottom-right'});
            }, 1000);
            const seqReqBody = seqReqBodyObj('Information', `Your preferred location has been set successfully`, `${phoneNumber}`);
            await seqLogger(seqReqBody);
        } else {
            this.props.setIsLoading(false);
        }
    };

    managePreviousPage = (route) => {
        if (route === 'unlimited_wash_club_box') {
            this.props.setPreviousPage();
        }
        this.props.setIsForMySelf();
    };

    render() {
        const {isContinue, isZipModalOpen, isNearByLocationOpen, locationData} = this.state;
        const customizedData = JSON.parse(localStorage.getItem('customizePage'));
        const siteHeader = customizedData.portalCustomizationsList.find((d) => d.sName == 'site_home_box_header');
        const siteFooter = customizedData.portalCustomizationsList.find((d) => d.sName == 'site_home_box_footer');

        return (
            <Layout>
                <div className='container'>
                    <div className='row'>
                        {!this.state.PCIConfig.isPaymentLive ? <div className='col-12'>
                            <div className='alert alert-danger d-flex align-items-center' role='alert'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' className='bi bi-exclamation-triangle-fill flex-shrink-0 me-2' viewBox='0 0 16 16' role='img' aria-label='Warning:'>
                                    <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
                                </svg>
                                <div>DEMO SITE ONLY, PAYMENTS ARE NOT LIVE</div>
                            </div>
                        </div> : ''}
                        <div className='col-12'>
                            <div className='main_title mb-4 mt-3'>
                                {siteHeader != undefined ? <h2>{siteHeader.sValue}</h2> : <h2>Hi ,Welcome to Car Wash !</h2>}
                                <p className='sitefooter'>{siteFooter.sValue}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="acc_box">
                            <div className="accordion" id="accordionExample">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <Account preferredCard={this.state.preferredCard} vipAccountData={this.state.vipAccountData} getCustomerInfo={this.getCustomerInfo} />
                                    </div>
                                    {this.state.prepaidWashService || this.state.washService || this.state.detailingService ?
                                        <div className="col-lg-4 col-md-6 col-sm-12 justify-content-start">
                                            <div className="accordion-item mb-4">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo" aria-expanded="false"
                                                        aria-controls="collapseTwo">
                                                        <div className="acc_profile">
                                                            <a href="#" className='userIcon'><RiCarWashingLine /></a>
                                                            <div className="">
                                                                <h5>Wash Manager</h5>
                                                                <span>Explore Wash Services</span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        {this.state.prepaidWashService ?
                                                            <Link to={`/${this.state.prepaidWashService.targetId}`}>
                                                                <div className="myacc_cm_detail">
                                                                    <div className="">
                                                                        <h5>{printText(this.state.prepaidWashService?.title, 'Prepaid Wash Plans')}</h5>
                                                                        <span>{printText(this.state.prepaidWashService?.description, 'Buy a prepaid deal')}</span>
                                                                    </div>
                                                                    <div className="card_arrow">
                                                                        <span><FiArrowRight /></span>
                                                                    </div>
                                                                </div>
                                                            </Link> : ''
                                                        }
                                                        {this.state.washService ?
                                                            <Link to={`/${this.state.washService.targetId}`}>
                                                                <div className="myacc_cm_detail">
                                                                    <div className="">
                                                                        <h5>{printText(this.state.washService?.title, 'Wash Services')}</h5>
                                                                        <span>{printText(this.state.washService?.description, 'Buy a single wash')}</span>
                                                                    </div>
                                                                    <div className="card_arrow">
                                                                        <span><FiArrowRight /></span>
                                                                    </div>
                                                                </div>
                                                            </Link> : ''
                                                        }
                                                        {this.state.detailingService ?
                                                            <Link to={`/${this.state.detailingService.targetId}`}>
                                                                <div className="myacc_cm_detail myacc_cp_detail">
                                                                    <div className="">
                                                                        <h5>{printText(this.state.detailingService?.title, 'Detailing Services')}</h5>
                                                                        <span>{printText(this.state.detailingService?.description, 'Buy a detailing service')}</span>
                                                                    </div>
                                                                    <div className="card_arrow">
                                                                        <span><FiArrowRight /></span>
                                                                    </div>
                                                                </div>
                                                            </Link> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''
                                    }
                                    <div className="col-lg-4 col-md-12">
                                        <div className="myacc_paycards">
                                            <div className="row g-3">
                                                {this.state.wallet ?
                                                    <div className="col">
                                                        <Link to={`/${this.state.wallet.targetId}`}>
                                                            <div className="d_card home_wallet_card">
                                                                <div className="mycard_icon userIcon">
                                                                    <BiWallet />
                                                                </div>
                                                                <div className="card_detail">
                                                                    <div className="">
                                                                        <h4>Wallet</h4>
                                                                        <span>Redeem a wash code</span>
                                                                    </div>
                                                                    <div className="card_arrow">
                                                                        <span><FiArrowRight /></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div> : ''
                                                }
                                                {this.state.giftCard ?
                                                    <div className="col">
                                                        <Link to={`/${this.state.giftCard.targetId}`}>
                                                            <div className="d_card home_gift_card">
                                                                <div className="mycard_icon userIcon">
                                                                    <BiGift />
                                                                </div>
                                                                <div className="card_detail">
                                                                    <div className="">
                                                                        <h4>{printText(this.state.giftCard?.title, 'Gift Card')}</h4>
                                                                        <span>{printText(this.state.giftCard?.description, 'Buy a gift card')}</span>
                                                                    </div>
                                                                    <div className="card_arrow">
                                                                        <span><FiArrowRight /></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div> : ''
                                                }
                                            </div>
                                        </div>
                                        {this.state.location ?
                                            <div className="locationcard mt-4">
                                                <div className="lcard_top">
                                                    <div className="lcard_icon"><FiMapPin /></div>
                                                    <span className="opentd d-none">Open Today</span>
                                                </div>
                                                <div className="card_detail w-100 mt-lg-3">
                                                    <div className="">
                                                        <h4>{this.state.preferredLocation?.sName}</h4>
                                                        <span>{this.state.preferredLocation?.sAddress}</span>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <Link to={`/${this.state.location.targetId}`} className='link-primary'>Change Location</Link>
                                                        {this.state.preferredLocation.sPhone !== null ?
                                                            <a href={`tel:${this.state.preferredLocation?.sPhone?.replace(/([() -])+/g, '')}`} className="lcard_callIcon"><FiPhoneCall /></a> : ''}
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal fade cw_modal add_info_pop ${isNearByLocationOpen ? 'show d-block' : ''}`} id='locationInfo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='locationInfoLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered '>
                        <div className='modal-content locationinfo p-4'>
                            <div className='modal-header'>
                                <h3 className='modal-title' id='locationInfoLabel'>Locations</h3>
                            </div>
                            <div className='modal-body'>
                                {locationData !== undefined && locationData !== null && locationData.map((service) => {
                                    return (
                                        <div className='select_loc_pop' key={service.locationId}>
                                            <div className='service_box'>
                                                <div className='ser_info'>
                                                    <h3>{service.sName}</h3>
                                                    <span>{service.sAddress}</span>
                                                </div>
                                                <button type='button' className='btn btn-primary' style={{float: 'right'}} onClick={() => this.setNearByLocationByLocation(service)}>Set Location</button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal fade cw_modal add_card_pop ${isZipModalOpen ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content p-4'>
                            <Formik
                                initialValues={{zipcode: ''}}
                                onSubmit={(values, {resetForm}) => this.setNearByLocationByZip(values, {resetForm})}
                                validationSchema={Yup.object().shape({
                                    zipcode: Yup.string()
                                        .required('ZIP Code is required.')
                                        .matches(/^[0-9]+$/, 'ZIP Code must be only digits.')
                                        .min(5, 'ZIP Code must be min 5 digits.')
                                        .max(5, 'ZIP Code must be max 5 digits.')
                                })}
                            >
                                {({values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isValid
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='modal-body zip_input_modal pt-4 pb-4'>
                                            <input
                                                type='text'
                                                name='zipcode'
                                                id='zipcode'
                                                className='form-control form-control-lg mb-1'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.zipcode}
                                                placeholder='ZIP Code'
                                            />
                                            {touched.zipcode && errors.zipcode ? (
                                                <small className="text-danger">{errors.zipcode}</small>
                                            ) : null}
                                        </div>
                                        <div className='modal-footer justify-content-center'>
                                            <button type='submit' className='btn btn-primary' disabled={!isValid || values.zipcode === ''}>Save</button>
                                            {locationData !== undefined && locationData !== null && locationData.length > 0 ?
                                                <button type='button' className='btn btn-secondary' onClick={() => this.setState({isZipModalOpen: false, isNearByLocationOpen: true})}>Cancel</button> : ''
                                            }
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                {/* continue modal */}
                {isContinue && customizedData?.portalScreenOptionsList?.filter((x) => x.type == 2 && x.isActive == true).length > 0 ?
                    <div className="conatiner welcome_intro_slides">
                        <div className="washes-pop-up ">
                            <div className={`modal fade show`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true" style={{display: 'block', background: 'rgba(0, 0, 0, 0.1)'}}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content" style={{background: '#fff', borderRadius: '10px'}}>
                                        <div className="modal-body">
                                            <div id="carouselWelcomeIntro" className="carousel slide cp_wlcm" data-bs-interval="false" data-bs-ride="carousel">
                                                <div className="carousel-indicators d-none">
                                                    <button type="button" data-bs-target="#carouselWelcomeIntro"
                                                        data-bs-slide-to="0" className="active" aria-current="true"
                                                        aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselWelcomeIntro"
                                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselWelcomeIntro"
                                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <h5 className='mb-3'>{this.state.count + 1}/{customizedData.portalScreenOptionsList?.filter((x) => x.type == 2 && x.isActive == true).length}</h5>
                                                    {customizedData.portalScreenOptionsList?.filter((x) => x.type == 2 && x.isActive == true)?.sort((a, b) => a.iOrderNumber > b.iOrderNumber ? 1 : -1).map((info, index) => {
                                                        if (info.type === 2 && info.isActive === true) {
                                                            return (
                                                                <div className={`carousel-item ${index == 0 ? 'active' : ''}`} key={info.id}>
                                                                    <img src="assets/images/user.png" alt="" />
                                                                    <h2>{info.title}</h2>
                                                                    <p>{info.description}</p>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                <button className="carousel-control-prev d-none" type="button"
                                                    data-bs-target="#carouselWelcomeIntro" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next d-none" type="button"
                                                    data-bs-target="#carouselWelcomeIntro" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="modal-footer">

                                            <button type="button" className="btn btn-primary carousel-control-next"
                                                data-bs-target="#carouselWelcomeIntro" data-bs-slide="next" onClick={() => this.hideContinue()}>
                                                Continue<i className="fa-solid fa-arrow-right"></i></button>
                                            <button type="button" className="btn" data-bs-dismiss="modal" onClick={() => this.setIsContinue()}>Skip</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
                {isContinue && customizedData?.portalScreenOptionsList?.length > 0 || isZipModalOpen || isNearByLocationOpen ? <div className="modal-backdrop fade show"></div> : ''}
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    // Pre load services, wallet and locations data.
    const userInfo = store.getState().auth.userInfo;
    const prepaidWashPlans = store.getState().prepaidWash.prepaidWashPlans;
    const washServices = store.getState().prepaidWash.services;
    const detailingServices = store.getState().prepaidWash.detailingServices;
    const walletServices = store.getState().wallet.services;
    const orderHistory = store.getState().wallet.orderHistory;
    const locations = store.getState().location.services;
    const giftCards = store.getState().giftCard.giftCards;
    const phoneNumber = localStorage.getItem('phoneNumber');
    const locationId = localStorage.getItem('locationIdForService') == null ? localStorage.getItem('locationId') : localStorage.getItem('locationIdForService');

    !prepaidWashPlans.length && LoadApis.prepaidWashPlans(userInfo.accessToken, locationId, dispatch, phoneNumber);
    !washServices.length && LoadApis.washServices(userInfo.accessToken, locationId, dispatch, phoneNumber);
    !detailingServices.length && LoadApis.detailingServices(userInfo.accessToken, locationId, dispatch, phoneNumber);
    !walletServices.length && LoadApis.wallet(userInfo, dispatch, phoneNumber);
    !orderHistory.length && LoadApis.orderHistory(userInfo, dispatch, phoneNumber);
    !locations.length && LoadApis.locations(userInfo.accessToken, dispatch, phoneNumber);
    !giftCards.length && LoadApis.giftCards(userInfo, dispatch, phoneNumber);

    return {
        setIsForMySelf: () => dispatch(setIsForMySelf({isForMySelf: true})),
        setIsLoading: (param) => dispatch(setIsLoading({isLoading: param})),
        setUserInfo: (param) => dispatch(setUserInfo({userInfo: param})),
        setPreviousPage: () => dispatch(setPreviousPage({previousPage: '/dashboard'}))
    };
};

export default connect(null, mapDispatchToProps)(Dashboard);
