import {createSlice} from '@reduxjs/toolkit';

const portalCustomizationsList = localStorage.getItem('customizePage') !== null && JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList;
const globalSlice = createSlice({
    name: 'global',
    initialState: {
        isLoading: false,
        portalCustomizationsList,
        barcodeString: '',
        buyServices: [],
        barcodeSignupOTPFlag: false
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setPortalCustomizationsList: (state, action) => {
            state.portalCustomizationsList = action.payload.portalCustomizationsList;
        },
        setBarcodeString: (state, action) => {
            state.barcodeString = action.payload.barcodeString;
        },
        setBuyServices: (state, action) => {
            state.buyServices = action.payload.buyServices;
        },
        setPurchaseDate: (state, action) => {
            state.purchaseDateString = action.payload.purchaseDateString;
        },
        setBarcodeSignupOTPFlag: (state, action) => {
            state.barcodeSignupOTPFlag = action.payload.barcodeSignupOTPFlag;
        }
    }
});

export const {
    setIsLoading,
    setPortalCustomizationsList,
    setBarcodeString,
    setBuyServices,
    setPurchaseDate,
    setBarcodeSignupOTPFlag
} = globalSlice.actions;
export default globalSlice.reducer;
