/* eslint-disable no-unused-vars */
export const currencyFormat = (num = 0) => {
    return '$' + Number(num)?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export const currencyFormatWithoutSymbol = (num = 0) => {
    return '$' + Number(num)?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export const phoneNumberFormat = (phoneNumber) => {
    if (phoneNumber == null || phoneNumber == undefined || phoneNumber == '') {
        phoneNumber = '0000000000';
    }
    const phoneWithoutFormat = phoneNumber.replace(/([() -])+/g, '');
    return `(${phoneWithoutFormat.slice(0, 3)}) ${phoneWithoutFormat.slice(3, 6)}-${phoneWithoutFormat.slice(6, 10)}`;
};
export const seqReqBodyObj= (Information ='Information', msg='CPV3', phoneNumber) =>{
    const domainHostName = window.location.hostname;
    const customerCode= localStorage.getItem('customerCode');
    return {
        '@t': new Date(),
        '@l': Information,
        '@mt': `CPV3 - ${msg}`,
        'PhoneNumber': phoneNumber,
        'CustomerCode': customerCode,
        'DomainName': domainHostName
    };
};

export const convertHexToHSL = (H) => {
    if (H == null || H == undefined) {
        return '192';
    }
    // Convert hex to RGB first
    let r = 0; let g = 0; let b = 0;
    if (H.length === 4) {
        r = parseInt('0x' + H[1] + H[1]);
        g = parseInt('0x' + H[2] + H[2]);
        b = parseInt('0x' + H[3] + H[3]);
    } else if (H.length === 7) {
        r = parseInt('0x' + H[1] + H[2]);
        g = parseInt('0x' + H[3] + H[4]);
        b = parseInt('0x' + H[5] + H[6]);
    }

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;
    if (delta === 0) {
        h = 0;
    } else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return h;
};

export const checkStr = (str) => {
    if (str != null && str != undefined) {
        return str;
    } else {
        return '';
    }
};
