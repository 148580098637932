import {createSlice} from '@reduxjs/toolkit';

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        orders: [],
        selectedPlan: {},
        orderReceipt: {}
    },
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload.orders;
        },
        setOrdersReceipt: (state, action) => {
            state.orderReceipt = action.payload.orderReceipt;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan= action.payload.selectedPlan;
        }
    }
});
export const {setOrders, setSelectedPlan, setOrdersReceipt} = orderSlice.actions;
export default orderSlice.reducer;
