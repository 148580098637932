import API from './api';

async function wallet(accessToken, identityId) {
    try {
        return await API.get(`PrepaidBooks/GetCustomerWalletWeb/?customerIdentityId=${identityId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
async function orderHistory(accessToken, identityId) {
    try {
        return await API.get(`PrepaidBooks/GetCustomerPurchaseHistory/?customerIdentityId=${identityId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

async function sendToDevic(accessToken, data) {
    try {
        return await API.post(`Wallet/SendToDevice`, data, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

async function walletBarcodeDetail(walletId) {
    try {
        const postModel = {value: walletId};
        return await API.post(`Wallet/WalletDetail`, postModel, {
            headers: {
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
export {wallet, sendToDevic, walletBarcodeDetail, orderHistory};

