import {currencyFormat, phoneNumberFormat} from '@utils/common-helper';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import React from 'react';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import dateFormatter from '@utils/date-formatter';
import {useSelector} from 'react-redux';

const WalletBarcode = () => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const selectedWallet = useSelector((state) => state.wallet.selectedWallet);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const barcodeString = selectedWallet?.qRcode || selectedWallet?.barcode;
    const barcodeStr = barcodeString !== '' ? barcodeString : 'DemoBarcode';
    return (
        <div className='container'>
            <div className="payment_method_wrap wallet_method_wrap">
                <div className='row'>
                    {!selectedWallet.forSomeOneElse ?
                        <div className='col-lg-6 text-center wallet_barcode'>
                            <div className='barcode_img mx-auto text-center'>
                                <div className={selectedWallet.active ? '' : 'opacity-25'}>
                                    <Barcode value={selectedWallet.active ? barcodeStr : 'Redeemed'} width={2} height={60} />
                                </div>
                            </div>
                            <div className='barcode_msg pt-3 pt-3'>
                                <p>Make sure to show this code to the attendant or,<br/> scan the bar code in the machine.</p>
                            </div>
                            <div className='barcode_img mx-auto text-center mt-4'>
                                <div className={selectedWallet.active ? '' : 'opacity-25'}>
                                    <QRCode value={selectedWallet.active ? barcodeStr : 'Redeemed'} size={200} />
                                </div>
                            </div>
                            <div className='barcode_number text-center p-4'>
                                <small>Your Redeem Code:</small>
                                <h3 className='mt-2'>{barcodeStr}</h3>
                            </div>
                        </div> : ''
                    }
                    <div className={`${selectedWallet.forSomeOneElse?'col-lg-12':'col-lg-6'}`}>
                        {/* new receipt */}
                        <div className="oc_bill_detail mt-3">
                            <h2 className='oc_bill_wm'>{selectedWallet.type === 3 ? 'UNLIMITED' : (selectedWallet.type === 1?'GIFT CARD':'WASH CARD')}</h2>
                            <div className="oc_bill_detail_inner">
                                <ul className='oc_bill_pro_details'>
                                    <li>
                                        <span>Product Name:</span>
                                        <h3>{selectedWallet.description}</h3>
                                    </li>
                                    <li>
                                        <span>Purchase Date:</span>
                                        <h3>{selectedWallet.active == false? dateFormatter(convertUTCDateToLocalDate(selectedWallet.walletDate)):dateFormatter(convertUTCDateToLocalDate(selectedWallet.walletDate))}</h3>
                                    </li>
                                </ul>
                                <ul className='oc_bill_user_details'>
                                    <li>
                                        <span>Customer:</span>
                                        <h3>{`${customerDetail?.customer.sFirstName !== null ? customerDetail.customer.sFirstName : userInfo.firstname}`} {customerDetail.customer?.sFirstName?.length > 18 || customerDetail.customer?.sLastName?.length > 18 ? <br /> : ''}{`${customerDetail?.customer?.sLastName !== null ? customerDetail?.customer?.sLastName : userInfo?.lastName}`}</h3>
                                    </li>
                                    <li>
                                        <span>Email:</span>
                                        <h3>{customerDetail.customer.sEmail}</h3>
                                    </li>
                                    <li>
                                        <span>Phone:</span>
                                        <h3>{phoneNumberFormat(customerDetail.customer.sPhone)}</h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="bill_total">
                                <div className="main_total"><span>Product Price:</span><span>{currencyFormat(selectedWallet?.productPrice)}</span></div>
                                <div className="main_total"><span>Add-on Service Price:</span><span>{currencyFormat(selectedWallet?.serviceTotalAmount)}</span></div>
                                <div className="main_total"><span>Subtotal:</span><span><b>{currencyFormat(selectedWallet?.productPrice + selectedWallet?.serviceTotalAmount)}</b></span></div>
                                <div className="main_total"><span>Tax:</span><span>{currencyFormat(selectedWallet?.taxAmount)}</span></div>
                            </div>
                            <div className="main_total"><span><b>Total:</b></span><span><b>{currencyFormat(selectedWallet?.totalAmount)}</b></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WalletBarcode;
