import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Components
import Layout from '@components/Layout';
// Services
import {giftCardList} from '@services/gift-card-service';
import {setSelectedGiftCard} from '@store/reducers/gift-card-reducer';
// Reducers
import {setGiftCardTab, setGiftCards} from '@store/reducers/gift-card-reducer';
import {setIsForMySelf} from '@store/reducers/auth-reducer';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import SkeletonGiftCardTable from '@utils/skeletons/SkeletonGiftCardTable';

import {convertUTCDateToLocalDate, dateFormatter} from '@utils/date-formatter';
// Services
import {seqLogger} from '@services/seq-logger-service';

const GiftCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const giftCards = useSelector((state) => state.giftCard.giftCards);
    const [isLoading, setIsLoading] = useState(giftCards.length ? false : true);
    const [giftCardServices, setgiftCardServices] = useState([]);
    const [filterType, setFilterType] = useState('All');
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));

    // set redux to init state
    const addGiftCardTabData= {
        isForMySelf: true,
        forOther: {
            FirstName: '',
            LastName: '',
            RecipientEmail: '',
            Message: ''
        }
    };
    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 10; index++) {
            content.push(<SkeletonGiftCardTable key={index} page={{GiftCardTable: true}} />);
        }
        return content;
    };

    const viewCard = (param) => {
        const selectedGiftCard = {
            dblBalance: param.dblBalance,
            sGiftCardNumber: param.sGiftCard_Number,
            dtCreated: dateFormatter(param.dtCreated),
            giftCardGuid: param.giftCardGUID
        };
        dispatch(setSelectedGiftCard({selectedGiftCard}));
        return navigate('/gift-card-details');
    };

    useEffect(() => {
        const getGiftCardList = async () => {
            setgiftCardServices(giftCards);
            if (giftCards.length === 0) {
                const response = await giftCardList(userInfo.accessToken, userInfo.customerIdentityID);
                if (response.status === 200) {
                    setTimeout(() => {
                        setIsLoading(false);
                        dispatch(setGiftCards({giftCards: response.data.filter((x)=>x.dblBalance!=null&&x.dblBalance!=0)}));
                        setgiftCardServices(response.data.filter((x)=>x.dblBalance!=null&&x.dblBalance!=0));
                    }, 1000);
                    const seqReqBody = seqReqBodyObj('Information', `All gift card retrieved successfully`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                } else if (response.status ==400 ) {
                    setIsLoading(false);
                    const seqReqBody = seqReqBodyObj('Worning', `No Cards Found`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                } else {
                    setIsLoading(false);
                }
            }
        };
        getGiftCardList();
    }, []);
    const filterAllItem = () => {
        setFilterType('All');
        const filteredGiftCard = giftCards;
        setgiftCardServices(filteredGiftCard);
        setGiftCards(filteredGiftCard);
    };

    const filterMyselfItem = (bSendasGift) => {
        setFilterType('For Myself');
        const filteredGiftCard = giftCards.filter((curElem) => {
            return curElem.bSendasGift === bSendasGift||curElem.bSendasGift === null;
        });
        setgiftCardServices(filteredGiftCard);
        setGiftCards(filteredGiftCard);
    };
    const filterOthersItem = (bSendasGift) => {
        setFilterType('For Others');
        const filteredGiftCard = giftCards.filter((curElem) => {
            return curElem.bSendasGift === bSendasGift;
        });
        setgiftCardServices(filteredGiftCard);
        setGiftCards(filteredGiftCard);
    };
    const giftCardServicesArr = giftCardServices.map((obj) => {
        return {...obj, dtCreated: new Date(obj.dtCreated)};
    });

    const sortedDescGiftCardServices = giftCardServicesArr.sort(
        (objA, objB) => new Date(objB.dtCreated) - new Date(objA.dtCreated)
    );
    return (
        <Layout>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                            <div className='main_title'>
                                <h2>Gift Cards</h2>
                                <p>Manage your gift card activity</p>
                            </div>
                            <Link to='/add-gift-card' className='btn btn-primary' onClick={() => dispatch(setGiftCardTab({giftCardTabs: addGiftCardTabData}))}>Buy New Gift Card</Link>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='nav custom_tabs mb-3' id='pills-tab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                                <button className='nav-link active' id='pills-all-tab' data-bs-toggle='pill' data-bs-target='#pills-all' type='button' role='tab' aria-controls='pills-all' aria-selected='true' onClick={()=>filterAllItem()}>All</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className='nav-link' id='pills-myself-tab' data-bs-toggle='pill' data-bs-target='#pills-myself' type='button' role='tab' aria-controls='pills-myself' aria-selected='true' onClick={()=>filterMyselfItem(false)}>Myself</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className='nav-link' id='pills-others-tab' data-bs-toggle='pill' data-bs-target='#pills-others' type='button' role='tab' aria-controls='pills-others' aria-selected='false' onClick={()=>filterOthersItem(true)}>For Others</button>
                            </li>
                        </ul>
                        <div className='tab-content' id='pills-tabContent' style={{minHeight: 'auto'}}>
                            <div className='tab-pane fade show active' id='pills-all' role='tabpanel' aria-labelledby='pills-all-tab'></div>
                            <div className='tab-pane fade ' id='pills-myself' role='tabpanel' aria-labelledby='pills-myself-tab'></div>
                            <div className='tab-pane fade ' id='pills-others' role='tabpanel' aria-labelledby='pills-others-tab'></div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='pay_method_list'>
                                    <div className='table-responsive mh-100'>
                                        <table className='table ccards_table table-borderless'>
                                            <thead>
                                                {sortedDescGiftCardServices.length>0 && !isLoading?
                                                    <tr>
                                                        <th>Gift Card details</th>
                                                        <th>Card Info</th>
                                                        <th>Date</th>
                                                        <th></th>
                                                    </tr>:<></>}
                                            </thead>
                                            <tbody>
                                                {isLoading && contentLoader()}
                                                {sortedDescGiftCardServices.length>0&&sortedDescGiftCardServices.map((cardItem, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td><div className="gift_card_name" >{currencyFormat(cardItem.dblBalance) ? currencyFormat(cardItem.dblBalance) : currencyFormat(0)} - Gift Card</div></td>
                                                            <td><div className="noc">Ending in {cardItem.sGiftCard_Number.substr(- 4)}</div></td>
                                                            <td><div className="exp_date">{dateFormatter(convertUTCDateToLocalDate(cardItem.dtCreated))}</div></td>
                                                            <td>
                                                                <button className="btn btn-outline-secondary" onClick={() => {
                                                                    dispatch(setIsForMySelf({isForMySelf: true}));
                                                                    viewCard(cardItem);
                                                                }}>Manage Card</button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) }
                                            </tbody>
                                        </table>
                                        {sortedDescGiftCardServices.length === 0 && isLoading === false ? <p className='notFound alert alert-info'>{filterType=='All' ? 'Gift Cards not available.':'' || filterType=='For Myself' ? 'Gift Cards not available.':'' || filterType=='For Others' ? 'Gift Cards not available.':''}</p> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default GiftCard;
