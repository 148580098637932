/* eslint-disable no-unused-vars */
import {useDispatch, useSelector} from 'react-redux';
import InputMask from 'react-input-mask';
import React from 'react';
import {config} from '../../../utils/config/config';
import {saveCreditCard} from '../../../services/order-service';
import {seqLogger} from '@services/seq-logger-service';
import {seqReqBodyObj} from '@utils/common-helper';
import {setCreditCards} from '@store/reducers/manage-account-reducer';
import {toast} from 'react-toastify';
import {useState} from 'react';
// Reducers
import {addNewAddedCard} from '@store/reducers/manage-account-reducer';

const DataCapPaymentCard = (props) => {
    const {cardInfo, setCardInfo} = props;
    toast.configure();
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [zipCode, setZipCode] = useState('');
    // const [card, setCard] = useState('');
    const [isZipCodeValid, setIsZipCodeValid] = useState(false);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const preferedHomeLocation = useSelector((state) => state.auth.userInfo.preferedHomeLocation);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const handleChangeBillingZipCode = async (e) => {
        const billingZipCode = document.getElementById('billingZipCodeDataCap');
        const zipCodeRegex = /^[0-9]+$/;
        setZipCode(e.target.value);
        if (e.target.value === '' || !zipCodeRegex.test(e.target.value) || e.target.value.length < 5 || e.target.value.length >= 6) {
            billingZipCode.style.border = '0.5px solid #FF0000';
            setIsZipCodeValid(false);
        } else {
            billingZipCode.removeAttribute('style');
            setIsZipCodeValid(true);
        }
    };

    const handleChangeCard = async (e) => {
        const cardNum = document.querySelector('[data-token="card_number"]');
        setCardInfo(e.target.value);
        const validCard = DatacapWebToken.validateCardNumber(cardNum.value.replace(/-/g, ''));
        if (!validCard) {
            cardNum.style.border = '0.5px solid #FF0000';
        } else {
            cardNum.removeAttribute('style');
        }
    };

    const handleChangeMobileNo = (e) => {
        const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        const txtMobileNo= document.getElementById('txtMobileNoDataCap');
        if (!regex.test(e.target.value)) {
            txtMobileNo.style.border = '0.5px solid #FF0000';
        } else {
            txtMobileNo.removeAttribute('style');
        }
    };


    const tokenCallback = async function(response) {
        const res = response;
        if (response.Error) {
            const error = response.Error;
            document.getElementById('pay_button').disabled = false;
        } else {
            const token = JSON.stringify(res, null, 2);
            document.getElementById('pay_button').disabled = false;
            // Submit payment form
            const expDate = `${res.ExpirationMonth}${res.ExpirationYear}`;
            if (props.noLogged == undefined) {
                const postModel = {
                    temporary_token: res.Token,
                    unixTimeStamp: Date.now(),
                    CustomerIdentityId: userInfo.customerIdentityID,
                    CustomerGuid: userInfo.customerGuid,
                    PreferHomeLocationId: preferedHomeLocation == null ? localStorage.getItem('locationId'):preferedHomeLocation,
                    sBillPostalCode: zipCode,
                    xExp: expDate,
                    sBin: res.Bin,
                    Span: res.Last4
                };
                props.loader(true);
                if (postModel.PreferHomeLocationId == null || postModel.PreferHomeLocationId == '') {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.info('Please set your preferred location first, then add your credit card.');
                    }
                    return false;
                }
                const response = await saveCreditCard(userInfo.accessToken, postModel);
                props.loader(false);
                if (response.status === 200) {
                    props.restDataCapForm();
                    if (props.setCard != undefined && props.setCard.length != undefined) {
                        props.setCard(response.data.item1);
                    }
                    dispatch(addNewAddedCard({cC: response.data.item1}));
                    dispatch(setCreditCards({creditCards: []}));
                    toast.success(response.data.item2, {autoClose: 3000});
                    const phoneNumber = customerDetail.customer.sPhone;
                    const seqReqBody = seqReqBodyObj('Information', `Card Added Successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                    document.getElementById('btnCCPopCanceldataCap').click();
                } else if (response.status === 400) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(response.data);
                        const phoneNumber = customerDetail.customer.sPhone;
                        if (response.data == 'This card has already been added') {
                            const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${phoneNumber}`);
                            await seqLogger(seqReqBody);
                        } else {
                            const seqReqBody = seqReqBodyObj('Error', `${response.data}`, `${phoneNumber}`);
                            await seqLogger(seqReqBody);
                        }
                    }
                    return false;
                } else {
                    toast.error('Error Processing Payment, Please Try Again.', {autoClose: 3000});
                }
            } else {
                const txtMobileNo= document.getElementById('txtMobileNoDataCap');
                const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
                if (!regex.test(txtMobileNo.value)) {
                    txtMobileNo.style.border = '0.5px solid #FF0000';
                    toast.error('Invalid mobile no.');
                    props.loader(false);
                    return false;
                }
                const postModel = {
                    temporary_token: res.Token,
                    unixTimeStamp: Date.now(),
                    xExp: expDate,
                    phoneNumber: String(txtMobileNo.value).replace('(', '').replace(')', '').replace(' ', '').replace(' ', ''),
                    sBin: res.Bin,
                    Span: res.Last4
                };
                props.checkout(postModel);
            }
        }
    };

    // Function to call for tokenization
    const tokenize = function(formName) {
        const card = document.querySelector('[data-token="card_number"]');
        const expMonthInput = document.querySelector('[data-token="exp_month"]').value;
        const expYearInput = document.querySelector('[data-token="exp_year"]').value;
        const cvv = document.querySelector('[data-token="cvv"]').value;
        // validation
        const validCard = DatacapWebToken.validateCardNumber(card.value.replace(/-/g, ''));
        const validExpirationDate = DatacapWebToken.validateExpirationDate(expMonthInput, expYearInput);
        const validCVV = DatacapWebToken.validateCVV(cvv);

        if (!validCard) {
            card.style.border = '0.5px solid #FF0000';
            toast.error('Invalid card number.');
            return false;
        } else if (!validCVV) {
            toast.error('Invalid CVV.');
            return false;
        } else if (!validExpirationDate) {
            toast.error('Invalid expiration date.');
            return false;
        } else if (!isZipCodeValid) {
            toast.error('Invalid ZIP code.');
            return false;
        }
        // if card validated submit it
        DatacapWebToken.requestToken(config.datacapRequestToken, formName, tokenCallback);
    };

    return (
        <div>
            <form id="payment_form">
                {/* <h5 className='mb-3'>{(props.noLogged == undefined) ? 'Add Credit Card' : 'Enter card details'}</h5> */}
                <h5 className='mb-3'>Add Credit Card</h5>
                <div className="row">
                    { (props.noLogged != undefined) ? <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="phone"><small>Phone<sup className="text-danger">*</sup></small></label>
                            <InputMask
                                id="txtMobileNoDataCap"
                                className="form-control form-control-lg"
                                onChange={(e) => handleChangeMobileNo(e)}
                                mask="(999) 999-9999"
                                maskChar=" "
                                onKeyPress={(e) => handleChangeMobileNo(e)}
                                name="phone"
                                placeholder="(000) 000-0000"
                            />
                        </div>
                    </div> : '' }
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor=""><small>Card Holder Name</small></label>
                            <div>
                                <input type="text" className="form-control form-control-lg" id='cardHolderName' placeholder="Name" required="" autoFocus="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor=""><small>Card Number <sup className='text-danger'> *</sup></small></label>
                            <div>
                                <input type="tel" className="form-control form-control-lg" value={cardInfo} data-token="card_number"
                                    placeholder="Valid Card Number" required="" onChange={(e)=> handleChangeCard(e)} autoFocus="" />
                                <input type="hidden" id="payment_token" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Billing Zip Code<sup className='text-danger'> *</sup></small></label>
                            <input placeholder='Billing Zip Code' className="form-control form-control-lg" onChange={(e) => handleChangeBillingZipCode(e)} id="billingZipCodeDataCap" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className='mb-2'>
                            <label><small>Exp Month<sup className='text-danger'> *</sup></small></label>
                            <div>
                                <input type="tel" className="form-control form-control-lg" data-token="exp_month" placeholder="MM"
                                    maxLength="2" required="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className='mb-2'>
                            <label><small>Exp Year<sup className='text-danger'> *</sup></small></label>
                            <div>
                                <input type="tel" className="form-control form-control-lg" data-token="exp_year" onChange={(e)=>console.log('expYear', e.target.value)} placeholder="YYYY"
                                    maxLength="4" required="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>CVV<sup className='text-danger'> *</sup></small></label>
                            <div>
                                <input type="tel" className="form-control form-control-lg" data-token="cvv" placeholder="CVV"
                                    maxLength="4" required="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {/* <button className='btn btn-primary mt-3' id="pay_button" type="button" onClick={() => tokenize('payment_form')}>Save Card</button> */}
                    {props.noLogged == undefined?
                        <button className='btn btn-primary w-100 mt-3' id="pay_button" type="button" onClick={() => tokenize('payment_form')}>Save Card</button>:
                        <button className='invisible' id="pay_button" type="button" onClick={() => tokenize('payment_form')}></button>
                    }
                </div>
            </form>
        </div>
    );
};

export default DataCapPaymentCard;
