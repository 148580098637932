/* eslint-disable camelcase */
import React from 'react';

import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import QRCode from 'react-qr-code';
import cancelIcon from '@assets/images/icons/cancel_icon.svg';

const GenerateBarcode = (props) => {
    const {encryptedCode, Loader} = props;
    const customerInfo = JSON.parse(localStorage.getItem('customerDetail'));

    return (
        <>
            {Loader === true ? <FullScreenLoader /> : ''}
            <div className={`modal fade cw_modal add_card_pop ${props.showBarcodePopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <div className='barcode_header up_barcode pb-3 mt-3 mb-4 mb-lg-5'>
                            <div className='text-center title_walletBar'>
                                <h5>{customerInfo?.vipAccountType?.sDescription}</h5>
                            </div>
                            <button onClick={() => {
                                props.setBarcodePopup(false);
                            }}><img src={cancelIcon}/></button>
                        </div>
                        <div className='text-center'>
                            <div className='barcode_msg pt-3 pt-3  text-center'>
                                <p>This is a temporary barcode</p>
                            </div>
                            <div className='barcode_img mx-auto text-center mt-4'>
                                <div className={customerInfo?.customer.sBarcode ? ' ':'opacity-25'}>
                                    <QRCode value={encryptedCode || ''} size={200} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.showBarcodePopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
        </>
    );
};

export default GenerateBarcode;
