import React from 'react';
// Components
import Layout from '@components/Layout';
// Assets
import logo from '@assets/images/car_wash.gif';

const LoginLoader = () => {
    return (
        <Layout>
            <div className="">
                <img src={logo} className='loginLoader' alt="loading..." />
                <h5 className='text-center'>"Did you know 8 million cars are washed every day in the USA!"</h5>
            </div>
        </Layout>
    );
};

export default LoginLoader;
