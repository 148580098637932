import React from 'react';
import parse from 'html-react-parser';

export default function TermsModal(props) {
    const policyDetail = localStorage.getItem('policyData') == ' '? null: JSON.parse(localStorage.getItem('policyData'));
    const closeModal = () =>{
        props.closeModal();
    };

    return <>
        <div className="modal fade show terms_modal" tabIndex="-1" style={{display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                    <div className="modal-body p-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="policy_wrap py-2">
                                    {policyDetail != null && policyDetail.length > 0 && policyDetail.filter((value) => value.contentId === props.id || value.contentId === props.id1).map((detail, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <h5>{parse(detail.title ?? '')}</h5><div>{parse(detail.contentDescription ?? '')}</div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-secondary mx-auto" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
    </>;
}
