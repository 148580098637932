import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {toast} from 'react-toastify';
// Components
import Alert from '@components/modals/AlertModal';
import AlertModalModule from '@components/modals/AlertModalCancelMembership';
import Layout from '@components/Layout';
import LinkExistingModal from '@components/modals/LinkExistingModal';
import PaymentCard from '@components/order/PCI/PaymentCard';
// Services
import {cancelAllow, pauseAndActiveMemberShipFromHold} from '@services/cancel-membership-service';
import {creditCardsList} from '@services/manage-account-service';
import {seqLogger} from '@services/seq-logger-service';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {dateFormatterWithoutTime} from '@utils/date-formatter';
import {getPCIDetailsLocal} from '@utils/common-repo';
// Reducers
import {setCreditCards} from '@store/reducers/manage-account-reducer';
import {setPreviousPage} from '@store/reducers/auth-reducer';
// Assets
import './ManageAccount.style.css';
import AlertModalModalUnlimitedInfo from '../../components/modals/AlertModalModalUnlimitedInfo';
import CardknoxPaymentCard from '../../components/order/PCI/CardknoxPaymentCard';
import GenerateBarcode from '../../components/GenerateBarcode';
import PaymentOptions from './PaymentOptions';
import PersonalInfo from './PersonalInfo';
import RecentOrderHistory from './RecentOrderHistory';
import cancelIcon from '@assets/images/icons/cancel_icon.svg';
import {encrypt} from '../../services/user-services';
import {getUtcDateTime} from '../../utils/date-formatter';

const ManageAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    toast.configure();
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [loading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [alertModalData, setAlertModalData]= useState({});
    const [alertData, setAlertData]= useState({});
    const [declineModal, setDeclineModal] = useState(false);
    const [declineModalData, setDeclineModalData] = useState({});
    const [Loader, setLoader] = useState(false);
    const [reload, setReload] = useState(false);
    const [showExistingPlanPopup, setAddExistingPlan] = useState(false);
    const [showBarcodePopup, setBarcodePopup] = useState(false);
    const [generateBarcodePopup, setGenerateBarcodePopup] = useState(false);
    const [viewCardForm, setViewCardForm] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(JSON.parse(localStorage.getItem('customerDetail')));
    const PCIScript = getPCIDetailsLocal().PCIScript;

    const location = localStorage.getItem('locations')!=null?JSON.parse(localStorage.getItem('locations')).find((x)=>x.sCode == customerInfo?.customerDetail?.sMonthlyLocation):{bAllowGenerateEncryptedBarcode: null};
    const bShowPause = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalSettingsList.find((value) => value.name === 'AllowPauseMembership');
    const cancelMembership = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalSettingsList.find((value) => value.name === 'AllowCancelMembership');
    const getCreditCardsList = async () => {
        if (creditCards.length === 0) {
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            const seqReqBody = seqReqBodyObj('Information', `Card list retrieve successfully`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
            if (response.status === 200) {
                dispatch(setCreditCards({creditCards: response.data}));
                localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
            } else {
                dispatch(setCreditCards({creditCards: []}));
            }
        }
    };
    const onClickPauseAndActiveMember = async () => {
        setLoader(true);
        const postData = {};
        let bMonthlyHoldVal = 'Resume';
        postData.customerGuid = localStorage.getItem('customerGuid');
        if (customerInfo.customerDetail.dtMonthlyPaused===null) {
            bMonthlyHoldVal='Pause';
        }
        postData.action=bMonthlyHoldVal;
        const response = await pauseAndActiveMemberShipFromHold(userInfo.accessToken, postData);
        if (response.status == 200) {
            setAlertModal(false);
            setReload(!reload);
            const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response.status == 400) {
            setLoader(false);
            toast.error(response.data);
        } else {
            setLoader(false);
            toast.error('Something went wrong.');
        }
    };
    const isCancelAllow = async () =>{
        setLoader(true);
        const response = await cancelAllow(userInfo.accessToken);
        if (response?.status === 200) {
            setLoader(false);
            navigate('/cancel-membership');
            const seqReqBody = seqReqBodyObj('Information', `cancel membership allowed`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response?.status === 404) {
            setLoader(false);
            toast.warning(response?.data, {autoClose: 10000, toastId: 'cancel_not_allowed'});
            const seqReqBody = seqReqBodyObj('Warning', `${response?.data}`, `${customerInfo.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            setLoader(false);
            toast.error('Internal server error.', {autoClose: 3000, toastId: 'cancel_fail'});
        }
    };

    const showConfirmationCancelMemberShip = () => {
        setAlertModalData({
            modalClass: 'alert_pop cancelMembership',
            title: 'Sad to see you go',
            message: 'Do you really want to cancel your membership?',
            btnSuccessText: 'Keep Plan',
            btnCancelText: 'Cancel Subscription',
            btnPrimary: true
        });
        setModal(true);
    };

    useEffect(()=>{
        // if (localStorage.getItem('customerGuid') == null) {
        //     localStorage.setItem('customerGuid', userInfo.customerGuid);
        // }
        getCreditCardsList();
        dispatch(setPreviousPage({previousPage: ''}));
    }, [reload, creditCards.length == 0]);

    const [encryptedCode, setEncryptedCode] = useState('');
    const [loader, showLoder] = useState(false);

    const encryptString = async () => {
        const createdon = getUtcDateTime();
        const barcode = customerInfo?.customer.sBarcode;
        showLoder(true);
        const response = await encrypt(userInfo.accessToken, JSON.stringify({createdon: createdon, barcode: barcode}));
        if (response.status == 200) {
            setEncryptedCode(`$ENC${response.data}`);
            setGenerateBarcodePopup(true);
            showLoder(false);
        }
    };
    const onClickShowBarcode = () => {
        try {
            const locationsData = JSON.parse(localStorage.getItem('locations'));
            if (!locationsData || !Array.isArray(locationsData)) {
                throw new Error('Invalid or missing locations data in localStorage');
            }
            const location = locationsData.find((x) => x.sCode === customerInfo?.customerDetail?.sMonthlyLocation);
            if (!location || !location.bAllowGenerateEncryptedBarcode) {
                setBarcodePopup(true);
                return;
            }
            encryptString();
        } catch (error) {
            console.error('Error:', error);
            setBarcodePopup(true);
        }
    };
    const ShowUlPlan = () => {
        const cSharpNullDateInSQL = new Date(null);
        const currentDate = new Date();
        const monthlyNextPay = new Date(customerInfo?.customerDetail?.dtMonthlyNextPay);
        const DifferenceInTime = monthlyNextPay.getTime() - currentDate.getTime();
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

        if (customerInfo?.customerDetail != null && customerInfo?.customerDetail.bMonthlyActive != null && customerInfo?.customerDetail.bMonthlyActive) {
            return (
                <div className="pi_um_detailcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Unlimited Membership</h2>
                            <small>Current Membership</small>
                        </div>
                        <div className="pi_um_cardcenter">
                            <div className="pi_um_plan_info">
                                <h4>{customerInfo?.vipAccountType?.sDescription}</h4>
                                <strong>{currencyFormat(customerInfo.vipAccountType?.dblAmount ?? '')}</strong>
                            </div>
                            {customerInfo.vipAccountType.bUnlimitedRecurring?
                                <div className="plan_link">
                                    {customerInfo?.customerDetail.dtMonthlyNextPay != null && customerInfo?.customerDetail.dtMonthlyNextPay != cSharpNullDateInSQL?
                                        <span className='next_paydate'>Next Pay: <b>{dateFormatterWithoutTime(customerInfo?.customerDetail.dtMonthlyNextPay)}</b></span>:''}
                                    <span className='plan_status active'>Active</span>
                                </div>:
                                <div className="plan_link">
                                    {customerInfo?.customerDetail.dtMonthlyExpires != null && customerInfo?.customerDetail.dtMonthlyExpires != cSharpNullDateInSQL?
                                        <span className='next_paydate'>Expires on: <b>{dateFormatterWithoutTime(customerInfo?.customerDetail.dtMonthlyExpires)}</b></span>:''}
                                    <span className='plan_status active'>Active</span>
                                </div>
                            }
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li><a href="#" role="button" className="link-primary" onClick={()=>onClickShowBarcode()}>{location?.bAllowGenerateEncryptedBarcode?'Generate Barcode' : 'View Barcode'}</a></li>
                        {customerInfo?.vipAccountType.bUnlimitedRecurring && bShowPause?.value?.toUpperCase() == 'TRUE' ?
                            <>
                                <li>|</li>
                                <li><Link to = "#" className="link-primary" onClick = {()=> {
                                    setAlertModal(true);
                                    setAlertData(
                                        {
                                            modalClass: 'alert_pop next',
                                            title: 'Confirmation',
                                            message: 'Do you want to pause membership?',
                                            btnSuccessText: 'Yes',
                                            btnCancelText: 'No',
                                            btnPrimary: true
                                        }
                                    );
                                }}>Pause Membership</Link></li>
                            </> :
                            ''}
                        {cancelMembership?.value?.toUpperCase() == 'FALSE' ? '' :
                            <>
                                <li>|</li>
                                <li><Link to="#" className="link-primary" onClick={() => showConfirmationCancelMemberShip()}>Cancel Membership</Link></li>
                            </>
                        }
                        <li>|</li>
                        <li><Link to='/unlimited_plans' onClick={()=>{
                            localStorage.setItem('isReactivate', 'false');
                            localStorage.setItem('isNewUnlimited', 'false');
                        }} className="link-primary">Change Plan</Link></li>
                    </ul>
                </div>
            );
        } else if (customerInfo?.customerDetail != null && customerInfo?.customerDetail.bMonthlyActive != null && customerInfo?.customerDetail.bMonthlyActive == false && customerInfo?.customerDetail.dtMonthlyPaused != null) {
            return (
                <div className="pi_um_detailcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Unlimited Membership</h2>
                            <small>Current Membership</small>
                        </div>
                        <div className="pi_um_cardcenter">
                            <div className="pi_um_plan_info">
                                <h4>{customerInfo?.vipAccountType?.sDescription}</h4>
                                <strong>{currencyFormat(customerInfo?.vipAccountType?.dblAmount ?? '')}</strong>
                            </div>
                            <div className="">
                                <span className='plan_status inactive'>Inactive</span>
                            </div>
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li><a href="#" role="button" className="link-primary" onClick={()=>onClickShowBarcode()}>{location?.bAllowGenerateEncryptedBarcode?'Generate Barcode' : 'View Barcode'}</a></li>
                        {bShowPause?.value?.toUpperCase() == 'TRUE' ?
                            <>
                                <li>|</li>
                                <li><Link to = "#" className="link-primary" onClick = {()=> {
                                    setAlertModal(true);
                                    setAlertData(
                                        {
                                            modalClass: 'alert_pop next',
                                            title: 'Confirmation',
                                            message: 'Do you want to resume membership?',
                                            btnSuccessText: 'Yes',
                                            btnCancelText: 'No',
                                            btnPrimary: true
                                        }
                                    );
                                }}>Resume Membership</Link></li>
                            </> :
                            ''}
                    </ul>
                </div>
            );
        } else if (customerInfo?.customerDetail != null && customerInfo?.customerDetail.bMonthlyDeclined != null && customerInfo?.customerDetail.bMonthlyDeclined == true) {
            return (
                <div className="pi_um_detailcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Unlimited Membership</h2>
                        </div>
                        <div className="pi_um_cardcenter">
                            <div className="pi_um_plan_info">
                                <span className='text-danger'>Please update your account details at the nearest location,your credit card is declined.</span>
                            </div>
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li><Link to='#' onClick={()=>{
                            setDeclineModal(true);
                            setDeclineModalData({
                                modalClass: 'alert_pop',
                                title: 'Account info Expires Soon!',
                                message: 'Please update your account details at the nearest location.',
                                btnSuccessText: 'Yes',
                                btnCancelText: 'No',
                                btnPrimary: true
                            });
                        }} className="link-primary">View Information</Link></li>
                    </ul>
                </div>
            );
        } else if (customerInfo?.customerDetail != null && customerInfo?.customerDetail.dtMonthlyNextPay !=null && customerInfo?.customerDetail.dtMonthlyNextPay !=cSharpNullDateInSQL) {
            if (customerInfo.location.iInactiveDaysForReactivation < DifferenceInDays) {
                return (
                    <div className="pi_um_detailcard">
                        <div>
                            <div className="pi_um_cardtop">
                                <h2 className="main_heading">Unlimited Membership</h2>
                            </div>
                            <div className="pi_um_cardcenter">
                                <div className="pi_um_plan_info">
                                    <h4>{customerInfo.vipAccountType?.sDescription}</h4>
                                    <strong>{currencyFormat(customerInfo.vipAccountType?.dblAmount ?? '')}</strong>
                                </div>
                                <div className="">
                                    <span className='plan_status inactive'>Inactive</span>
                                </div>
                            </div>
                        </div>
                        <ul className="pi_um_cardbottom">
                            <li><Link to='/unlimited_plans' onClick={()=>{
                                localStorage.setItem('isReactivate', 'true');
                                localStorage.setItem('isNewUnlimited', 'false');
                            }} className="link-primary">Reactivate Membership</Link></li>
                        </ul>
                    </div>
                );
            } else {
                return (
                    <div className="pi_um_detailcard">
                        <div>
                            <div className="pi_um_cardtop">
                                <h2 className="main_heading">Unlimited Membership</h2>
                            </div>
                            <div className="pi_um_cardcenter">
                                <div className="pi_um_plan_info">
                                    <span>You don't have an unlimited membership</span>
                                </div>
                            </div>
                        </div>
                        <ul className="pi_um_cardbottom">
                            <li><Link to='/unlimited_plans' onClick={()=>{
                                localStorage.setItem('isReactivate', 'false');
                                localStorage.setItem('isNewUnlimited', 'true');
                            }} className="link-primary">+ Add New Plan</Link></li>
                            <li>|</li>
                            <li><Link to="#" className="link-primary" onClick={()=>setAddExistingPlan(true)}>Link Existing Plan</Link></li>
                        </ul>
                    </div>
                );
            }
        } else {
            return (
                <div className="pi_um_detailcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Unlimited Membership</h2>
                        </div>
                        <div className="pi_um_cardcenter">
                            <div className="pi_um_plan_info">
                                <span>You don't have an unlimited membership</span>
                            </div>
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li><Link to='/unlimited_plans' onClick={()=>{
                            localStorage.setItem('isReactivate', 'false');
                            localStorage.setItem('isNewUnlimited', 'true');
                        }} className="link-primary">+ Add New Plan</Link></li>
                        <li>|</li>
                        <li><Link to="#" className="link-primary" onClick={()=>setAddExistingPlan(true)}>Link Existing Plan</Link></li>
                    </ul>
                </div>
            );
        }
    };

    useEffect(()=>{
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);
        getCreditCardsList();
    }, []);

    const expandPCIForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setViewCardForm(true);
    };
    return (
        <div>
            {!loading &&
                    <Layout>
                        {Loader === true ? <FullScreenLoader /> : ''}
                        {modal ? <AlertModalModule data={alertModalData} targetEvent={() => isCancelAllow()} closeModal={() => setModal(false)} /> : '' }
                        {declineModal?<AlertModalModalUnlimitedInfo data={declineModalData} targetEvent={()=>setDeclineModal(false)}/>:''}
                        {alertModal ? <Alert data={alertData} targetEvent={() => onClickPauseAndActiveMember()} closeModal={() => setAlertModal(false)} /> : '' }
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                                </div>
                                <div className='col-12'>
                                    <div className='main_title mb-4 mt-3'>
                                        <h2>Hi, {userInfo.firstname} {userInfo.lastname}</h2>
                                        <p>Welcome to your profile page, here you can manage your personal info, membership and payment cards.</p>
                                    </div>
                                </div>
                            </div>
                            <PersonalInfo reload={reload} Loader={Loader} setLoader={setLoader} customerInfo={customerInfo} setCustomerInfo={setCustomerInfo} loading={loading}/>
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <ShowUlPlan/>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <PaymentOptions expandPCIForm={expandPCIForm}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mt-3">
                                    <RecentOrderHistory/>
                                </div>
                            </div>
                        </div>
                        {/* view barcode  popup */}
                        <div className={`modal fade cw_modal add_card_pop ${showBarcodePopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content p-4'>
                                    <div className='barcode_header up_barcode pb-3 mt-3 mb-4 mb-lg-5'>
                                        <div className='text-center title_walletBar'>
                                            <h5>{customerInfo?.vipAccountType?.sDescription}</h5>
                                        </div>
                                        <button onClick={() => {
                                            setBarcodePopup(false);
                                        }}><img src={cancelIcon}/></button>
                                    </div>
                                    <div className='text-center'>
                                        <div className='barcode_img mx-auto text-center'>
                                            <div className={customerInfo?.customer.sBarcode ? ' ':'opacity-25'}>
                                                <Barcode value={customerInfo?.customer.sBarcode || ''} width={2} height={60} />
                                            </div>
                                        </div>
                                        <div className='barcode_msg pt-3 pt-3 border-top text-center'>
                                            <p>Make sure to show this code to the attendant or,<br/> scan the bar code in the machine.</p>
                                        </div>
                                        <div className='barcode_img mx-auto text-center mt-4'>
                                            <div className={customerInfo?.customer.sBarcode ? ' ':'opacity-25'}>
                                                <QRCode value={customerInfo?.customer.sBarcode || ''} size={200} />
                                            </div>
                                        </div>
                                        <div className='barcode_number text-center p-4'>
                                            <small>Your Redeem Code</small>
                                            <h3 className='mt-2'>{customerInfo?.customer.sBarcode}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GenerateBarcode showBarcodePopup={generateBarcodePopup} setBarcodePopup={setGenerateBarcodePopup} encryptedCode={encryptedCode} Loader={loader}/>
                        {/* barcode fade */}
                        {showBarcodePopup ?
                            <div className="modal-backdrop fade show"></div>:
                            ''
                        }
                        <div className='modal fade cw_modal add_card_pop' id='addCardpop2' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content p-4'>
                                    <button type="button" className="btn-close" id="btnCCPopCancel" data-bs-dismiss="modal" onClick={() => {
                                        setViewCardForm(false);
                                    }}></button>
                                    <div className='modal-body'>
                                        {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} hidePop={() => setViewCardForm(false)} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal fade cw_modal add_card_pop' id='addCardpop3' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content p-4'>
                                    <button type="button" className="btn-close" id="btnCCPopCancelCardKnox" data-bs-dismiss="modal" onClick={() => {
                                        // setViewCardForm(false);
                                        console.log('cardknox');
                                    }}></button>
                                    <div className='modal-body'>
                                        <CardknoxPaymentCard loader={(val) => setLoader(val)} />
                                        {/* {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} hidePop={() => setViewCardForm(false)} /> : ''} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LinkExistingModal showExistingPlanPopup = {showExistingPlanPopup} setAddExistingPlan = {setAddExistingPlan}/>
                    </Layout>
            }
        </div>
    );
};

export default ManageAccount;
