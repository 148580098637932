import API from '../api';

export const unlimitedWashServices = async (customerCode) => {
    return await API.get('QuickBuy/GetUnlimitedPlans', {headers: {
        'X-Customer-Code': customerCode,
        'locationId': '',
        'CallingApplication': 'CPV3'
    }}).catch((error) => {
        console.clear();
        return error;
    });
};
export async function buyUnlimitedPlanQuickBuy(customerCode, locationId, reqBody) {
    try {
        return await API.post(`QuickBuy/QuickBuyCustomerUnlimitedPlan`, reqBody, {
            headers: {
                'X-Customer-Code': customerCode,
                'X-Location-Id': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        console.clear;
        return error.response;
    }
};
