import {Navigate} from 'react-router-dom';
import React from 'react';
import {isLogin} from './login';

const PrivateRoute = ({children}) => {
    const auth = isLogin(); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? children : <Navigate to="/" />;
};

export default PrivateRoute;
