import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import React from 'react';

const EmployeeThanks = (props) =>{
    const barcodeString = props.barcode;
    const barcodeStr = barcodeString !== undefined ? barcodeString : 'DemoBarcode';
    return (
        <React.Fragment>
            <div className='form_sec animate_next'>
                <div className='barcode_wrap'>
                    <div className='code_area mb-5'>
                        <div className='box'>
                            <div id='barcodeTarget' className='barcodeTarget content-center-h' style={{marginTop: '25%', padding: '0px'}}>
                                <Barcode value={barcodeStr} />
                            </div>
                        </div>
                        <div className='qr_box'>
                            <QRCode value={barcodeStr}/>
                        </div>
                        <div className='plate_number' style={{width: '100%', maxWidth: '332px', textAlign: 'center'}} >
                            <input type='text' name='licenseplate' className='licenseplate no-boxshadow' style={{border: 'none'}} disabled='disabled' value={barcodeString} />
                        </div>
                    </div>
                    <div className='thanks_msg'>
                        <button type='button' className='main_btn' id='prevS2' onClick={props.handleThanksCallBack}>Return</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeThanks;
