import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
// Components
import AccountUnlimitedPlan from '@components/unlimited-plans/AccountUnlimitedPlan';
import Layout from '@components/Layout';
import LinkExistingModal from '@components/modals/LinkExistingModal';
import OrderSummary from '@components/order/OrderSummary';
// Services
import {getCustomerInfoByGuid} from '@services/user-services';
// CSS
import './UnlimitedPlans.style.css';

const UnlimitedPlans = () => {
    toast.configure();
    const [isUWCCheckoutShow, setIsUWCCheckoutShow] = useState(false);
    const [customerData, setCustData] = useState(JSON.parse(localStorage.getItem('customerDetail')));
    const [orderJson, setOrderJson] = useState({});
    const [showExistingPlanPopup, setAddExistingPlan] = useState(false);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const previousPage = useSelector((state) => state.auth.previousPage);
    const [selectedVehiclePlateInfo, setSelectedVehiclePlateInfo] = useState({VehicleID: 0, Plate: ''});
    const otherFormData = {
        isForMySelf: true,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    };

    const showUWCCheckoutView = (order) => {
        setOrderJson(order);
        setIsUWCCheckoutShow(true);
    };

    const onClickBack = () =>{
        console.log('');
    };

    useEffect(()=>{
        if (localStorage.getItem('customerGuid') == null) {
            localStorage.setItem('customerGuid', userInfo.customerGuid);
        }
        getCustomerInfo();
    }, []);

    const getCustomerInfo = async () => {
        const response = await getCustomerInfoByGuid(userInfo.accessToken, localStorage.getItem('customerGuid'), userInfo.customerIdentityID);
        if (response.status == 200) {
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
            setCustData(response.data);
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
        }
    };

    return (
        <Layout>
            {isUWCCheckoutShow ?
                <OrderSummary
                    setCustData={setCustData}
                    hideCheckoutPage={(isCheckout) => {
                        if (isCheckout) {
                            getCustomerInfo();
                        }
                        setIsUWCCheckoutShow(false);
                    }}
                    order={orderJson}
                    onClickBack={onClickBack}
                    otherFormData = {otherFormData}
                    selectedVehiclePlateInfo={selectedVehiclePlateInfo}
                    setSelectedVehiclePlateInfo={setSelectedVehiclePlateInfo} /> :
                <div className='container'>
                    <div className="row">
                        {previousPage !== '/dashboard' ?
                            <div className='col-12 mb-3'>
                                <Link to='/manage_account' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                            </div> :
                            <div className='col-12 mb-3'>
                                <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                            </div>
                        }
                    </div>
                    <div className='main_title pup_title'>
                        {customerData?.customerDetail !=null && customerData?.customerDetail.bMonthlyActive != null && customerData?.customerDetail.bMonthlyActive ?
                            <h2>Change Unlimited Plan</h2> :
                            <>
                                <h2>Purchase Unlimited Plan</h2>
                                <Link to='#' onClick={() => setAddExistingPlan(true)} className='link-primary'>Link Existing Plan</Link>
                            </>
                        }
                    </div>
                    <div className='purchasechckbox'>
                        {/* {( customerData != null && customerData?.customerDetail != undefined) ? */}
                        <AccountUnlimitedPlan
                            goToUWCCheckout={(order) => showUWCCheckoutView(order)}
                            custData={customerData}
                            setPreferredCard={(sPayerIdentifier) => setPreferredCard(sPayerIdentifier)}
                            selectedVehiclePlateInfo={selectedVehiclePlateInfo}
                            setSelectedVehiclePlateInfo={setSelectedVehiclePlateInfo}
                        />
                        {/* } */}
                    </div>
                </div>
            }
            <LinkExistingModal showExistingPlanPopup={showExistingPlanPopup} setAddExistingPlan={setAddExistingPlan} />
        </Layout>
    );
};

export default UnlimitedPlans;
