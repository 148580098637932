import {createSlice} from '@reduxjs/toolkit';

const giftCardSlice = createSlice({
    name: 'giftCard',
    initialState: {
        giftCards: [],
        selectedGiftCard: {},
        selectedGiftCardForViewReceipt: {},
        giftCardAmounts: [],
        giftCardTabs: {
            isForMySelf: true,
            forOther: {
                FirstName: '',
                LastName: '',
                RecipientEmail: '',
                Message: ''
            }
        }
    },
    reducers: {
        setGiftCards: (state, action) => {
            state.giftCards = action.payload.giftCards;
        },
        setSelectedGiftCard: (state, action) => {
            state.selectedGiftCard = action.payload.selectedGiftCard;
        },
        setSelectedGiftCardForViewReceipt: (state, action) => {
            state.selectedGiftCardForViewReceipt = action.payload.selectedGiftCardForViewReceipt;
        },
        setGiftCardAmounts: (state, action) => {
            state.giftCardAmounts = action.payload.giftCardAmounts;
        },
        setGiftCardTab: (state, action) => {
            state.giftCardTabs = action.payload.giftCardTabs;
        },
        setIsForMySelf: (state, action) => {
            state.giftCardTabs.isForMySelf = action.payload.isForMySelf;
        },
        setformOtherData: (state, action) => {
            state.giftCardTabs.forOther= action.payload.forOther;
        }
    }
});

export const {setGiftCards, setSelectedGiftCard, setSelectedGiftCardForViewReceipt, setGiftCardAmounts, setGiftCardTab, setIsForMySelf, setformOtherData} = giftCardSlice.actions;
export default giftCardSlice.reducer;
