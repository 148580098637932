import {FiArrowLeft, FiDownload} from 'react-icons/fi';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import Layout from '../Layout';
// Services
import {orderHistory} from '@services/wallet-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setOrderHistory} from '../../store/reducers/wallet-reducer';
import {setSelectedWallet} from '@store/reducers/wallet-reducer';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import SkeletonOrderHistory from '@utils/skeletons/SkeletonOrderHistory';
import WalletBarcode from '../common/WalletBarcode';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import dateFormatter from '@utils/date-formatter';
import exportAsImage from '@utils/export-as-image';

const OrderHistory = (props) => {
    const dispatch = useDispatch();
    const exportRef = useRef();
    const userInfo = useSelector((state) => state.auth.userInfo);
    let services = useSelector((state) => state.wallet.orderHistory);
    const previousPage = useSelector((state) => state.auth.previousPage);
    const [isLoading, setIsLoading] = useState(services.length ? false : true);
    const [pageServices, setPageServices] = useState([]);
    const [defaultListLength, setDefaultListLength] = useState(10);
    const [isDownload, setIsDownload] = useState(false);
    const phoneNumber = localStorage.getItem('phoneNumber');

    const handleSelectedWallet = (wallet) => {
        const upDatedWallet = {...wallet, pageBackUrl: '/order-history'};
        dispatch(setSelectedWallet({selectedWallet: upDatedWallet}));
    };

    const filterServices = (services) => {
        if (services.length) {
            const filteredServices = [];
            for (let index = 0; index < services.length; index++) {
                const service = services[index];
                if (service.amount !== 0) {
                    filteredServices.push(service);
                }
            }
            return filteredServices;
        }
    };

    useEffect(() => {
        const getServices = async () => {
            if (services.length>0) {
                setPageServices(filterServices(services));
                services = filterServices(services);
            }
            if (services.length === 0) {
                setIsLoading(true);
                const response = await orderHistory(userInfo.accessToken, userInfo.customerIdentityID);
                if (response.status === 200) {
                    setTimeout(() => {
                        setIsLoading(false);
                        dispatch(setOrderHistory({orderHistory: response.data}));
                        const filteredServices = filterServices(response.data);
                        setPageServices(filteredServices);
                        services = filteredServices;
                    }, 1000);
                    const seqReqBody = seqReqBodyObj('Information', `Order history retrieved successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                } else {
                    setIsLoading(false);
                    toast.error('Something went wrong.', {autoClose: 3000});
                }
            }
        };
        getServices();
    }, []);

    const pageServicesArr = pageServices?.map((obj) => {
        return {...obj, walletDate: new Date(obj.walletDate)};
    });

    const sortedDescPageServicesArr = pageServicesArr?.sort(
        (objA, objB) => new Date(objB.walletDate) - new Date(objA.walletDate)
    );

    return (
        <Layout>
            <div className="container">
                <div className="row">
                    {previousPage === '/manage_account'?
                        <div className="col-12 mb-3">
                            <Link to='/manage_account' className="btn btn-outline-secondary"><FiArrowLeft /> Back</Link>
                        </div>:
                        <div className="col-12 mb-3">
                            <Link to='/dashboard' className="btn btn-outline-secondary"><FiArrowLeft /> Back</Link>
                        </div>
                    }
                    <div className="col-12">
                        <div className="main_title mb-4 mt-3">
                            <h2>Order History</h2>
                            <p>Manage your all orders and purchases</p>
                        </div>
                    </div>
                </div>
                <div className="row odr_history_table border-0">
                    <div className='col-12'>
                        <div className='odr_history_table_table_resp pb-2'>
                            {isLoading ?
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Plans/Cards</th>
                                            <th scope="col">Purchase Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Order Number</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading && <SkeletonOrderHistory count={5}></SkeletonOrderHistory>}
                                    </tbody>
                                </table>:''
                            }
                            {!isLoading && sortedDescPageServicesArr?.length > 0 &&
                                <table className="table">
                                    <thead>
                                        <tr className='border-bottom'>
                                            <th scope="col">Plans/Cards</th>
                                            <th scope="col">Purchase Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Order Number</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading && sortedDescPageServicesArr?.length > 0 && sortedDescPageServicesArr.map((service, index) => {
                                            if (index < defaultListLength) {
                                                return (
                                                    <tr key={service.barcode}>
                                                        <td><strong>{service.description}</strong></td>
                                                        <td>{dateFormatter(convertUTCDateToLocalDate(service.walletDate))}</td>
                                                        <td>{currencyFormat(service?.totalAmount)}</td>
                                                        <td>{service.orderNumber}</td>
                                                        <td>
                                                            <button className="btn btn-outline-secondary" onClick={() => {
                                                                handleSelectedWallet(service);
                                                                setTimeout(() => {
                                                                    setIsDownload(true);
                                                                    exportAsImage(exportRef.current, service.barcode);
                                                                    setIsDownload(false);
                                                                }, 100);
                                                            }}><FiDownload/> Download</button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>}
                            {!isLoading && sortedDescPageServicesArr?.length == 0 &&
                            <p className='notFound alert alert-info'>Order history not available.</p>
                            }
                        </div>
                        {sortedDescPageServicesArr?.length > 0 && sortedDescPageServicesArr?.length > defaultListLength ?
                            <div className='text-center mt-5'>
                                <button className='btn btn-primary' onClick={() => setDefaultListLength(defaultListLength + 10)} disabled={sortedDescPageServicesArr?.length < defaultListLength}>Load More</button>
                            </div> : ''
                        }
                        {isDownload ?
                            <div ref={exportRef}>
                                <WalletBarcode />
                            </div> : ''
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default OrderHistory;
