import API from '../api';

const employeeAccessToken = localStorage.getItem('employeeAccessToken');
export const getUnlimitedTypesForLocation = async () => {
    return await API.get('VIPAccountType/GetUnlimitedTypesForLocation', {headers: {
        'locationId': '',
        'X-Authentication-Token': employeeAccessToken,
        'X-Customer-Code': localStorage.getItem('customerCode'),
        'CallingApplication': 'CPV3'

    }}).catch((error) => {
        return error;
    });
};

export const getSurchargeById = async (locationId, surchargeId) => {
    return await API.get('Surcharges/GetSurchargeById', {headers: {
        'locationId': locationId,
        'surchargeId': surchargeId,
        'X-Authentication-Token': employeeAccessToken,
        'CallingApplication': 'CPV3'

    }}).catch((error) => {
        return error;
    });
};

export const buyUnlimitedPlanByEmployee = async (locationId, reqBody) => {
    return await API.post(`QuickBuy/QuickBuyUnlimitedPlanByEmployee`, reqBody, {
        headers: {
            'X-Authentication-Token': employeeAccessToken,
            'X-Customer-Code': localStorage.getItem('customerCode'),
            'CallingApplication': 'CPV3'
        }}).catch((error) => {
        return error;
    });
};


