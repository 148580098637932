import {createSlice} from '@reduxjs/toolkit';

const locationSlice = createSlice({
    name: 'location',
    initialState: {
        services: [],
        buyLocations: [],
        selectedLocation: {}
    },
    reducers: {
        setServices: (state, action) => {
            state.services = action.payload.services;
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload.selectedLocation;
        },
        setBuyLocation: (state, action) => {
            state.buyLocations = action.payload.buyLocations;
        }
    }
});
export const {setServices, setSelectedLocation, setBuyLocation} = locationSlice.actions;
export default locationSlice.reducer;
