import React, {Component} from 'react';
import {FiArrowRight} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {convertHexToHSL} from '../../utils/common-helper';
// Store
import store from '@store/index';
// Components
import LayoutBuy from '@components/LayoutBuy';
// Services
import {getCarWashInfo, getCarWashPaymentType} from '../../services/auth-service';
import {getActiveQuickBuyServices} from '@services/buy-module-services/buy-service';
import {getLocationsForQuickBuy} from '@services/location-service';
import {getPortalThemeColor} from '@services/auth-service';
// Reducers
import {setBuyLocation} from '@store/reducers/location-reducer';
import {setBuyServices} from '@store/reducers/global-reducer';
import {setIsForMySelf} from '@store/reducers/auth-reducer';
import {setSelectedPlan} from '@store/reducers/order-reducer';
import {setServices} from '@store/reducers/prepaid-wash-reducer';

// Utils
import {fetchPCIDetailsFromServer, getPCIDetailsLocal} from '@utils/common-repo';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
// CSS
import './../Dashboard/Dashboard.style.css';

class DashboardBuy extends Component {
    constructor(props) {
        super(props);
        fetchPCIDetailsFromServer();
        this.script1 = document.createElement('script');
        this.script2 = document.createElement('script');
        this.state = {
            customerCode: localStorage.getItem('customerCode'),
            services: store.getState().global.buyServices,
            isLoading: false,
            PCIConfig: (localStorage.getItem('pciDetails') != null && localStorage.getItem('pciDetails') != undefined && localStorage.getItem('pciDetails') !='null')?{isPaymentLive: JSON.parse(localStorage.getItem('pciDetails'))?.isPaymentLive}:{isPaymentLive: true},
            isNearByLocationOpen: false,
            locationData: store.getState().auth.locations,
            buyLocationData: store.getState().location.buyLocations,
            portalCustomizationsList: JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList
        };

        if (this.state.customerCode == null || this.state.customerCode == 'null' || this.state.customerCode == undefined) {
            return window.location.href = '/';
        } else {
            localStorage.setItem('allowAnnomous', true);
        }

        this.props.setSelectedPlan();
        this.props.setServicesNull();
    };

    componentDidMount() {
        const paymentMode = getPCIDetailsLocal();
        if (paymentMode.isPaymentLive == false) {
            this.script1.src = 'https://token-cert.dcap.com/v1/client';
            this.script1.async = true;
            document.body.appendChild(this.script1);
        } else {
            this.script2.src = 'https://token.dcap.com/v1/client';
            this.script2.async = true;
            document.body.appendChild(this.script2);
        }

        const carWashInfo = async () => {
            const response = await getCarWashInfo(this.state.customerCode);
            if (response.status == 200 && response.data != '' && response.data != null) {
                localStorage.setItem('title', response.data);
                document.title = response.data;
            } else {
                document.title = 'Car Wash';
                localStorage.setItem('title', 'Car Wash');
            }
        };
        carWashInfo();
        this.carWashPaymentType(this.state.customerCode);
        const getPciData = async () =>{
            await fetchPCIDetailsFromServer();
            const pciDetail = getPCIDetailsLocal();
            this.setState({PCIConfig: pciDetail});
        };
        getPciData();
        const faviconIcon = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_logo_url');
        const favicon = document.getElementById('favicon');
        favicon.href = faviconIcon.sValue;
        if (localStorage.getItem('buyLocationId') === null || localStorage.getItem('buyLocationId') === undefined) {
            this.setState({isNearByLocationOpen: true});
        }
        if (this.state.buyLocationData.length === 0) {
            const getLocations = async () => {
                const response = await getLocationsForQuickBuy(this.state.customerCode);
                if (response.status === 200) {
                    this.setState({buyLocationData: response.data});
                    setTimeout(() => {
                        this.props.setBuyLocationData(response.data);
                    }, 1000);
                }
            };
            getLocations();
        }
        if (this.state.services.length === 0) {
            const getServies = async () => {
                this.setState({isLoading: true});
                const response = await getActiveQuickBuyServices(this.state.customerCode);
                if (response.status === 200) {
                    setTimeout(() => {
                        this.setState({
                            services: response.data.sort((a, b) => a.iOrderNumber > b.iOrderNumber ? 1 : -1),
                            isLoading: false
                        });
                        this.props.setBuyServices(response.data);
                    }, 1000);
                }
            };
            getServies();
        }
        // Dynamic theme color.
        const getPortalThemeColorResponse = getPortalThemeColor(this.state.customerCode);
        if (this.state.portalCustomizationsList !== null && this.state.portalCustomizationsList.length) {
            for (let index = 0; index < this.state.portalCustomizationsList.length; index++) {
                const obj = this.state.portalCustomizationsList[index];
                if (obj.sName === 'site_cpv3_theme_color' && obj.sValue !== null && obj.sValue !== undefined && obj.sValue !== '') {
                    document.documentElement.style.setProperty('--_hue', convertHexToHSL(obj.sValue));
                    break;
                }
            }
        }
        if (getPortalThemeColorResponse.status === 200) {
            const colorValue = getPortalThemeColorResponse.data.sValue;
            if (colorValue !== null && colorValue !== undefined && colorValue !== '') {
                document.documentElement.style.setProperty('--_hue', convertHexToHSL(colorValue));
            }
        }
    }
    componentWillUnmount() {
        const paymentMode = getPCIDetailsLocal();
        if (paymentMode.isPaymentLive == false) {
            document.body.removeChild(this.script1);
        } else {
            document.body.removeChild(this.script2);
        }
    }
    // carWashPaymentType = async (customerCode) => {
    //     localStorage.setItem('isCardknox', false);
    //     const response = await getCarWashPaymentType(customerCode);
    //     if (response.status == 200) {
    //         if (response.data == 2) {
    //             localStorage.setItem('isCardknox', true);
    //         } else {
    //             localStorage.setItem('isCardknox', false);
    //         }
    //     } else {
    //         localStorage.setItem('isCardknox', false);
    //     }
    // };

    carWashPaymentType = async (customerCode) => {
        localStorage.setItem('gateWayType', 'openEdge');
        const response = await getCarWashPaymentType(customerCode);
        if (response.status == 200) {
            if (response.data == 2) {
                localStorage.setItem('gateWayType', 'cardknox');
            } else if (response.data == 3) {
                localStorage.setItem('gateWayType', 'dataCap');
            }
        } else {
            localStorage.setItem('gateWayType', 'openEdge');
        }
    };

    setNearByLocationByLocation = async (location) => {
        localStorage.setItem('buyLocationId', location.locationId);
        this.setState({isNearByLocationOpen: false});
    };

    render() {
        const {services, isLoading, isNearByLocationOpen, buyLocationData} = this.state;
        const customizedData = JSON.parse(localStorage.getItem('customizePage'));
        const siteHeader = customizedData.portalCustomizationsList.find((d) => d.sName == 'site_home_box_header');
        const siteFooter = customizedData.portalCustomizationsList.find((d) => d.sName == 'site_home_box_footer');

        return (
            ( this.state.customerCode == null || this.state.customerCode == 'null' || this.state.customerCode == undefined) ? '' :
                <LayoutBuy>
                    {isLoading === true ? <FullScreenLoader /> : ''}
                    <div className='container'>
                        <div className='row'>
                            {!this.state.PCIConfig.isPaymentLive && <div className='col-12'>
                                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' className='bi bi-exclamation-triangle-fill flex-shrink-0 me-2' viewBox='0 0 16 16' role='img' aria-label='Warning:'>
                                        <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
                                    </svg>
                                    <div>DEMO SITE ONLY, PAYMENTS ARE NOT LIVE</div>
                                </div>
                            </div>}
                            <div className='col-12'>
                                <div className='main_title mb-4 mt-3'>
                                    {siteHeader != undefined ? <h2>{siteHeader.sValue}</h2> : <h2>Hi ,Welcome to Car Wash !</h2>}
                                    <p className='sitefooter'>{siteFooter.sValue}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='services_list acc_box'>
                                    <div className='row'>
                                        {services.length > 0 ? services.map((service) => {
                                            if (service.type === 1 && service.isActive === true) {
                                                return (
                                                    <div className='col-md-6 col-sm-6 col-12' key={service.id}>
                                                        <Link to={`/${service.bSellOnQuickBuySection && 'buy/'}${service.targetId}`} onClick={()=> this.props.setIsForMySelf()}>
                                                            <div className="myacc_cm_detail">
                                                                <div className="">
                                                                    <h5>{service.title}</h5>
                                                                    <span>{service.description}</span>
                                                                </div>
                                                                <div className="card_arrow">
                                                                    <a href="#"><FiArrowRight /></a>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            }
                                        }) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade cw_modal add_info_pop ${isNearByLocationOpen ? 'show d-block' : ''}`} id='locationInfo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='locationInfoLabel' aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered '>
                            <div className='modal-content locationinfo p-4'>
                                <div className='modal-header'>
                                    <h3 className='modal-title' id='locationInfoLabel'>Locations</h3>
                                </div>
                                <div className='modal-body'>
                                    {buyLocationData !== undefined && buyLocationData !== null && buyLocationData.map((service) => {
                                        return (
                                            <div className='select_loc_pop' key={service.locationId}>
                                                <div className='service_box'>
                                                    <div className='ser_info'>
                                                        <h3>{service.sName}</h3>
                                                        <span>{service.sAddress}</span>
                                                    </div>
                                                    <button type='button' className='btn btn-primary' style={{float: 'right'}} onClick={() => this.setNearByLocationByLocation(service)}>Set Location</button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isNearByLocationOpen ? <div className="modal-backdrop fade show"></div> : ''}
                </LayoutBuy>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIsForMySelf: () => dispatch(setIsForMySelf({isForMySelf: true})),
        setBuyServices: (data) => dispatch(setBuyServices({buyServices: data})),
        setSelectedPlan: () => dispatch(setSelectedPlan({selectedPlan: {}})),
        setServicesNull: () => dispatch(setServices({services: {}})),
        setBuyLocationData: (data) => dispatch(setBuyLocation({buyLocations: data}))
    };
};

export default connect(null, mapDispatchToProps)(DashboardBuy);
