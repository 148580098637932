import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InitializePCI as intializePCIGatway} from './employee-global-payment';
import {toast} from 'react-toastify';

const EmployeePaymentCard = (props) => { // for logged in users
    toast.configure();
    const toastId = React.useRef(null);
    if (props.noLogged == undefined) {
        const userInfo = useSelector((state) => state.auth.userInfo);
        const preferedHomeLocation = useSelector((state) => state.auth.userInfo.preferedHomeLocation);
        const dispatch = useDispatch();
        useEffect(() => {
            intializePCIGatway(userInfo, preferedHomeLocation, dispatch, props, toast, toastId);
        }, []);
    } else { // for annomous
        useEffect(() => {
            intializePCIGatway(null, null, null, props, toast, toastId);
        }, []);
    }

    return (
        <div>
            <form id="payment-form" >
                <div className="row">
                    <div className='col-12 mb-3'>
                        <label htmlFor="cardOwner"><small>Card Holder Name<sup className='text-danger'>*</sup></small></label>
                        <input type='text' className='form-control form-control-lg' id='cardOwner' placeholder />
                    </div>
                    <div className='col-12 mb-3'>
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Credit Card Number<sup className='text-danger'>*</sup></small></label>
                            <div id="credit-card-card-number"></div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Expiration Date<sup className='text-danger'>*</sup></small></label>
                            <div id="credit-card-card-expiration"></div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>CVV<sup className='text-danger'>*</sup></small></label>
                            <div id="credit-card-card-cvv"></div>
                        </div>
                    </div>
                    { (props.noLogged == undefined) ? <div className="col-sm-12">
                        <div id="credit-card-submit"></div>
                    </div> : '' }
                </div>
            </form>
        </div>
    );
};

export default EmployeePaymentCard;
