import API from './api';
const localStorageCustomerCode = localStorage.getItem('customerCode');
const headers = {
    'Content-Type': 'application/json',
    'X-Customer-Code': localStorageCustomerCode,
    'CallingApplication': 'CPV3',
    'X-Location-Id': ''
};

export const loginApi = async (phoneNumber) => {
    return await API.get(`CustomerIdentities/LoginByPhoneCPV3/?phone=${phoneNumber}`, {headers: {
        'Content-Type': 'application/json',
        'X-Customer-Code': localStorageCustomerCode,
        'CallingApplication': 'CPV3',
        'X-Location-Id': ''
    }}).catch((error) => {
        return error;
    });
};

export const verifyOTPApi = async (phoneNumber, otp) => {
    return await API.get(`CustomerIdentities/LoginByPhone/ValidateTokenWeb/?phone=${phoneNumber}&otp=${otp}`, {headers}).catch((error) => {
        return error.response;
    });
};
// Barcode Signup Model API's
export const sendOTP = async (phoneNumber) => {
    return await API.get(`CustomerIdentities/SendOTPCPV3/?PhoneNumber=${phoneNumber}`, {headers}).catch((error) => {
        return error.response;
    });
};

export const validateOTP = async (phoneNumber, otp) => {
    return await API.get(`CustomerIdentities/ValidateOTPCpv3/?phone=${phoneNumber}&otp=${otp}`, {headers}).catch((error) => {
        return error.response;
    });
};
export const submitbarcodesignup = async (data) => {
    console.log(data);
    return await API.post(`Login/CheckCustomerIdentityRecord`, data, {headers}).catch((error) => {
        return error.response;
    });
};
// Barcode Signup Model API's
export const signupApi = async (data) => {
    return await API.post('CustomerIdentities', data, {headers: {
        'Content-Type': 'application/json',
        'X-Customer-Code': localStorageCustomerCode,
        'CallingApplication': 'CPV3',
        'X-Location-Id': ''
    }}).catch((error) => {
        return error;
    });
};
export const updateRegisterApi = async (data) => {
    return await API.post('CustomerIdentities/UpdateRegisterCPV3', data, {headers}).catch((error) => {
        return error;
    });
};
export const loginByPhoneV2Api = async (phoneNumber) => {
    return await API.get(`CustomerIdentities/LoginByPhoneCPV3?phone=${phoneNumber}&isRegister=true`, {headers}).catch((error) => {
        return error.response;
    });
};

export const customizationApi = async (customerCode) => {
    return await API.get('PortalHome/GetPortalCustomizations', {
        headers: {
            'X-Customer-Code': customerCode,
            'X-Location-Id': '',
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const getPortalThemeColor = async (customerCode) => {
    return await API.get('PortalHome/GetPortalThemeColor', {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const getCarWashInfo = async (customerCode) => {
    return await API.get('PortalHome/GetCarwashInfo', {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const getCarWashPaymentType = async (customerCode) => {
    return await API.get('PortalHome/GetCarwashPaymentType', {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const barcodeApi = async (reqBody) => {
    return await API.get(`Login/CustomerPortalLogin/LoginWithBarcodeCpv3/${reqBody.Barcode}/${reqBody.CCNumber}`, {
        headers: {
            'X-Customer-Code': localStorageCustomerCode,
            'CallingApplication': 'CPV3'
        }}).catch((error) => {
        return error;
    });
};
export const customerUrlApi = async (customerUrl) => {
    return await API.get(`Portal/GetCustomerCode/?CustomerPortalUrl=${customerUrl}`, {
        headers: {
            'CustomerPortalUrl': customerUrl,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const policyData = async (customerCode) => {
    return await API.get('PortalHome/GetPortalAboutUs', {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const checkCustomerDuplicateRecord = async (phone, customerCode, locationId) => {
    return await API.get(`CustomerIdentities/LoginByPhone/CheckCustomerDuplicateRecord/?phone=${phone}`, {
        headers: {
            'X-Customer-Code': customerCode,
            'locationId': locationId,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const updateCustomerGuid = async (customerGuid, id, customerCode) => {
    return await API.get(`CustomerIdentities/LoginByPhone/UpdateCustomerGuid/?Id=${id}&CustomerGuid=${customerGuid}`, {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const getuserinfobyguid = async (customerGuid, customerCode) => {
    return await API.get(`CustomerIdentities/LoginByPhone/GetUserInfobyGuidweb/?CustomerGuid=${customerGuid}`, {
        headers: {
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};
export const checkoutJson = () => {
    return {
        TopHeaderText: '',
        ListItemText: '',
        ListItemDescription: '',
        IsCardSelected: false,
        IsNewCard: false,
        SelectedCard: {},
        IsCheckedTermAndCondition: false,
        IsShowPromocodeDiv: false,
        IsPromocodeApplied: false,
        IsRecieveSMSReciept: false,
        IsRecieveEmailReciept: false,
        IsSavePaymentInfoForFuturePurchase: false,
        IsUWCRequest: false,
        IsUpgradeULPlan: false, // UL for Unlimited plans
        termsId: 0,
        ULPlanData: {
            CurrentPlan: '',
            CurrentRecurringPayment: 0,
            NewSelectedPlan: '',
            NewRecuringPayment: ''
        },
        Promocode: '',
        TaxAmount: 0.0,
        DiscountAmount: 0.0,
        SubTotal: 0.0,
        GrandTotal: 0.0,
        Email: '',
        AdditionalPageData: null
    };
};
