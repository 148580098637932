import {FiArrowLeft, FiArrowRight} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import Checkout from '../checkout/Checkout';
import CheckoutSuccess from '../checkout/CheckoutSuccess';
import GiftCard from '../../gift-card/GiftCard';
import LayoutBuy from '../../LayoutBuy';
// Services
import {buyGiftCard} from '@services/buy-module-services/gift-card-service';
import {giftCardList} from '@services/buy-module-services/gift-card-service';
// Reducers
import {setBarcodeString} from '@store/reducers/global-reducer';
import {setGiftCardAmounts} from '@store/reducers/gift-card-reducer';
import {setPurchaseDate} from '@store/reducers/global-reducer';
// Utils
import {getNoLoginCheckoutJSON, getPCIDetailsLocal, quickBuyGiftCardModel} from '@utils/common-repo';
import commonTableLoader from '@utils/common-table-loader';
import {currencyFormatWithoutSymbol} from '@utils/common-helper';
import {overwriteSiteProductColors} from '@utils/config/site-theme-config';
// Assets
import GCLogo from '@assets/images/logo_white.png';

const BuyGiftCard = (props) => {
    toast.configure();
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    const giftCardAmounts = useSelector((state) => state.giftCard.giftCardAmounts);
    const [cardAmount, setCardAmount] = useState('');
    const [selectedGCAmount, setSelectedGCAmount] = useState({});
    const [viewCheckoutSuccess, setViewCheckoutSuccess] = useState(false);
    const orderJson= useState(getNoLoginCheckoutJSON());
    const [isCheckoutShow, setIsCheckoutShow] = useState(false);
    const [defaultGCInputData, setDefaultGCInputData]= useState({});
    const [isGiftCardAmountLoading, setISGiftCardAmountLoading] = useState(false);
    const [isProcessCheckout, setIsProcessCheckout] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const customerCode = localStorage.getItem('customerCode');
    const buylocationId = localStorage.getItem('buyLocationId');
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');


    const onSelectGCAmount = async (value) => {
        setCardAmount('');
        const jsonData = quickBuyGiftCardModel(true, value.taxIncluded, value.price, value.productTypeId, value.description, value.taxRate);
        setSelectedGCAmount(jsonData);
    };

    const onCardAmountInputFocus = (e, defaultInputData) => {
        if (selectedGCAmount.IsSelected != undefined && selectedGCAmount.IsSelected) {
            setCardAmount('');
            const jsonData = quickBuyGiftCardModel(false, defaultInputData.taxIncluded, '', defaultInputData.productTypeId, 'Ohter', defaultGCInputData.taxRate);
            setSelectedGCAmount(jsonData);
        };
    };

    const onCardAmountInputChange = (e, defaultInputData) => {
        currencyMask(e);
        const fields = e.target.value.split(',');
        const giftAmount = fields[0].concat(fields[1] ?fields[1]:'');
        if (Number(giftAmount) >= 10000.01) {
            toast.error('Amount cannot be more than $10,000.00', {toastId: 'invalidAmt'});
            return false;
        }
        if (giftAmount.length >= 0 && giftAmount.length < 9) {
            const jsonData = quickBuyGiftCardModel(false, defaultInputData.taxIncluded, giftAmount, defaultInputData.productTypeId, 'Other', defaultGCInputData.taxRate);
            setSelectedGCAmount(jsonData);
            setCardAmount(e.target.value);
        }
    };

    const continueToAddGCCheckout = async () => {
        orderJson[0].TermsId = 4;
        if (selectedGCAmount.ProductTypeId == undefined) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select gift card first.');
            }
            return false;
        }
        if (selectedGCAmount.Description == 'Other' && selectedGCAmount.Amount < 1) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please enter gift card amount. Amount should be greater than 0.');
            }
            return false;
        }
        orderJson[0].TopHeaderText = 'Buy a Gift Card';
        orderJson[0].ListItemText = 'Gift Card';
        orderJson[0].ListItemDescription = selectedGCAmount.Description;
        orderJson[0].TaxAmount = 0;
        if (selectedGCAmount.IsTaxable && selectedGCAmount.TaxAmount != 0) {
            orderJson[0].TaxAmount = Math.round((double)(selectedGCAmount.Amount / (1 + (selectedGCAmount.TaxAmount / 100))));
        }
        orderJson[0].SubTotal = selectedGCAmount.Amount;
        orderJson[0].GrandTotal = (parseFloat(orderJson[0].SubTotal) + parseFloat(orderJson[0].TaxAmount)).toFixed(2);
        orderJson[0].IsShowRecieveEmailReciept = false;
        orderJson[0].IsShowRecieveSMSReciept = false;
        setIsCheckoutShow(true);
    };

    const giftCardCheckout = async (order, ccDetails) => {
        if (order.IsCheckedTermAndCondition) {
            const postModel = {};
            postModel.productTypeId= selectedGCAmount.ProductTypeId;
            postModel.phoneNumber= ccDetails.phoneNumber;
            postModel.temporary_token = ccDetails.temporary_token;
            postModel.unixTime = ccDetails.unixTimeStamp;
            postModel.Span = ccDetails.Span;
            postModel.Bin = ccDetails.sBin;
            postModel.amount = selectedGCAmount.Description == 'Other' ? selectedGCAmount.Amount : 0;
            postModel.CreditCardNumber = '';
            postModel.CardHolderName = '';
            postModel.ExpiryDate = ccDetails.xExp;
            postModel.Alias = '';
            postModel.LocationId = buylocationId;
            if (gateWayType == 'cardknox') {
                postModel.expiryDate = ccDetails.xExp;
                postModel.xExp = ccDetails.xExp;
            } else {
                postModel.expiryDate = '';
            }
            setPhoneNumber(postModel.phoneNumber);
            setIsProcessCheckout(true);
            const response = await buyGiftCard(customerCode, '', postModel);
            setIsProcessCheckout(false);
            if (response.status === 200) {
                dispatch(setBarcodeString({barcodeString: response.data[0].sGiftCard_Number}));
                dispatch(setPurchaseDate({purchaseDateString: response.data[0].dtCreated}));
                setViewCheckoutSuccess(true);
                setIsCheckoutShow(false);
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
                return false;
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card before checkout.');
            }
            return false;
        }
    };

    const PCIScript = getPCIDetailsLocal().PCIScript;

    useEffect(() => {
        const getGiftCardAmountList = async () => {
            setISGiftCardAmountLoading(true);
            const response = await giftCardList(customerCode);
            setISGiftCardAmountLoading(false);
            if (response.status === 200) {
                overwriteSiteProductColors(response.data.products[0].stylings);
                dispatch(setGiftCardAmounts({giftCardAmounts: response.data.products}));
                setDefaultGCInputData(response.data.products.find((d) => d.price == 9999));
            } else {
                dispatch(setGiftCardAmounts({giftCardAmounts: []}));
                toast.error('Something went wrong.', {autoClose: 3000});
            }
        };

        if (giftCardAmounts == null || giftCardAmounts.length == 0) {
            getGiftCardAmountList();
        }

        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const currencyMask = (e) =>{
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e;
    };

    return (
        <LayoutBuy>
            {viewCheckoutSuccess ? <CheckoutSuccess Annomous={true} order={orderJson} phoneNumber = {phoneNumber}/> : isCheckoutShow ? <Checkout processCheckout={(o, cc) => giftCardCheckout(o, cc)} hideCheckoutPage={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                setIsCheckoutShow(false);
            }} order={orderJson} /> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                                <div className='inner_title d-flex align-items-start'>
                                    <div className='progress-circle over50 p50'>
                                        <span>1 of 2</span>
                                        <div className='left-half-clipper'>
                                            <div className='first50-bar'></div>
                                            <div className='value-bar'></div>
                                        </div>
                                    </div>
                                    <div className='main_title'>
                                        <h2>Add Gift Card</h2>
                                        <p>Select an amount</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tab-content' id='pills-tabContent'>
                                <div className={`tab-pane fade show active`} id='pills-myself' role='tabpanel' aria-labelledby='pills-myself-tab'></div>
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='add_amount_wrap'>
                                            <h5>Select an Amount</h5>
                                            <div className='add_amount gift_card_amount'>
                                                {isGiftCardAmountLoading ?
                                                    <>
                                                        <div className='amount_btn_grp'>
                                                            {commonTableLoader(5, 'skeletonAddGiftCardAmounts')}
                                                        </div>
                                                    </> :
                                                    <>
                                                        {
                                                            giftCardAmounts == null && giftCardAmounts.length == 0 ? <h3>No gift card found.</h3> :
                                                                defaultGCInputData != null ? <div className='other_amount_input input-group mb-3'>
                                                                    <span className='input-group-text'>$</span>
                                                                    <input pattern="[0-9]*" value={cardAmount} onClick={(event) => onCardAmountInputFocus(event, defaultGCInputData)} onChange={(event) => onCardAmountInputChange(event, defaultGCInputData)} maxLength='9' min="0" max="10000" type='text' className='form-control form-control-lg' aria-label='Amount (to the nearest dollar)' placeholder='Type amount here' />
                                                                </div> : ''
                                                        }
                                                        <div className='amount_btn_grp gift_card_btn'>
                                                            {giftCardAmounts.map((value, i) => {
                                                                return defaultGCInputData != null && defaultGCInputData.productTypeId == value.productTypeId ? '' : <button className={`${selectedGCAmount.ProductTypeId == value.productTypeId ? 'active' : ''}`} type='button' key={i} onClick={() => onSelectGCAmount(value)}>Gift Card<br /><span>{currencyFormatWithoutSymbol(value.price)}</span></button>;
                                                            })}
                                                        </div>
                                                    </> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='order_summ_wrap'>
                                            <h5>Gift Card Preview</h5>
                                            <GiftCard amount={selectedGCAmount.Amount} brandLogo={GCLogo}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-end mt-3 pt-3 border-top'>
                                <div className='btn_grps d-flex flex-wrap'>
                                    <Link to='/buy' className='btn btn-secondary me-2'><FiArrowLeft /> Back</Link>
                                    <button onClick={() => continueToAddGCCheckout()} className='btn btn-primary' {...(isProcessCheckout ? {disabled: 'disabled'} : {})} >{isProcessCheckout && <span className="spinner-border text-light btnLoader"></span>}Continue <FiArrowRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </LayoutBuy>
    );
};

export default BuyGiftCard;
