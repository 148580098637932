import '@assets/css/skeleton.css';
import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonGiftNWashCard = (props) => {
    let index = 0;
    const data = [];
    const makeRows = () => {
        do {
            data.push(
                <div className='col-md-6 col-sm-12 col-12 skeletonGiftNWashCard' id={index} key={index}>
                    <div className={`wallet_card mb-2 wallet_item status_badge`}>
                        <div className="wallet_card_type"><span>{props.cardType}</span></div>
                        <h3><SkeletonElement type="text" /></h3>
                        <div className="wallet_card_dates">
                            <div className="info_date">
                                <small className='d-block mb-2'><SkeletonElement type="text" /></small>
                                <h6 className='text-dark mb-0'><SkeletonElement type="text" /></h6>
                            </div>
                        </div>
                    </div>
                </div>
            );
            index += 1;
        } while (index < props.count);
        return data;
    };

    return makeRows();
};

export default SkeletonGiftNWashCard;
