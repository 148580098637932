import * as Yup from 'yup';
// const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const nameRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export default Yup.object().shape({
    firstName: Yup.string().required('First Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'First Name must have maximum 25 characters.'),
    lastName: Yup.string().required('Last Name is required.')
        .test('valid', 'Special characters are not allowed.', (val= '')=> {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'Last Name must have maximum 25 characters.')
    // phoneNumber: Yup.
    //     string()
    //     .test('valid', 'Cell Phone  is not valid.', (val = '') => {
    //         const checkLength = val.replace(/\(|\)/g, '').length;
    //         if (checkLength === 0) {
    //             return true;
    //         }
    //         if (phoneRegExp.test(val)) {
    //             return true;
    //         }
    //         return false;
    //     })
    //     .test('empty', 'Cell Phone  is required.', (val = '') => {
    //         const checkLength = val.replace(/\(|\)/g, '').length;
    //         return checkLength > 0;
    //     })
    //     .min(10, 'Cell Phone  must be 10 digits.')
    //     .max(14, 'Cell Phone  must be 10 digits.')

});
