import {FiArrowLeft, FiArrowRight, FiEdit2, FiX} from 'react-icons/fi';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import AlertModalModule from '../modals/AlertModal';
import PaymentCard from './PCI/PaymentCard';
import TermsModal from '../modals/TermsModal';
// Services
import {addNewUWCPlan, upgradeUWCPlan} from '@services/unlimited-plan-service';
import {getCustomerInfoByGuid, putCustomerInfoByGuid} from '@services/user-services';
import {creditCardsList} from '@services/manage-account-service';
import {getPromotionAvailable} from '@services/order-service';
import {seqLogger} from '@services/seq-logger-service';

import {getuserinfobyguid} from '@services/auth-service';

// Reducers
import {setOrderHistory, setwalletServices} from '@store/reducers/wallet-reducer';
import {setCreditCards} from '@store/reducers/manage-account-reducer';
import {setGiftCards} from '@store/reducers/gift-card-reducer';
import {setIsLoading} from '@store/reducers/global-reducer';
import {setOrdersReceipt} from '@store/reducers/order-reducer';
import {setUserInfo} from '@store/reducers/auth-reducer';
// Utils
import {currencyFormat, phoneNumberFormat, seqReqBodyObj} from '@utils/common-helper';
import {GetCreditCardIcon as getCCIcon, getPCIDetailsLocal} from '@utils/common-repo';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import InputMask from 'react-input-mask';
import dateFormatter from '@utils/date-formatter';
import {initFormik} from '@utils/formik-helper';
// CSS
import './Order.css';

import CardknoxPaymentCard from './PCI/CardknoxPaymentCard';
import DataCapPaymentCard from './PCI/DataCapPaymentCard';
import loginSchema from '@validation-schemas/login-schema';
import {useFormik} from 'formik';
import zipCodeSchema from '../../validation-schemas/zip-code-schema';

const OrderSummary = (props) => {
    const [order, setOrder]=useState(props.order[0]);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [showAddon, setShowAddon] = useState(false);
    const [sendSms, setSendSms] = useState(true);
    const [addonTotal, setAddonTotal] = useState(0);
    const [isZipModalOpen, setIsZipModal] = useState(false);
    const [sendBillPostalCode, setSendBillPostalCode] = useState(false);
    const [addOnServiceTax, setAddOnServiceTax] = useState(false);
    toast.configure();
    const toastId = React.useRef(null);
    const [alertModalData, setAlertModalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [viewCardForm, setViewCardForm] = useState(false);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [showPhonePopup, setShowPhonePopup] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [IsRecieveEmailReciept, setIsRecieveEmailReciept]=useState(false);

    const [customerInfo, setCustomerInfo] = useState(JSON.parse(localStorage.getItem('customerDetail')));
    const newCustomerInfo = JSON.parse(localStorage.getItem('customerDetail'));
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const customerCode = localStorage.getItem('customerCode');
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const selectedPlan = useSelector((state) => state.order.selectedPlan);
    const {otherFormData} = props;
    const dispatch = useDispatch();
    const isReactivate = localStorage.getItem('isReactivate');
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');
    const setOrderCheckout = () => {
        setModal(false);
    };
    const orderCheckout = async () => {
        setLoading(false);
        dispatch(setwalletServices({services: []}));
        dispatch(setOrderHistory({orderHistory: []}));
        dispatch(setGiftCards({giftCards: []}));
        setLoader(true);
        if (order.IsUWCRequest) {
            const isLoading = await processUWCCheckout();
            setLoading(isLoading);
            setLoader(false);
        } else {
            const isLoading = await props.processCheckout(order);
            dispatch(setOrdersReceipt({orderReceipt: order}));
            setLoading(isLoading);
            setLoader(false);
        }
    };
    const processCheckout = async () => {
        setAlertModalData({
            modalClass: 'alert_pop',
            title: 'Confirmation',
            message: 'Do you want to checkout?',
            btnSuccessText: 'Yes',
            btnCancelText: 'No',
            btnPrimary: true
        });
        if (order.IsCheckedTermAndCondition && order.IsCardSelected && sendBillPostalCode === false && newCustomerInfo.customer.sPhone != '' && newCustomerInfo.customer.sPhone != null) {
            setModal(true);
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(creditCards.length === 0 ? 'Please add the credit card.':'Please select credit card.');
            }
            return false;
        } else if (sendBillPostalCode) {
            setIsZipModal(true);
            return false;
        } else if (newCustomerInfo.customer.sEmail === null || newCustomerInfo.customer.sEmail === '') {
            setShowPhonePopup(true);
        }
    };

    if (customerInfo !=null && customerInfo.customerDetail!=null && customerInfo.customerDetail.sPayerIdentifier !=null && creditCards.length > 0) {
        const card= creditCards.find((d) => d.sPayerIdentifier === customerInfo.customerDetail.sPayerIdentifier && !order.IsCardSelected);
        if (card!= null) {
            order.IsCardSelected= true;
            order.SelectedCard= card;
            setOrder(order);
        }
    }
    if ((order.Email== '' || order.Email== undefined || order.Email== null) && newCustomerInfo.customer.sEmail!=null && newCustomerInfo.customer.sEmail!= '') {
        order.Email= newCustomerInfo.customer.sEmail;
        setOrder(order);
    }
    // const userInfo = useSelector((state) => state.auth.userInfo);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [Loader, setLoader] = useState(false);
    const [selectedCardId, setSelectedCardId]= useState(order.IsCardSelected ? order.SelectedCard.id : -1);
    const [emailIsTrueFalse, setEmailIsTrueFalse] = useState(null);
    const [emailLoading, setEmailLoading] = useState(false);
    const [phoneLoading, setPhoneLoading] = useState(false);
    const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

    const applyPromotionCode = async () => {
        order.IsPromocodeApplied = true;
        setOrder(order);
        const response = await getPromotionAvailable(userInfo.accessToken);
        if (response.status === 200) {
            if (response.data[0].discountAmount > 0) {
                order.DiscountAmount= response.data[0].discountAmount;
                order.GrandTotal= order.SubTotal - order.DiscountAmount;
                setOrder(order);
            } else {
                toast.warning('Discount not available on this code.', {autoClose: 3000});
            }
        } else {
            dispatch(setIsLoading(false));
            toast.error('Something went wrong.', {autoClose: 3000});
        }
    };

    const handleChangeCards = (e, card) => {
        const orderData = {...order};
        orderData.IsCardSelected= e.target.checked;
        orderData.SelectedCard= card;
        setOrder(orderData);
        setSelectedCardId(card.id);
        if (card.hasOwnProperty('sBillPostalCode')) {
            if (card.sBillPostalCode === '' || card.sBillPostalCode === null) {
                setSendBillPostalCode(true);
            } else {
                setSendBillPostalCode(false);
            }
        } else {
            setSendBillPostalCode(true);
        }
    };

    const setNewAddedCard = (card) => {
        order.IsCardSelected= true;
        order.SelectedCard= card;
        setSelectedCardId(card.id);
    };

    const handleChangeTerm = (e) => {
        const OrderData = {...order};
        document.getElementById('termcondi').setAttribute('checked', e.target.checked);
        OrderData.IsCheckedTermAndCondition= e.target.checked;
        setOrder(OrderData);
    };
    const setPromocode = (code) => {
        order.Promocode= code;
        setOrder(order);
    };


    const callbackOnPreviousPage = () => {
        props.onClickBack();
        if (order.IsUWCRequest) {
            props.hideCheckoutPage(false);
        } else {
            props.hideCheckoutPage();
        }
    };
    const getCustomerInfo = async () => {
        const response = await getCustomerInfoByGuid(userInfo.accessToken, localStorage.getItem('customerGuid'), userInfo.customerIdentityID);
        if (response.status == 200) {
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
            props.setCustData(response.data);
            props.hideCheckoutPage(true);
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
        }
    };
    const getuserinfo = async () => {
        const response = await getuserinfobyguid(localStorage.getItem('customerGuid'), customerCode);
        console.log(response);
        if (response.status === 200) {
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            dispatch(setUserInfo({userInfo: response.data}));
        }
    };

    const processUWCCheckout = async () => {
        const selectedPlateInfo = {...props.selectedVehiclePlateInfo};
        delete selectedPlateInfo.plateNo;
        if (order.IsCheckedTermAndCondition && order.IsCardSelected) {
            const postJson= order.AdditionalPageData.postJson;
            postJson.Alias = order.SelectedCard.sPayerIdentifier;
            postJson.CreditCardNumber= order.SelectedCard.id;
            if (postJson.CustomerGuid === null) {
                postJson.CustomerGuid = 0;
            }
            // postJson.ExpiryDate= String(order.SelectedCard.sExpiredMonth) +'/'+ String(order.SelectedCard.sExpireYear);

            postJson.isSendMail= order.IsRecieveEmailReciept;
            postJson.isSendSMS= order.IsRecieveSMSReciept;
            postJson.sEmail = order.Email !== undefined ? order.Email : newCustomerInfo.customer.sEmail;
            postJson.VehicleDetails = selectedPlateInfo;
            postJson.sBillPostalCode = order.sBillPostalCode;
            postJson.surchargeamount = order.surchargeamount;
            postJson.dblSurchargeTaxRate = order.dblSurchargeTaxRate;
            postJson.ExpiryDate = String(order.SelectedCard.sExpiredMonth ?? '') + String(order.SelectedCard.sExpireYear ?? '');
            postJson.Tax = order.TaxAmount;
            postJson.offSet = new Date().getTimezoneOffset() * -1;
            if (order.lChargeFrequency > 0 && order.lChargeFrequency != null) {
                postJson.NonReccuring = true;
            } else {
                postJson.NonReccuring = false;
            }
            let purchaseDate = new Date();
            purchaseDate = dateFormatter(purchaseDate);
            let response;
            if (order.AdditionalPageData.IsMonthlyActive && order.AdditionalPageData.vipAccountTypeId > 0) {
                postJson.Amount = order.Amount;
                response = await upgradeUWCPlan(order.AdditionalPageData.accessToken, postJson, purchaseDate);
            } else {
                postJson.bIsReactivation = order.bIsReactivation;
                postJson.Amount = order.Amount;
                response = await addNewUWCPlan(order.AdditionalPageData.accessToken, postJson, purchaseDate);
            }
            setLoading(false);
            if (response.status == 200) {
                if (localStorage.getItem('customerGuid') == null || localStorage.getItem('customerGuid') == 'undefined' || localStorage.getItem('customerGuid') == 'null') {
                    localStorage.setItem('customerGuid', response.data.customerGUID);
                }
                getCustomerInfo();
                getuserinfo();
                if (order.AdditionalPageData.IsMonthlyActive && order.AdditionalPageData.vipAccountTypeId > 0) {
                    toast.success(response.data);
                    const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                } else {
                    toast.success('Unlimited plan added sucessfully.');
                    localStorage.setItem('isReactivate', 'false');
                    localStorage.setItem('isNewUnlimited', 'false');
                    const seqReqBody = seqReqBodyObj('Information', `Unlimited plan added sucessfully`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                    setLoader(false);
                    dispatch(setCreditCards({creditCards: []}));
                    cardList();
                }
            } else if (response.status === (401 | 404 | 470)) {
                toast.warning(`${response.data.title}.`, {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Error', `${response.data.title}`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
                setLoader(false);
            } else if (response.status === 400) {
                toast.error(response.data, {autoClose: 3000});
            } else {
                toast.error('Some error occurred.', {autoClose: 3000});
                setLoader(false);
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card before checkout.');
            }
            return false;
        }
    };

    const expandPCIForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setViewCardForm(true);
    };

    const PCIScript= getPCIDetailsLocal().PCIScript;

    useEffect(() => {
        order.IsCheckedTermAndCondition= false;
        order.IsRecieveSMSReciept = sendSms;
        setOrder(order);
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    // const getAddOnServiceTax = async (serviceId) => {
    //     setLoader(true);
    //     const response = await getTaxforAddOnServices(userInfo.accessToken, serviceId.toString(), locationId);
    //     if (response.status == 200) {
    //         setAddOnServiceTax(response.data.dblTaxAmount);
    //         order.addonTax = response.data.dblTaxAmount;
    //         setLoader(false);
    //     }
    // };


    useEffect(() => {
        order.sBillPostalCode = '';
        order.addonTax = 0;
        if (localStorage.getItem('customerGuid') == null || localStorage.getItem('customerGuid') == 'undefined' || localStorage.getItem('customerGuid') == 'null') {
            localStorage.setItem('customerGuid', userInfo.customerGuid);
        }
        if (props.selecteAddondServices && props.selecteAddondServices.length > 0) {
            debugger;
            order.addon = Number(props.selecteAddondServices.reduce((a, c)=> a + c.dblServiceAmount, 0).toFixed(2));
            setAddonTotal(order.addon);
            const orderTax = Number(props.selecteAddondServices.filter((service) => service.bTaxIncluded === false).reduce((a, c)=> a + c.taxAmount, 0).toFixed(2));
            setAddOnServiceTax(orderTax);
            order.addonTax = orderTax;
        } else {
            order.addon = 0;
        }
        if (order.SelectedCard.hasOwnProperty('sBillPostalCode')) {
            if (order.SelectedCard.sBillPostalCode === '' || order.SelectedCard.sBillPostalCode === null) {
                setSendBillPostalCode(true);
            } else {
                setSendBillPostalCode(false);
            }
        } else {
            setSendBillPostalCode(true);
        }
        getCreditCardsList();
    }, [creditCards.length == 0, props?.selecteAddondServices]);

    const getCreditCardsList = async () => {
        if (creditCards.length === 0) {
            setLoading(true);
            const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
            if (response.status === 200) {
                dispatch(setCreditCards({creditCards: response.data}));
                localStorage.setItem('creditCardDetail', JSON.stringify(response.data));
                const seqReqBody = seqReqBodyObj('Information', `Card list retrieve successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            } else {
                toast.error('Some error occurred when fetching card details.', {autoClose: 3000});
                // dispatch(setCreditCards({creditCards: []}));
                const seqReqBody = seqReqBodyObj('Error', `Some error occurred when fetching card details`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
            setLoading(false);
        }
    };
    const cardList = async () =>{
        const response = await creditCardsList(userInfo.accessToken, userInfo.customerIdentityID);
        if (response.status === 200) {
            dispatch(setCreditCards({creditCards: response.data}));
            const seqReqBody = seqReqBodyObj('Information', `Card list retrieve successfully`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else {
            toast.error('Some error occurred when fetching card details.', {autoClose: 3000});
            dispatch(setCreditCards({creditCards: []}));
            const seqReqBody = seqReqBodyObj('Error', `Some error occurred when fetching card details`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
        }
    };
    const handleEmail = async () => {
        setEmailLoading(true);
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userEmail.match(validRegex)) {
            const customerData = customerInfo;
            customerData.customer.sEmail = userEmail;
            localStorage.setItem('customerDetail', JSON.stringify(customerData));
            setCustomerInfo(customerData);
            const values = {
                firstName: newCustomerInfo.customer.sFirstName == null ? '' : newCustomerInfo.customer.sFirstName,
                lastName: newCustomerInfo.customer.sLastName == null ? '' : newCustomerInfo.customer.sLastName,
                phoneNumber: localStorage.getItem('phoneNumber').replace(/([() -])+/g, ''),
                email: userEmail,
                customerGuid: newCustomerInfo.customer.customerGUID,
                customerIdentityID: userInfo.customerIdentityID,
                bUpdateMobilephone: false
            };
            const response = await putCustomerInfoByGuid(userInfo.accessToken, values);
            if (response.status === 200) {
                if (response.data == 'Something went wrong.') {
                    toast.warning(response.data, {autoClose: 3000});
                    setPhoneLoading(false);
                    resetForm();
                    return false;
                }
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                dispatch(setUserInfo({userInfo: response.data}));
                setEmailLoading(false);
                setShowEmailPopup(false);
                setEmailIsTrueFalse(null);
                setUserEmail('');
                const seqReqBody = seqReqBodyObj('Information', `Your email added successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
            order.Email = userEmail;
            setOrder(order);
        } else {
            setEmailLoading(false);
            toast.error('Invalid email address.', {autoClose: 3000, toastId: 'invalid_email'});
        }
    };
    const initialValuesPhone = {
        phone: ''
    };
    const handlePhone = async (param, resetForm) => {
        setPhoneLoading(true);
        const customerData = customerInfo;
        const values = {
            firstName: newCustomerInfo.customer.sFirstName == null ? '' : newCustomerInfo.customer.sFirstName,
            lastName: newCustomerInfo.customer.sLastName == null ? '' : newCustomerInfo.customer.sLastName,
            phoneNumber: param.phone.replace(/([() -])+/g, ''),
            email: newCustomerInfo.customer.sEmail == null ? '' : newCustomerInfo.customer.sEmail,
            customerGuid: newCustomerInfo.customer.customerGUID,
            customerIdentityID: userInfo.customerIdentityID,
            bUpdateMobilephone: true
        };
        const response = await putCustomerInfoByGuid(userInfo.accessToken, values);
        if (response.status === 200) {
            if (response.data == 'Phone number already taken') {
                toast.warning(response.data, {autoClose: 3000});
                setPhoneLoading(false);
                resetForm();
                return false;
            }
            customerData.customer.sPhone = param.phone.replace(/([() -])+/g, '');
            setCustomerInfo(customerData);
            localStorage.setItem('customerDetail', JSON.stringify(customerData));
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            dispatch(setUserInfo({userInfo: response.data}));
            localStorage.setItem('phoneNumber', param.phone.replace(/([() -])+/g, ''));
            setPhoneLoading(false);
            setShowPhonePopup(false);
        }
    };
    const phoneFormik = initFormik(initialValuesPhone, loginSchema, handlePhone, true);

    const formik = useFormik({
        initialValues: {
            zipcode: ''
        },
        validationSchema: zipCodeSchema,
        onSubmit: (values, {resetForm}) => {
            const OrderData = {...order};
            OrderData.sBillPostalCode= values.zipcode;
            setOrder(OrderData);
            setIsZipModal(false);
            setSendBillPostalCode(false);
            resetForm();
        }
    });

    const [card, setCard] = useState('');
    const restDataCapForm = () => {
        setCard('');
        // document.querySelector('[data-token="card_number"]').value = '';
        document.querySelector('[data-token="exp_month"]').value = '';
        document.querySelector('[data-token="exp_year"]').value = '';
        document.querySelector('[data-token="cvv"]').value = '';
        document.getElementById('cardHolderName').value = '';
        document.getElementById('billingZipCodeDataCap').value = '';
    };

    return (
        <div>
            {modal1 ? <TermsModal id={order.termsId} closeModal={() => setModal1(false)} /> : ''}
            {Loader === true ? <FullScreenLoader /> : ''}
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center flex-wrap border-bottom'>
                            <div className='inner_title d-flex align-items-start'>
                                <div className='progress-circle over50 p100'>
                                    <span>2 of 2</span>
                                    <div className='left-half-clipper'>
                                        <div className='first50-bar'></div>
                                        <div className='value-bar'></div>
                                    </div>
                                </div>
                                <div className='main_title'>
                                    <h2>{order.TopHeaderText}</h2>
                                    <p>Order Summary</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modal ? <AlertModalModule data={alertModalData} targetEvent={() => {
                    setOrderCheckout();
                    orderCheckout();
                }} closeModal={() => setModal(false)} /> : ''}
                <div className='row payment_method_wrap'>
                    <div className='col-lg-7'>
                        <div className='pay_method_list'>
                            <h5 className='mb-3'>Select a Credit Card</h5>
                            <div className="cc_card_list mb-2">
                                {creditCards.length > 0 && !loading ? creditCards.map((service, index) => {
                                    const ccDetail=getCCIcon(service);
                                    const isChecked=(order.IsCardSelected && (selectedCardId == service.id || order.SelectedCard.id == service.id));
                                    return (
                                        <div className='cc_card' key={index}>
                                            <div className='form-check'>
                                                <input
                                                    className='form-check-input check_pointer'
                                                    type='radio'
                                                    name='ccCardRadio'
                                                    key={index}
                                                    checked={isChecked ? true: false}
                                                    onChange={(event) => handleChangeCards(event, service)}
                                                    id={`card-${service.id}`}
                                                />
                                                <label className='form-check-label' htmlFor={`card-${service.id}`}>
                                                    <div className="cc_number">xxxx xxxx xxxx {service.sSpan}</div>
                                                    <div className='card_type'>
                                                        <span className={`card_icon ${ccDetail.icon}`}></span>
                                                    </div>
                                                </label>
                                                {service.bIsRecurring!=null && service.bIsRecurring ?
                                                    <div className='rc_card_badge'>Primary Card</div> : '' }
                                            </div>
                                        </div>
                                    );
                                }) : <></>}
                            </div>
                            <div className='new_card_add'>
                                {/* {isCardknox == 'false'?
                                    <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                        data-bs-target='#add_new_card_pop' aria-expanded='false' aria-controls='add_new_card_from' onClick={(event)=> expandPCIForm(event)}> + Add a new card</button>:
                                    <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                        data-bs-target='#addCardpop3' aria-expanded='false' aria-controls='add_new_card_from'> + Add a new card</button>
                                } */}
                                {gateWayType == 'openEdge' &&
                                    <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                        data-bs-target='#add_new_card_pop' aria-expanded='false' aria-controls='add_new_card_from' onClick={(event)=> expandPCIForm(event)}> + Add a new card</button>}
                                {gateWayType == 'cardknox' &&
                                    <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                        data-bs-target='#addCardpop3' aria-expanded='false' aria-controls='add_new_card_from'> + Add a new card</button>
                                }
                                {gateWayType == 'dataCap' &&
                                <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                    data-bs-target='#addCardpop4' aria-expanded='false' aria-controls='add_new_card_from'> + Add a new card</button>
                                }
                                {/* <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                    data-bs-target='#add_new_card_pop' aria-expanded='false' aria-controls='add_new_card_from' onClick={(event)=> expandPCIForm(event)}> + Add a new card</button> */}
                            </div>
                            {/* <div className='new_card_add'>
                                <button className='btn btn-outline-secondary' type='button' data-bs-toggle='modal'
                                    data-bs-target='#addCardpop4' aria-expanded='false' aria-controls='add_new_card_from'> + Add a new card</button>
                            </div> */}
                        </div>
                    </div>
                    <div className='modal fade cw_modal add_card_pop' id='add_new_card_pop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content p-4'>
                                <button type="button" className="btn-close" id="btnCCPopCancel" data-bs-dismiss="modal" onClick={() => {
                                    setViewCardForm(false);
                                }}></button>
                                <div className='modal-body'>
                                    {(viewCardForm) ? <PaymentCard loader={(val) => setLoader(val)} setCard={(card) => setNewAddedCard(card)} hidePop={() => setViewCardForm(false)} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal fade cw_modal add_card_pop' id='addCardpop3' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content p-4'>
                                <button type="button" className="btn-close" id="btnCCPopCancelCardKnox" data-bs-dismiss="modal"></button>
                                <div className='modal-body'>
                                    <CardknoxPaymentCard loader={(val) => setLoader(val)} setCard={(card) => setNewAddedCard(card)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal fade cw_modal add_card_pop' id='addCardpop4' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content p-4'>
                                <button type="button" className="btn-close" id="btnCCPopCanceldataCap" data-bs-dismiss="modal" onClick={() => {
                                    console.log('dataCap');
                                    restDataCapForm();
                                }}></button>
                                <div className='modal-body'>
                                    <DataCapPaymentCard cardInfo={card} setCardInfo={setCard} restDataCapForm={restDataCapForm} loader={(val) => setLoader(val)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='order_summ_wrap'>
                            <h5 className='mb-3'>Order Summary</h5>
                            { (order.IsUWCRequest && order.AdditionalPageData.IsMonthlyActive && order.AdditionalPageData.vipAccountTypeId > 0 ) ?
                                <div className="summary_card">
                                    <div className="summ_wrap">
                                        <div className="os_user_plan">
                                            <ul className="list_items">
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Current Plan:</div>
                                                        <b className='un_plan_chang'>{order.ULPlanData.CurrentPlan}</b>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>{order.ListItemText}:</div>
                                                        <b className='un_plan_chang'>{order.ListItemDescription}</b>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="os_user_plan">
                                            <ul className="list_items">
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Customer:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah'>{`${customerDetail?.customer.sFirstName !== null ? customerDetail.customer.sFirstName : userInfo.firstname}`} {customerDetail.customer?.sFirstName?.length > 18 || customerDetail.customer?.sLastName?.length > 18 ? <br /> : ''}{`${customerDetail?.customer?.sLastName !== null ? customerDetail?.customer?.sLastName : userInfo?.lastName}`}</b>
                                                    </div>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Email:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah text-lowercase'>{customerDetail.customer.sEmail}</b>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Cell Phone:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah'>{phoneNumberFormat(customerDetail.customer.sPhone)}</b>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className='list_items order_details'>
                                            <li>
                                                <span>Old Recurring Payment:</span> <span>{currencyFormat(parseFloat(order?.ULPlanData?.CurrentRecurringPayment))}</span>
                                            </li>
                                            <li>
                                                <span>New Recurring Payment:</span> <span>{currencyFormat(parseFloat(order?.ULPlanData?.NewRecuringPayment))}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="order_total"><b>Prorate:</b> <b>{order?.AdditionalPageData?.postJson?.Price>=0?'':'-'}{currencyFormat(parseFloat(order?.AdditionalPageData?.postJson?.Price>0?order?.AdditionalPageData?.postJson?.Price:0 - order?.AdditionalPageData?.postJson?.Price))}</b></div>
                                </div> :
                                <div className='summary_card'>
                                    <div className="summ_wrap">
                                        <div className="os_user_plan">
                                            <ul className='list_items'>
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>{order.ListItemText}:</div>
                                                        <b className='un_plan_chang'>{order.ListItemDescription}</b>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {order.IsUWCRequest && !order.AdditionalPageData.IsMonthlyActive ?
                                            <div className="os_user_plan">
                                                <ul className='list_items'>
                                                    {isReactivate == 'true' ?
                                                        <><li>
                                                            <div className="purchase_item_info mb-3">
                                                                <div className='mb-2'>Reactivation Charge</div>
                                                                <b className='un_plan_chang un_plan_chang_uah'>{!selectedPlan.bRecurring ? currencyFormat(selectedPlan.surchargeamount) : currencyFormat(selectedPlan.dblAmount)}</b>
                                                            </div>
                                                        </li>
                                                        {selectedPlan.bRecurring ?
                                                            <><li>
                                                                <div className="purchase_item_info mb-3">
                                                                    <div className='mb-2'>Every Month Charge</div>
                                                                    <b className='un_plan_chang un_plan_chang_uah'>{currencyFormat(selectedPlan.dblAmount)}</b>
                                                                </div>
                                                            </li></>: ''
                                                        }</> :
                                                        <><li>
                                                            <div className="purchase_item_info mb-3">
                                                                <div className='mb-2'>One Time Charge</div>
                                                                <b className='un_plan_chang un_plan_chang_uah'>{currencyFormat(selectedPlan.surchargeamount)}</b>
                                                            </div>
                                                        </li>
                                                        {selectedPlan.bRecurring ?
                                                            <><li>
                                                                <div className="purchase_item_info mb-3">
                                                                    <div className='mb-2'>Every Month Charge</div>
                                                                    <b className='un_plan_chang un_plan_chang_uah'>{currencyFormat(selectedPlan.dblAmount)}</b>
                                                                </div>
                                                            </li></>: ''
                                                        }
                                                        </>}
                                                </ul>
                                            </div>:''
                                        }
                                        <div className="os_user_plan">
                                            <ul className='list_items'>
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Customer:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah'>{`${customerDetail?.customer.sFirstName !== null ? customerDetail.customer.sFirstName : userInfo.firstname}`} {customerDetail.customer?.sFirstName?.length > 18 || customerDetail.customer?.sLastName?.length > 18 ? <br /> : ''}{`${customerDetail?.customer?.sLastName !== null ? customerDetail?.customer?.sLastName : userInfo?.lastName}`}</b>
                                                    </div>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Email:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah text-lowercase'>{customerDetail.customer.sEmail}</b>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="purchase_item_info mb-3">
                                                        <div className='mb-2'>Cell Phone:</div>
                                                        <b className='un_plan_chang un_plan_chang_uah'>{phoneNumberFormat(customerDetail.customer?.sPhone)}</b>
                                                    </div>
                                                    {order.IsUWCRequest?
                                                        <div className="purchase_item_info mb-3">
                                                            <div className='mb-2'>Vehicle Plate:</div>
                                                            <b className='un_plan_chang'>{props?.selectedVehiclePlateInfo?.Plate !== '' ? props?.selectedVehiclePlateInfo?.Plate : props?.selectedVehiclePlateInfo?.plateNo}</b>
                                                        </div>:''
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        {otherFormData.isForMySelf ? '' :
                                            <div className={`os_user_plan recipient_info`}>
                                                <ul className='list_items'>
                                                    <li>
                                                        <div className="purchase_item_info mb-3">
                                                            <div className='mb-2'>Recipient:</div>
                                                            <b className='un_plan_chang un_plan_chang_uah'>{otherFormData.firstName} {otherFormData.lastName}</b>
                                                        </div>
                                                        <div className="purchase_item_info mb-3">
                                                            <div className='mb-2'>Email:</div>
                                                            <b className='un_plan_chang un_plan_chang_uah text-lowercase'>{otherFormData.email}</b>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="purchase_item_info mb-3">
                                                            <div className='mb-2'>Cell Phone:</div>
                                                            <b className='un_plan_chang un_plan_chang_uah'>{phoneNumberFormat(otherFormData.phoneNumber)}</b>
                                                        </div>
                                                        <a href='#' type='button' onClick={() => props.showForOthersPopup()} className="text-primary"><FiEdit2/> Edit Info</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                        {order.IsShowPromocodeDiv ?
                                            <div className='promo_form'>
                                                <div className='form-group d-flex mt-3 mb-3'>
                                                    <input type='text' className='form-control promo_code me-2 form-control-lg  id="myInput' placeholder='Enter Promotion Code' value={order.Promocode} onChange={(event) => setPromocode(event.target.value)} />
                                                    <button type='button' className='main_btn' onClick={() => applyPromotionCode()}>Apply</button>
                                                </div>
                                                {order.Promocode === '' && order.IsPromocodeApplied ? <p className='text-danger'>Please Enter Promotion Code</p>:''}
                                            </div> : ''}
                                        {order.IsUWCRequest || order.ListItemText === 'Gift Card'? '' :
                                            <ul className='list_items order_details'>
                                                <li>
                                                    <span>Wash Price:</span> <span>{currencyFormat(order.SubTotal)}</span>
                                                </li>
                                            </ul>
                                        }
                                        { props.selecteAddondServices && props.selecteAddondServices.length > 0 ?
                                            <div className='order_total addon_total_order'>
                                                <div className='add_os'>
                                                    <span>Add-on Services <span className='link-primary check_pointer popohvr' onClick={()=>setShowAddon(!showAddon)}>({props.selecteAddondServices.length} {props.selecteAddondServices.length>1?'services':'service'})
                                                    </span></span>
                                                    <span>${props.selecteAddondServices.reduce((a, c)=> a + c.dblServiceAmount, 0).toFixed(2)}</span>
                                                    { showAddon?
                                                        <div className='pop_add_os'>
                                                            <div className='pop_add_os_title'>
                                                                <span>Add-on Services</span>
                                                                <button type='button' onClick={()=>setShowAddon(false)}><FiX/></button>
                                                            </div>
                                                            {props.selecteAddondServices.map((value)=>{
                                                                return (
                                                                    <div className='pop_add_os_line' key={value.service_ID}>
                                                                        <span>{value.sDescription}</span>
                                                                        <b>${value.dblServiceAmount.toFixed(2)}</b>
                                                                    </div>
                                                                );
                                                            })}
                                                            <div className='p_os_total'>
                                                                <a href="#" className='link-primary' onClick={()=>{
                                                                    props.setShowAddServicePopup && props.setShowAddServicePopup(true);
                                                                    props.setAddonServiceEdit && props.setAddonServiceEdit(true);
                                                                    setShowAddon(false);
                                                                }}><FiEdit2/> Edit</a>
                                                                <b>${props.selecteAddondServices.reduce((a, c)=> a + c.dblServiceAmount, 0).toFixed(2)}</b>
                                                            </div>
                                                        </div>:''
                                                    }
                                                </div>
                                            </div>:''
                                        }
                                        <ul className='list_items order_details'>
                                            {props.selecteAddondServices && props.selecteAddondServices.length > 0 ?
                                                <li>
                                                    <span>Subtotal:</span> <b>{currencyFormat(order.SubTotal + Number(addonTotal))}</b>
                                                </li> :
                                                <li>
                                                    <span>Subtotal:</span> <b>{currencyFormat(order.SubTotal)}</b>
                                                </li>
                                            }
                                            {props.selecteAddondServices && props.selecteAddondServices.length > 0 ?
                                                <li>
                                                    <span>Tax:</span> <span>{currencyFormat(order.TaxAmount + addOnServiceTax)}</span>
                                                </li>:
                                                <li>
                                                    <span>Tax:</span> <span>{order.TaxAmount != undefined ? currencyFormat(order.TaxAmount) : parseFloat(order.TaxAmount)}</span>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    {props.selecteAddondServices && props.selecteAddondServices.length > 0 ?
                                        <div className="order_total"><b>Total:</b> <b>{currencyFormat(order.GrandTotal + Number(addonTotal) + addOnServiceTax)}</b></div>:
                                        <div className="order_total"><b>Total:</b> <b>{currencyFormat(order.GrandTotal)}</b></div>
                                    }
                                </div>}
                            <div className='condi_checks pt-4'>
                                <div className='form-check'>
                                    <input className='form-check-input check_pointer' type='checkbox' id='rEmail' checked={IsRecieveEmailReciept} onChange={(event) => {
                                        const orderData = {...order};
                                        orderData.IsRecieveEmailReciept=event.target.checked;
                                        setIsRecieveEmailReciept(event.target.checked);
                                        setOrder(orderData);
                                        if (newCustomerInfo !== null && Object.keys(newCustomerInfo).length > 0) {
                                            if (newCustomerInfo.customer.sEmail === null || newCustomerInfo.customer.sEmail === ''||!userInfo.bEmailVerified) {
                                                if (event.target.checked) {
                                                    setShowEmailPopup(true);
                                                }
                                            } else {
                                                setShowEmailPopup(false);
                                            }
                                        }
                                    }} />
                                    <label className='form-check-label' htmlFor='rEmail'>
                                            Receive email receipt
                                    </label>
                                </div>
                                <div className='form-check'>
                                    <input className='form-check-input check_pointer' type='checkbox' id='rSMS' checked={sendSms} onChange={(event) => {
                                        const orderData = {...order};
                                        orderData.IsRecieveSMSReciept = event.target.checked;
                                        setSendSms(!sendSms);
                                        setOrder(orderData);
                                        if (newCustomerInfo !== null && Object.keys(newCustomerInfo).length > 0) {
                                            if (newCustomerInfo.customer.sPhone === null || newCustomerInfo.customer.sPhone === '') {
                                                if (event.target.checked) {
                                                    setShowPhonePopup(true);
                                                }
                                            } else {
                                                setShowPhonePopup(false);
                                            }
                                        }
                                    }} />
                                    <label className='form-check-label' htmlFor='rSMS'>
                                            Receive SMS receipt
                                    </label>
                                </div>
                                <div className='form-check'>
                                    <input className='form-check-input check_pointer' type='checkbox' id='termcondi' onChange={(event) => handleChangeTerm(event)}/>
                                    <label className='form-check-label' htmlFor='termcondi'>
                                        I Agree to the <Link to='#' className='termandcondition_link' onClick={() => setModal1(true)}> Terms and Conditions<span className="text-danger">*</span> </Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex justify-content-end mt-3 pt-3 border-top'>
                            <div className='btn_grps d-flex flex-wrap'>
                                <button className='btn btn-secondary me-2' onClick={() => {
                                    callbackOnPreviousPage();
                                    props?.setAddonServiceEdit && props?.setAddonServiceEdit(false);
                                    props?.setSelectedAddonServices && props?.setSelectedAddonServices([]);
                                    props?.setListOfUpgradeServiceIds && props?.setListOfUpgradeServiceIds([]);
                                    props?.setSelectedVehiclePlateInfo && props?.setSelectedVehiclePlateInfo({VehicleID: 0, Plate: ''});
                                }}><FiArrowLeft/> Back</button>
                                <button className='btn btn-primary' {...(loading ? {disabled: 'disabled'} : {})} onClick={() => {
                                    processCheckout();
                                }} >{loading && <span className="spinner-border text-light btnLoader"></span>} Checkout <FiArrowRight/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* email popup */}
            <div className={`modal fade cw_modal add_card_pop ${showEmailPopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-3'>
                        <form>
                            <div className='modal-body p-3'>
                                <label className='mb-2'>Enter email for receiving receipt.</label>
                                <input
                                    type='text'
                                    name='emailReceipt'
                                    id='emailReceipt'
                                    className='form-control form-control-lg mb-1'
                                    placeholder='Email'
                                    value={userEmail}
                                    onChange={(e) => {
                                        setUserEmail(e.target.value);
                                        if (e.target.value.match(emailRegExp)) {
                                            setEmailIsTrueFalse(true);
                                        } else {
                                            setEmailIsTrueFalse(false);
                                        }
                                    }}
                                />
                                {emailIsTrueFalse == false && userEmail != ''? (
                                    <small className="text-danger pt-2">Please enter valid Email.</small>
                                ) : null}
                                {userEmail == '' && emailIsTrueFalse == false ? (
                                    <small className="text-danger pt-2">Email is required</small>
                                ) : null}
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type='submit' id="btnAlertYes" onClick={(e)=> {
                                    e.preventDefault();
                                    handleEmail();
                                }} className='btn btn-primary' disabled={emailLoading || emailIsTrueFalse == false && userEmail == '' || emailIsTrueFalse == null && userEmail == '' || emailIsTrueFalse == false && userEmail != ''}> {emailLoading && <span className="spinner-border text-light btnLoader"></span>}Save</button>
                                <button type='button' className='btn btn-secondary' onClick={() => {
                                    order.IsRecieveEmailReciept= false;
                                    setUserEmail('');
                                    setIsRecieveEmailReciept(false);
                                    setOrder(order);
                                    setShowEmailPopup(false);
                                }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* email fade */}
            {showEmailPopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
            {/* phone popup */}
            <div className={`modal fade cw_modal add_card_pop ${showPhonePopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-3'>
                        <form onSubmit={phoneFormik.handleSubmit}>
                            <div className="modal-body p-3">
                                <label htmlFor="phone" className="form-label">Cell Phone<sup className="text-danger">*</sup></label>
                                <InputMask
                                    className="form-control form-control-lg"
                                    onChange={phoneFormik.handleChange}
                                    onBlur={phoneFormik.handleBlur}
                                    mask="(999) 999-9999"
                                    maskChar=""
                                    value={phoneFormik.values.phone}
                                    name="phone"
                                    placeholder="(000) 000-0000"
                                />
                                {phoneFormik.touched.phone && phoneFormik.errors.phone ? (
                                    <small className="text-danger validationError">{phoneFormik.errors.phone}</small>
                                ) : ''}
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type="submit" id="btnAlertYes" className="button btn btn-primary" disabled={phoneLoading || !phoneFormik.isValid || phoneFormik.values.phone === ''}>
                                    {phoneLoading && <span className="spinner-border text-light btnLoader"></span>}<span>Save</span>
                                </button>
                                <button type='button' className='btn btn-secondary' onClick={() => {
                                    setShowPhonePopup(false);
                                    setSendSms(false);
                                }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* phone fade */}
            {showPhonePopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
            {/* zip code  */}
            <div className={`modal fade cw_modal add_card_pop ${isZipModalOpen ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-4'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='modal-body zip_input_modal pt-4 pb-4'>
                                <input
                                    type='text'
                                    name='zipcode'
                                    id='zipcode'
                                    className='form-control form-control-lg mb-1 text-center'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.zipcode}
                                    placeholder='Please enter your Zip Code.'
                                />
                                {formik.touched.zipcode && formik.errors.zipcode ? (
                                    <small className="text-danger">{formik.errors.zipcode}</small>
                                ) : null}
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type='submit' className='btn btn-primary'>Submit</button>
                                <button type='button' className='btn btn-secondary' onClick={() => {
                                    setIsZipModal(false);
                                    formik.resetForm();
                                }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {isZipModalOpen ? <div className="modal-backdrop fade show"></div>:''}
        </div>
    );
};
export default OrderSummary;

