import './../../assets/css/skeleton.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import React from 'react';

const SkeletonEmpWashCard = ({type}) => {
    const classes = `${type}`;

    return (
        <SkeletonTheme highlightColor="#e0dfdf">
            <div className={classes}>
                <Skeleton />
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonEmpWashCard;
