// Services
import {saveCreditCard} from '@services/order-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {addNewAddedCard} from '@store/reducers/manage-account-reducer';
// Utils
// import axios from 'axios';
import {getPCIDetailsLocal} from '@utils/common-repo';
import {seqReqBodyObj} from '@utils/common-helper';
import {setCreditCards} from '../../../store/reducers/manage-account-reducer';

function InitializePCI(userInfo, preferedHomeLocation, dispatch, props, toast, toastId, btnName) {
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const pciConfig = getPCIDetailsLocal();

    if (typeof GlobalPayments == 'undefined') {
        console.log('Variable GlobalPayments is not defined.');
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error('Global Payments Script Failed. Please Try Again!');
        }
        document.getElementById('btnCCPopCancel').click();
        setTimeout(()=>{
            document.getElementById('btnCCPopCancel').click();
        }, 500);
        return false;
    }
    GlobalPayments?.configure({
        // publicApiKey: 'pkapi_cert_dNpEYIISXCGDDyKJiV'
        'X-GP-Api-Key': pciConfig.XGPApiKey,
        'X-GP-Environment': pciConfig.Enviornment
    });

    // Create Form
    const cardForm = GlobalPayments?.ui.form({
        fields: {
            'card-holder-name': {
                placeholder: 'Card Holder Name',
                target: '#credit-card-card-holder'
            },
            'card-number': {
                placeholder: '•••• •••• •••• ••••',
                target: '#credit-card-card-number'
            },
            'card-expiration': {
                placeholder: 'MM / YYYY',
                target: '#credit-card-card-expiration'
            },
            'card-cvv': {
                placeholder: '•••',
                target: '#credit-card-card-cvv'
            },
            'submit': {
                value: props.noLogged == undefined ? 'Submit' : 'Checkout',
                target: '#credit-card-submit',
                text: btnName
            }
        },
        styles: {
            'button.submit': {
                'background': 'transparent',
                'width': '100% !important',
                'font-size': '17px',
                'font-weight': 'bold',
                'border': '1px solid transparent',
                'border-radius': '10px',
                'cursor': 'pointer',
                'outline': 'none',
                'color': '#fff',
                'height': '52px',
                'line-height': '1.5',
                'max-width': 'inherit',
                'position': 'absolute',
                'right': '0'
            },
            'input': {
                'display': 'block',
                'background': 'none',
                'border': 'none',
                'border-bottom': '1px solid #fefefe',
                'font-size': '1.275rem',
                'color': 'black',
                'width': '100%',
                'text-align': 'center'
            },
            'input.card-holder-name': {
                'letter-spacing': '0.05rem',
                'border': '1px solid #e5e5e5',
                'border-radius': '10px',
                'margin-bottom': '10px',
                'width': '100%',
                'height': '57px',
                'box-sizing': 'border-box',
                'font-size': '15px',
                'outline': 'none',
                'color': '#363636',
                'background-color': '#ffffff!important',
                'padding': '0.375rem 0.75rem;'
            },
            'input.card-number': {
                'letter-spacing': '0.15rem',
                'border': '1px solid #e5e5e5',
                'border-radius': '10px',
                'margin-bottom': '10px',
                'width': '100%',
                'height': '57px',
                'box-sizing': 'border-box',
                'font-size': '15px',
                'outline': 'none',
                'color': '#363636',
                'background-color': '#ffffff!important',
                'padding': '0.375rem 0.75rem;'
            },
            'input.card-expiration': {
                'border': '1px solid #e5e5e5',
                'border-radius': '10px',
                'margin-bottom': '10px',
                'width': '100%',
                'height': '57px',
                'box-sizing': 'border-box',
                'font-size': '15px',
                'outline': 'none',
                'color': '#363636',
                'background-color': '#ffffff!important',
                'padding': '0.375rem 0.75rem;'
            },
            'input.card-cvv': {
                'letter-spacing': '0.35rem',
                'border': '1px solid #e5e5e5',
                'border-radius': '10px',
                'margin-bottom': '10px',
                'width': '100%',
                'height': '57px',
                'box-sizing': 'border-box',
                'font-size': '15px',
                'outline': 'none',
                'color': '#363636',
                'background-color': '#ffffff!important',
                'padding': '0.375rem 0.75rem;'
            },
            'input.invalid': {
                'border-color': '#dc3545 !important;'
            },
            'input:focus': {
                '-webkit-box-shadow': 'inset 0px 0px 3px 0px #b0dcff !important', 'box-shadow': 'inset 0px 0px 3px 0px #b0dcff !important', 'border-color': '#b0dcff !important'
            },
            'input': {
                'border-width': '1px'
            }
        }
    });

    cardForm.ready(() => {
        console.log('Registration of all credit card fields occurred');
    });

    cardForm.on('token-success', async (resp) => {
        if (props.noLogged == undefined) { // for logged in customer'
            const billingZipCode= document.getElementById('billingZipCode');
            const zipCodeRegex = /^[0-9]+$/;
            if (billingZipCode.value === '') {
                billingZipCode.style.border = '0.5px solid #FF0000';
                toast.error('Billing Zip code is required.');
                return false;
            } else if (!zipCodeRegex.test(billingZipCode.value)) {
                billingZipCode.style.border = '0.5px solid #FF0000';
                toast.error('Billing Zip Code must be only digits.', {autoClose: 3000});
                return false;
            } else if (billingZipCode.value.length < 5) {
                billingZipCode.style.border = '0.5px solid #FF0000';
                toast.error('Billing Zip Code must be min 5 digits.', {autoClose: 3000});
                return false;
            } else if (billingZipCode.value.length >= 6) {
                billingZipCode.style.border = '0.5px solid #FF0000';
                toast.error('Billing Zip Code must not exceed 5 digits.', {autoClose: 3000});
                return false;
            } else {
                billingZipCode.removeAttribute('style');
            }
            const postModel = {
            // eslint-disable-next-line camelcase
                temporary_token: resp.temporary_token,
                unixTimeStamp: Date.now(),
                CustomerIdentityId: userInfo.customerIdentityID,
                CustomerGuid: userInfo.customerGuid,
                PreferHomeLocationId: preferedHomeLocation == null ? localStorage.getItem('locationId'):preferedHomeLocation,
                sBillPostalCode: billingZipCode.value
            };
            props.loader(true);
            if (postModel.PreferHomeLocationId == null || postModel.PreferHomeLocationId =='') {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.info('Please set your preferred location first, then add your credit card.');
                }
                return false;
            }
            const response = await saveCreditCard(userInfo.accessToken, postModel);
            props.loader(false);
            if (response.status === 200) {
                if (props.setCard!= undefined && props.setCard.length !=undefined) {
                    props.setCard(response.data.item1);
                }
                dispatch(addNewAddedCard({cC: response.data.item1}));
                dispatch(setCreditCards({creditCards: []}));
                toast.success(response.data.item2, {autoClose: 3000});
                const phoneNumber = customerDetail.customer.sPhone;
                const seqReqBody = seqReqBodyObj('Information', `Card Added Successfully`, `${phoneNumber}`);
                await seqLogger(seqReqBody);
                document.getElementById('btnCCPopCancel').click();
            } else if (response.status === 400) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(response.data);
                    const phoneNumber = customerDetail.customer.sPhone;
                    if (response.data == 'This card has already been added') {
                        const seqReqBody = seqReqBodyObj('Information', `${response.data}`, `${phoneNumber}`);
                        await seqLogger(seqReqBody);
                    } else {
                        const seqReqBody = seqReqBodyObj('Error', `${response.data}`, `${phoneNumber}`);
                        await seqLogger(seqReqBody);
                    }
                }
                return false;
            } else {
                toast.error('Error Processing Payment, Please Try Again.', {autoClose: 3000});
            }
        } else {
            const txtMobileNo= document.getElementById('txtMobileNo');
            const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
            if (!regex.test(txtMobileNo.value)) {
                txtMobileNo.style.border = '0.5px solid #FF0000';
                toast.error('Invalid mobile no.');
                return false;
            }
            const postModel = {
                // eslint-disable-next-line camelcase
                temporary_token: resp.temporary_token,
                unixTimeStamp: Date.now(),
                phoneNumber: String(txtMobileNo.value).replace('(', '').replace(')', '').replace(' ', '').replace(' ', '')
            };

            props.checkout(postModel);
        }
    });

    cardForm.on('token-error', (resp) => {
        const error = resp.error;
        const validationDataset = [
            {
                isValid: (error.code === 'invalid_card') ? false : true,
                searchTerm: 'card_number', elementID: 'card-number',
                message: 'Invalid card number.'
            },
            {
                isValid: true, searchTerm: 'expiry',
                elementID: 'card-expiration',
                message: 'Invalid expiration date'
            },
            {
                isValid: true, searchTerm: 'card_security_code',
                elementID: 'card-cvv', message: 'Invalid CVV'
            }
        ];

        for (const validationData of validationDataset) {
            if (error.detail) {
                for (const detail of error.detail) {
                    const dataPath = detail.data_path || '';
                    const description = detail.description || '';
                    if (dataPath.search(validationData.searchTerm) > -1 || description.search(validationData.searchTerm) > -1) {
                        validationData.isValid = false;
                    }
                }
            }
            if (!validationData.isValid) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(validationData.message);
                }
            }
        }
    });

    // field-level event handlers. example:
    cardForm.on('card-number', 'register', () => {});
}

export {InitializePCI};
