import API from './api';

export const washManagerServices = async (accessToken, locationId, title) => {
    let url= '';
    if (title== 'Buy a Wash Service') {
        url= 'GetWashService';
    } else if (title== 'Buy a Detailing Service') {
        url= 'GetDetailingServices';
    } else if (title== 'Buy a Prepaid Wash Plan') {
        url= 'GetWashPlans';
    }
    try {
        return await API.get(`PrepaidBookTypes/${url}/${locationId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'locationId': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

export const washServices = async (accessToken, locationId) => {
    try {
        return await API.get(`PrepaidBookTypes/GetWashService/${locationId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'locationId': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

export const detailingServices = async (accessToken, locationId) => {
    try {
        return await API.get(`PrepaidBookTypes/GetDetailingServices/${locationId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'locationId': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
export const washPlans = async (accessToken, locationId) => {
    try {
        return await API.get(`PrepaidBookTypes/GetWashPlans/${locationId}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'locationId': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
export const getTaxforAddOnServices = async (accessToken, serviceIds, locationId) => {
    try {
        return await API.get(`PrepaidBookTypes/GetTaxforAddOnServices/${locationId}?serviceIds=${serviceIds}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'locationId': locationId,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
export const buyPrepaidBook = async (accessToken, locationId, otherPhoneNumber, reqBody, purchaseDate) => {
    try {
        return await API.post(`PrepaidBooks/BuyPrepaidBookForWeb/?locationid=${locationId}&otherPhoneNumber=${otherPhoneNumber}&purchaseDate=${purchaseDate}`, reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};
