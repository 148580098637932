import API from './api';
const customerCode = localStorage.getItem('customerCode');
export const getPromotionAvailable = async (accessToken) => {
    return await API.get(`Customers/GetPromotionAvailable?id=1`, {
        headers: {
            'X-Authentication-Token': accessToken,
            'X-Customer-Code': customerCode,
            'CallingApplication': 'CPV3'
        }
    }).catch((error) => {
        return error;
    });
};

export const saveCreditCard = async (accessToken, reqBody) => {
    try {
        return await API.post(`/CustomerIdentityCardOnFiles/AddPCICard`, reqBody, {
            headers: {
                'X-Authentication-Token': accessToken,
                'X-Customer-Code': customerCode,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};

export const checkGateWay = (gateWay) => {
    if (gateWay == 1) {
        return true;
    } else {
        return false;
    }
};

export const getPCIDetails = async () => {
    try {
        return await API.get(`/MobilePCI/GetPCIDetail`, {
            headers: {
                'X-Customer-Code': localStorage.getItem('customerCode'),
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
