import React, {useState} from 'react';
import DatePicker from 'react-date-picker';
import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
// Components
import EmployeePaymentCard from '@components/order/PCI/EmployeePaymentCard';
// Services
import {buyUnlimitedPlanByEmployee} from '@services/employee-module-services/employee-plan-service';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import {currencyFormat} from '@utils/common-helper';

const EmployeeCheckout = (props) =>{
    toast.configure();
    const [Loader, setLoader] = useState(false);
    const [setNewAddedCard] = useState(-1);
    const [startDate, setStartDate] = useState(new Date());

    const [term] = useState(true);
    const handleChangeFirstName = (e) => {
        const firstName= document.getElementById('firstName');
        if (e.target.value === '') {
            firstName.style.border = '0.5px solid #FF0000';
        } else {
            firstName.removeAttribute('style');
        }
    };
    const handleChangeLastName = (e) => {
        const lastName= document.getElementById('lastName');
        if (e.target.value === '') {
            lastName.style.border = '0.5px solid #FF0000';
        } else {
            lastName.removeAttribute('style');
        }
    };
    const handleChangeEmail = (e) => {
        const emailRegex = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

        const email= document.getElementById('email');
        if (!emailRegex.test(e.target.value)) {
            email.style.border = '0.5px solid #FF0000';
        } else {
            email.removeAttribute('style');
        }
    };
    const handleChangeMobileNo = (e) => {
        const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        const txtMobileNo= document.getElementById('txtMobileNo');
        if (!regex.test(e.target.value)) {
            txtMobileNo.style.border = '0.5px solid #FF0000';
        } else {
            txtMobileNo.removeAttribute('style');
        }
    };
    const processCheckout = async (ccDetails) => {
        document.getElementById('submitCheckout').click();
        setLoader(true);
        const isLoading= await prepaidWashCardCheckout(ccDetails);
        setLoader(isLoading);
    };
    const prepaidWashCardCheckout = async (ccDetails) => {
        const firstName= document.getElementById('firstName');
        if (firstName.value === '') {
            firstName.style.border = '0.5px solid #FF0000';
            toast.error('Please enter first name.', {autoClose: 3000, toastId: 'firstName'});
            return false;
        }
        const lastName= document.getElementById('lastName');
        if (lastName.value === '') {
            lastName.style.border = '0.5px solid #FF0000';
            toast.error('Please enter last name.', {autoClose: 3000, toastId: 'lastName'});
            return false;
        }
        const email= document.getElementById('email');
        const emailRegex = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email.value === '') {
            email.style.border = '0.5px solid #FF0000';
            toast.error('Please enter email.', {autoClose: 3000, toastId: 'email'});
            return false;
        }
        if (!emailRegex.test(email.value)) {
            email.style.border = '0.5px solid #FF0000';
            toast.error('Please enter valid email.', {autoClose: 3000, toastId: 'invalidEmail'});
            return false;
        }
        const txtMobileNo= document.getElementById('txtMobileNo');
        const cardOwner = document.getElementById('cardOwner');
        if (term === true) {
            const postModel = {
                sPhone: txtMobileNo.value.replace(/([() -])+/g, ''),
                sFirstName: firstName.value,
                sLastName: lastName.value,
                sAddress1: null,
                sAddress2: null,
                sCity: null,
                sState: null,
                sZipcode: null,
                sEmail: email.value,
                LocationId: null,
                sMonthlyLocation: null,
                EnableMonthlyRecipts: true,
                iYear: null,
                sMake: null,
                sModel: null,
                sPlate: null,
                Amount: props.subscriptionData.dblAmount,
                EarlyAmount: null,
                EarlySubAmount: null,
                Token: null,
                TransactionType: null,
                lAccountType: 4,
                CreditCardNumber: null,
                CardHolderName: cardOwner.value,
                ExpiryDate: null,
                CreditCardEncrypted: null,
                cardType: null,
                transactionId: null,
                bMonthlyHold: false,
                SubTotal: null,
                Tax: 0,
                EarlyAccessTax: null,
                bRecurringPlan: null,
                bSurchargeFirstMonthEnabled: false,
                Discount_ID: null,
                discountAmount: null,
                VipAccountAmount: null,
                VipAccountTax: null,
                DiscountTax: null,
                isDiscountApplied: null,
                UnlimitedPlan: props.subscriptionData.sDescription,
                campaignParam: null,
                approval_code: null,
                lTerminalId: null,
                unixTime: ccDetails.unixTimeStamp,
                bFuturePayment: false,
                temporary_token: ccDetails.temporary_token,
                userId: 1,
                dtFuturePayment: null,
                utcOffset: 330
            };
            const locId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
            const response = await buyUnlimitedPlanByEmployee(locId, postModel);
            if (response.data === undefined) {
                toast.error('Payment Failed', {autoClose: 3000, toastId: 'pmtFaild'});
                return false;
            }
            if (response.status === 200) {
                props.handleCheckoutNext(response.data.sBarcode);
            };
        };
    };
    const handleCallBack = () => {
        props.handleTermAndCondtion(false);
        props.handleCheckoutCallBack();
    };
    return (
        <React.Fragment>
            {Loader === true ? <FullScreenLoader /> : ''}

            <div className='form_wrap step_three animate_next' id='stepThree'>
                <div className='form_sec'>
                    <div className='form_title'>
                        <h2>Checkout</h2>
                    </div>
                    <form className='subs_form p-4' onSubmit={(e)=>e.preventDefault()}>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='cc_info'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 mb-3'>
                                            <label htmlFor="firstName"><small>First Name <sup className='text-danger'>*</sup></small></label>
                                            <InputMask
                                                id="firstName"
                                                className="form-control form-control-lg"
                                                onChange={(e) => handleChangeFirstName(e)}
                                                maskChar=" "
                                                onKeyPress={(e) => handleChangeFirstName(e)}
                                                name="firstName"
                                            />
                                        </div>
                                        <div className='col-12 col-md-6 mb-3'>
                                            <label htmlFor="lasttName"><small>Last Name <sup className='text-danger'>*</sup></small></label>
                                            <InputMask
                                                id="lastName"
                                                className="form-control form-control-lg"
                                                onChange={(e) => handleChangeLastName(e)}
                                                maskChar=" "
                                                onKeyPress={(e) => handleChangeLastName(e)}
                                                name="lastName"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label htmlFor="email"><small>Email Address <sup className='text-danger'>*</sup></small></label>
                                            <InputMask
                                                id="email"
                                                className="form-control form-control-lg"
                                                onChange={(e) => handleChangeEmail(e)}
                                                maskChar=" "
                                                onKeyPress={(e) => handleChangeEmail(e)}
                                                name="email"
                                            />
                                        </div>
                                        <div className='col-12 col-md-6 mb-3'>
                                            <label htmlFor="txtMobileNo"><small>Phone Number <sup className='text-danger'>*</sup></small></label>
                                            <InputMask
                                                id="txtMobileNo"
                                                className="form-control form-control-lg"
                                                onChange={(e) => handleChangeMobileNo(e)}
                                                mask="(999) 999-9999"
                                                maskChar=""
                                                onKeyPress={(e) => handleChangeMobileNo(e)}
                                                name="txtMobileNo"
                                                placeholder="(000) 000-0000"
                                            />
                                        </div>
                                        <EmployeePaymentCard checkout={(data) => processCheckout(data)} loader={(val) => setLoader(val)} noLogged={true} setCard={(id) => setNewAddedCard(id)} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 offset-md-1'>
                                <div className='order_summ_wrap'>
                                    <div className='summary_card'>
                                        <div className='list_items border-bottom mb-3 pb-4'>
                                            <span>Subscription:</span>
                                            <b className='d-block mt-3'>{props.subscriptionData.sDescription}</b>
                                        </div>
                                        <ul className='list_items order_details'>
                                            <li>
                                                <span>Subtotal:</span>
                                                <span>{currencyFormat(props.subscriptionData.dblAmount)}</span>
                                            </li>
                                            <li>
                                                <span>Discount:</span> <span>$0.00</span>
                                            </li>
                                            <li>
                                                <span>Tax:</span> <span>$0.00</span>
                                            </li>
                                            <li>
                                                <b>Total:</b> <b>{currencyFormat(props.subscriptionData.dblAmount)}</b>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='condi_checks pt-4'>
                                        <div className='form-check'>
                                            <input className='form-check-input check_pointer' type='checkbox' defaultValue id='rEmail' />
                                            <label className='form-check-label' >Receive email receipt</label>
                                        </div>
                                        <small className='mt-4 d-block'>You can easily unsubscribe at any time.</small>
                                    </div>
                                    <div className='input-group my-4'>
                                        <DatePicker className='carwashdatepicker form-control' onChange={(date) =>{
                                            setStartDate(date);
                                        }} value={startDate} dateFormat='MM/dd/y' monthPlaceholder='mm' dayPlaceholder='dd' yearPlaceholder='yyyy' minDate = {new Date(1700, 11, 24, 10, 33, 30, 0)} maxDate={new Date()}/>
                                    </div>
                                    <button type="submit" className="main_btn d-none" id="submitCheckout">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='btn_btm px-2'>
                    <button type='button' className='main_btn' id='prevS2' style={{height: '57px', background: '#00b4e6'}} onClick={handleCallBack}>Previous</button>
                    <div id="credit-card-submit" className="emp_pci_sub"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeCheckout;
