import {createSlice} from '@reduxjs/toolkit';

const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
const customerInfoSlice = createSlice({
    name: 'customer',
    initialState: {
        customersInfoByGuid: customerInfo !== null ? customerInfo : {},
        custData: {}
    },
    reducers: {
        setCustomerInfo: (state, action) => {
            state.customersInfoByGuid = action.payload.customersInfoByGuid;
        },
        setCustomerData: (state, action) => {
            state.custData = action.payload.custData;
        }
    }
});

export const {setCustomerInfo, getCustomerInfo, setCustomerData} = customerInfoSlice.actions;
export default customerInfoSlice.reducer;
