import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Services
import {getCustomerProRateAmountMobile, getUnlimitedPlans, getVehiclePlatesByCustomerGuid} from '@services/unlimited-plan-service';
import {checkoutJson} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';
// Components
import AlertModalModule from '../modals/AlertModal';
// Reducers
import {setSelectedPlan} from '@store/reducers/order-reducer';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
import SkeletonUnlimitedPlan from '@utils/skeletons/SkeletonUnlimitedPlan';

const AccountUnlimitedPlan=(props)=>{
    toast.configure();
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    const currentCustomerULPlan = props.custData.vipAccountType;
    const userInfo = useSelector((state) => state.auth.userInfo);
    const locationId = useSelector((state) => state.auth.userInfo.preferedHomeLocation);
    const accessToken = userInfo.accessToken;
    const orderJson = useState(checkoutJson());
    const [unlimitedPlanData, setUnlimitedPlanData] = useState([]);
    const [isLoadUlPlan, setIsLoadUlPlan]= useState(true);
    const [Loader, setLoader] = useState(false);
    const [plates, setPlates] = useState([]);
    const [selectedULPlan, setSelectedULPlan] = useState('');
    const [alertModalData, setAlertModalData] = useState({});
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const customerData = props.custData;
    const vipAccountTypeId = currentCustomerULPlan != null ? currentCustomerULPlan.lAccountType : 0;
    const IsMonthlyActive = props.custData.customerDetail != null && props.custData.customerDetail.bMonthlyActive != null && props.custData.customerDetail.bMonthlyActive;
    const [customerInfo] = useState(JSON.parse(localStorage.getItem('customerDetail')));
    const isReactivate = localStorage.getItem('isReactivate');
    const isNewUnlimited = localStorage.getItem('isNewUnlimited');

    const getUnlimitedPlanList = async () => {
        const locId = locationId == null ? localStorage.getItem('locationId'): locationId;
        try {
            setIsLoadUlPlan(true);
            if (locId == null) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.info('Please set your preferred location first.');
                }
            } else {
                const response = await getUnlimitedPlans(accessToken, locId);
                setIsLoadUlPlan(false);
                if (response.status === 200) {
                    const phoneNumber = customerDetail.customer.sPhone;
                    const planData = response.data;
                    if (customerData.customerDetail!=null && customerData.customerDetail.bMonthlyActive != null && customerData.customerDetail.bMonthlyActive) {
                        if (currentCustomerULPlan.bUnlimitedRecurring) {
                            setUnlimitedPlanData(planData.filter((x)=>x.bRecurring));
                        } else {
                            setUnlimitedPlanData([...planData.filter((x)=>x.lAccountType == currentCustomerULPlan.lAccountType), ...planData.filter((x)=>x.bRecurring)]);
                        }
                    } else {
                        setUnlimitedPlanData(planData);
                    }

                    const seqReqBody = seqReqBodyObj('Information', `Unlimited plan information retrieved successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                } else if (response.status === (401 | 404 | 470)) {
                    toast.warning(`${response.data.title}.`, {autoClose: 3000});
                    const seqReqBody = seqReqBodyObj('Error', `${response.data.title}`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                } else {
                    toast.error('No Plan has been found for the selected location.', {autoClose: 3000});
                }
            }
        } catch (ex) {
            console.log('Fail');
            toast.error('Some error occurred. When fetching unlimited plans.', {autoClose: 3000});
        }
    };

    const getVehiclePlates = async () => {
        if (plates.length === 0) {
            const response = await getVehiclePlatesByCustomerGuid(userInfo.accessToken, localStorage.getItem('customerGuid'));
            if (response.status === 200) {
                setPlates(response.data);
                const seqReqBody = seqReqBodyObj('Information', `Vehicle plates list retrieve successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem('customerGuid') == null || localStorage.getItem('customerGuid') == 'undefined') {
            localStorage.setItem('customerGuid', userInfo.customerGuid);
        }
        getUnlimitedPlanList();
        getVehiclePlates();
    }, []);

    const showULPlanCheckoutView = async () => {
        setModal(false);
        setLoader(true);
        orderJson[0].TopHeaderText = vipAccountTypeId > 0 && customerData?.customerDetail.bMonthlyActive != null && customerData?.customerDetail.bMonthlyActive ? 'Change Unlimited plan' : 'Purchase Unlimited plan';
        orderJson[0].ListItemText= 'New Selected Plan';
        orderJson[0].ListItemDescription= selectedULPlan.sDescription;
        orderJson[0].IsUWCRequest= true;
        orderJson[0].surchargeamount= selectedULPlan.surchargeamount;
        orderJson[0].dblSurchargeTaxRate= selectedULPlan.dblSurchargeTaxRate;
        orderJson[0].lChargeFrequency= selectedULPlan.lChargeFrequency;
        orderJson[0].TaxIncluded = selectedULPlan.bTaxIncluded;

        if (isReactivate == 'true') {
            orderJson[0].SubTotal = selectedULPlan.dblAmount == null ? 0 : selectedULPlan.dblAmount;
        } else {
            orderJson[0].SubTotal = selectedULPlan.surchargeamount;
        }
        if (IsMonthlyActive > 0) {
            orderJson[0].TaxAmount= 0;
            orderJson[0].IsUpgradeULPlan= true;
            orderJson[0].ULPlanData= {
                CurrentPlan: currentCustomerULPlan.sDescription,
                CurrentRecurringPayment: currentCustomerULPlan.dblAmount == null ? 0 : currentCustomerULPlan.dblAmount,
                NewSelectedPlan: selectedULPlan.sDescription,
                NewRecuringPayment: selectedULPlan.dblAmount
            };
        } else {
            orderJson[0].IsUpgradeULPlan= false;
            orderJson[0].TaxAmount= parseFloat((selectedULPlan.dblAmount * selectedULPlan.dblTaxRate)/100).toFixed(2);
        }
        if (customerData.customerDetail != null && customerData.customerDetail.bMonthlyActive != null && customerData.customerDetail.bMonthlyActive) {
            // change plan
            orderJson[0].Amount = selectedULPlan.dblAmount == null ? 0 : selectedULPlan.dblAmount;
            orderJson[0].TaxAmount = parseFloat((selectedULPlan.dblAmount * selectedULPlan.dblTaxRate)/100).toFixed(2);
            orderJson[0].GrandTotal= parseFloat(parseFloat(orderJson[0].SubTotal) + parseFloat(orderJson[0].TaxAmount)).toFixed(2);
        } else {
            // new paln
            orderJson[0].bIsReactivation = false;
            orderJson[0].TaxAmount = parseFloat((selectedULPlan.surchargeamount * selectedULPlan.dblSurchargeTaxRate) / 100).toFixed(2);
            if (isReactivate == 'true') {
                orderJson[0].TaxAmount = parseFloat((selectedULPlan.dblAmount * selectedULPlan.dblTaxRate) / 100).toFixed(2);
            }
            orderJson[0].Amount = selectedULPlan.surchargeamount;
            const cSharpNullDateInSQL = new Date(null);
            const currentDate = new Date();
            const monthlyNextPay = new Date(customerInfo?.customerDetail?.dtMonthlyNextPay);
            const DifferenceInTime = monthlyNextPay.getTime() - currentDate.getTime();
            const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
            if (customerInfo.customerDetail !=null && customerInfo.customerDetail.dtMonthlyNextPay != null && customerInfo.customerDetail.dtMonthlyNextPay != cSharpNullDateInSQL) {
                if (customerInfo.location.iInactiveDaysForReactivation < DifferenceInDays) {
                    orderJson[0].bIsReactivation = true;
                } else {
                    orderJson[0].bIsReactivation = false;
                }
            } else {
                orderJson[0].bIsReactivation = false;
            }
            if (isNewUnlimited == 'true' || selectedULPlan.bRecurring != true) {
                if (selectedULPlan.bTaxIncluded) {
                    if (selectedULPlan.bRecurring != true || (isReactivate == 'false' && selectedULPlan.bRecurring == true)) {
                        orderJson[0].SubTotal = selectedULPlan.surchargeamountWithoutTax;
                        orderJson[0].TaxAmount = (selectedULPlan.dblSurchargeTaxRate != null && selectedULPlan.dblSurchargeTaxRate > 0) ? parseFloat(selectedULPlan.surchargeamount - selectedULPlan.surchargeamountWithoutTax).toFixed(2) : 0;
                        orderJson[0].GrandTotal = parseFloat(selectedULPlan.surchargeamount).toFixed(2);
                    } else {
                        orderJson[0].TaxAmount = selectedULPlan.bTaxable ? parseFloat((selectedULPlan.dblAmount * selectedULPlan.dblTaxRate)/100).toFixed(2) : 0;
                        orderJson[0].SubTotal = selectedULPlan.dblAmount - parseFloat(orderJson[0].TaxAmount);
                        orderJson[0].GrandTotal= parseFloat(selectedULPlan.dblAmount + parseFloat(orderJson[0].TaxAmount)).toFixed(2);
                    }
                } else {
                    orderJson[0].TaxAmount = (selectedULPlan.dblSurchargeTaxRate != null && selectedULPlan.dblSurchargeTaxRate > 0) ? parseFloat((selectedULPlan.surchargeamount * selectedULPlan.dblSurchargeTaxRate)/100).toFixed(2) : 0;
                    orderJson[0].SubTotal = parseFloat(selectedULPlan.surchargeamountWithoutTax).toFixed(2);
                    orderJson[0].GrandTotal= parseFloat(parseFloat(orderJson[0].TaxAmount) + parseFloat(orderJson[0].SubTotal)).toFixed(2);
                }
            } else {
                if (selectedULPlan.bRecurring == true && isReactivate == 'true') {
                    orderJson[0].TaxAmount = selectedULPlan.bTaxable ? parseFloat((selectedULPlan.dblAmount * selectedULPlan.dblTaxRate)/100).toFixed(2) : 0;
                    orderJson[0].SubTotal = selectedULPlan.dblAmount;
                    orderJson[0].GrandTotal= parseFloat(selectedULPlan.dblAmount + parseFloat(orderJson[0].TaxAmount)).toFixed(2);
                }
            }
        }
        orderJson[0].Email= '';
        orderJson[0].AdditionalPageData= {
            postJson: {
                CustomerIdentityId: userInfo.customerIdentityID,
                CustomerGuid: userInfo.customerGuid,
                MonthlyAccountTypeId: vipAccountTypeId,
                NewMonthlyAccountTypeId: selectedULPlan.lAccountType,
                Alias: '',
                ccType: '',
                CreditCardNumber: null,
                ExpiryDate: null,
                Price: selectedULPlan.dblAmount,
                Discount_ID: 1,
                discountAmount: 0,
                VipAccountAmount: 50,
                VipAccountTax: 0,
                DiscountTax: 0,
                isDiscountApplied: true,
                approval_code: ''
            },
            IsMonthlyActive: IsMonthlyActive,
            vipAccountTypeId: vipAccountTypeId,
            selectedULPlan: selectedULPlan,
            accessToken: accessToken
        };

        if (IsMonthlyActive) {
            // calculate pro rata amount
            const response = await getCustomerProRateAmountMobile(accessToken, {
                CustomerGuid: userInfo.customerGuid,
                ConfigurationUniqId: customerData.customer.configurationUniqId,
                MonthlyAccountTypeId: vipAccountTypeId,
                NewMonthlyAccountTypeId: selectedULPlan.lAccountType});

            if (response.status == 200) {
                orderJson[0].AdditionalPageData.postJson.Price= response.data;
                props.goToUWCCheckout(orderJson);
                setLoader(false);
            } else if (response.status === (401 | 404 | 470)) {
                setLoader(false);
                toast.warning(`${response.data.title}.`, {autoClose: 3000});
            } else {
                setLoader(false);
                toast.error('Some error occurred.', {autoClose: 3000});
            }
        } else {
            setLoader(false);
            props.goToUWCCheckout(orderJson);
        }
    };

    const showUPConfimationPopup = (newSelectedPlan) => {
        orderJson[0].termsId = 5;
        if (IsMonthlyActive) {
            let planType = 'upgrade';
            if (currentCustomerULPlan.dblAmount > newSelectedPlan.dblAmount) {
                planType = 'downgrade';
            }
            setAlertModalData({
                modalClass: 'alert_pop change_plan',
                title: 'Do you want to '+ planType +' current unlimited plan' + '?',
                message: currentCustomerULPlan.sDescription,
                message2: newSelectedPlan.sDescription,
                btnSuccessText: 'Yes',
                btnCancelText: 'No',
                btnPrimary: true
            });
        } else {
            setAlertModalData({
                modalClass: 'alert_pop purchase_plan',
                title: 'Purchase Unlimited plan',
                message: 'Do you want to purchase ' + newSelectedPlan.sDescription + ' plan? ',
                btnSuccessText: 'Yes',
                btnCancelText: 'No',
                btnPrimary: true
            });
        }
        setSelectedULPlan(newSelectedPlan);
    };

    const selectPlan = (newSelectedPlan) => {
        dispatch(setSelectedPlan({selectedPlan: newSelectedPlan}));
        showUPConfimationPopup(newSelectedPlan);
    };

    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 4; index++) {
            content.push(<SkeletonUnlimitedPlan key={index} />);
        }
        return content;
    };

    return (
        <div>
            <div>
                {modal ?
                    <AlertModalModule
                        data={alertModalData}
                        plates={plates}
                        targetEvent={() => showULPlanCheckoutView()}
                        closeModal={() => {
                            setModal(false);
                        }}
                        selectedVehiclePlateInfo={props.selectedVehiclePlateInfo}
                        setSelectedVehiclePlateInfo={props.setSelectedVehiclePlateInfo}
                    /> : null }
                {Loader === true ? <FullScreenLoader /> : ''}
                <div className='more_plans'>
                    {isLoadUlPlan && contentLoader()}
                    {unlimitedPlanData.length === 0 && isLoadUlPlan == false&&<p className='notFound alert alert-info'>Unlimited plans not available.</p>
                    }
                    {unlimitedPlanData!= null && !isLoadUlPlan ? unlimitedPlanData.map((plan, index) => {
                        console.log('plan', plan);
                        if (currentCustomerULPlan!= null && plan.lAccountType == currentCustomerULPlan.lAccountType && IsMonthlyActive) {
                            return (
                                <div className="form-check" id={`${index}`} key={index}>
                                    <input className="form-check-input" disabled type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <div className="purchase_check current_pc">
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            <div className='up_plan_details'>
                                                <div className='up_inner'>
                                                    <h5>{plan.sDescription}<span className='pc_best_value'>best value</span></h5>
                                                    {plan?.sSubDescriptionList != null ?
                                                        <ul className='ws_extra_services mt-0'>
                                                            <li><small>{plan.sSubDescription}</small></li>
                                                            {plan?.sSubDescriptionList.split(',').map((desc, index)=>{
                                                                return (
                                                                    <li key={index}>{desc}</li>
                                                                );
                                                            })}
                                                        </ul>:''
                                                    }
                                                </div>
                                            </div>
                                            {customerData.customerDetail !=null && customerData.customerDetail.bMonthlyActive != null && customerData.customerDetail.bMonthlyActive && customerData.vipAccountType.bUnlimitedRecurring ?
                                                <span>Monthly Charge : {(plan.dblAmount) != undefined ? currencyFormat(plan.dblAmount) : (plan.dblAmount)}</span> :
                                                <span>{(plan.surchargeamount) != undefined && (plan.surchargeamount) != null ? currencyFormat(plan.surchargeamount) : (plan.dblAmount)}</span>
                                            }
                                        </label>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="form-check" id={`${index}`} key={index}>
                                    <input className="form-check-input" onClick={()=>{
                                        selectPlan(plan);
                                        setModal(true);
                                    }} type="radio" name="flexRadioDefault" id={`plan${index}`} />
                                    <div className="purchase_check">
                                        <label className="form-check-label" htmlFor={`plan${index}`}>

                                            <div className='up_plan_details'>
                                                <div className='up_inner'>
                                                    <h5>{plan.sDescription}</h5>
                                                    {plan?.sSubDescriptionList != null ?
                                                        <ul className='ws_extra_services mt-0'>
                                                            <li><small>{plan.sSubDescription}</small></li>
                                                            {plan?.sSubDescriptionList.split(',').map((desc, index)=>{
                                                                return (
                                                                    <li key={index}>{desc}</li>
                                                                );
                                                            })}
                                                        </ul>:''
                                                    }
                                                </div>
                                            </div>
                                            {isReactivate == 'true' ?
                                                <span>{(plan.dblAmount) != undefined ? currencyFormat(plan.dblAmount) : (plan.dblAmount)}</span> :
                                                (customerData.customerDetail !=null && customerData.customerDetail.bMonthlyActive != null && customerData.customerDetail.bMonthlyActive) ?
                                                    <span>{(plan.dblAmount) != undefined ? currencyFormat(plan.dblAmount) : (plan.dblAmount)}</span> :
                                                    <span>{(plan.surchargeamount) != undefined && (plan.surchargeamount) != null ? currencyFormat(plan.surchargeamount) : (plan.dblAmount)}</span>
                                            }
                                        </label>
                                    </div>
                                </div>
                            );
                        }
                    }) : ''}
                </div>
            </div>
        </div>
    );
};

export default AccountUnlimitedPlan;
