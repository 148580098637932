import axios from 'axios';
import {config} from '../utils/config/config';
export const seqLogger = async (reqBody) =>{
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Seq-ApiKey': config.seqApiKey
    };
    return await axios.post(config.seqUrl, reqBody, {headers}).catch((error)=>{
        return error.response;
    });
};
