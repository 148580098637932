import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import Checkout from '@components/buy-module-components/checkout/Checkout';
import CheckoutSuccess from '@components/buy-module-components/checkout/CheckoutSuccess';
import LayoutBuy from '@components/LayoutBuy';
// Services
import {buyUnlimitedPlanQuickBuy, unlimitedWashServices} from '@services/buy-module-services/unlimited-wash-service';
// Reducers
import {setBarcodeString, setPurchaseDate} from '@store/reducers/global-reducer';
import {setSelectedPlan} from '@store/reducers/order-reducer';
import {setServices} from '@store/reducers/prepaid-wash-reducer';
// Utils
import {getNoLoginCheckoutJSON, getPCIDetailsLocal} from '@utils/common-repo';
// import ServiceCardLoader from '@utils/loaders/ServiceCardLoader';
import {currencyFormat} from '@utils/common-helper';

import AlertModalModuleBuy from '../../components/modals/AlertModalBuy';
import SkeletonUnlimitedPlan from '@utils/skeletons/SkeletonUnlimitedPlan';


const BuyUnlimitedPlan = (props) => {
    toast.configure();
    const toastId = React.useRef(null);
    const [modal, setModal] = useState(false);
    const [alertModalData, setAlertModalData] = useState({});
    const dispatch = useDispatch();
    const services = useSelector((state) => state.prepaidWash.services);
    console.log('services', services);
    const selectedPlan= useSelector((state) => state.order.selectedPlan);
    // const [productTypeId, setProductTypeId] = useState(selectedPlan!=null ? selectedPlan.productTypeId : -1);
    // const [isLoading, setIsLoading] = useState(false);
    const orderJson= useState(getNoLoginCheckoutJSON());
    const [isCheckoutShow, setIsCheckoutShow] = useState(false);
    const [isLoadUlPlan, setIsLoadUlPlan]= useState(true);
    // const [isBuyNow, setIsBuyNow] = useState(false);
    const customerCode = localStorage.getItem('customerCode');
    const [viewCheckoutSuccess, setViewCheckoutSuccess] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const buylocationId = localStorage.getItem('buyLocationId');
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');

    const PCIScript= getPCIDetailsLocal().PCIScript;
    useEffect(() => {
        const getServies = async () => {
            if (services.length === 0 || services.length == undefined) {
                setIsLoadUlPlan(true);
                const response = await unlimitedWashServices(customerCode);
                if (response.status === 200) {
                    setIsLoadUlPlan(false);
                    dispatch(setServices({services: response.data.products}));
                }
            }
        };
        getServies();
        const script = document.createElement('script');
        script.src = PCIScript;
        script.async = false;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSelectPlan = (service) => {
        console.log('sel', service);
        setAlertModalData({
            modalClass: 'alert_pop purchase_plan',
            title: 'Purchase Unlimited plan',
            message: 'Do you want to purchase ' + service.description + ' plan? ',
            btnSuccessText: 'Yes',
            btnCancelText: 'No',
            btnPrimary: true
        });
        setModal(true);
        // setIsBuyNow(true);
        orderJson[0].TopHeaderText= 'Buy Unlimited Wash Plan';
        orderJson[0].SubTotal= service.surchargeamountWithoutTax;
        orderJson[0].ListItemText= 'Unlimited plan';
        orderJson[0].ListItemDescription= service.description;
        orderJson[0].TaxAmount = service.taxAmount;
        dispatch(setSelectedPlan({selectedPlan: service}));
        // setProductTypeId(service.productTypeId);
    };
    const showULPlanCheckoutView = () => {
        orderJson[0].GrandTotal= (parseFloat(orderJson[0].SubTotal) + parseFloat(orderJson[0].TaxAmount ?? 0)).toFixed(2);
        orderJson[0].IsShowRecieveEmailReciept= false;
        orderJson[0].IsShowRecieveSMSReciept= false;
        orderJson[0].TermsId = 5;
        setIsCheckoutShow(true);
        setModal(false);
    };
    const buyUnlimitedPlanCheckout = async (order, ccDetails) => {
        if (order.IsCheckedTermAndCondition) {
            const postModel= {};
            postModel.productTypeId= selectedPlan.productTypeId;
            postModel.phoneNumber= ccDetails.phoneNumber;
            // eslint-disable-next-line camelcase
            postModel.temporary_token= ccDetails.temporary_token;
            postModel.unixTime= ccDetails.unixTimeStamp;
            postModel.Span = ccDetails.Span;
            postModel.Bin = ccDetails.sBin;
            postModel.amount= selectedPlan.price;
            postModel.creditCardNumber= '';
            postModel.cardHolderName= '';
            // postModel.expiryDate= ccDetails.xExp;
            postModel.alias= '';
            postModel.LocationId = buylocationId;
            if (gateWayType == 'cardknox') {
                postModel.expiryDate = ccDetails.xExp;
                postModel.xExp = ccDetails.xExp;
            } else {
                postModel.expiryDate = '';
            }
            // setIsBuyNow(true);
            const response = await buyUnlimitedPlanQuickBuy(customerCode, '', postModel);
            // setIsBuyNow(false);
            setPhoneNumber(postModel.phoneNumber);
            if (response.status === 200) {
                dispatch(setBarcodeString({barcodeString: response.data.sBarcode}));
                dispatch(setPurchaseDate({purchaseDateString: response.data.dtCreated}));
                setViewCheckoutSuccess(true);
                setIsCheckoutShow(false);
            } else if (response.status === 400) {
                toast.error(response.data, {autoClose: 3000});
                return false;
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
                return false;
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card before checkout.');
            }
            return false;
        }
    };
    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 4; index++) {
            content.push(<SkeletonUnlimitedPlan key={index} />);
        }
        return content;
    };
    return (
        <LayoutBuy>
            {viewCheckoutSuccess ? <CheckoutSuccess order={orderJson} Annomous={true} phoneNumber = {phoneNumber}/> : isCheckoutShow ? <Checkout processCheckout={(o, cc) => buyUnlimitedPlanCheckout(o, cc)} hideCheckoutPage={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                setIsCheckoutShow(false);
            }} order={orderJson} /> :
                <div className="container">
                    {modal ?
                        <AlertModalModuleBuy
                            data={alertModalData}
                            targetEvent={() => showULPlanCheckoutView()}
                            closeModal={() => {
                                setModal(false);
                            }}
                        /> : null }
                    <div className="row">
                        <div className='col-12 mb-3'>
                            <Link to='/buy' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                        </div>
                    </div>
                    <div className='main_title pup_title'>
                        <h2>Purchase Unlimited Plan</h2>
                    </div>
                    <div className='purchasechckbox'>
                        <div className='more_plans'>
                            {isLoadUlPlan && contentLoader()}
                            {!isLoadUlPlan&&services.length > 0 && services.map((service, index) => {
                                if (service.price !== 0) {
                                    return (
                                        <div className="form-check" id={`${index}`} key={index}>
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id={`plan${index}`} onClick={()=>{
                                                onSelectPlan(service);
                                            }} />
                                            <div className="purchase_check">
                                                <label className="form-check-label" htmlFor={`plan${index}`}>
                                                    <div className='up_plan_details'>
                                                        <div className='up_inner'>
                                                            <h5>{service.description}</h5>
                                                            {service?.serviceList != null ?
                                                                <ul className='ws_extra_services mt-0'>
                                                                    <li><small>It includes</small></li>
                                                                    {service?.serviceList.map((desc, index)=>{
                                                                        return (
                                                                            <li key={index}>{desc}</li>
                                                                        );
                                                                    })}
                                                                </ul>:''
                                                            }
                                                        </div>
                                                    </div>
                                                    <span>{currencyFormat(service.price)}</span>
                                                </label>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            }
        </LayoutBuy>
    );
};

export default BuyUnlimitedPlan;
