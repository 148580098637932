import {Link} from 'react-router-dom';
import React from 'react';
import {GetCreditCardIcon as getCCIcon} from '@utils/common-repo';
import {getCardTypeFromIIN} from '../../utils/common-repo';
import {useSelector} from 'react-redux';

const PaymentOptions = (props) => {
    const {expandPCIForm} = props;
    const creditCards = useSelector((state) => state.manageAccount.creditCards);
    const preferredCardDetails = creditCards.filter((creditCards) => creditCards.bIsRecurring);
    // const isCardknox = localStorage.getItem('isCardknox');
    const gateWayType = localStorage.getItem('gateWayType');
    let ccDetail = {};
    if (preferredCardDetails.length>0) {
        ccDetail= getCCIcon(preferredCardDetails[0]);
    }
    return (
        <>
            {creditCards != null && creditCards.length > 0 ?
                <div className="pi_um_detailcard paymethdcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Payment Options</h2>
                            {preferredCardDetails != null && preferredCardDetails.length > 0 ?
                                <small>Primary Card</small> : ''}
                        </div>
                        <div className="pi_um_cardcenter">
                            {preferredCardDetails != null && preferredCardDetails.length > 0 ?
                                <>
                                    <div className="">
                                        <h4>{getCardTypeFromIIN(preferredCardDetails[0]?.sBin)} ending with {preferredCardDetails[0].sSpan}</h4>
                                        <h3>Expires on: {preferredCardDetails[0].sExpiredMonth}/{preferredCardDetails[0].sExpireYear}</h3>
                                    </div>
                                    <div className="card_type">
                                        <span className={`card_icon ${ccDetail.icon}`}></span>
                                    </div>
                                </> :
                                <p>No primary card found.</p>
                            }
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li><Link to="/payment-options" className="link-primary">{preferredCardDetails != null && preferredCardDetails.length > 0 ? 'Change Card' : 'Manage Card'}</Link></li>
                    </ul>
                </div> :
                <div className="pi_um_detailcard paymethdcard">
                    <div>
                        <div className="pi_um_cardtop">
                            <h2 className="main_heading">Payment Method</h2>
                        </div>
                        <div className="pi_um_cardcenter">
                            <p>No card found.</p>
                        </div>
                    </div>
                    <ul className="pi_um_cardbottom">
                        <li>
                            {gateWayType == 'openEdge' &&
                                <Link to="#" data-bs-toggle='modal' data-bs-target='#addCardpop2' className="link-primary" onClick={(event) => expandPCIForm(event)}>+ Add Card</Link>
                            }
                            {gateWayType == 'cardknox' &&
                                <Link to="#" data-bs-toggle='modal' data-bs-target='#addCardpop3' className="link-primary">+ Add Card</Link>
                            }
                            {gateWayType == 'dataCap' &&
                                <Link to="#" data-bs-toggle='modal' data-bs-target='#addCardpop4' className="link-primary">+ Add Card</Link>
                            }
                        </li>
                    </ul>
                </div>
            }
        </>
    );
};

export default PaymentOptions;
