import {convertHexToHSL} from '../common-helper';

function overwriteSiteThemeColor(themeData) {
    for (let index = 0; index < themeData.length; index++) {
        if (themeData[index].sValue != null && themeData[index].sValue != undefined && themeData[index].sValue != '') {
            document.documentElement.style.setProperty('--' + themeData[index].sName, themeData[index].sValue);
            if (themeData[index].sName === 'site_cpv3_theme_color') {
                document.documentElement.style.setProperty('--_hue', convertHexToHSL(themeData[index].sValue));
            }
        }
    }
}

function overwriteSiteProductColors(themeData) {
    if (themeData != null && themeData != undefined && themeData != '') {
        if (themeData.sTitleColor != null && themeData.sTitleColor != undefined && themeData.sTitleColor != '') {
            document.documentElement.style.setProperty('--sTitleColor', themeData.sTitleColor);
        }
        if (themeData.sDescriptionTitleColor != null && themeData.sDescriptionTitleColor != undefined && themeData.sDescriptionTitleColor != '') {
            document.documentElement.style.setProperty('--sDescriptionTitleColor', themeData.sDescriptionTitleColor);
        }
        if (themeData.sCardBoxColor != null && themeData.sCardBoxColor != undefined && themeData.sCardBoxColor != '') {
            document.documentElement.style.setProperty('--sCardBoxColor', themeData.sCardBoxColor);
        }
        if (themeData.sPriceTextColor != null && themeData.sPriceTextColor != undefined && themeData.sPriceTextColor != '') {
            document.documentElement.style.setProperty('--sPriceTextColor', themeData.sPriceTextColor);
        }
        if (themeData.sSelecterBorderColor != null && themeData.sSelecterBorderColor != undefined && themeData.sSelecterBorderColor != '') {
            document.documentElement.style.setProperty('--sSelecterBorderColor', themeData.sSelecterBorderColor);
        }
        if (themeData.sDescriptionUnorderList != null && themeData.sDescriptionUnorderList != undefined && themeData.sDescriptionUnorderList != '') {
            document.documentElement.style.setProperty('--sDescriptionUnorderList', themeData.sDescriptionUnorderList);
        }
        if (themeData.sPriceBoxColor != null && themeData.sPriceBoxColor != undefined && themeData.sPriceBoxColor != '') {
            document.documentElement.style.setProperty('--sPriceBoxColor', themeData.sPriceBoxColor);
        }
    }
}

export {overwriteSiteThemeColor, overwriteSiteProductColors};
