/* eslint-disable sort-imports */
import {BrowserRouter, MemoryRouter, Route, Routes} from 'react-router-dom';
import {Provider, useDispatch} from 'react-redux';
import React, {useEffect} from 'react';
import App from './App';
import BuyCommonWashServices from './containers/buy-module-containers/BuyCommonWashServices';
import BuyGiftCard from './components/buy-module-components/gift-card/BuyGiftCard';
import BuyPolicy from './components/buy-module-components/BuyPolicies';
import BuyUnlimitedPlan from './containers/buy-module-containers/BuyUnlimitedPlan';
import DashboardBuy from './containers/buy-module-containers/DashboardBuy';
import Employee from './containers/Employee';
import ErrorPage from './containers/ErrorPage';
import Home from './containers/Home';
import ReactDOM from 'react-dom';
import TinyWalletBarcode from './containers/TinyWalletBarcode';
import {getPortalThemeColor} from '@services/auth-service';
import {overwriteSiteThemeColor} from './utils/config/site-theme-config';
import reportWebVitals from './reportWebVitals';
import {setLoginStatus} from './store/reducers/auth-reducer';
import store from './store/index';
import {convertHexToHSL} from './utils/common-helper';

const Startup = (props) => {
    const customerUrl = window.location.pathname.split('/').pop();

    // we set islogged in false when we call buy section
    const dispatch = useDispatch();
    if (customerUrl.length > 0 || window.location.pathname.toLowerCase() == '/buy') {
        if (customerUrl.toLowerCase() =='expresswcurl') {

        } else if (window.location.pathname.toLowerCase().includes('/wallet/details/')) {

        } else {
            delete localStorage['userInfo'];
            dispatch(setLoginStatus({isLoggedIn: false}));
        }
    };
    const setThemeColor = async (customerCode) => {
        const getPortalThemeColorResponse = await getPortalThemeColor(customerCode);
        if (getPortalThemeColorResponse.status === 200) {
            const colorValue = convertHexToHSL(getPortalThemeColorResponse.data.sValue);
            if (colorValue !== null && colorValue !== undefined && colorValue !== '') {
                localStorage.setItem('colorValue', colorValue);
                if (localStorage.getItem('colorValue') != null && localStorage.getItem('colorValue') != undefined) {
                    const colorValue = localStorage.getItem('colorValue');
                    document.documentElement.style.setProperty('--_hue', colorValue);
                } else {
                    document.documentElement.style.setProperty('--_hue', colorValue);
                }
            } else {
                if (localStorage.getItem('colorValue') != null && localStorage.getItem('colorValue') != undefined) {
                    const colorValue = localStorage.getItem('colorValue');
                    document.documentElement.style.setProperty('--_hue', colorValue);
                } else {
                    document.documentElement.style.setProperty('--_hue', 192);
                }
                let customizePageData = JSON.parse(localStorage.getItem('customizePage'));
                const siteCpv3 = {...customizePageData.portalCustomizationsList.find((x)=>x.sName === 'site_cpv3_theme_color')};
                siteCpv3.sValue = '192';
                const portalCustomizationsList = JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.map((x)=>x.sName === 'site_cpv3_theme_color' && x.sValue === ''? siteCpv3 : x );
                customizePageData = {...customizePageData, portalCustomizationsList};
                localStorage.setItem('customizePage', JSON.stringify(customizePageData));
            }
        } else {
            if (localStorage.getItem('colorValue') != null && localStorage.getItem('colorValue') != undefined) {
                const colorValue = localStorage.getItem('colorValue');
                document.documentElement.style.setProperty('--_hue', colorValue);
            } else {
                document.documentElement.style.setProperty('--_hue', 192);
            }
            let customizePageData = JSON.parse(localStorage.getItem('customizePage'));
            const siteCpv3 = {...customizePageData.portalCustomizationsList.find((x)=>x.sName === 'site_cpv3_theme_color')};
            siteCpv3.sValue = '192';
            const portalCustomizationsList = JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.map((x)=>x.sName === 'site_cpv3_theme_color' && x.sValue === ''? siteCpv3 : x );
            customizePageData = {...customizePageData, portalCustomizationsList};
            localStorage.setItem('customizePage', JSON.stringify(customizePageData));
        }
    };
    if (localStorage.getItem('customizePage') != null && localStorage.getItem('customizePage') != undefined) {
        const customizePageData = JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList;
        overwriteSiteThemeColor(customizePageData);
        if (localStorage.getItem('customerCode') != null && localStorage.getItem('customerCode') != undefined) {
            const customerCode = localStorage.getItem('customerCode');
            setThemeColor(customerCode);
        }
    }
    useEffect(()=>{
        // localStorage.setItem('isCardknox', true);
        if (localStorage.getItem('title') != null && localStorage.getItem('title') != undefined) {
            document.title = localStorage.getItem('title');
        } else {
            document.title = 'Car Wash';
        }

        if (localStorage.getItem('colorValue') != null && localStorage.getItem('colorValue') != undefined) {
            const colorValue = localStorage.getItem('colorValue');
            document.documentElement.style.setProperty('--_hue', colorValue);
        } else {
            document.documentElement.style.setProperty('--_hue', 192);
        }
    }, []);
    return (
        <React.StrictMode>
            {
                (customerUrl.length > 0 || window.location.pathname.toLowerCase() == '/buy' && window.location.pathname.toLowerCase() == '/employee') ?
                    <BrowserRouter>
                        <Routes>
                            {/* Here "*" is added in id to accept any string as id */}
                            <Route path="/Wallet/Details/:id*" element={<TinyWalletBarcode />} />
                            <Route path='/buy' element={<DashboardBuy />} />
                            <Route path='/buy/gift_card' element={<BuyGiftCard />} />
                            <Route path='/buy/buy_Policy' element={<BuyPolicy />} />
                            <Route path='/buy/prepaid_wash_service' element={<BuyCommonWashServices page="prepaid_service" pageTitle="Buy prepaid wash plan" pageSubTitle="Prepaid wash plan" pageHeading="Buy a prepaid wash" />} />
                            <Route path='/buy/detailing_services' element={<BuyCommonWashServices page="detail_service" pageTitle="Buy a detailing service plan" pageSubTitle="Detailing service plan" pageHeading="Buy a detailing service" />} />
                            <Route path='/buy/wash_services' element={<BuyCommonWashServices page="wash_service" pageTitle="Buy a wash service plan" pageSubTitle="Wash service plan" pageHeading="Buy a wash service" />} />
                            <Route path='/buy/unlimited_wash_club_box' element={<BuyUnlimitedPlan />} />
                            <Route path='/buy/:id' element={<Home page={'buy'} />} />
                            <Route path='/:id' element={<Home />} />
                            <Route path='/employee' element={<Employee page={'employeelogin'}/>} />
                            <Route path='*' exact={true} element={<ErrorPage />} />
                        </Routes>
                    </BrowserRouter> :
                    <MemoryRouter>
                        <App />
                    </MemoryRouter>
            }
        </React.StrictMode>
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Startup />
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
