import React from 'react';
// Utils
import {currencyFormat} from '@utils/common-helper';
import {isNumeric} from '@utils/common-repo';
// Assets
import GCLogo from '@assets/images/logo_white.png';

const GiftCard = (props) => {
    const giftCardLogo = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_card_logo');
    const giftCardImg = giftCardLogo?.sValue || GCLogo;
    return (
        <div className="gift_card_preview gc_bg">
            <div className="gc_logo"><img src={giftCardImg} className="img-fluid" alt="" /></div>
            <div className="gc_price">{isNumeric(props.amount) != undefined ? currencyFormat(props.amount == undefined ? 0 : props.amount) : (props.amount)}</div>
        </div>
    );
};

export default GiftCard;
