import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FiArrowLeft} from 'react-icons/fi';
import {toast} from 'react-toastify';
// Utils
import {currencyFormat, seqReqBodyObj} from '@utils/common-helper';
import FullScreenLoader from '@utils/loaders/FullScreenLoader';
// Components
import GiftCard from './GiftCard';
import Layout from '@components/Layout';
// Services
import {giftCardList, mergeGiftCard} from '@services/gift-card-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setGiftCards} from '@store/reducers/gift-card-reducer';
import {setwalletServices} from '@store/reducers/wallet-reducer';
// Assets
import GCLogo from '@assets/images/logo_white.png';

const MergeGiftCard = (props) => {
    toast.configure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cardDetail = useSelector((state) => state.giftCard.selectedGiftCard);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const giftCards = useSelector((state) => state.giftCard.giftCards);
    const [amount, setAmount] = useState(cardDetail.dblBalance);
    const [Loader, setLoader] = useState(false);
    const [showNotAvailable, setShowNotAvailable] = useState(false);
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));

    const accessToken = userInfo.accessToken;

    const mergeCardBalance = async (param) => {
        dispatch(setGiftCards({giftCards: []}));
        setAmount((amount + param.dblBalance));
        setLoader(true);
        const reqBody = {
            fromGiftCard_Number: cardDetail.sGiftCardNumber,
            toGiftCard_Number: param.sGiftCard_Number,
            CustomerIdentityID: userInfo.customerIdentityID,
            customerGUID: userInfo.customerGuid
        };
        const customerCode=localStorage.getItem('customerCode');
        const response = await mergeGiftCard(accessToken, reqBody, customerCode);
        if (response.status === 200) {
            dispatch(setwalletServices({services: []}));
            setLoader(false);
            toast.success('Card balance merged successfully.', {autoClose: 3000});
            mergeGCData(accessToken);
            const seqReqBody = seqReqBodyObj('Information', `Card balance merged successfully`, `${customerDetail.customer.sPhone}`);
            await seqLogger(seqReqBody);
            return navigate('/gift_card');
        } else {
            setLoader(false);
            toast.error('Something went wrong.', {autoClose: 3000});
        }
    };
    const mergeGCData = async (accessToken) => {
        const response = await giftCardList(accessToken, userInfo.customerIdentityID);
        if (response.status == 200) {
            setTimeout(() => {
                dispatch(setGiftCards({giftCards: response.data}));
            }, 1000);
        }
    };

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (giftCards.length<=1) {
            setShowNotAvailable(true);
        }
    }, []);
    return (
        <>
            {Loader === true ? <FullScreenLoader /> : ''}
            <Layout>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <Link to='/gift-card-details' className='btn btn-outline-secondary icon_btn'><FiArrowLeft/> Back</Link>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                                <div className='main_title'>
                                    <h2>Merge Balance</h2>
                                    <p>Merge gift cards balance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='pay_method_list'>
                                <div className='table-responsive border-bottom'>
                                    {giftCards.length<=1?'':
                                        <table className='table ccards_table  table-borderless'>
                                            <thead>
                                                <tr>
                                                    <th>Gift card details</th>
                                                    <th>Card Info</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {giftCards.length > 0 && giftCards.map((value, index) => {
                                                    if (value.sGiftCard_Number !== cardDetail.sGiftCardNumber) {
                                                        return (
                                                            <tr key={index}>
                                                                <td><div className='gift_card_name'>{value.dblBalance != undefined ? currencyFormat(value.dblBalance) : (value.dblBalance)} - Gift Card</div></td>
                                                                <td><div className='noc'>Ending in {value.sGiftCard_Number.substr(- 4)}</div></td>
                                                                <td><span to='#' className='view_btn' onClick={() => mergeCardBalance(value)}><span className='icon merge_icon' title="Merge Balance"></span></span></td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                    {showNotAvailable?
                                        <p className='notFound alert-info'>Gift Cards not available to merge balance.</p>:
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <GiftCard amount={amount} brandLogo={GCLogo} />
                        </div>
                    </div>
                </div>
                <div className='modal fade custom_pop success_pop' id='successModal' data-bs-backdrop='static' data-bs-keyboard='false' aria-labelledby='successModalLabel' aria-hidden='true'>
                    <div className='modal-dialog  modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-body'>
                                <h2>Success!</h2>
                                <p>Card Balance Merged Successfully done.</p>
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type='button' className='main_btn' data-bs-dismiss='modal'>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default MergeGiftCard;
