// import {Link} from 'react-router-dom';
import {FiArrowLeft} from 'react-icons/fi';
import React from 'react';
import parse from 'html-react-parser';
import {toast} from 'react-toastify';
// Components
import Layout from '../Layout';
import {useNavigate} from 'react-router-dom';


const Policies = (props) => {
    toast.configure();
    const policyDetail = JSON.parse(localStorage.getItem('policyData'));
    const navigate = useNavigate();
    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <button onClick={() => navigate(-1)} className="btn btn-outline-secondary icon_btn"><FiArrowLeft/> Back</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="policy_wrap mb-5 mt-4">
                            {policyDetail.length > 0 && policyDetail.filter((value) => value.contentId === props.id || value.contentId === props.id1).map((detail, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <h5>{parse(detail.title ?? '')}</h5><div>{parse(detail.contentDescription ?? '')}</div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Policies;
