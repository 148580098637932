import React, {useEffect, useState} from 'react';
const EmployeeSlider = (props) =>{
    const [isLoggedInFinishCls, setIsLoggedInFinishCls] = useState('');
    const [issubscriptionCls, setSubscriptionCls] = useState('');
    useEffect(() => {
        if (props.subscription) {
            setIsLoggedInFinishCls('finish');
        };
        if (props.checkout) {
            setIsLoggedInFinishCls('finish');
            setSubscriptionCls('finish');
        };
    }, []);
    return (
        <React.Fragment>
            <div className='col-12'>
                <div className='indicators'>
                    <span className={`step  ${props.isLoggedIn ? 'active':''} ${isLoggedInFinishCls}`}></span>
                    <span className={`step  ${props.subscription ? 'active':''} ${issubscriptionCls}`}></span>
                    <span className={`step  ${props.checkout ? 'active':''}`}></span>
                    <span className={`step `}></span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeSlider;
