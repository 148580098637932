import API from './api';

async function getCustomerInfoByGuid(accessToken, Guid, customeridentityid) {
    try {
        return await API.get(`Customers/GetCustomerCpv3/?customerGuid=${Guid}&customerIdentityId=${customeridentityid}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};
async function encrypt(accessToken, str) {
    try {
        return await API.get(`Customers/Encrypt/?plainData=${str}`, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error;
    }
};

async function putCustomerInfoByGuid(accessToken, data) {
    try {
        return await API.post(`Customers/UpdateCustomerProfileWeb`, data, {
            headers: {
                'X-Authentication-Token': accessToken,
                'CallingApplication': 'CPV3'
            }
        });
    } catch (error) {
        return error.response;
    }
};
export {getCustomerInfoByGuid, putCustomerInfoByGuid, encrypt};
