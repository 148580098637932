import React from 'react';
import SkeletonElement from './SkeletonElement';

const SkeletonGiftCardTable = (props) => {
    if (props.page.GiftCardTable) {
        return (
            <tr className="gift-card-table table-row-skeleton">
                <td><SkeletonElement type="text" /></td>
                <td><SkeletonElement type="text" /></td>
                <td><SkeletonElement type="text" /></td>
                <td><SkeletonElement type="text" /></td>
            </tr>
        );
    } else if (props.page.GiftCardAmount) {
        return (
            <button>
                <SkeletonElement type="text" />
            </button>
        );
    }
};


export default SkeletonGiftCardTable;
