function isLogin() {
    const userInfo = localStorage.getItem('userInfo');
    const isLoggedIn = userInfo !== null ? true : false;

    if (isLoggedIn !== null && isLoggedIn) {
        return true;
    } else {
        return false;
    }
}

export {isLogin};
