import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
// Components
import Layout from '@components/Layout';
// Services
import {customerUrlApi, customizationApi, policyData} from '@services/auth-service';
// Reducers
import {setCustomizePage} from '@store/reducers/auth-reducer';
import {setPortalCustomizationsList} from '@store/reducers/global-reducer';
// Utils
import LoginLoader from '@utils/loaders/LoginLoader';
// Assets
import CustomizationPage from '@assets/JSON/CustomizationPage.json';

const Home = (props) => {
    toast.configure();
    const urlParam = useParams();
    const dispatch = useDispatch();
    const [Loader, setLoader] = useState(false);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    useEffect(() => {
        if (isLoggedIn) {
            return window.location.href = '/';
        }
        const getCustomerCode = async () => {
            delete localStorage['allowAnnomous'];
            setLoader(true);
            const customerUrl = urlParam.id;
            const page = props.page == undefined ? '' : String(props.page).toLowerCase();
            localStorage.clear();
            if (customerUrl === '' || customerUrl === undefined) {
                const customerCode = localStorage.getItem('customerCode');
                if (page == 'buy' && customerCode !== '' && customerCode !== undefined) {
                    window.location.href = '/buy';
                }
                if (page === 'employeelogin' && customerCode !== '' && customerCode !== undefined) {
                    window.location.href = '/employee';
                }
                toast.error('Incorrect URL.', {autoClose: 3000});
                return window.location.href = '/';
            }
            const customerCode = await customerUrlApi(customerUrl);
            if (customerCode.status === 200) {
                const policyDataResponse = await policyData(customerCode.data);
                if (policyDataResponse.status == 200) {
                    localStorage.setItem('policyData', JSON.stringify(policyDataResponse.data));
                } else {
                    localStorage.setItem('policyData', ' ');
                }
                localStorage.setItem('customerCode', customerCode.data);
                localStorage.setItem('customerUrl', customerUrl);
                const responce = await customizationApi(customerCode.data);
                if (responce.status == undefined) {
                    setLoader(false);
                    localStorage.setItem('customizePage', JSON.stringify(CustomizationPage));
                    dispatch(setCustomizePage({customizePage: CustomizationPage}));
                    dispatch(setPortalCustomizationsList({portalCustomizationsList: CustomizationPage.portalCustomizationsList}));
                    if (page == 'buy') {
                        localStorage.setItem('allowAnnomous', true);
                        return window.location.href = '/buy';
                    }
                    return window.location.href = '/';
                } else if (responce.status == 200) {
                    setLoader(false);
                    localStorage.setItem('customizePage', JSON.stringify(responce.data));
                    dispatch(setCustomizePage({customizePage: responce.data}));
                    dispatch(setPortalCustomizationsList({portalCustomizationsList: responce.data.portalCustomizationsList}));
                    setLoader(false);
                    const cpv3Enabled = responce.data.portalSettingsList.find((x)=>x.name.toUpperCase() == 'BCPV3ENABLED')?.value || 0;
                    if (cpv3Enabled == '1') {
                        if (page == 'buy') {
                            localStorage.setItem('allowAnnomous', true);
                            return window.location.href = '/buy';
                        }
                        return window.location.href = '/';
                    } else {
                        delete localStorage['customerCode'];
                        localStorage.setItem('errMsgType', 0);
                        setLoader(false);
                        return window.location.href = '/';
                    }
                } else {
                    setLoader(false);
                    if (page == 'buy') {
                        localStorage.setItem('allowAnnomous', true);
                        return window.location.href = '/buy';
                    }
                    return window.location.href = '/';
                }
            } else {
                delete localStorage['customerCode'];
                localStorage.setItem('errMsgType', 1);
                setLoader(false);
                return window.location.href = '/';
            }
        };
        getCustomerCode();
    }, []);
    return (
        <Layout>
            {Loader === true ? <LoginLoader /> : ''}
        </Layout>
    );
};

export default Home;
