import * as Yup from 'yup';
const nameRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export default Yup.object().shape({
    firstName: Yup.string().required('First Name is required.')
        .test('valid', 'Special characters are not allowed.', (val = '') => {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'First Name must have maximum 25 characters.'),
    lastName: Yup.string().required('Last Name is required.')
        .test('valid', 'Special characters are not allowed.', (val = '') => {
            if (nameRegExp.test(val)) {
                return false;
            } else {
                return true;
            }
        }).max(25, 'Last Name must have maximum 25 characters.')
});
