import React from 'react';
import {useSelector} from 'react-redux';
// Components
import Footer from './layout/Footer';
import Header from './layout/Header';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';

const Layout = (props) => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isLoading = useSelector((state) => state.global.isLoading);

    return (
        <React.Fragment>
            {isLoading ? <FullScreenLoader /> : ''}
            {isLoggedIn ? <Header /> : ''}
            <div className='main_wrap'>{props.children}</div>
            {isLoggedIn ? <Footer /> : ''}
        </React.Fragment>
    );
};

export default Layout;
