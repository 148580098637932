import React, {useEffect, useState} from 'react';
import {setSelectedWallet, setwalletServices} from '@store/reducers/wallet-reducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import dateFormatter from '@utils/date-formatter';
import {toast} from 'react-toastify';
// Components
import Layout from '@components/Layout';
// Services
import {seqLogger} from '@services/seq-logger-service';
import {wallet} from '@services/wallet-service';
// Utils
import SkeletonGiftNWashCard from '@utils/skeletons/SkeletonGiftNWashCard';
import {convertUTCDateToLocalDate} from '@utils/date-formatter';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './Wallet.style.css';

const Wallet = (props) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const services = useSelector((state) => state.wallet.services);
    const selectedWalletDetail = useSelector((state) => state.wallet.selectedWallet);
    const [isLoading, setIsLoading] = useState(services.length ? false : true);
    const [selectMyself, setSelectedMyself] = useState(false);
    const [selectForSomeone, setSelectedForSomeone] = useState(false);
    const [pageServices, setPageServices] = useState([]);
    const [cardType, setCardType] = useState('');
    const [isActive, setIsActive] = useState('');
    const [filterType, setFilterType] = useState(selectedWalletDetail.walletFilterType==undefined?'All':selectedWalletDetail.walletFilterType);
    const phoneNumber = localStorage.getItem('phoneNumber');

    useEffect(() => {
        const getServices = async () => {
            setPageServices(services);
            if (services.length === 0) {
                const response = await wallet(userInfo.accessToken, userInfo.customerIdentityID);
                if (response.status === 200) {
                    setTimeout(() => {
                        setIsLoading(false);
                        const shortWalletData = response.data.sort((a, b) => new Date(b.walletDate) - new Date(a.walletDate));
                        dispatch(setwalletServices({services: shortWalletData}));
                        setPageServices(shortWalletData);
                    }, 1000);
                    const seqReqBody = seqReqBodyObj('Information', `Wallet details retrieved successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                } else {
                    setIsLoading(false);
                    toast.error('Something went wrong.', {autoClose: 3000});
                }
            }
        };
        getServices();
        handleFilters(filterType, '', '');
    }, []);

    const handleFilters = async (filter, cardFor, type) => {
        if (filter == '') {
            filter = filterType;
        }
        if (cardFor == '') {
            cardFor = cardType;
        }
        if (type == '') {
            type = isActive;
        }

        setSelectedMyself(false);
        setSelectedForSomeone(false);
        setIsActive('');
        setCardType('');
        if (filter == 'All') {
            setFilterType('All');
            setIsActive('');
            setCardType('');
            if (type == 'redeemed') {
                setIsActive('redeemed');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false;
                    });
                    setPageServices(filteredServices);
                }
            } else if (type == 'redeemable') {
                setIsActive('redeemable');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true;
                    });
                    setPageServices(filteredServices);
                }
            }
            if (cardFor == '') {
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === false;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === true;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services;
                    setPageServices(filteredServices);
                }
            }
            if (cardFor == 'mySelf') {
                setSelectedMyself(true);
                setSelectedForSomeone(false);
                setCardType('mySelf');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false;
                    });
                    setPageServices(filteredServices);
                }
                return;
            } else if (cardFor == 'someOneElse') {
                setSelectedMyself(false);
                setSelectedForSomeone(true);
                setCardType('someOneElse');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true;
                    });
                    setPageServices(filteredServices);
                }
                return;
            }
        } else if (filter == 'WashCard') {
            setFilterType('WashCard');
            setIsActive('');
            setCardType('');
            if (type == 'redeemed') {
                setIsActive('redeemed');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                }
            } else if (type == 'redeemable') {
                setIsActive('redeemable');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                }
            }
            if (cardFor == '') {
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            }
            if (cardFor == 'mySelf') {
                setSelectedMyself(true);
                setSelectedForSomeone(false);
                setCardType('mySelf');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            } else if (cardFor == 'someOneElse') {
                setSelectedMyself(false);
                setSelectedForSomeone(true);
                setCardType('someOneElse');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.type !== 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            }
        } else {
            setFilterType('GiftCard');
            setIsActive('');
            setCardType('');
            if (type == 'redeemed') {
                setIsActive('redeemed');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                }
            } else if (type == 'redeemable') {
                setIsActive('redeemable');
                if (cardFor == 'mySelf') {
                    setSelectedMyself(true);
                    setSelectedForSomeone(false);
                    setCardType('mySelf');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else if (cardFor == 'someOneElse') {
                    setSelectedMyself(false);
                    setSelectedForSomeone(true);
                    setCardType('someOneElse');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                }
            }
            if (cardFor == '') {
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.active === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            }
            if (cardFor == 'mySelf') {
                setSelectedMyself(true);
                setSelectedForSomeone(false);
                setCardType('mySelf');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.active === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            } else if (cardFor == 'someOneElse') {
                setSelectedMyself(false);
                setSelectedForSomeone(true);
                setCardType('someOneElse');
                if (type == 'redeemed') {
                    setIsActive('redeemed');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === false && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else if (type == 'redeemable') {
                    setIsActive('redeemable');
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.active === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                } else {
                    const filteredServices = services.filter((curElem) => {
                        return curElem.forSomeOneElse === true && curElem.type === 1;
                    });
                    setPageServices(filteredServices);
                }
                return;
            }
        }
    };

    const handleSelectedWallet = (wallet) => {
        const upDatedWallet = {...wallet, pageBackUrl: '/wallet', walletFilterType: filterType};
        dispatch(setSelectedWallet({selectedWallet: upDatedWallet}));
    };

    const pageServicesArr = pageServices.map((obj) => {
        return {...obj, walletDate: new Date(obj.walletDate)};
    });

    const sortedDescPageServicesArr = pageServicesArr.sort(
        (objA, objB) => new Date(objB.walletDate) - new Date(objA.walletDate)
    );

    return (
        <Layout>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                            <div className='main_title'>
                                <h2>Wallet</h2>
                                <p>Manage your wallet</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='nav custom_tabs wallet_tabs mb-3' id='pills-tab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link ${filterType == 'All' ? 'active' : ''}`} id='pills-all-tab' data-bs-toggle='pill' data-bs-target='#pills-all' type='button' role='tab' aria-controls='pills-all' aria-selected='true' onClick={() => {
                                    handleFilters('All', '', '');
                                    setIsActive('');
                                    setCardType('');
                                    setSelectedForSomeone('');
                                    setSelectedMyself('');
                                    setPageServices(services);
                                }}>All</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link ${filterType == 'WashCard' ? 'active' : ''}`} id='pills-washcard-tab' data-bs-toggle='pill' data-bs-target='#pills-washcard' type='button' role='tab' aria-controls='pills-washcard' aria-selected='true' onClick={() => {
                                    handleFilters('WashCard', '', '');
                                }}>Wash Card</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link ${filterType == 'GiftCard' ? 'active' : ''}`} id='pills-giftcard-tab' data-bs-toggle='pill' data-bs-target='#pills-giftcard' type='button' role='tab' aria-controls='pills-giftcard' aria-selected='false' onClick={() => {
                                    handleFilters('GiftCard', '', '');
                                }}>Gift Card</button>
                            </li>
                            <li className='nav-item ms-auto d-inline-flex gap-1'>
                                <div className='sw_btn_grp d-flex'>
                                    <button className={`nav-link ${isActive == 'redeemable' ? 'active' : ''}`} to='#' onClick={() => handleFilters('', '', 'redeemable')}>Redeemable</button>
                                    <button className={`nav-link ${isActive == 'redeemed' ? 'active' : ''}`} to='#' onClick={() => handleFilters('', '', 'redeemed')}>Redeemed</button>
                                </div>
                                <div className='sw_btn_grp d-flex'>
                                    <button className={`nav-link ${selectMyself ? 'active' : ''}`} to='#' onClick={() => handleFilters('', 'mySelf', '')}>For Myself</button>
                                    <button className={`nav-link ${selectForSomeone ? 'active' : ''}`} to='#' onClick={() => handleFilters('', 'someOneElse', '')}>For Others</button>
                                </div>
                            </li>
                        </ul>
                        <div className='tab-content' id='pills-tabContent' style={{minHeight: 'auto'}}>
                            {sortedDescPageServicesArr.length === 0 && isLoading === false ?
                                <div>{filterType == 'WashCard' ?
                                    <p className='text-center alert alert-info'>Wash Card details not available.</p> : null ||
                                    filterType == 'All' ?
                                        <p className='text-center alert alert-info'>Wallet details not available.</p> : null}
                                </div> : null}
                            <div className='tab-pane fade show active' id='pills-all' role='tabpanel' aria-labelledby='pills-all-tab'></div>
                            <div className='tab-pane fade' id='pills-washcard' role='tabpanel' aria-labelledby='pills-washcard-tab'></div>
                            <div className='tab-pane fade' id='pills-giftcard' role='tabpanel' aria-labelledby='pills-giftcard-tab'></div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='row'>
                                {isLoading && <SkeletonGiftNWashCard count={6} cardType='Wash Card' />}
                                {sortedDescPageServicesArr.map((service, index) => {
                                    return (
                                        <div className='col-md-6 col-sm-12 col-12' id={index} key={index}>
                                            <Link to='/wallet-barcode' onClick={() => handleSelectedWallet(service)}>
                                                <div className={`wallet_card mb-2 wallet_item status_badge ${service.active === true ? 'st_active' : 'st_redeemed'}`}>
                                                    <div className="wallet_card_type"><span>{service.type === 1 ? 'Gift Card' : 'Wash Card'}</span></div>
                                                    <h3>{service.description}</h3>
                                                    <div className="mt-4 wallet_card_dates">
                                                        <div className="info_date">
                                                            <small className='d-block mb-2'>Purchase Date</small>
                                                            <h6 className='text-dark mb-0'>{service.walletDate == null ? '' : dateFormatter(convertUTCDateToLocalDate(service.walletDate))}</h6>
                                                        </div>
                                                        {service.active !== true ?
                                                            <div className="info_date">
                                                                <small className='d-block mb-2'>Redeemed Date</small>
                                                                <h6 className='text-dark mb-0'>{dateFormatter(convertUTCDateToLocalDate(service.redeemDate))}</h6>
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                                {filterType === 'GiftCard' ? <div className='col-md-12 col-12 mt-4 text-center'><Link to='/add-gift-card' className='btn btn-outline-secondary py-4 w-100'>+ add a new Gift card</Link></div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Wallet;
