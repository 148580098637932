import {createSlice} from '@reduxjs/toolkit';

const prepaidWashSlice = createSlice({
    name: 'prepaidWash',
    initialState: {
        selectedPrepaidCard: {},
        services: [],
        prepaidWashPlans: [],
        detailingServices: []
    },
    reducers: {
        setSelectedPrepaidCard: (state, action) => {
            state.selectedPrepaidCard = action.payload.selectedPrepaidCard;
        },
        setServices: (state, action) => {
            state.services = action.payload.services;
        },
        setPrepaidWashPlans: (state, action) => {
            state.prepaidWashPlans = action.payload.prepaidWashPlans;
        },
        setDetailingServices: (state, action) => {
            state.detailingServices = action.payload.detailingServices;
        }
    }
});

export const {setSelectedPrepaidCard, setServices, setPrepaidWashPlans, setDetailingServices} = prepaidWashSlice.actions;
export default prepaidWashSlice.reducer;
