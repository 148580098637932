export const config = {
    apiUrl: 'https://apialpha.washassist.com/api/',
    // apiUrl: 'https://qaapi.washassist.com/api/'

    googleMapsApiKey: 'AIzaSyA_YhGHasVDkKao-DPVLgntROoERk4fIyI',
    seqUrl: 'https://seq.micrologic.net/api/events/raw?clef',
    seqApiKey: 'h0F8DaMIbOQJEToXsdYp',
    cardkNoxIfield: 'ifields_micrologicheadquartersb4ab308297d84f6',
    datacapRequestToken: 'e118ed93b87d4cf38e69af98a4ee6fa5'
};
