import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import Layout from '@components/Layout';
// CSS
import './Wallet.style.css';

const WalletFilter = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/wallet');
    }, []);

    return (
        <Layout>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                            <div className='main_title'>
                                <h2>Wallet</h2>
                                <p>Manage your wallet</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='nav custom_tabs wallet_tabs mb-3' id='pills-tab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link`} id='pills-all-tab' data-bs-toggle='pill' data-bs-target='#pills-all' type='button' role='tab' aria-controls='pills-all' aria-selected='true' onClick={() => {
                                }}>All</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link`} id='pills-washcard-tab' data-bs-toggle='pill' data-bs-target='#pills-washcard' type='button' role='tab' aria-controls='pills-washcard' aria-selected='true' onClick={() => {
                                }}>Wash Card</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link`} id='pills-giftcard-tab' data-bs-toggle='pill' data-bs-target='#pills-giftcard' type='button' role='tab' aria-controls='pills-giftcard' aria-selected='false' onClick={() => {
                                }}>Gift Card</button>
                            </li>
                            <li className='nav-item ms-auto d-inline-flex gap-1'>
                                <div className='sw_btn_grp d-flex'>
                                    <button className={`nav-link`} to='#'>Redeemable</button>
                                    <button className={`nav-link`} to='#'>Redeemed</button>
                                </div>
                                <div className='sw_btn_grp d-flex'>
                                    <button className={`nav-link`} to='#'>For Myself</button>
                                    <button className={`nav-link`} to='#'>For Others</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default WalletFilter;
