import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InputMask from 'react-input-mask';
// import axios from 'axios';
import {InitializePCI as intializePCIGatway} from './global-payment';
import {toast} from 'react-toastify';

const PaymentCard = (props) => { // for logged in users
    toast.configure();
    const toastId = React.useRef(null);
    const handleChangeMobileNo = (e) => {
        const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        const txtMobileNo= document.getElementById('txtMobileNo');
        if (!regex.test(e.target.value)) {
            txtMobileNo.style.border = '0.5px solid #FF0000';
        } else {
            txtMobileNo.removeAttribute('style');
        }
    };
    const handleChangeBillingZipCode = async (e) => {
        // const billingZipCode= document.getElementById('billingZipCode');
        // const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAW9jj6HnCRWDP6Xb9I8Pf2Mk8c0-LWgj4&components=postal_code:' + billingZipCode.value).then((data)=>{
        //     return data;
        // });
        // if (billingZipCode.value === '') {
        //     billingZipCode.style.border = '0.5px solid #FF0000';
        // } else if (response.data.status === 'ZERO_RESULTS') {
        //     billingZipCode.style.border = '0.5px solid #FF0000';
        // } else {
        //     billingZipCode.removeAttribute('style');
        // }
        const billingZipCode= document.getElementById('billingZipCode');
        const zipCodeRegex = /^[0-9]+$/;
        if (billingZipCode.value === '') {
            billingZipCode.style.border = '0.5px solid #FF0000';
        } else if (!zipCodeRegex.test(billingZipCode.value)) {
            billingZipCode.style.border = '0.5px solid #FF0000';
        } else if (billingZipCode.value.length < 5) {
            billingZipCode.style.border = '0.5px solid #FF0000';
        } else if (billingZipCode.value.length >= 6) {
            billingZipCode.style.border = '0.5px solid #FF0000';
        } else {
            billingZipCode.removeAttribute('style');
        }
    };

    if (props.noLogged == undefined) {
        const userInfo = useSelector((state) => state.auth.userInfo);
        const preferedHomeLocation = useSelector((state) => state.auth.userInfo.preferedHomeLocation);
        const dispatch = useDispatch();
        useEffect(() => {
            intializePCIGatway(userInfo, preferedHomeLocation, dispatch, props, toast, toastId, 'Save Card');
        }, []);
    } else { // for annomous
        useEffect(() => {
            intializePCIGatway(null, null, null, props, toast, toastId, 'Checkout');
        }, []);
    }

    return (
        <div>
            <form id="payment-form" >
                <h5 className='mb-3'>{(props.noLogged == undefined) ? 'Add Credit Card' : 'Enter card details'}</h5>
                <div className="row">
                    { (props.noLogged != undefined) ? <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="phone"><small>Phone<sup className="text-danger">*</sup></small></label>
                            <InputMask
                                id="txtMobileNo"
                                className="form-control form-control-lg"
                                onChange={(e) => handleChangeMobileNo(e)}
                                mask="(999) 999-9999"
                                maskChar=" "
                                onKeyPress={(e) => handleChangeMobileNo(e)}
                                name="phone"
                                placeholder="(000) 000-0000"
                            />
                        </div>
                    </div> : '' }
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccOwner"><small>Card Holder Name</small></label>
                            <div id="credit-card-card-holder"></div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Credit Card Number<sup className='text-danger'> *</sup></small></label>
                            <div id="credit-card-card-number"></div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Billing Zip Code<sup className='text-danger'> *</sup></small></label>
                            <input placeholder='Billing Zip Code' onChange={(e) => handleChangeBillingZipCode(e)} className="form-control form-control-lg" id="billingZipCode" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>Expiration Date<sup className='text-danger'> *</sup></small></label>
                            <div id="credit-card-card-expiration"></div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='mb-2'>
                            <label htmlFor="ccNumber"><small>CVV</small></label>
                            <div id="credit-card-card-cvv"></div>
                        </div>
                    </div>
                    { (props.noLogged == undefined) ? <div className="col-sm-12">
                        <div id="credit-card-submit"></div>
                    </div> : '' }
                </div>
            </form>
        </div>
    );
};

export default PaymentCard;
