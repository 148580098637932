import {FiCheckCircle, FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {currencyFormat} from '@utils/common-helper';
import {toast} from 'react-toastify';
// Components
import Layout from '@components/Layout';
import OrderSummary from '@components/order/OrderSummary';
// Services
import {buyPrepaidBook, washManagerServices} from '@services/wash-service';
import {checkoutJson} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setDetailingServices, setPrepaidWashPlans, setServices} from '@store/reducers/prepaid-wash-reducer';
import {setFormOtherData, setIsForMySelf} from '@store/reducers/auth-reducer';
import {setBarcodeString} from '@store/reducers/global-reducer';
import {setPurchaseDate} from '@store/reducers/global-reducer';
// Utils
import SkeletonWashManager from '@utils/skeletons/SkeletonWashManager';
import dateFormatter from '@utils/date-formatter';
import {overwriteSiteProductColors} from '@utils/config/site-theme-config';
import {seqReqBodyObj} from '@utils/common-helper';
// CSS
import './PrepaidWashPlans/PrepaidWashPlans.style.css';
import OtherFormModal from '../components/modals/OtherFormModal';

const WashManager = (props) => {
    toast.configure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    let services = [];
    const wservices = useSelector((state) => state.prepaidWash.services);
    const pservices = useSelector((state) => state.prepaidWash.prepaidWashPlans);
    const dservices = useSelector((state) => state.prepaidWash.detailingServices);
    const washInfo = useSelector((state) => state.prepaidWash.selectedPrepaidCard);
    const isForMySelf = useSelector((state) => state.auth.pageCommonTab.isForMySelf);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [lBookTypeId, setlBookTypeId] = useState('');
    const [isLoading, setIsLoading] = useState(services.length ? false : true);
    const orderJson= useState(checkoutJson());
    const [isCheckoutShow, setIsCheckoutShow] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [showForOthersPopup, setShowForOthersPopup] = useState(false);
    const [showAddServicePopup, setShowAddServicePopup] = useState(false);
    const [listOfUpgradeServiceIds, setListOfUpgradeServiceIds] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selecteAddondServices, setSelectedAddonServices] = useState([]);
    const [addonServices, setAddonServices] = useState([]);
    const [selectedService, setSelectedService] = useState({});
    const [isAddonServiceEdit, setAddonServiceEdit] = useState(false);
    const [moSwitch, setMoSwitch] = useState(true);
    const mySelfHandler = () => {
        setMoSwitch(true);
        dispatch(setIsForMySelf({isForMySelf: true}));
    };
    const otherHandler = () => {
        setMoSwitch(false);
        dispatch(setIsForMySelf({isForMySelf: false}));
    };
    const phoneNumber = localStorage.getItem('phoneNumber');
    const locationId = localStorage.getItem('locationIdForService') == null ? localStorage.getItem('locationId') : localStorage.getItem('locationIdForService');
    const customerInfo = JSON.parse(localStorage.getItem('customerDetail'));
    const washType = props.washInfo.title;
    // common tab
    const commonTabs = useSelector((state) => state.auth.pageCommonTab);
    const otherFormData = {
        isForMySelf: isForMySelf,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone,
        email: email
    };
    if (props.washInfo.title == 'Buy a Wash Service') {
        services = wservices;
    } else if (props.washInfo.title == 'Buy a Prepaid Wash Plan') {
        services = pservices;
    } else {
        services = dservices;
    }
    useEffect(() => {
        const getServices = async () => {
            if (services.length === 0) {
                const response = await washManagerServices(userInfo.accessToken, locationId, props.washInfo.title);
                if (response.status === 200) {
                    overwriteSiteProductColors(response.data[0]);
                    setTimeout(() => {
                        if (props.washInfo.title == 'Buy a Wash Service') {
                            dispatch(setServices({services: response.data}));
                        } else if (props.washInfo.title == 'Buy a Prepaid Wash Plan') {
                            dispatch(setPrepaidWashPlans({prepaidWashPlans: response.data}));
                        } else {
                            dispatch(setDetailingServices({detailingServices: response.data}));
                        }
                        setIsLoading(false);
                    }, 1000);
                    const seqReqBody = seqReqBodyObj('Information', `All Wash Services retrieved successfully`, `${phoneNumber}`);
                    await seqLogger(seqReqBody);
                } else {
                    setIsLoading(false);
                }
            }
        };
        getServices();
        if (washInfo.length === undefined) {
            setlBookTypeId(washInfo.lBookTypeId);
        }
        dispatch(setIsForMySelf({isForMySelf: true}));
        return () => {
            dispatch(setFormOtherData({forOther: {}}));
        };
    }, []);

    useEffect(()=>{
        if (selecteAddondServices && selecteAddondServices.length > 0) {
            orderJson[0].addon = Number(selecteAddondServices.reduce((a, c)=> a + c.dblAmount, 0).toFixed(2));
        } else {
            orderJson[0].addon = 0;
        }
    }, [selecteAddondServices]);
    const getUpgradeServices = (service) => {
        const upgradeServices = {...service}.upgradeServices;
        setAddonServices(upgradeServices);
        if (upgradeServices.length > 0) {
            if (isForMySelf) {
                setShowAddServicePopup(true);
            } else {
                setShowAddServicePopup(true);
                setSelectedService(service);
            }
        } else {
            if (isForMySelf) {
                setIsCheckoutShow(true);
            } else {
                setSelectedService(service);
                setShowForOthersPopup(true);
            }
            setSelectedAddonServices([]);
        }
    };
    const innerServicesList = (data) => {
        if (data) {
            const serviceArray = data.sServiceList !== null ? data.sServiceList.split(',') : [];
            return (
                <ul className='ws_extra_services'>
                    {serviceArray.map((value, index) => {
                        return <li key={index}><FiCheckCircle/>{value}</li>;
                    })}
                </ul>
            );
        }
    };
    const contentLoader = () => {
        const content = [];
        for (let index = 0; index < 4; index++) {
            content.push(<SkeletonWashManager key={index} />);
        }
        return content;
    };
    const buyNow = (service) => {
        setShowForOthersPopup(false);
        if (props.washInfo.title == 'Buy a Wash Service') {
            orderJson[0].TopHeaderText= 'Buy a Wash card';
            orderJson[0].ListItemText= 'Wash Card';
        } else if (props.washInfo.title == 'Buy a Prepaid Wash Plan') {
            orderJson[0].TopHeaderText= 'Buy Prepaid Wash Plan';
            orderJson[0].ListItemText= 'Prepaid Wash Card';
        } else {
            orderJson[0].TopHeaderText= 'Buy Detailing Services';
            orderJson[0].ListItemText= 'Detailing Card';
        }
        orderJson[0].SubTotal= service.dblBookPrice;
        orderJson[0].ListItemDescription= service.sBookDescription;
        orderJson[0].TaxAmount = 0;
        orderJson[0].TaxIncluded = service.bTaxIncluded;
        setlBookTypeId(service.lBookTypeId);
        orderJson[0].termsId= 3;
        if (service.bTaxIncluded) {
            orderJson[0].TaxAmount = service.dblTaxAmount;
            orderJson[0].SubTotal= service.dblBookPrice - Number(orderJson[0].TaxAmount);
            orderJson[0].GrandTotal = Number(orderJson[0].SubTotal) + Number(orderJson[0].TaxAmount);
        } else {
            if (service.bTaxable) {
                orderJson[0].TaxAmount = service.dblTaxAmount;
                orderJson[0].GrandTotal = Number(orderJson[0].SubTotal) + Number(orderJson[0].TaxAmount);
            } else {
                orderJson[0].GrandTotal = Number(orderJson[0].SubTotal);
            }
        }
        orderJson[0].Email= '';
        getUpgradeServices(service);
    };

    const washCardCheckout = async (order) => {
        const otherPhoneNumber= !commonTabs.isForMySelf?commonTabs.forOther:'';
        if (order.IsCheckedTermAndCondition && order.IsCardSelected) {
            const postModel= {};
            // postModel.Amount= order.GrandTotal + order.addon + order.addonTax;
            postModel.Amount= order.GrandTotal;
            postModel.Alias= order.IsNewCard ? '' : order.SelectedCard.sPayerIdentifier;
            postModel.CreditCardNumber= order.SelectedCard.id;
            postModel.CardHolderName= order.IsNewCard ? order.SelectedCard.CardHolderName : '';
            postModel.CustomerGuid = userInfo.customerGuid;
            postModel.isExisting= !order.IsNewCard;
            postModel.cardType= '';
            postModel.sCompany= '';
            postModel.bSendWash= !commonTabs.isForMySelf;
            postModel.CustomerIdentityId = userInfo.customerIdentityID;
            postModel.BookTypeId = lBookTypeId;
            postModel.sPhone = phoneNumber;
            postModel.sEmail = order.Email !== undefined ? order.Email : customerInfo.customer.sEmail;
            postModel.RecipientEmail = commonTabs.forOther.RecipientEmail;
            postModel.FirstName = commonTabs.forOther.FirstName;
            postModel.LastName = commonTabs.forOther.LastName;
            postModel.Message = commonTabs.forOther.Message;
            postModel.isSendMail = order.IsRecieveEmailReciept;
            postModel.isSendSMS = order.IsRecieveSMSReciept;
            postModel.LocationId = locationId;
            postModel.isDiscountApplied = false;
            postModel.listOfUpgradeServiceIds = listOfUpgradeServiceIds.toString();
            postModel.sBillPostalCode = order.sBillPostalCode;
            postModel.washType = 1;
            if (washType == 'Buy a Wash Service') {
                postModel.washType = 2;
            } else if (washType == 'Buy a Prepaid Wash Plan') {
                postModel.washType = 1;
            } else {
                postModel.washType = 3;
            }
            // setting ExpiryDate null for cardknox
            postModel.ExpiryDate = order.SelectedCard.sExpiredMonth ?? '' + order.SelectedCard.sExpireYear ?? '';
            let purchaseDate = new Date();
            purchaseDate = dateFormatter(purchaseDate);
            setIsLoading(true);
            const response = await buyPrepaidBook(userInfo.accessToken, locationId, otherPhoneNumber, postModel, purchaseDate);
            setIsLoading(false);
            if (response.status === 200) {
                dispatch(setFormOtherData({forOther: {}}));
                dispatch(setBarcodeString({barcodeString: response.data.sBarcode}));
                dispatch(setPurchaseDate({purchaseDateString: response.data.dtCreated}));
                let message = '';
                if (props.washInfo.title == 'Buy a Wash Service') {
                    message = 'Wash';
                } else if (props.washInfo.title == 'Buy a Prepaid Wash Plan') {
                    message = 'Prepaid';
                } else {
                    message = 'Detailing';
                }
                const seqReqBody = seqReqBodyObj('Information', `${message} Services purchased successfully`, `${phoneNumber}`);
                seqLogger(seqReqBody);
                return navigate('/order-success', {state: otherFormData});
            } else if (response.status == 400) {
                toast.error(response.data, {autoClose: 3000});
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
                return false;
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card.');
            }
            return false;
        }
    };
    const onClickBack = () =>{
        console.log('');
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setIsCheckAll(true);
            setSelectedAddonServices([...addonServices]);
            setListOfUpgradeServiceIds(addonServices.map((x) => x.service_ID));
        } else {
            setIsCheckAll(false);
            setSelectedAddonServices([]);
            setListOfUpgradeServiceIds([]);
        }
    };

    const handleClickAddonService = (e, service) => {
        const {checked} = e.target;
        setSelectedAddonServices([...selecteAddondServices, service]);
        setListOfUpgradeServiceIds([...listOfUpgradeServiceIds, service.service_ID]);
        if (!checked) {
            setListOfUpgradeServiceIds(listOfUpgradeServiceIds.filter((item) => item !== service.service_ID));
            setSelectedAddonServices(selecteAddondServices.filter((item) => item.service_ID !== service.service_ID));
        }
    };
    // const servicessFilter = services.filter((service)=>service.iWashQty === 1 && service.dblBookPrice > 0);
    return (
        <Layout>
            {isCheckoutShow ? <OrderSummary processCheckout={(data) => washCardCheckout(data)} hideCheckoutPage={() => setIsCheckoutShow(false)} order={orderJson} onClickBack={onClickBack} otherFormData={otherFormData} showForOthersPopup={() => setShowForOthersPopup(true)} setShowAddServicePopup={()=>setShowAddServicePopup(true)} selecteAddondServices={selecteAddondServices} setAddonServiceEdit={setAddonServiceEdit} setSelectedAddonServices = {setSelectedAddonServices}
                setListOfUpgradeServiceIds = {setListOfUpgradeServiceIds} /> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <Link to='/dashboard' className='btn btn-outline-secondary icon_btn'><span className='icon arrow_left back_icon'></span> Back</Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                                <div className='inner_title d-flex align-items-start'>
                                    <div className='progress-circle over50 p50'>
                                        <span>1 of 2</span>
                                        <div className='left-half-clipper'>
                                            <div className='first50-bar'></div>
                                            <div className='value-bar'></div>
                                        </div>
                                    </div>
                                    <div className='main_title'>
                                        <h2>{props.washInfo.title}</h2>
                                        <p>{props.washInfo.subTitle}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <div className={moSwitch? 'mo_switch' : 'mo_switch active'}>
                                <small onClick={mySelfHandler}>Myself</small>
                                <span onClick={()=>{
                                    setMoSwitch(!moSwitch);
                                    if (isForMySelf) {
                                        dispatch(setIsForMySelf({isForMySelf: false}));
                                    } else {
                                        dispatch(setIsForMySelf({isForMySelf: true}));
                                    }
                                }}></span>
                                <small onClick={otherHandler}>For Others</small>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tab-content' id='pills-tabContent' style={{minHeight: 'auto'}}>
                                <div className={`tab-pane fade ${commonTabs.isForMySelf ? 'show active' : {}}`} aria-labelledby='pills-myself-tab'></div>
                            </div>
                            {isLoading && !services.length ? <div className='row wash_list'>{contentLoader()}</div> : ''}
                            <div className={`row ${services?.length>3 ? 'new_wash_list':''}`}>
                                {services.map((service, index) => {
                                    // if (service.iWashQty === 1 && service.dblBookPrice > 0) {
                                    const serviceArray = service.sServiceList !== null ? service.sServiceList.split(',') : [];
                                    return (
                                        <div className='col-md-12' id = {service.lBookTypeId} key={index}>
                                            <div className='wash_serv_box mb-2'>
                                                <div className="wsb_wrap">
                                                    {service.sImageUrl != null ?
                                                        <div className='wash_serv_info'>
                                                            <div className='wash_serv_img'>
                                                                <img src={service.sImageUrl} alt='test-image' />
                                                            </div>
                                                            <div className="wsp_col wash_serv_price"><span className="wc_prices mb-0">{service.dblBookPrice != undefined ? currencyFormat(service.dblBookPrice) : (service.dblBookPrice)}</span></div>
                                                        </div> :
                                                        <div className="wash_serv_info">
                                                            <div className="wsp_col">
                                                                <h3 className='sTitleColor'>{service.sBookDescription}</h3>
                                                                {serviceArray.length>0?
                                                                    <h6>+ {serviceArray.length} more services</h6>:''
                                                                }
                                                            </div>
                                                            <div className='wsp_col wash_serv_price'>
                                                                <span className='wc_prices'>{service.dblBookPrice != undefined ? currencyFormat(service.dblBookPrice) : (service.dblBookPrice)}</span>
                                                                {serviceArray.length>0?
                                                                    <a className="link-primary" data-bs-toggle="collapse" href={`#collapseWashServInfo${index}`} role="button" aria-expanded={'false'} aria-controls="collapseWashServInfo">
                                                                        <small className='more_info'>more details <FiChevronDown/></small>
                                                                        <small className='hide_info'>hide details <FiChevronUp/></small>
                                                                    </a>:
                                                                    ''
                                                                }
                                                            </div>
                                                        </div>}
                                                    <div className='wash_serv_btns'>
                                                        <Link to='#' className='btn btn-primary' onClick={() => {
                                                            buyNow(service);
                                                        }}>Buy Now!</Link>
                                                    </div>
                                                </div>
                                                <div className="collapse" id={`collapseWashServInfo${index}`}>
                                                    <div className="wsb_extra_info">
                                                        {/* <div><small>Includes {service.sBookDescription} and the follwing:</small></div> */}
                                                        <div><small>{service.sSubBookDescription}</small></div>
                                                        {innerServicesList(service)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    // }
                                })}
                                {isLoading === false&&services.length == 0 ? <p className='notFound alert alert-info'>Services not available.</p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`modal fade cw_modal add_card_pop ${showAddServicePopup ? 'show d-block' : ''}`} id='addCardpop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='add_new_card_popLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content p-0'>
                        <form>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='updatePersonalInfoLabel'>Add-on Services</h5>
                            </div>
                            <div className='modal-body add_ser tbl-header'>
                                <table className="table table-responsive add_ser_table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input
                                                    type='checkbox'
                                                    className='me-2 check_pointer'
                                                    name="selectAll"
                                                    id="selectAll"
                                                    onChange={handleSelectAll}
                                                    checked={isCheckAll}/>
                                                <label htmlFor='selectAll' className='check_pointer'>
                                                 Select all Services
                                                </label>
                                            </th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addonServices.map((service)=>{
                                            return (
                                                <tr key={service.service_ID}>
                                                    <td>
                                                        <input
                                                            type='checkbox'
                                                            id={service.service_ID}
                                                            name={service.sDescription}
                                                            className='me-2 check_pointer'
                                                            onChange={(e)=>handleClickAddonService(e, service)}
                                                            checked={listOfUpgradeServiceIds.includes(service.service_ID)}
                                                        />
                                                        <label className='check_pointer' htmlFor={service.service_ID}>
                                                            {service.sDescription}
                                                        </label>
                                                    </td>
                                                    <td>${service.dblServiceAmount.toFixed(2)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <button type='button' className='btn btn-primary' onClick={()=>{
                                    if (isAddonServiceEdit) {
                                        setShowAddServicePopup(false);
                                        setIsCheckoutShow(true);
                                    } else {
                                        if (isForMySelf) {
                                            setIsCheckoutShow(true);
                                            setShowAddServicePopup(false);
                                        } else {
                                            setShowForOthersPopup(true);
                                        }
                                    }
                                }}>{listOfUpgradeServiceIds.length == 0 ? 'Go without Services': 'Go to Checkout'}</button>
                                <button type='button' className='btn btn-secondary' onClick={()=>{
                                    if (isAddonServiceEdit) {
                                        setShowAddServicePopup(false);
                                    } else {
                                        setIsCheckAll(false);
                                        setSelectedAddonServices([]);
                                        setListOfUpgradeServiceIds([]);
                                        if (isForMySelf) {
                                            setShowAddServicePopup(false);
                                        } else {
                                            setShowAddServicePopup(false);
                                            setShowForOthersPopup(true);
                                        }
                                    }
                                }}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {showAddServicePopup ?
                <div className="modal-backdrop fade show"></div>:
                ''
            }
            <OtherFormModal
                selectedService={selectedService}
                buyNow={buyNow}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phone={phone}
                setPhoneNumber={setPhoneNumber}
                email={email}
                setEmail={setEmail}
                message={message}
                setMessage={setMessage}
                showForOthersPopup={showForOthersPopup}
                setShowForOthersPopup={setShowForOthersPopup}
                setShowAddServicePopup={setShowAddServicePopup}
                setIsCheckoutShow={setIsCheckoutShow}
            />
        </Layout>
    );
};

export default WashManager;
