import {FiRefreshCw, FiShoppingCart, FiTrash} from 'react-icons/fi';
import React, {useState} from 'react';
import {useEffect} from 'react';

const AlertModalModule = (props) => {
    const {
        data,
        plates,
        selectedVehiclePlateInfo,
        setSelectedVehiclePlateInfo,
        targetEvent,
        closeModal} = props;
    const [old, setOld] = useState(false);

    useEffect(()=>{
        setSelectedVehiclePlateInfo && setSelectedVehiclePlateInfo({VehicleGUID: 0, Plate: ''});
    }, []);

    return (
        <>
            <div className={`modal fade custom_pop ${data.modalClass ? data.modalClass : '' } show`} id='modal_pop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='errorModalLabel' aria-hidden='true' style={{display: 'block'}}>
                <div className='modal-dialog  modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            {data.modalClass === 'alert_pop next'?'':
                                <div className="modal_icon">{data.modalClass === 'alert_pop card_delete' ? <FiTrash/> : <FiShoppingCart/>}</div>
                            }
                            <h2>{data.title}</h2>
                            <p className='px-4'>{data.message}</p>
                            {data.modalClass === 'alert_pop change_plan' ? <div className='cp_divide'><FiRefreshCw/></div> :''}
                            {data.modalClass === 'alert_pop change_plan' ? <p className='mb-0'>{data.message2}</p> :''}
                            {data.modalClass === 'alert_pop purchase_plan' ?
                                <div className='mb-0 d-flex w-100'>
                                    {!old && plates.length ?
                                        <select className="form-select slct_plate" id='plate_slct' aria-label="Default select"
                                            defaultValue={selectedVehiclePlateInfo.vehicleGUID}
                                            onChange={(event) => {
                                                const optionValue = document.getElementById('plate_slct');
                                                setSelectedVehiclePlateInfo({VehicleGUID: event.target.value, Plate: '', plateNo: optionValue.options[optionValue.selectedIndex].text});
                                            }}>
                                            <option value="" >Select Plate No.</option>
                                            {plates.length ? plates.map((plate) => {
                                                return <option value={plate.vehicleGUID} id={plate.lVehicleID} key={plate.vehicleGUID} selected={plate.vehicleGUID === selectedVehiclePlateInfo.vehicleGUID}>{plate.sPlate}</option>;
                                            }) : null}
                                        </select> :
                                        <>
                                            <input type="text" placeholder='Enter Plate No' className="form-control text-center slct_plate"
                                                value={selectedVehiclePlateInfo.Plate.trim()}
                                                onChange={(event) => {
                                                    const value = event.target.value.replace(/[^a-z, 0-9]/gi, '');
                                                    setSelectedVehiclePlateInfo({VehicleGUID: 0, Plate: value});
                                                }}
                                                maxLength={10} />

                                        </>
                                    }
                                    {plates.length ? <button className='btn btn-primary ms-2'
                                        onClick={() => setOld(!old)}>{old ? 'Plate List' : 'Add New Plate'}</button> : null}
                                </div> : null}
                            {data.modalClass === 'alert_pop purchase_plan'&&old&&selectedVehiclePlateInfo.Plate.length === 10 ? <small className='text-warning d-block mt-2 w-100 text-start ms-2'>Maximum plate number length reached.</small> : null}

                        </div>
                        <div className='modal-footer justify-content-center'>
                            <button className={data.btnPrimary ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => {
                                targetEvent();
                            }} id="btnAlertYes">
                                <span id="spanLoader" className="spinner-border text-light btnLoader" style={{display: 'none'}} /> {data.btnSuccessText}
                            </button>
                            <button className={!data.btnPrimary ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => closeModal()} data-bs-dismiss='modal'>{data.btnCancelText}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};

export default AlertModalModule;
