import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
// Assets
import authImg from '@assets/images/auth_bg.png';
// Components
import Layout from '@components/Layout';
// Services
import {barcodeApi, loginApi} from '@services/auth-service';
import {getCustomerInfoByGuid} from '@services/user-services';
import {location} from '@services/location-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setBarcodeCC, setCreditCardN, setLocations, setLoginStatus, setPhoneNumber, setUserInfo} from '@store/reducers/auth-reducer';
import {setBarcodeSignupOTPFlag} from '@store/reducers/global-reducer';
// Utills
// import LoginLoader from '@utils/loaders/LoginLoader';
import {fetchPCIDetailsFromServer} from '@utils/common-repo';
import {initFormik} from '@utils/formik-helper';
import {seqReqBodyObj} from '@utils/common-helper';
// Validation Schemas
import {getCarWashInfo, getCarWashPaymentType, sendOTP} from '../services/auth-service';
import AlertModalModule from '../components/modals/AlertModal';
// import customerSchema from '@validation-schemas/update-customer-info-schema';
// import BarcodeSignUpModal from '../components/modals/BarcodeSignUpModal';
import barcodeSchema from '@validation-schemas/barcode-schema';
import barcodeSignupSchema from '@validation-schemas/barcode-signup-schema';
import {getPCIDetailsLocal} from '@utils/common-repo';
import {useRef} from 'react';

const Login = (props) => {
    toast.configure();
    delete localStorage['allowAnnomous'];
    const [error, setError] = useState(false);
    const [phonError, setPhoneError] = useState(true);
    const [err, setErr] = useState('');
    const [alertModalData, setAlertModalData] = useState({});
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signupLoading, setSignupLoading] = useState(false);
    const [isBarcodeSignUpModalOpen, setIsBarcodeSignUpModalOpen] = useState(false);
    const [phoneErrorForBarcode, setPhoneErrorForBarcode] = useState(false);
    const [errForBarcode, setErrForBarcode] = useState(false);
    // const [isPreLoading, setIsPreLoading] = useState(true);
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

    const [card, setCard] = useState();
    const [phoneNumberForBarcode, setPhoneNumberForBarcode] = useState();
    const inputCard = useRef();
    const inputCardForBarcodeSignup = useRef();
    const portalCustomizationsList = useSelector((state) => state.global.portalCustomizationsList);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const d = new Date();
    const currentYear = d.getFullYear();
    const LoginBarcodeWording = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalSettingsList.find((value) => value.name === 'LoginBarcodeWording');
    const ShowCreateAccount = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalSettingsList.find((value) => value.name === 'bShowCreateAccount');
    console.log('ShowCreateAccount', ShowCreateAccount);
    const ActiveButtonColor = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_active_btn_color');
    const ActiveMenuColor = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_activemenu_color');

    if (ActiveMenuColor.sValue != null && ActiveMenuColor?.sValue != undefined && ActiveMenuColor.sValue === '#FFFFFF') {
        document.documentElement.style.setProperty('--site_activemenu_color', '#121212');
    } else if (ActiveMenuColor.sValue != null && ActiveMenuColor.sValue != undefined) {
        document.documentElement.style.setProperty('--site_activemenu_color', ActiveMenuColor?.sValue);
    }
    if (ActiveButtonColor.sValue != null && ActiveButtonColor.sValue != undefined) {
        document.documentElement.style.setProperty('--button_background_color', ActiveButtonColor?.sValue);
    }
    const faviconIcon = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value?.sName === 'site_logo_url');
    const favicon = document.getElementById('favicon');
    favicon.href = faviconIcon?.sValue;
    const customerCode= localStorage.getItem('customerCode');

    // Background Image Dynamically
    const backgroundImgUrl = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value?.sName === 'site_background_image');
    const bgImage = backgroundImgUrl?.sValue;

    const setIsContinue = () => {
        localStorage.setItem('isContinue', false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlertModalData({
            modalClass: 'alert_pop next',
            title: 'Confirmation',
            message: 'Your account was not found. Would you like to create one?',
            btnSuccessText: 'Yes',
            btnCancelText: 'No',
            btnPrimary: true
        });
        setLoading(true);
        const phoneNumber = (card);
        const response = await loginApi(phoneNumber);
        if (response.status === 200) {
            setLoading(false);
            if (response.data != null && response.data.status) {
                dispatch(setPhoneNumber({phoneNumber}));
                localStorage.setItem('phoneNumber', phoneNumber);
                toast.success('OTP has been sent successfully on your registered phone number.', {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Information', `Successfully OTP sent on your registered Phone number`, `${phoneNumber}`);
                seqLogger(seqReqBody);
                return navigate('/verify-otp');
            } else if (response.data != null && !response.data.status && response.data.isValidPhoneNumber != null && !response.data.isValidPhoneNumber) {
                toast.warning(response.data.message, {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Information', `Please enter USA phone number`, `${phoneNo}`);
                await seqLogger(seqReqBody);
            } else if (response.data != null && !response.data.status) {
                toast.warning('There was an error sending OTP. Please try again.', {autoClose: 3000});
            }
        } else {
            setModal(true);
            localStorage.setItem('newMob', card);
            setLoading(false);
        }
    };
    const handleBarcodeSubmit = async (param, resetForm) => {
        setLoading(true);
        const reqBody = {
            Barcode: param.barCode,
            CCNumber: param.ccNumber
        };
        dispatch(setBarcodeCC({reqBody}));
        dispatch(setCreditCardN({reqBody}));
        const response = await barcodeApi(reqBody);
        if (response.status === 200 && response.data.message != 'Not Found') {
            if (response.data.accessToken !== null) {
                await getCustomerInfo(response.data);
                setLoading(false);
                const userData = response.data;
                userData['expiryTime'] = new Date(userData.expires).getTime();
                getAllLocations(userData.accessToken);
                await fetchPCIDetailsFromServer();
                dispatch(setUserInfo({userInfo: userData}));
                localStorage.setItem('userInfo', JSON.stringify(userData));
                setTimeout(() => {
                    dispatch(setLoginStatus({isLoggedIn: true}));
                    return navigate('/dashboard');
                }, 500);
            } else {
                if (response.data.message === 'Incorrect Barcode and CC') {
                    setLoading(false);
                    toast.error('Incorrect Barcode or CC.', {autoClose: 3000, toastId: 'failed'});
                } else {
                // Need to open Popup
                    localStorage.setItem('CustomerGuidBarcodeSignUp', response.data.customerGuid);
                    setLoading(false);
                    resetForm();
                    setIsBarcodeSignUpModalOpen(true);
                // toast.error('Customer not found, Please register.', {autoClose: 3000, toastId: 'failed'});
                }
            }
        } else {
            setLoading(false);
            toast.error('Incorrect Barcode or CC.', {autoClose: 3000});
        }
    };
    const getCustomerInfo = async (userInfo) => {
        const response = await getCustomerInfoByGuid(userInfo.accessToken, userInfo.customerGuid, userInfo.customerIdentityID);
        if (response.status == 200) {
            localStorage.setItem('customerDetail', JSON.stringify(response.data));
            localStorage.setItem('phoneNumber', response.data.customer.sPhone);
            const seqReqBody = seqReqBodyObj('Information', `Customer information retrieve successfully`, `${response.data.customer.sPhone}`);
            await seqLogger(seqReqBody);
        } else if (response.status === (401 | 404 | 470)) {
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
        } else {
            toast.error('Some error occurred.', {autoClose: 3000});
        }
    };
    const getAllLocations = async (accessToken) => {
        const response = await location(accessToken);
        if (response.status == 200) {
            dispatch(setLocations({locations: response.data}));
            localStorage.setItem('locations', JSON.stringify(response.data));
            localStorage.setItem('locationId', response.data[0].locationId);
        }
    };

    useEffect(() => {
        if (customerCode == null || customerCode == 'null' || customerCode == undefined) {
            const errMsgType = localStorage.getItem('errMsgType');
            return navigate('/error-page', {state: {message: errMsgType}});
        }
        localStorage.setItem('newMob', '');
        // setTimeout(() => {
        //     setIsPreLoading(false);
        // }, 5000);
    }, []);

    // const initialValuesPhone = {
    //     phone: ''
    // };
    // const phoneFormik = initFormik(initialValuesPhone, loginSchema, handleSubmit, true);

    const initialValuesBarcode = {
        barCode: '',
        ccNumber: ''
    };
    const barcodeFormik = initFormik(initialValuesBarcode, barcodeSchema, handleBarcodeSubmit, true);

    const phoneMask = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputCard.current.value = !cardValue[2] ?
            cardValue[1] :
            `(${cardValue[1]}) ${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        if (inputCard.current.value.length == 0) {
            setErr('Cell phone number is required.');
            setPhoneError(true);
        } else if (!phoneRegExp.test(inputCard.current.value)) {
            setErr('cell phone number is invalid.');
            setPhoneError(true);
        } else {
            setErr('');
            setPhoneError(false);
        }
        setCard(numbers);
    };

    const phoneMaskForBarcodeSignup = () => {
        const cardValue = inputCardForBarcodeSignup.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputCardForBarcodeSignup.current.value = !cardValue[2] ?
            cardValue[1] :
            `(${cardValue[1]}) ${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCardForBarcodeSignup.current.value.replace(/(\D)/g, '');
        if (inputCardForBarcodeSignup.current.value.length == 0) {
            setErrForBarcode('Cell phone number is required.');
            setPhoneErrorForBarcode(true);
        } else if (!phoneRegExp.test(inputCardForBarcodeSignup.current.value)) {
            setErrForBarcode('cell phone number is invalid.');
            setPhoneErrorForBarcode(true);
        } else {
            setErrForBarcode('');
            setPhoneErrorForBarcode(false);
        }
        setPhoneNumberForBarcode(numbers);
    };

    const carWashInfo = async () => {
        const response = await getCarWashInfo(customerCode);
        if (response.status == 200 && response.data != '' && response.data != null) {
            localStorage.setItem('title', response.data);
            document.title = response.data;
        } else {
            document.title = 'Car Wash';
            localStorage.setItem('title', 'Car Wash');
        }
    };
    // const carWashPaymentType = async () => {
    //     localStorage.setItem('isCardknox', false);
    //     const response = await getCarWashPaymentType(customerCode);
    //     if (response.status == 200) {
    //         if (response.data == 2) {
    //             localStorage.setItem('isCardknox', true);
    //         } else {
    //             localStorage.setItem('isCardknox', false);
    //         }
    //     } else {
    //         localStorage.setItem('isCardknox', false);
    //     }
    // };

    const carWashPaymentType = async () => {
        localStorage.setItem('gateWayType', 'openEdge');
        const response = await getCarWashPaymentType(customerCode);
        if (response.status == 200) {
            if (response.data == 2) {
                localStorage.setItem('gateWayType', 'cardknox');
            } else if (response.data == 3) {
                localStorage.setItem('gateWayType', 'dataCap');
            }
        } else {
            localStorage.setItem('gateWayType', 'openEdge');
        }
    };

    const initialBarcodeSignupValues = {
        firstName: '',
        lastName: '',
        phoneNumber: ''
    };
    const handleBarcodeModalSubmit = async (value, resetForm) => {
        setSignupLoading(true);
        const phoneNumber=phoneNumberForBarcode;
        const response = await sendOTP(phoneNumber);
        const barcodeSignupModalInfo = {
            ...value,
            phoneNumber: phoneNumberForBarcode
        };
        localStorage.setItem('barcodeSignupModalInfo', JSON.stringify(barcodeSignupModalInfo));
        dispatch(setBarcodeSignupOTPFlag({barcodeSignupOTPFlag: true}));
        localStorage.setItem('phoneNumber', phoneNumber);
        if (response.status == 200) {
            if (response.data.status==true) {
                // localStorage.setItem('customerDetail', JSON.stringify(response.data));
                // localStorage.setItem('phoneNumber', response.data.customer.sPhone);
                // dispatch(setPhoneNumber({phoneNumber}));
                toast.success('OTP has been sent successfully on your phone number.', {autoClose: 3000});
                const seqReqBody = seqReqBodyObj('Information', `Successfully OTP sent on your Phone number`, `${phoneNumber}`);
                seqLogger(seqReqBody);
                setSignupLoading(false);
                return navigate('/verify-otp');
            } else {
                setSignupLoading(false);
                toast.warning('There was an error sending OTP. Please try again.', {autoClose: 3000});
            }
        } else if (response.status === (401 | 404 | 470)) {
            setSignupLoading(false);
            toast.warning(`${response.data.title}.`, {autoClose: 3000});
        } else {
            setSignupLoading(false);
            toast.error('Some error occurred.', {autoClose: 3000});
        }
        // resetForm();
    };
    const barcodeSignupFormik = initFormik(initialBarcodeSignupValues, barcodeSignupSchema, handleBarcodeModalSubmit, true);

    const barcodeSignupCancel = () =>{
        setSignupLoading(false);
        setIsBarcodeSignUpModalOpen(false);
    };

    useEffect(()=>{
        carWashInfo();
        carWashPaymentType();
        const paymentMode = getPCIDetailsLocal();
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        if (paymentMode.isPaymentLive == false) {
            script1.src = 'https://token-cert.dcap.com/v1/client';
            script1.async = true;
            document.body.appendChild(script1);
        } else {
            script2.src = 'https://token.dcap.com/v1/client';
            script2.async = true;
            document.body.appendChild(script2);
        }

        return () => {
            if (paymentMode.isPaymentLive == false) {
                document.body.removeChild(script1);
            } else {
                document.body.removeChild(script2);
            }
        };
    }, []);
    return (
        <>
            {
                (customerCode == null || customerCode == 'null' || customerCode == undefined) ? '' :
                    <Layout>
                        {/* {isPreLoading ?
                            <div style={{height: '100vh', width: '100%', position: 'absolute', background: '#fff', zIndex: '1'}}>
                                <LoginLoader />
                            </div> : null } */}
                        {modal ? <AlertModalModule data={alertModalData} targetEvent={() => {
                            navigate('/signup');
                        }} closeModal={() => setModal(false)} /> : ''}
                        <div className="auth_page">
                            <div className="auth_watermark" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                            <div className="auth_wrap">
                                <div className="auth_left">
                                    <div className="auth_form_wrap pt-5">
                                        <div className="brand_logo mb-4">
                                            <Link to="/">
                                                {portalCustomizationsList.length && portalCustomizationsList.map((value, key) => {
                                                    if (value.sName === 'site_logo_url') {
                                                        return <img src={value.sValue} className="img-fluid" alt="logo" key={key}/>;
                                                    }
                                                })}
                                            </Link>
                                        </div>
                                        <div className="auth_form_sec">
                                            <h1 className="form_title mt-5 mb-5">Welcome!</h1>
                                            <ul className="nav nav-pills justify-content-between mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-phone-tab" data-bs-toggle="pill" data-bs-target="#pills-phone" type="button" role="tab" aria-controls="pills-phone" aria-selected="true">Cell Phone</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-barcode-tab" data-bs-toggle="pill" data-bs-target="#pills-barcode" type="button" role="tab" aria-controls="pills-barcode" aria-selected="false">{LoginBarcodeWording?.value === '' || LoginBarcodeWording?.value === null || LoginBarcodeWording == undefined? 'Barcode or  License plate #' : LoginBarcodeWording?.value}</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content login-tab-content pt-3" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-phone" role="tabpanel" aria-labelledby="pills-phone-tab">
                                                    <form className="auth_form mb-5" action='#'>
                                                        <div className="mb-3">
                                                            <label htmlFor="phone" className="form-label">Cell Phone<sup className="text-danger">*</sup></label>
                                                            <input type="text" className="form-control form-control-lg"
                                                                name="phone"
                                                                onChange={phoneMask}
                                                                ref={inputCard}
                                                                // value={phoneFormik.values.phone}
                                                                placeholder="(000) 000-0000"
                                                            />
                                                            <small className="text-danger validationError">{err}</small>
                                                        </div>
                                                        <button type="submit" className="button btn btn-primary" disabled={phonError} onClick = {(e)=> {
                                                            handleSubmit(e);
                                                            setIsContinue();
                                                        }}>
                                                            {loading && <span className="spinner-border text-light btnLoader"></span>}<span>Submit</span>
                                                        </button>
                                                    </form>
                                                </div>
                                                <div className="tab-pane fade" id="pills-barcode" role="tabpanel" aria-labelledby="pills-barcode-tab">
                                                    <form className="auth_form mb-5" action="" onSubmit={barcodeFormik.handleSubmit}>
                                                        <div className="mb-3">
                                                            <label htmlFor="barCode" className="form-label">{LoginBarcodeWording?.value === '' || LoginBarcodeWording?.value === null || LoginBarcodeWording == undefined? 'Barcode or  License plate #' : LoginBarcodeWording?.value}<sup className="text-danger">*</sup></label>
                                                            <input type="text" className="form-control form-control-lg" id="barCode" placeholder=""
                                                                name="barCode"
                                                                onChange={barcodeFormik.handleChange}
                                                                onBlur={barcodeFormik.handleBlur}
                                                                maxLength='20'
                                                                value={barcodeFormik.values.barCode}
                                                            />
                                                            {barcodeFormik.touched.barCode && barcodeFormik.errors.barCode ? (
                                                                <small className="text-danger validationError">{barcodeFormik.errors.barCode}</small>
                                                            ) : ''}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="ccNumber" className="form-label">Last 4 of CC<sup className="text-danger">*</sup></label>
                                                            <input type="text" className="form-control form-control-lg" id="ccNumber" placeholder=""
                                                                name="ccNumber"
                                                                onChange={barcodeFormik.handleChange}
                                                                onBlur={barcodeFormik.handleBlur}
                                                                maxLength='4'
                                                                value={barcodeFormik.values.ccNumber}
                                                            />
                                                            {barcodeFormik.touched.ccNumber && barcodeFormik.errors.ccNumber ? (
                                                                <small className="text-danger validationError">{barcodeFormik.errors.ccNumber}</small>
                                                            ) : ''}
                                                        </div>
                                                        <button type="submit" className="btn btn-primary" disabled={loading || !barcodeFormik.isValid || barcodeFormik.values.barCode === '' || barcodeFormik.values.ccNumber === ''}
                                                            onClick={() => {
                                                                setIsContinue();
                                                            }}>{loading && <span className="spinner-border text-light btnLoader"></span>}<span>Continue</span></button>
                                                    </form>
                                                </div>
                                                {ShowCreateAccount?.value?.toUpperCase() == 'FALSE' ? '' :
                                                    <div className="switch_form text-center">Not a member? <Link to="/signup" className='m-0 link-primary'>Create an account</Link></div>
                                                }
                                                <div className='copy_text text-center mt-5'><small>All rights reserved | Copyright 2018-{currentYear}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="auth_right" style={{backgroundImage: bgImage===null || bgImage==='' || bgImage===undefined||(/(http(s?)):\/\//i.test(bgImage))===false ?`url(${authImg})`:`url('${bgImage}')`}}></div>
                            </div>
                        </div>

                        <div className={`modal fade custom_pop error_pop ${error ? 'show d-block' : ''}`} id="errorModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="errorModalLabel" aria-hidden="true">
                            <div className="modal-dialog  modal-dialog-centered">
                                <div className="modal-content p-4">
                                    <div className="modal-body">
                                        <h2>Error!</h2>
                                        <p>Invalid Number !!!</p>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="button" className="main_btn" data-bs-dismiss="modal" onClick={() => setError(false)}>Okay</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {error ? <div className="modal-backdrop fade show"></div> : ''}

                        <div className={`modal fade cw_modal cust_info_mdl barcode_signup ${isBarcodeSignUpModalOpen ? 'show d-block' : ''}`} id="cust_info" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabIndex="-1" aria-labelledby="cust_infoLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header justify-content-center">
                                        <h5 className="modal-title" id="cust_infoLabel">Please provide below Information!</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="cust_info_mdl_body h-auto">
                                            <form onSubmit={barcodeSignupFormik.handleSubmit}>
                                                <div className="cust_info_mdl_form p-0">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="firstName" className="form-label">First Name</label>
                                                                <input type="text" id="firstName" className="form-control form-control-md"
                                                                    name="firstName"
                                                                    onChange={barcodeSignupFormik.handleChange}
                                                                    onBlur={barcodeSignupFormik.handleBlur}
                                                                    value={barcodeSignupFormik.values.firstName}
                                                                />
                                                                {barcodeSignupFormik.touched.firstName && barcodeSignupFormik.errors.firstName ? (
                                                                    <small className="text-danger validationError">{barcodeSignupFormik.errors.firstName}</small>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                                                <input type="text" id="lastName" className="form-control form-control-md"
                                                                    name='lastName'
                                                                    onChange={barcodeSignupFormik.handleChange}
                                                                    onBlur={barcodeSignupFormik.handleBlur}
                                                                    value={barcodeSignupFormik.values.lastName} />
                                                                {barcodeSignupFormik.touched.lastName && barcodeSignupFormik.errors.lastName ? (
                                                                    <small className="text-danger validationError">{barcodeSignupFormik.errors.lastName}</small>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                                                <input type="tel" id="phoneNumber" className="form-control form-control-md"
                                                                    name="phoneNumber"
                                                                    onChange={(e) => {
                                                                        phoneMaskForBarcodeSignup();
                                                                        barcodeSignupFormik.handleChange(e);
                                                                    }}
                                                                    ref={inputCardForBarcodeSignup}
                                                                    // value={phoneFormik.values.phone}
                                                                    placeholder="(000) 000-0000"
                                                                    value={barcodeSignupFormik.values.phoneNumber} />
                                                                <small className="text-danger validationError">{errForBarcode}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-12">
                                                            <div className="mb-3">
                                                                <button type="submit" disabled={signupLoading || !barcodeSignupFormik.isValid || barcodeSignupFormik.values.firstName === '' || barcodeSignupFormik.values.lastName === '' || phoneErrorForBarcode} className="btn btn-primary w-100">
                                                                    {signupLoading && <span className="spinner-border text-light btnLoader"></span>}<span>Continue</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-12">
                                                            <button type="button" onClick={()=> {
                                                                barcodeSignupFormik.resetForm();
                                                                barcodeSignupCancel();
                                                            }} className="btn btn-secondary w-100 justify-content-center" data-bs-dismiss="modal">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isBarcodeSignUpModalOpen ? <div className="modal-backdrop fade show"></div> : ''}
                    </Layout>
            }
        </>
    );
};

export default Login;
