import {FiHome, FiMapPin, FiMenu, FiPower} from 'react-icons/fi';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InputMask from 'react-input-mask';
import {Link} from 'react-router-dom';
import {RiArrowDropRightFill} from 'react-icons/ri';
// Utils
import {authVerification} from '@utils/auth-verification';
import {isStringNullOrEmpty} from '@utils/common-repo';
import {seqReqBodyObj} from '@utils/common-helper';
// Reducers
import {setSelectedWallet} from '@store/reducers/wallet-reducer';
// Services
import {seqLogger} from '@services/seq-logger-service';
// CSS
import './Header.css';
import {checkStr} from '../../utils/common-helper';

const Header = (props) => {
    const dispatch = useDispatch();
    let allowAnnomous = localStorage.getItem('allowAnnomous');
    if (allowAnnomous == 'true') {
        allowAnnomous = true;
    }
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let firstName = '';
    let lastName = '';
    if (!allowAnnomous) {
        if (customerDetail !== null) {
            firstName = customerDetail.customer.sFirstName;
            lastName = customerDetail.customer.sLastName;
        }
        if (isStringNullOrEmpty(firstName) && !isStringNullOrEmpty(userInfo.firstname)) {
            firstName = userInfo.firstname;
        }
        if (isStringNullOrEmpty(lastName) && !isStringNullOrEmpty(userInfo.lastname)) {
            lastName = userInfo.lastname;
        }
    }
    const portalCustomizationsList = useSelector((state) => state.global.portalCustomizationsList);
    const customizedData = JSON.parse(localStorage.getItem('customizePage'))?.portalScreenOptionsList.filter((service) => service.type === 1 && service.isActive === true);
    const wallet = customizedData?.find((x) => x.targetId === 'wallet');
    const giftCard = customizedData?.find((x) => x.targetId === 'gift_card');
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const phoneNumber = localStorage.getItem('phoneNumber');
    const customerCode = localStorage.getItem('customerCode');
    const customerUrl = localStorage.getItem('customerUrl');
    const logout = async () => {
        const seqReqBody = seqReqBodyObj('Information', `Logout successfully`, `${phoneNumber}`);
        await seqLogger(seqReqBody);
        localStorage.clear();
        localStorage.setItem('customerCode', customerCode);
        localStorage.setItem('customerUrl', customerUrl);
        window.location.href = `/${customerUrl}`;
    };
    const getFaviconEl = () => {
        return document.getElementById('favicon');
    };
    useEffect(() => {
        const favicon = getFaviconEl(); // Accessing favicon element
        const siteLogoUrl = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_logo_url');
        const seteLogo = siteLogoUrl?.sValue;
        favicon.href = seteLogo;
        authVerification();
    }, []);

    return (
        <><header className="header mb-3">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="header_logo">
                                <Link to={isLoggedIn ? '/' : '/buy'}>
                                    {portalCustomizationsList?.length && portalCustomizationsList?.map((value, key) => {
                                        if (value.sName === 'site_logo_url') {
                                            return <img src={value?.sValue || ''} className="img-fluid" alt="logo" key={key} />;
                                        }
                                    })}
                                </Link>
                            </div>
                            {(!allowAnnomous) ?
                                <div className="user_acc_head d-flex justify-content-end h-100 header_right">
                                    {Boolean(isLoggedIn) ? <div className="btn-group dropdown align-items-center align-self-center">
                                        <Link to="/">
                                            <div className='header_location me-3'>
                                                <span className='header_icon me-2'>
                                                    <FiHome />
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to="/find_location_btn">
                                            <div className='header_location me-3'>
                                                <span className='header_icon me-2'>
                                                    <FiMapPin />
                                                </span>
                                                <span className='f_location'>Find Location</span>
                                            </div>
                                        </Link>
                                        <button className="ms-2" type="button" id="userHeaddrop" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className='header_icon'>
                                                <FiMenu />
                                            </span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end header_dropdown" aria-labelledby="userHeaddrop">
                                            <li className='pt-0 pb-2 px-0'>
                                                <div className="profile_drop">
                                                    <span className="headerdrop_topicon"><b>{checkStr(firstName) == ''? '' : firstName.charAt(0).toUpperCase()}{checkStr(lastName) == ''? '' : lastName.charAt(0).toUpperCase()}</b></span>
                                                    <div className="">
                                                        <h5 className="name-break text-capitalize">Hi, {checkStr(firstName)} {checkStr(lastName)}</h5>
                                                        <span>
                                                            <InputMask
                                                                className="headerPhone"
                                                                readOnly={true}
                                                                mask="(999) 999-9999"
                                                                maskChar=""
                                                                value={phoneNumber}
                                                                name="phone"
                                                                placeholder="(000) 000-0000"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><Link to="/order-history"><RiArrowDropRightFill className='dropMenuIcon' />Order History</Link></li>
                                            <li><Link to="/manage_account"><RiArrowDropRightFill className='dropMenuIcon' />My Account</Link></li>
                                            {wallet ?
                                                <li><Link to="/wallet" onClick={() => dispatch(setSelectedWallet({selectedWallet: {}}))}><RiArrowDropRightFill className='dropMenuIcon' />Wallet</Link></li> : ''
                                            }
                                            {giftCard ?
                                                <li><Link to="/gift_card"><RiArrowDropRightFill className='dropMenuIcon' />Gift Cards</Link></li> : ''
                                            }
                                            <hr className="mb-2 mt-2"></hr>
                                            <li className='pt-0 pb-2 px-0'><Link className="dropdown-item p-0" to="#" onClick={() => logout()}><FiPower className='me-2' /> Logout</Link></li>
                                        </ul>
                                    </div> : ''}
                                </div> :
                                ''}
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
}; export default Header;

