import {FiArrowLeft, FiEdit2, FiMail, FiSmartphone, FiUser} from 'react-icons/fi';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
// import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
// Components
import GiftCard from './GiftCard';
import Layout from '@components/Layout';
import OrderSummary from '../order/OrderSummary';
// Services
import {addValueOnExistingGC, buyGiftCardOnline, getCalculatedGCTax, giftCardAmountList} from '@services/gift-card-service';
import {checkoutJson} from '@services/auth-service';
import {seqLogger} from '@services/seq-logger-service';
// Reducers
import {setGiftCardAmounts, setIsForMySelf, setformOtherData} from '@store/reducers/gift-card-reducer';
import {setBarcodeString} from '@store/reducers/global-reducer';
import {setPurchaseDate} from '@store/reducers/global-reducer';
// Utils
import {currencyFormatWithoutSymbol, seqReqBodyObj} from '@utils/common-helper';
import commonTableLoader from '@utils/common-table-loader';
import dateFormatter from '@utils/date-formatter';
import {initFormik} from '@utils/formik-helper';
//
import {giftCardModel, isNumericFloat} from '@utils/common-repo';
// Validation Schema
import washCardSchema from '@validation-schemas/wash-card-schema';
// Assets
import GCLogo from '@assets/images/logo_white.png';

const AddGiftCard = (props) => {
    toast.configure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlParam = useParams();
    const toastId = useRef(null);
    const requestPageType= urlParam.type == undefined ? '' : urlParam.type;
    const giftCardGuid= urlParam.gcGuid == undefined ? '' : urlParam.gcGuid;
    // eslint-disable-next-line no-unused-vars
    const accessToken = useSelector((state) => state.auth.userInfo.accessToken);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const giftCardAmounts = useSelector((state) => state.giftCard.giftCardAmounts);
    const giftCardTabs = useSelector((state) => state.giftCard.giftCardTabs);
    const preferedHomeLocation = useSelector((state) => state.auth.locations[0].locationId);
    const isForMySelf = useSelector((state) => state.giftCard.giftCardTabs.isForMySelf);

    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const inputCard = useRef();
    const [phonError, setPhoneError] = useState(true);
    const [err, setErr] = useState('');
    const [card, setCard] = useState();

    const [cardAmount, setCardAmount] = useState('');
    const orderJson= useState(checkoutJson());
    const [isCheckoutShow, setIsCheckoutShow] = useState(false);
    const [isGiftCardAmountLoading, setISGiftCardAmountLoading] = useState(false);
    const [defaultGCInputData, setDefaultGCInputData]= useState(giftCardAmounts !=null && giftCardAmounts.length > 0 ? giftCardAmounts.find((d) => d.dblAmount == 9999) : {});
    const [selectedGCAmount, setSelectedGCAmount] = useState({});
    const [isProcessCheckout, setIsProcessCheckout] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    // const [phone, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [moSwitch, setMoSwitch] = useState(true);
    const [showForOthersPopup, setShowForOthersPopup] = useState(false);
    const customerInfo = JSON.parse(localStorage.getItem('customerDetail')).customer;
    const emailRegex = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
    const customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    const giftCardLogo = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_card_logo');
    const giftCardImg = giftCardLogo.sValue;

    const mySelfHandler = () => {
        setMoSwitch(true);
        dispatch(setIsForMySelf({isForMySelf: true}));
    };
    const otherHandler = () => {
        setMoSwitch(false);
        dispatch(setIsForMySelf({isForMySelf: false}));
    };
    const otherFormData = {
        isForMySelf: isForMySelf,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: card,
        email: email
    };
    const forOtherTabJson= {
        FirstName: giftCardTabs.forOther.FirstName,
        LastName: giftCardTabs.forOther.LastName,
        PhoneNumber: giftCardTabs.forOther.PhoneNumber,
        RecipientEmail: giftCardTabs.forOther.RecipientEmail,
        Message: giftCardTabs.forOther.Message
    };
    const handleSubmit = () => {
        continueToAddGCCheckout();
    };
    const initialValues = {
        firstname: giftCardTabs.forOther.FirstName,
        lastname: giftCardTabs.forOther.LastName,
        email: giftCardTabs.forOther.RecipientEmail,
        phone: giftCardTabs.forOther.PhoneNumber,
        message: giftCardTabs.forOther.Message
    };
    const formik = initFormik(initialValues, washCardSchema, handleSubmit);

    const onSelectGCAmount = async (value) => {
        const jsonData = giftCardModel(true, (value.bTaxable && value.lTaxID != null), value.dblAmount, value.surcharge_ID, value.sDescription, '', userInfo.customerGuid, value.bTaxIncluded);
        setSelectedGCAmount(jsonData);
        setCardAmount('');
    };

    const onCardAmountInputFocus = (e, defaultInputData) => {
        if (selectedGCAmount.IsSelected !=undefined && selectedGCAmount.IsSelected) {
            setCardAmount('');
            const jsonData= giftCardModel(false, (defaultInputData.bTaxable && defaultInputData.lTaxID != null), '00.00', defaultInputData.surcharge_ID, 'Other', '00.00', userInfo.customerGuid, defaultInputData.bTaxIncluded);
            setSelectedGCAmount(jsonData);
        };
    };

    const onCardAmountInputChange = (e, defaultInputData) => {
        currencyMask(e);
        const fields = e.target.value.split(',');
        const giftAmount = fields[0].concat(fields[1] ?fields[1]:'');
        if (Number(giftAmount)>=10000.01) {
            toast.error('Amount cannot be more than $10,000.00', {toastId: 'invalidAmt'});
            return false;
        }
        if (giftAmount.length >= 0 && giftAmount.length < 9) {
            const jsonData = giftCardModel(false, (defaultInputData.bTaxable && defaultInputData.lTaxID != null), giftAmount, defaultInputData.surcharge_ID, 'Other', giftAmount, userInfo.customerGuid);
            setSelectedGCAmount(jsonData);
            setCardAmount(e.target.value);
        }
    };
    const continueToAddGCCheckout = async () => {
        orderJson[0].termsId= 4;
        if (selectedGCAmount.SurchargeId == undefined) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select gift card first.');
            }
            return false;
        }
        if (selectedGCAmount.Amount < 1) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Amount should be greater than 0.');
            }
            return false;
        }
        orderJson[0].TopHeaderText= 'Buy a Gift Card';
        orderJson[0].SubTotal= selectedGCAmount.Amount;
        orderJson[0].TaxIncluded= selectedGCAmount.TaxIncluded;
        orderJson[0].ListItemText= 'Gift Card';
        orderJson[0].ListItemDescription= selectedGCAmount.Description;
        if (!giftCardTabs.isForMySelf) {
            if (firstName.length == 0) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('First Name is mandatory.');
                }
                return false;
            } else if (lastName.length == 0) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('Last Name is mandatory.');
                }
                return false;
            } else if (email.length == 0) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('Recipient email is mandatory.');
                }
                return false;
            } else if (!emailRegex.test(email)) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('Recipient email should be a valid email.');
                }
            } else if (card.length == 0) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('Phone Number is mandatory.');
                }
                return false;
            } else if (message.length == 0) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error('Message is mandatory.');
                }
                return false;
            }
        }
        setShowForOthersPopup(false);
        const selectedAmount = isNumericFloat(selectedGCAmount.Other) ? parseFloat(selectedGCAmount.Other) : 0;
        orderJson[0].Email= '';
        if (selectedGCAmount.IsTaxable && selectedAmount === 0) {
            const postData = {};
            postData.Other= selectedAmount;
            postData.SurchargeId= selectedGCAmount.SurchargeId;
            postData.CustomerGuid= selectedGCAmount.CustomerGuid;
            postData.customerIdentityID = userInfo.customerIdentityID;
            setIsProcessCheckout(true);
            const response = await getCalculatedGCTax(accessToken, postData);
            setIsProcessCheckout(false);
            if (response.status === 200) {
                orderJson[0].TaxAmount= response.data;
                if (selectedGCAmount.TaxIncluded) {
                    orderJson[0].SubTotal= selectedGCAmount.Amount - parseFloat(response.data);
                    orderJson[0].GrandTotal= (parseFloat(orderJson[0].SubTotal) + parseFloat(response.data)).toFixed(2);
                    setIsCheckoutShow(true);
                } else {
                    orderJson[0].GrandTotal= (parseFloat(orderJson[0].SubTotal) + parseFloat(response.data)).toFixed(2);
                    setIsCheckoutShow(true);
                }
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
            }
        } else {
            orderJson[0].TaxAmount= 0;
            orderJson[0].GrandTotal= orderJson[0].SubTotal;
            setIsCheckoutShow(true);
        }
        // }
    };

    const giftCardCheckout = async (order) => {
        const otherPhoneNumber= !giftCardTabs.isForMySelf ? giftCardTabs.forOther.PhoneNumber.replace(/([() -])+/g, '') : '';
        if (order.IsCheckedTermAndCondition && order.IsCardSelected) {
            const postModel= {};
            if (requestPageType== 'add_value') {
                postModel.responseCodeID= '';
                postModel.authID= '';
                postModel.dblBalance= selectedGCAmount.IsSelected ? selectedGCAmount.Amount : selectedGCAmount.Other;
                postModel.giftCardGUID= giftCardGuid;
                postModel.customerGUID= userInfo.customerGuid;
                postModel.encryptednumber= '';
                postModel.paymenttype= '';
                postModel.Token= order.SelectedCard.sPayerIdentifier;
                postModel.creditCardNumber= '';
                postModel.cardHolderName= '';
                postModel.isExisting= true;
                postModel.expiryDate= '';
                postModel.CustomerIdentityId=userInfo.customerIdentityID;
            } else {
                postModel.surchargeId = selectedGCAmount.SurchargeId;
                postModel.other = selectedGCAmount.IsSelected ? 0 : selectedGCAmount.Other;
                postModel.firstName = giftCardTabs.forOther.FirstName;
                postModel.email = order.Email !== undefined ? order.Email : customerInfo.customer.sEmail;
                postModel.otherPhoneNumber = !giftCardTabs.isForMySelf ? giftCardTabs.forOther.PhoneNumber : '';
                postModel.recipientEmail = !giftCardTabs.isForMySelf ? giftCardTabs.forOther.RecipientEmail : '';
                postModel.message = giftCardTabs.forOther.Message;
                postModel.paymentCardId = order.SelectedCard.id;
                postModel.isSendEmail = order.IsRecieveEmailReciept;
                postModel.bSendasGift = !giftCardTabs.isForMySelf;
                postModel.formyself = giftCardTabs.isForMySelf;
                postModel.discountAmount = 0;
                postModel.token = order.SelectedCard.sPayerIdentifier;
                postModel.isSendSms = order.IsRecieveSMSReciept;
                postModel.discountTaxAmount = 0;
                postModel.discountId = 0;
                postModel.locationId = userInfo.preferedHomeLocation;
                postModel.customerIdentityId = userInfo.customerIdentityID;
                postModel.customerGuid = userInfo.customerGuid;
                postModel.sBillPostalCode = order.sBillPostalCode;
            }

            setISGiftCardAmountLoading(true);
            let response = null;
            let purchaseDate = new Date();
            purchaseDate = dateFormatter(purchaseDate);
            if (requestPageType == 'add_value') {
                response = await addValueOnExistingGC(accessToken, preferedHomeLocation, postModel, purchaseDate);
            } else {
                response = await buyGiftCardOnline(accessToken, otherPhoneNumber, postModel, purchaseDate);
            }
            setISGiftCardAmountLoading(false);
            if (response.status== 200) {
                if (requestPageType== 'add_value') {
                    const seqReqBody = seqReqBodyObj('Information', `Gift card merged successfully`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                    // return navigate('/gift_card');
                    dispatch(setBarcodeString({barcodeString: response.data.sGiftCard_Number}));
                    return navigate('/order-success', {state: otherFormData});
                } else {
                    const seqReqBody = seqReqBodyObj('Information', `Gift card purchased successfully`, `${customerDetail.customer.sPhone}`);
                    await seqLogger(seqReqBody);
                    dispatch(setBarcodeString({barcodeString: response.data.sGiftCard_Number}));
                    dispatch(setPurchaseDate({purchaseDateString: response.data.dtCreated}));
                    return navigate('/order-success', {state: otherFormData});
                }
            } else if (response.status == 400) {
                toast.error(response.data, {autoClose: 3000});
            } else {
                toast.error('Something went wrong.', {autoClose: 3000});
                return false;
            }
        } else if (!order.IsCheckedTermAndCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please check Terms and Conditions.');
            }
            return false;
        } else if (!order.IsCardSelected) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select credit card.');
            }
            return false;
        }
    };
    useEffect(() => {
        const getGiftCardAmountList = async () => {
            setISGiftCardAmountLoading(true);
            const response = await giftCardAmountList(accessToken);
            setISGiftCardAmountLoading(false);
            if (response.status === 200) {
                setDefaultGCInputData(response.data.find((d) => d.dblAmount == 9999));
                dispatch(setGiftCardAmounts({giftCardAmounts: response.data}));
                const seqReqBody = seqReqBodyObj('Information', `All gift card retrieved successfully`, `${customerDetail.customer.sPhone}`);
                await seqLogger(seqReqBody);
            } else {
                dispatch(setGiftCardAmounts({giftCardAmounts: []}));
                toast.error('Something went wrong.', {autoClose: 3000});
            }
        };
        if (giftCardAmounts==null || giftCardAmounts.length == 0) {
            getGiftCardAmountList();
        }
        dispatch(setIsForMySelf({isForMySelf: true}));
    }, []);
    const onClickBack = () =>{
        console.log('');
    };
    const currencyMask = (e) =>{
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e;
    };

    const phoneMask = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputCard.current.value = !cardValue[2] ?
            cardValue[1] :
            `(${cardValue[1]}) ${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');
        if (inputCard.current.value.length == 0) {
            setErr('Cell Phone is required.');
            setPhoneError(true);
        } else if (!phoneRegExp.test(inputCard.current.value)) {
            setErr('Cell Phone  Number is not valid.');
            setPhoneError(true);
        } else {
            setErr('');
            setPhoneError(false);
        }
        forOtherTabJson.PhoneNumber= numbers;
        dispatch(setformOtherData({forOther: forOtherTabJson}));
        setCard(numbers);
    };
    return (
        <Layout>
            {isCheckoutShow ? <OrderSummary processCheckout={(data) => giftCardCheckout(data)} hideCheckoutPage={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                setIsCheckoutShow(false);
            }} order={orderJson} onClickBack={onClickBack} otherFormData={otherFormData} showForOthersPopup={() => setShowForOthersPopup(true)} /> :
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <div className='d-flex mb-4 mt-3 justify-content-between align-items-center flex-wrap'>
                                <div className='inner_title d-flex align-items-start'>
                                    <div className='progress-circle over50 p50'>
                                        <span>1 of 2</span>
                                        <div className='left-half-clipper'>
                                            <div className='first50-bar'></div>
                                            <div className='value-bar'></div>
                                        </div>
                                    </div>
                                    <div className='main_title'>
                                        <h2>Add Gift Card</h2>
                                        <p>Select an amount</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {requestPageType !== 'add_value' ?
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className={moSwitch? 'mo_switch' : 'mo_switch active'}>
                                    <small onClick={mySelfHandler}>Myself</small>
                                    <small onClick={otherHandler}>For Others</small>
                                </div>
                            </div> : null}
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tab-content' id='pills-tabContent'>
                                <div className={`tab-pane fade ${giftCardTabs.isForMySelf ? 'show active' : {}}`} id='pills-myself' role='tabpanel' aria-labelledby='pills-myself-tab'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='add_amount_wrap'>
                                                <h5>Select an Amount</h5>
                                                <div className='add_amount gift_card_amount'>
                                                    {isGiftCardAmountLoading ?
                                                        <div className='amount_btn_grp'>
                                                            { commonTableLoader(5, 'skeletonAddGiftCardAmounts') }
                                                        </div> :
                                                        giftCardAmounts == null && giftCardAmounts.length == 0 ? <h3>No gift card found.</h3> :
                                                            defaultGCInputData != null ? <div className='other_amount_input input-group mb-3'>
                                                                <span className='input-group-text'>$</span>
                                                                <input pattern="[0-9]*" value={cardAmount} onClick={(event) => onCardAmountInputFocus(event, defaultGCInputData)} onChange={(event) => onCardAmountInputChange(event, defaultGCInputData)} maxLength='9' min="0" max="10000" type='text' className='form-control form-control-lg' aria-label='Amount (to the nearest dollar)' placeholder='Type amount here' />
                                                            </div> : '' }
                                                    <div className='amount_btn_grp gift_card_btn'>
                                                        {giftCardAmounts.map((value, i) => {
                                                            return defaultGCInputData != null && defaultGCInputData.surcharge_ID == value.surcharge_ID ? '' : <button className={`${selectedGCAmount.SurchargeId == value.surcharge_ID ? 'active' : ''}`} type='button' key={i} onClick={() => onSelectGCAmount(value)}>Gift Card<br /><span>{currencyFormatWithoutSymbol(value.dblAmount)}</span></button>;
                                                        })}
                                                        {isGiftCardAmountLoading == false&&giftCardAmounts.length === 0 ?
                                                            <p className='alert alert-info text-center w-100'>Gift Cards not available.</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='order_summ_wrap'>
                                                <h5>Gift Card Preview</h5>
                                                <GiftCard amount={selectedGCAmount.Amount} brandLogo={giftCardImg===null || giftCardImg==='' || giftCardImg===undefined||(/(http(s?)):\/\//i.test(giftCardImg))===false ?GCLogo:giftCardImg}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${giftCardTabs.isForMySelf ? {} : 'show active'}`} id='pills-others' role='tabpanel' aria-labelledby='pills-others-tab'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='add_amount_wrap'>
                                                <h5>Select an Amount</h5>
                                                <div className='add_amount gift_card_amount'>
                                                    {isGiftCardAmountLoading ?
                                                        <div className='amount_btn_grp'>
                                                            {commonTableLoader(5, 'skeletonAddGiftCardAmounts')}
                                                        </div> :
                                                        giftCardAmounts == null && giftCardAmounts.length == 0 ? <h3>No gift card found.</h3> :
                                                            defaultGCInputData != null ? <div className='other_amount_input input-group mb-3'>
                                                                <span className='input-group-text'>$</span>
                                                                <input pattern="[0-9]*" value={cardAmount} onClick={(event) => onCardAmountInputFocus(event, defaultGCInputData)} onChange={(event) => onCardAmountInputChange(event, defaultGCInputData)} maxLength='9' min="0" max="10000" type='text' className='form-control form-control-lg' aria-label='Amount (to the nearest dollar)' placeholder='Type amount here' />
                                                            </div> : '' }
                                                    <div className='amount_btn_grp gift_card_btn'>
                                                        {giftCardAmounts.map((value, i) => {
                                                            return defaultGCInputData != null && defaultGCInputData.surcharge_ID == value.surcharge_ID ? '' : <button className={`${selectedGCAmount.SurchargeId == value.surcharge_ID ? 'active' : ''}`} type='button' key={i} onClick={() => onSelectGCAmount(value)}>Gift Card<br /><span>{currencyFormatWithoutSymbol(value.dblAmount)}</span></button>;
                                                        })}
                                                        {isGiftCardAmountLoading == false&&giftCardAmounts.length === 0 ?
                                                            <p className='alert alert-info text-center w-100'>Gift Cards not available.</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='order_summ_wrap'>
                                                <h5>Gift Card Preview</h5>
                                                <GiftCard amount={selectedGCAmount.Amount} brandLogo={giftCardImg===null || giftCardImg==='' || giftCardImg===undefined||(/(http(s?)):\/\//i.test(giftCardImg))===false ?GCLogo:giftCardImg}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-end mt-3 pt-3 border-top'>
                                <div className='btn_grps d-flex flex-wrap'>
                                    <button onClick={()=>navigate(-1)} className='btn btn-secondary icon_btn me-2'><FiArrowLeft/> Back</button>
                                    <button onClick={() => {
                                        if (isForMySelf) {
                                            continueToAddGCCheckout();
                                        } else {
                                            if (selectedGCAmount.SurchargeId == undefined) {
                                                if (!toast.isActive(toastId.current)) {
                                                    toastId.current = toast.error('Please select gift card first.');
                                                }
                                                return false;
                                            } else {
                                                setShowForOthersPopup(true);
                                            }
                                        }
                                    }} className='btn btn-primary icon_btn cursor_pointer' {...(isProcessCheckout ? {disabled: 'disabled'} : {})} >{isProcessCheckout && <span className="spinner-border text-light btnLoader"></span>}Continue <span className='icon arrow_right_white'></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='container'>
                <div className='row'>
                    <div className={`modal fade cw_modal add_info_pop ${showForOthersPopup ? 'show d-block' : '' }`} id='updatePersonalInfo' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='updatePersonalInfoLabel' aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered '>
                            <div className='modal-content personalinfo  p-4'>
                                <form action='#' onSubmit={formik.handleSubmit}>
                                    <div className='row g-3'>
                                        <div className='col-12'><h5 className='mb-4'>Recipient Information</h5></div>
                                        <div className='col-12 col-sm-6 col-md-6'>
                                            <div className='input_wrap form-floating'>
                                                <input type='text' id='fName' className='form-control form-control-lg mb-1' placeholder='First Name'
                                                    name="firstname"
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        setFirstName(event.target.value);
                                                        forOtherTabJson.FirstName= event.target.value;
                                                        dispatch(setformOtherData({forOther: forOtherTabJson}));
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={firstName}
                                                />
                                                <label htmlFor='fName' className='form-label'><FiUser/> First Name <sup className='text-danger'>*</sup></label>
                                                {formik.touched.firstname && formik.errors.firstname ? (
                                                    <small className="text-danger">{formik.errors.firstname}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6'>
                                            <div className='input_wrap form-floating'>
                                                <input type='text' id='lName' className='form-control form-control-lg mb-1' placeholder='Last Name'
                                                    name="lastname"
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        setLastName(event.target.value);
                                                        forOtherTabJson.LastName= event.target.value;
                                                        dispatch(setformOtherData({forOther: forOtherTabJson}));
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={lastName}
                                                />
                                                <label htmlFor='lName' className='form-label'><FiUser/> Last Name <sup className='text-danger'>*</sup></label>
                                                {formik.touched.lastname && formik.errors.lastname ? (
                                                    <small className="text-danger">{formik.errors.lastname}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6'>
                                            <div className='input_wrap form-floating'>
                                                {/* <InputMask
                                                    className="form-control form-control-lg mb-1"
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        setPhoneNumber(event.target.value);
                                                        forOtherTabJson.PhoneNumber= event.target.value;
                                                        dispatch(setformOtherData({forOther: forOtherTabJson}));
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    mask="(999) 999-9999"
                                                    maskChar=""
                                                    value={phone}
                                                    name="phone"
                                                    placeholder="(000) 000-0000"
                                                /> */}
                                                <input type="text" className="form-control form-control-lg"
                                                    name="phone"
                                                    // onChange={phoneMask}
                                                    onChange={(event) => {
                                                        // formik.handleChange(event);
                                                        // setPhoneNumber(event.target.value);
                                                        phoneMask();
                                                    }}
                                                    ref={inputCard}
                                                    // value={phoneFormik.values.phone}
                                                    placeholder="(000) 000-0000"
                                                />
                                                <label htmlFor='phoneNumber' className='form-label'><FiSmartphone/> Cell Phone <sup className='text-danger'>*</sup></label>
                                                {/* {formik.touched.phone && formik.errors.phone ? (
                                                    <small className="text-danger">{formik.errors.phone}</small>
                                                ) : null} */}
                                                <small className="text-danger validationError">{err}</small>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6'>
                                            <div className='input_wrap form-floating'>
                                                <input type='email' id='repEmail' className='form-control form-control-lg mb-1' placeholder='Email'
                                                    name="email"
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        setEmail(event.target.value);
                                                        forOtherTabJson.RecipientEmail= event.target.value;
                                                        dispatch(setformOtherData({forOther: forOtherTabJson}));
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={email}
                                                />
                                                <label htmlFor='repEmail' className='form-label'><FiMail/> Recipient Email <sup className='text-danger'>*</sup></label>
                                                {formik.touched.email && formik.errors.email ? (
                                                    <small className="text-danger">{formik.errors.email}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='input_wrap your_message form-floating'>
                                                <textarea type='text' name='message' rows="8" cols="50" id='message' className='form-control form-control-lg mb-1' placeholder='Message'
                                                    value={message}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        setMessage(event.target.value);
                                                        forOtherTabJson.Message= event.target.value;
                                                        dispatch(setformOtherData({forOther: forOtherTabJson}));
                                                    }}/>
                                                <label htmlFor='message' className='form-label'><FiEdit2/> Your Message <sup className='text-danger'>*</sup></label>
                                                {formik.touched.message && formik.errors.message ? (
                                                    <small className="text-danger">{formik.errors.message}</small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='modal-footer justify-content-center'>
                                            <button type='submit' className='btn btn-primary' disabled = {!formik.isValid || phonError} data-bs-dismiss="offcanvas" id="btnAlertYes"> <span id="spanLoader" className="spinner-border text-light btnLoader" style={{display: 'none'}} />Continue</button>
                                            <button type='button' className='btn btn-secondary' onClick={() => setShowForOthersPopup(false)} data-bs-dismiss='modal'>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {showForOthersPopup ?
                        <div className="modal-backdrop fade show"></div> : null}
                </div>
            </div>
        </Layout>
    );
};

export default AddGiftCard;
