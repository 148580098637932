import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FiArrowLeft} from 'react-icons/fi';
import {toast} from 'react-toastify';
// Components
import GiftCard from './GiftCard';
import Layout from '@components/Layout';
// Reducers
import {setIsForMySelf} from '@store/reducers/auth-reducer';
import {setSelectedGiftCardForViewReceipt} from '@store/reducers/gift-card-reducer';
// Utils
import FullScreenLoader from '@utils/loaders/FullScreenLoader';

import {convertUTCDateToLocalDate, dateFormatter} from '@utils/date-formatter';
// Services
import {getGiftCardReceipt} from '@services/gift-card-service';
// Assets
import './GiftCard.css';
import GCLogo from '@assets/images/logo_white.png';

const GFDetails = (props) => {
    toast.configure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cardDetail = useSelector((state) => state.giftCard.selectedGiftCard);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [isLoading, setIsLoading] = useState(false);
    const giftCardLogo = localStorage.getItem('customizePage') == null | undefined ? {} : JSON.parse(localStorage.getItem('customizePage')).portalCustomizationsList.find((value) => value.sName === 'site_card_logo');
    const giftCardImg = giftCardLogo.sValue;

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    const viewReceipt = async () => {
        setIsLoading(true);
        const response = await getGiftCardReceipt(userInfo.accessToken, userInfo.customerIdentityID, cardDetail.giftCardGuid);
        if (response.status === 200) {
            const receiptData = response.data;
            receiptData.pageBackUrl = '/gift-card-details';
            dispatch(setSelectedGiftCardForViewReceipt({selectedGiftCardForViewReceipt: receiptData}));
            setIsLoading(false);
            return navigate('/wallet-barcode', {state: {from: 'giftCardDetails'}});
        } else {
            toast.error('Something went wrong.', {autoClose: 3000});
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            {isLoading ? <FullScreenLoader /> : ''}
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Link to='/gift_card' className='btn btn-outline-secondary icon_btn'><FiArrowLeft/> Back</Link>
                    </div>
                    <div className='col-12'>
                        <div className='d-flex mb-4 mt-3 justify-content-between align-items-center  flex-wrap'>
                            <div className='main_title'>
                                <h2>Card Details</h2>
                                <p>Manage Gift card</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row gift_cd_row'>
                    <div className='col-lg-8'>
                        <div className='gcd_wrap'>
                            <div className={`wallet_card mb-2 wallet_item status_badge st_active`}>
                                <div className="wallet_card_type"><span>Gift Card</span></div>
                                <div className='giftcardinner'>
                                    <div>
                                        <h3>Gift Card - {cardDetail.sGiftCardNumber}</h3>
                                        <p>Card Ending in {(cardDetail.sGiftCardNumber).slice(- 4)}</p>
                                    </div>
                                    <div className="mt-5 wallet_card_dates">
                                        <div className="info_date">
                                            <small className='d-block mb-2'>Purchase Date:</small>
                                            <h6 className='text-dark mb-0'>{cardDetail.dtCreated == null ? '' : dateFormatter(convertUTCDateToLocalDate(cardDetail.dtCreated))}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn_grp my-3'>
                                <button onClick={() => {
                                    dispatch(setIsForMySelf({isForMySelf: true}));
                                    navigate('/add-gift-card/add_value/'+ cardDetail.giftCardGuid);
                                }} className='btn btn-primary me-2'>Add Value</button>
                                <Link to='/merge-gift-card' className='btn btn-primary me-2'>Merge Balance</Link>
                                <button type='button' className='btn btn-primary' onClick={() => viewReceipt()}>View Receipt</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <GiftCard amount={cardDetail.dblBalance} brandLogo={giftCardImg===null || giftCardImg==='' || giftCardImg===undefined||(/(http(s?)):\/\//i.test(giftCardImg))===false ?GCLogo:giftCardImg} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default GFDetails;
