import {useFormik} from 'formik';
export const initFormik = (initialValues, validationSchema, callback=null, reinitialize=false) => {
    const formik = useFormik({
        initialValues,
        enableReinitialize: reinitialize,
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            callback(values, resetForm);
        }
    });
    return formik;
};
