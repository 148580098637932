/* eslint-disable no-unused-vars */
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
// CSS
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/media.css';
// const url = window.location.href;
// const test = url.includes('employee');
// const empMainCssUrl = test === true && import('./assets/employee-assets/css/main.css');
// const empMediaCssUrl = test === true && import('./assets/employee-assets/css/media.css');
// const mainCssUrl = !test === true && import('./assets/css/main.css');
// const mediaCssUrl = !test === true && import('./assets/css/media.css');
// Containers
import Dashboard from './containers/Dashboard/Dashboard';
import ErrorPage from './containers/ErrorPage';
import GiftCard from './containers/GiftCard';
import Locations from './containers/Location/Location';
import Login from './containers/Login';
import ManageAccount from './containers/ManageAccount/ManageAccount';
import Signup from './containers/Signup';
import Verification from './containers/Verification';
import Wallet from './containers/Wallet/Wallet';
import WashManager from './containers/WashManager';
// Components
import AddGiftCard from './components/gift-card/AddGiftCard';
import CancelMembership from './components/manage-account/CancelMembership';
import GiftCardDetails from './components/gift-card/GiftCardDetails';
import MergeGiftCard from './components/gift-card/MergeGiftCard';
import OrderHistory from './components/manage-account/OrderHistory';
import OrderSuccess from './components/order/OrderSuccess';
import PaymentOptions from './components/manage-account/PaymentOptions';
import Policies from './components/manage-account/Policies';
import WalletBarcode from './components/order/WalletBarcode';
// Utils
// import CustomerRecord from './containers/CustomerRecord';
import PrivateRoute from './utils/routes/PrivateRoute';
import UnlimitedPlans from './containers/UnlimitedPlans/UnlimitedPlans';
import WalletFilter from './containers/Wallet/WalletFilter';
import {useEffect} from 'react';


const App = () => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    if (isLoggedIn) {
        history.pushState(null, null, location.href);
        window.onpopstate = function(event) {
            history.go(1);
        };
    }
    useEffect(()=>{
        // document.title = 'new car wash';
    }, []);
    return (
        <Routes>
            <Route path="/verify-otp" element={<Verification />} />
            {/* <Route path="/customer-record" element={ <CustomerRecord />} /> */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/error-page" element={<ErrorPage />} />
            <Route path="/dashboard" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />
            <Route path="/wash_services" element={
                <PrivateRoute>
                    <WashManager washInfo={{title: 'Buy a Wash Service', subTitle: 'Select a Wash Service'}}/>
                </PrivateRoute>
            } />
            <Route path="/prepaid_wash_service" element={
                <PrivateRoute>
                    <WashManager washInfo={{title: 'Buy a Prepaid Wash Plan', subTitle: 'Select a Prepaid Wash Service'}}/>
                </PrivateRoute>
            } />
            <Route path="/detailing_services" element={
                <PrivateRoute>
                    <WashManager washInfo={{title: 'Buy a Detailing Service', subTitle: 'Select a Detailing Service'}}/>
                </PrivateRoute>
            } />
            <Route path="/gift_card" element={
                <PrivateRoute>
                    <GiftCard />
                </PrivateRoute>
            } />
            <Route path="/find_location_btn" element={
                <PrivateRoute>
                    <Locations />
                </PrivateRoute>
            } />
            <Route path="/manage_account" element={
                <PrivateRoute>
                    <ManageAccount />
                </PrivateRoute>
            } />
            <Route path="/wallet" element={
                <PrivateRoute>
                    <Wallet />
                </PrivateRoute>
            } />
            <Route path="/wallet_filter" element={
                <PrivateRoute>
                    <WalletFilter />
                </PrivateRoute>
            } />
            <Route path="/wash-card" element={
                <PrivateRoute>
                    <WashManager />
                </PrivateRoute>
            } />
            <Route path="/order-history" element={
                <PrivateRoute>
                    <OrderHistory />
                </PrivateRoute>
            } />
            <Route path="/payment-options" element={
                <PrivateRoute>
                    <PaymentOptions />
                </PrivateRoute>
            } />
            <Route path="/terms" element={
                <PrivateRoute>
                    <Policies id={0} />
                </PrivateRoute>
            } />
            <Route path="/policies" element={
                <PrivateRoute>
                    <Policies id={1} />
                </PrivateRoute>
            } />
            <Route path="/terms-policies" element={
                <PrivateRoute>
                    <Policies id={0} id1={1} />
                </PrivateRoute>
            } />
            <Route path="/cancel-membership" element={
                <PrivateRoute>
                    <CancelMembership />
                </PrivateRoute>
            } />
            <Route path="/add-gift-card/:type/:gcGuid" element={
                <PrivateRoute>
                    <AddGiftCard />
                </PrivateRoute>
            } />
            <Route path="/add-gift-card" element={
                <PrivateRoute>
                    <AddGiftCard />
                </PrivateRoute>
            } />
            <Route path="/gift-card-details" element={
                <PrivateRoute>
                    <GiftCardDetails />
                </PrivateRoute>
            } />
            <Route path="/merge-gift-card" element={
                <PrivateRoute>
                    <MergeGiftCard />
                </PrivateRoute>
            } />
            <Route path="/order-success" element={
                <PrivateRoute>
                    <OrderSuccess />
                </PrivateRoute>
            } />
            <Route path="/wallet-barcode" element={
                <PrivateRoute>
                    <WalletBarcode />
                </PrivateRoute>
            } />
            <Route path="/unlimited_plans" element={
                <PrivateRoute>
                    <UnlimitedPlans />
                </PrivateRoute>
            } />
            <Route path="/" element={isLoggedIn ?
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute> : <Login />} />
        </Routes>
    );
};

export default App;
