import React, {useState} from 'react';
import EmployeeSelectPlan from './EmployeeSelectPlan';

const EmployeeDiscription = (props) =>{
    const [SubscriptionData, setSubscriptionData] = useState([]);
    const [loading, setLoading] = useState(false);

    const callBackEmployee = (data) => {
        setSubscriptionData(data);
    };
    const handleClickNext= () => {
        setLoading(true);
        props.handleSubscriptionNext(SubscriptionData);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };
    return (
        <React.Fragment>
            <div className='form_wrap step_two animate_next' id='stepTwo'>
                <div className='form_sec'>
                    <div className='form_title'>
                        <h2>Subscription</h2>
                    </div>
                    <div className='plan_selection_tabs'>
                        <ul className='nav nav-pills mb-3 text-center' id='pills-tab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                                <button className='nav-link active' id='pills-give-tab' data-bs-toggle='pill' data-bs-target='#pills-give' type='button' role='tab' aria-controls='pills-give' aria-selected='true'>Select Plan</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                            </li>
                        </ul>
                        <div className='tab-content' id='pills-tabContent'>
                            <div className='tab-pane fade' id='pills-history' role='tabpanel' aria-labelledby='pills-history-tab'>
                            </div>
                            <div className='tab-pane fade show active' id='pills-give' role='tabpanel' aria-labelledby='pills-give-tab'>
                                <EmployeeSelectPlan callBackEmployee={callBackEmployee} handleTermAndCondtion={props.handleTermAndCondtion}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btn_btm justify-content-end emp_wash_btn'>
                    {props.isLoggedIn === true ? <button type='button' className='main_btn' id='prevS1' onClick={props.handleSubscriptionCallBack}>Previous</button> : ''}
                    <button type='submit' className='main_btn' id='nextS3' disabled={loading} onClick={handleClickNext}>
                        {loading && <span className="spinner-border text-light btnLoader me-2"></span>}<span>Next</span>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeDiscription;
